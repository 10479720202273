import React from 'react';
import { useIndexedDBStore } from 'use-indexeddb';
import { Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Navbar, Loading, Scrolltop } from '../components';
import {
	Barrasearch,
	Listdefinition,
	dictionary,
	dictionaryItem,
} from '../components/Dictionary-Conten';

import { useStyles, usePeticiones } from '../lib';

export interface PagedictionaryProps {
	modeTheme?: boolean;
	changeTheme?: any;
}

Pagedictionary.defaultProps = {
	modeTheme: null,
	changeTheme: undefined,
};

function Pagedictionary(props: PagedictionaryProps): JSX.Element {
	const classes = useStyles();
	const { modeTheme, changeTheme } = props;
	const { peticion } = usePeticiones();
	const { getOneByIndex, update } = useIndexedDBStore('Content');

	const [contenido, setContenido] = React.useState<dictionary>();

	const [diccionary, setDiccionary] = React.useState<number>(0);
	const [search, setSearch] = React.useState<string>('');
	const [filtro, setFiltro] = React.useState<dictionaryItem[]>();
	const [page, setPage] = React.useState<number>(0);
	const [mount, setMount] = React.useState<boolean>(true);

	React.useEffect(() => {
		const body = {};
		const fetchdata = async (info: any) => {
			const result = await peticion({
				accion: 'dictionary/',
				formData: body,
			});
			if (info !== JSON.stringify(result.data)) {
				setContenido(result.data);
				update({
					id: 1,
					keysearch: 'dictionary',
					contenido: JSON.stringify(result.data),
				}).then(event => {});
			}
		};

		if (mount) {
			getOneByIndex('keysearch', 'dictionary').then((infoDB: any) => {
				if (infoDB) {
					setContenido(JSON.parse(infoDB.contenido));
				}
				fetchdata(infoDB?.contenido ?? infoDB);
			});
			setMount(false);
		}
	}, [mount, peticion, contenido, update, getOneByIndex]);

	const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	const handleClick = () => {
		if (contenido) {
			const result =
				diccionary === 0
					? contenido.Spanish.filter(definicion => definicion.PALABRA.startsWith(search))
					: contenido.English.filter(definicion => definicion.PALABRA.startsWith(search));
			setFiltro(result.length > 0 ? result : undefined);
			setPage(result.length > 0 ? 1 : 0);
		}
	};

	return (
		<>
			<Navbar modeTheme={modeTheme} changeTheme={changeTheme} />
			{!contenido ? (
				<Loading page='page' />
			) : (
				<>
					<Container maxWidth='lg' className={classes.PageDictionary}>
						<Barrasearch
							diccionary={diccionary}
							setDiccionary={setDiccionary}
							search={search}
							setSearch={setSearch}
							handleClick={handleClick}
						/>
						<Listdefinition filtro={filtro} page={page} />
						{filtro && page > 0 ? (
							<>
								<Pagination
									color='primary'
									count={Math.round(filtro.length / 10)}
									defaultPage={6}
									page={page}
									boundaryCount={2}
									onChange={handlePage}
									size='large'
								/>
							</>
						) : null}
					</Container>
				</>
			)}
			<Scrolltop />
		</>
	);
}

export { Pagedictionary };
export default Pagedictionary;
