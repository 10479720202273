import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {
	Card,
	CardContent,
	CardHeader,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { AccessTime, Today } from '@material-ui/icons';
import { usePeticiones } from '../../lib';
import Iconsvg from '../Iconsvg';
import DivSanitized from '../DivSanitized';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},

	title: { fontSize: '15px !important' },
}));

export interface IVirtualClass {
	idprograma: string;
	name: string;
	date: string;
	unit: string;
	lesson: string;
	type: string;
	google?: string;
	outlook?: string;
	office365?: string;
	yahoo?: string;
	ics?: string;
}
interface PropsList {
	data: IVirtualClass[];
	type: 'past' | 'today' | 'unit';
	isFull?: boolean;
	calendarLink?: boolean;
}

export function PinnedSubheaderList({ data, type, isFull, calendarLink }: PropsList) {
	return (
		<List style={{ maxHeight: isFull ? '100%' : '200px', overflow: 'auto' }}>
			{data.map((item, index) => (
				<ListItem button key={`item-${index * 3}-${type}`}>
					<ListItemText
						primary={
							<div style={{ display: 'flex ', fontSize: '13px' }}>
								<div>
									<div style={{ fontWeight: 'bold' }}>
										{item.unit ? `Unit ${item.unit}` : null}
										{item.lesson ? <> Lesson {item.lesson} </> : null}
										<DivSanitized component='span' text={` ${item.name}`} />
									</div>
									<Typography
										color='primary'
										style={{
											fontSize: '13px',
											display: 'flex',
											alignItems: 'center',
											minWidth: '300px',
										}}
									>
										<div
											style={{
												fontSize: '13px',
												display: 'flex',
												alignItems: 'center',
												marginRight: '20px',
											}}
										>
											<Today />
											<Moment format='yyyy MM DD'>{item.date}</Moment>
										</div>
										<div
											style={{
												fontSize: '13px',
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<AccessTime />
											<Moment format='H:mm'>{item.date}</Moment>
										</div>
									</Typography>
									<div>
										{calendarLink ? (
											<div style={{ display: 'flex ', fontSize: '13px' }}>
												<a aria-label='a' href={item.google} target='_blank' rel='noreferrer'>
													<Iconsvg name='google-calendar' fontSize='small' />
												</a>{' '}
												<a aria-label='a' href={item.outlook} target='_blank' rel='noreferrer'>
													<Iconsvg name='outlook' fontSize='small' />
												</a>{' '}
												<a aria-label='a' href={item.office365} target='_blank' rel='noreferrer'>
													<Iconsvg name='office-365' fontSize='small' />
												</a>{' '}
												<a aria-label='a' href={item.yahoo} target='_blank' rel='noreferrer'>
													<Iconsvg name='yahoo' fontSize='small' />
												</a>{' '}
												<a aria-label='a' href={item.ics} target='_blank' rel='noreferrer'>
													ics
												</a>{' '}
											</div>
										) : null}
									</div>
								</div>
							</div>
						}
					/>
				</ListItem>
			))}
		</List>
	);
}
PinnedSubheaderList.defaultProps = {
	isFull: false,
	calendarLink: null,
};
export function CurrentUnitClasses() {
	const classes = useStyles();
	const { peticion } = usePeticiones();
	const [mount, setMount] = useState<boolean>(false);
	const [virtualClasses, SetVirtualClasses] = useState<IVirtualClass[]>([]);

	useEffect(() => {
		const getData = async () => {
			const { data } = await peticion({ formData: {}, accion: 'home/unit-virtual-class' });
			SetVirtualClasses(data);
		};
		if (!mount) {
			getData();
			setMount(true);
		}
	}, [mount, peticion]);
	return (
		<Card style={{ height: '100%' }}>
			<CardHeader
				className={classes.header}
				title={<b className={classes.title}>Current Unit Classes</b>}
			/>

			<CardContent>
				<PinnedSubheaderList data={virtualClasses} type='unit' />
			</CardContent>
		</Card>
	);
}
export default CurrentUnitClasses;
