import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

import {
	Button,
	Grid,
	Theme,
	makeStyles,
	Typography,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import { CheckCircle, Cancel as CancelIcon } from '@material-ui/icons';
import CheckButtons from '../Check-Buttons';
import ImageExercise from '../Image-Exercise';
import AudioExercise from '../audio-exercise';
import DivSanitized from '../../../DivSanitized';

import { ReactiveProps } from '../../Activity-interface';

export interface WordSenteceOption {
	text: string;
	id: number;
	isCorrect: boolean | null;
}
const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},

	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},

	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},
	rootLine: {
		marginTop: theme.spacing(1),
		borderColor: theme.palette.primary.main,
		'& .MuiInputBase-root': {
			fontSize: 'inherit',
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
			borderBottomStyle: 'dotted',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
			borderColor: theme.palette.primary.main,
			borderBottomStyle: 'inset',
		},
		'& .MuiInputBase-root:not(.Mui-disabled)::before': {
			borderBottomStyle: 'inset',
		},
	},
	correct: {
		'& .MuiInputBase-root,.MuiInputBase-root p': {
			color: theme.palette.success.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
		},
	},
	incorrect: {
		'& .MuiInputBase-root, .MuiInputBase-root p': {
			color: theme.palette.error.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.error.main}`,
		},
	},
	completeSentence: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		backgroundColor: `rgba(0, 0, 0, 0.05)`,
		'&.correct': {
			backgroundColor: '#4caf500d',
		},
		'&.incorrect': {
			backgroundColor: '#f443360d',
		},
		'& .question': {
			padding: theme.spacing(1),
			display: 'flex',
			alignItems: 'center',
		},
	},

	completeSentenceButton: {
		fontSize: 'inherit',
		fontFamily: 'inherit',
		marginRight: '4px',
		textTransform: 'none',
		'&.Mui-disabled': {
			color: theme.palette.grey[500],
			border: `1px solid ${theme.palette.grey[500]}`,
		},
	},

	buttonContainer: {
		borderBottom: `2px solid ${theme.palette.grey[500]}`,
		flex: '1',
		padding: theme.spacing(0.25),
		paddingBottom: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
		'& .MuiButtonBase-root, .MuiButton-root': {
			fontSize: 'inherit',
			fontFamily: 'inherit',
			margin: theme.spacing(0.25),
			textTransform: 'none',
		},
		'&.correct': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
			'& .Mui-disabled': {
				color: theme.palette.success.main,
				border: `1px solid ${theme.palette.success.main}`,
			},
		},
		'&.incorrect': {
			borderBottom: `2px solid ${theme.palette.error.light}`,
			'& .Mui-disabled': {
				color: theme.palette.error.light,
				border: `1px solid ${theme.palette.error.light}`,
			},
		},
		'& .normal': {
			color: `${theme.palette.text.primary}`,
			border: `2px solid transparent`,
		},
	},

	buttonContainerDrag: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexWrap: 'wrap',
		marginTop: theme.spacing(1),
		'& .MuiButtonBase-root, .MuiButton-root': {
			fontSize: 'inherit',
			fontFamily: 'inherit',
			color: theme.palette.primary.main,
			margin: theme.spacing(0.5),
			textTransform: 'none',
			border: 'unset',
			borderBottom: '1px solid',
			borderBottomStyle: 'dotted',
			borderBottomColor: theme.palette.primary.main,
		},
	},

	answer: {
		marginTop: theme.spacing(1),
	},
	adornment: {
		fontSize: '2rem',
	},
}));

function WriteCompleteSentence(props: ReactiveProps) {
	const useStyle = useStyles();

	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;

	const { answer, reactives, question, img, media, localMedia, time } = reactive;
	const { typeComponent } = typeActivity;
	const [userAnswer, setUserAnswer] = useState('');

	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [reactiveWords, setReactiveWords] = useState<WordSenteceOption[]>([]);
	const questionClean = question?.replace('|.', '').replace('|?', '');
	const [mount, setMount] = useState(false);

	const checkAnswer = () => {
		const correct = answer.includes(userAnswer);
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
	};

	const nextAnswer = () => {
		onNext();

		let ua = '<div>';
		reactiveWords.reduce((array, item) => {
			ua = `${ua}<u class='sample' >${item.text}</u> `;
			return array;
		}, []);
		ua = `${ua}</div>`;
		ua = `<div><u>${userAnswer}</></div>${ua}`;

		onCheck(isCorrect ?? false, [ua], question, media, localMedia, img, activaAnswer);
	};

	let endChar = '';
	let answerActivity = '';
	if (typeComponent === 'signo') {
		const chars = question.split('|');
		endChar = `${chars[1]}`;
	} else if (typeComponent === 'question') {
		endChar = '?';
		answerActivity = question.replace('|.', '').replace('|', '');
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = `${event.target.value}`;
		setUserAnswer(value);
		if (value !== '') {
			setCheckDisabled(false);
		}
	};

	useEffect(() => {
		const createButtonWords = () => {
			let i = 0;
			const buttonWords = reactives.reduce((array: WordSenteceOption[], word: string) => {
				array.push({ text: word, id: i, isCorrect: null });
				i += 1;
				return array;
			}, []);
			setReactiveWords(buttonWords);
		};
		if (!mount) {
			createButtonWords();
			setMount(true);
		}
	}, [mount, setMount, reactives, question, typeComponent]);
	return (
		<>
			<div className={clsx(useStyle.reactive)}>
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>

				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />

				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={useStyle.example}>
						Example:
					</Typography>
				) : null}
				<Grid container justifyContent='center'>
					<Grid item xs={11}>
						{typeComponent === 'signo' && question && questionClean ? (
							<Typography component='div' className={useStyle.question}>
								<DivSanitized text={question?.replace('|.', '').replace('|?', '')} />
							</Typography>
						) : null}
						{typeComponent !== 'question' && typeComponent !== 'signo' && question ? (
							<Typography component='div' className={useStyle.question}>
								<DivSanitized text={question?.replace('|', '')} />
							</Typography>
						) : null}
					</Grid>

					<Grid
						item
						xs={11}
						className={clsx(useStyle.completeSentence, {
							correct: isCorrect,
							incorrect: !isCorrect && isCorrect !== null,
						})}
					>
						<TextField
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										{isCorrect || isExample ? <CheckCircle /> : null}
										{!isCorrect && isCorrect !== null ? <CancelIcon /> : null}
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position='end' className={useStyle.adornment}>
										<b>{endChar}</b>
									</InputAdornment>
								),
							}}
							autoFocus
							fullWidth
							variant='standard'
							className={clsx(useStyle.rootLine, {
								[useStyle.correct]: isCorrect,
								[useStyle.incorrect]: !isCorrect && isCorrect !== null,
							})}
							disabled={isExample || isCorrect !== null}
							defaultValue={isExample ? answer[0] : userAnswer}
							type='text'
							onChange={handleChange}
						/>
					</Grid>
				</Grid>

				<Grid container justifyContent='center'>
					<Grid item sm={11} className={useStyle.buttonContainerDrag}>
						{reactiveWords.map((reactiveWord: WordSenteceOption) => (
							<Button disabled key={uuid()} color='primary' variant='outlined' size='small'>
								{reactiveWord.text}
							</Button>
						))}
					</Grid>
				</Grid>

				<DivSanitized className={useStyle.answer} text={answerActivity} />
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { WriteCompleteSentence };
export default WriteCompleteSentence;
