import React from 'react';
import { Avatar, Typography } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons/';

type aling = 'inherit' | 'center' | 'left' | 'right' | 'justify';
type IconName = 'LockOutlined' | 'MailOutlineOutlined' | 'AccountCircleOutlined';

export interface TituloLoginProps {
	titulo: string;
	iconName: IconName;
	align: aling;
	text?: string;
}

function TituloLogin(props: TituloLoginProps): JSX.Element {
	const { titulo, iconName, align, text } = props;
	const Icon = React.createElement(MuiIcons[iconName]);

	return (
		<>
			<Avatar>{Icon}</Avatar>
			<Typography gutterBottom component='span' variant='h6' align={align}>
				{titulo}
			</Typography>
			<Typography gutterBottom component='span' variant='body1' align={align}>
				{text}
			</Typography>
		</>
	);
}

TituloLogin.defaultProps = {
	text: '',
};

export { TituloLogin };
export default TituloLogin;
