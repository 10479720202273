import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, makeStyles, Theme } from '@material-ui/core';
import { usePeticiones } from '../../lib';
import { IVirtualClass, PinnedSubheaderList } from './CurrentUnitClasses';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},

	title: { fontSize: '15px !important' },
}));

export function PastClasses() {
	const classes = useStyles();
	const { peticion } = usePeticiones();
	const [mount, setMount] = useState<boolean>(false);
	const [virtualClasses, SetVirtualClasses] = useState<IVirtualClass[]>([]);

	useEffect(() => {
		const getData = async () => {
			const { data } = await peticion({ formData: {}, accion: 'home/lastest-virtual-class' });
			SetVirtualClasses(data);
		};
		if (!mount) {
			getData();
			setMount(true);
		}
	}, [mount, peticion]);
	return (
		<Card style={{ height: '100%' }}>
			<CardHeader
				className={classes.header}
				title={<b className={classes.title}>My Past Classes</b>}
			/>

			<CardContent>
				<PinnedSubheaderList data={virtualClasses} type='past' />
			</CardContent>
		</Card>
	);
}
export default PastClasses;
