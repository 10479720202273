import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

import { Button, Grid, Theme, makeStyles, Typography } from '@material-ui/core';
import CheckButtons from '../Check-Buttons';
import ImageExercise from '../Image-Exercise';
import AudioExercise from '../audio-exercise';
import DivSanitized from '../../../DivSanitized';

import { ReactiveProps } from '../../Activity-interface';

export interface WordSenteceOption {
	text: string;
	id: number;
	isCorrect: boolean | null;
}

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},

	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},

	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},

	completeSentence: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		backgroundColor: `rgba(0, 0, 0, 0.05)`,
		'& .question': {
			padding: theme.spacing(1),
			display: 'flex',
			alignItems: 'center',
		},
	},

	completeSentenceButton: {
		fontSize: 'inherit',
		fontFamily: 'inherit',
		marginRight: '4px',
		textTransform: 'none',
		'&.Mui-disabled': {
			color: theme.palette.grey[500],
			border: `1px solid ${theme.palette.grey[500]}`,
		},
	},

	buttonContainer: {
		borderBottom: `2px solid ${theme.palette.grey[500]}`,
		flex: '1',
		padding: theme.spacing(0.25),
		paddingBottom: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
		'& .MuiButtonBase-root, .MuiButton-root': {
			fontSize: 'inherit',
			fontFamily: 'inherit',
			margin: theme.spacing(0.25),
			textTransform: 'none',
		},
		'&.correct': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
			'& .Mui-disabled': {
				color: theme.palette.success.main,
				border: `1px solid ${theme.palette.success.main}`,
			},
		},
		'&.incorrect': {
			borderBottom: `2px solid ${theme.palette.error.light}`,
			'& .Mui-disabled': {
				color: theme.palette.error.light,
				border: `1px solid ${theme.palette.error.light}`,
			},
		},
		'& .normal': {
			color: `${theme.palette.text.primary}`,
			border: `2px solid transparent`,
		},
	},

	buttonContainerDrag: {
		display: 'flex',
		flexFlow: 'row wrap',
		flexWrap: 'wrap',
		marginTop: theme.spacing(1),
		'& .MuiButtonBase-root,& .MuiButton-root': {
			fontSize: 'inherit',
			fontFamily: 'inherit',
			margin: theme.spacing(0.5),
			textTransform: 'none',
			minHeight: '46px',
			maxWidth: '70px',
		},
	},

	emptyItemQuestion: {
		borderBottom: `2px solid ${theme.palette.grey[500]}`,
		flex: '10',
		width: '100%',
		padding: theme.spacing(0.25),
		paddingBottom: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
		'&.correct': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
			'& div': {
				color: theme.palette.success.main,
			},
		},
		'&.incorrect': {
			borderBottom: `2px solid ${theme.palette.error.light}`,
			'& div': {
				color: theme.palette.error.light,
			},
		},
		'& div': {
			margin: theme.spacing(0.25),
			minHeight: '24px',
			width: '100%',
			textAlign: 'right',
			display: 'flex',
			padding: '8px',
			justifyContent: 'flex-end',
		},
	},

	emptyItem: {
		flex: '1',
		width: '100%',
	},

	correct: {
		borderBottom: `2px solid ${theme.palette.success.main}`,
	},

	incorrect: {
		borderBottom: `2px solid ${theme.palette.error.light} `,
	},
	correctButton: {
		'&.Mui-disabled': {
			color: theme.palette.success.main,
			border: `1px solid ${theme.palette.success.main}`,
		},
	},
	incorrectButton: {
		'&.Mui-disabled': {
			color: theme.palette.error.main,
			border: `1px solid ${theme.palette.error.main}`,
		},
	},
	hiidenButton: {
		border: '3px solid',
		borderStyle: 'dashed',
		display: 'none',
	},
}));

function CompleteSentenceLetter(props: ReactiveProps) {
	const useStyle = useStyles();
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;

	const { answer, reactives, question, img, media, localMedia, time } = reactive;
	const { typeComponent } = typeActivity;
	const [userAnswer, setUserAnswer] = useState('');
	const [completeLineWords, setCompleteLineWords] = useState<string[]>([]);

	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [reactiveWords, setReactiveWords] = useState<WordSenteceOption[]>([]);
	const [selectedWords, setSelectedWords] = useState<WordSenteceOption[]>([]);
	const [mount, setMount] = useState(false);

	const checkAnswer = () => {
		const wordsAnswer = answer[0].split('');
		const arrayWords = selectedWords;
		let isTrue = true;
		let index = 0;
		let sentence = '';
		for (const word of arrayWords) {
			sentence = `${sentence} ${word.text}`;
			if (word.text !== wordsAnswer[index]) {
				word.isCorrect = false;
				isTrue = false;
			} else {
				word.isCorrect = true;
			}
			index += 1;
		}
		setUserAnswer(sentence);
		setSelectedWords(arrayWords);
		setNextDisabled(false);
		setCheckDisabled(true);
		const correct = isTrue && arrayWords.length === wordsAnswer.length;
		setIsCorrect(correct);
	};

	const nextAnswer = () => {
		onNext();
		onCheck(isCorrect ?? false, [userAnswer], question, media, localMedia, img, activaAnswer);
	};

	const exampleNoHidden = (letter: string) => answer[0].includes(letter);

	const handleSelectWord = (wordSelected: WordSenteceOption) => {
		setCheckDisabled(false);
		const sentence = selectedWords;
		sentence.push(wordSelected);
		setSelectedWords(sentence);
		const buttonWords = reactiveWords.reduce(
			(array: WordSenteceOption[], word: WordSenteceOption) => {
				const exist = sentence.includes(word);
				if (!exist) {
					array.push(word);
				}
				return array;
			},
			[]
		);
		setReactiveWords(buttonWords);
	};
	const handleUnSelectWord = (wordSelected: WordSenteceOption) => {
		const sentence = selectedWords.reduce((array: WordSenteceOption[], word: WordSenteceOption) => {
			if (wordSelected !== word) {
				array.push(word);
			}
			return array;
		}, []);
		setSelectedWords(sentence);
		const buttonWords = reactiveWords;
		buttonWords.push(wordSelected);
		setReactiveWords(buttonWords);
	};

	useEffect(() => {
		if (typeComponent === 'complete-line') {
			const completeWords = question.split('|')[0].split(' ');
			completeWords.pop();
			setCompleteLineWords(completeWords);
		}

		const createButtonWords = () => {
			let i = 0;
			const buttonWords = reactives.reduce((array: WordSenteceOption[], word: string) => {
				array.push({ text: word, id: i, isCorrect: null });
				i += 1;
				return array;
			}, []);
			setReactiveWords(buttonWords);
		};
		if (!mount) {
			createButtonWords();
			setMount(true);
		}
	}, [mount, setMount, reactives, question, typeComponent, setCompleteLineWords]);

	return (
		<>
			<div className={useStyle.reactive}>
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>

				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />

				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={useStyle.example}>
						Example:
					</Typography>
				) : null}

				<Typography component='div' className={useStyle.question}>
					<Grid container justifyContent='center'>
						<Grid item xs={11} className={useStyle.completeSentence}>
							{typeComponent === 'complete-line' ? (
								<>
									{completeLineWords.map(word => (
										<div className={useStyle.buttonContainer} key={uuid()}>
											<Button
												color='primary'
												className='normal'
												variant='outlined'
												size='small'
												disabled
											>
												{word}
											</Button>
										</div>
									))}
								</>
							) : null}
							{isExample
								? answer[0].split('').map((word: string, index: number) => (
										<div className={clsx(useStyle.buttonContainer, 'correct')} key={uuid()}>
											<Button
												disabled
												className={clsx(useStyle.completeSentenceButton, useStyle.correct)}
												color='primary'
												variant='outlined'
												size='small'
											>
												{word}
											</Button>
										</div>
								  ))
								: selectedWords?.map((word: WordSenteceOption, index: number) => (
										<div
											key={uuid()}
											className={clsx(useStyle.buttonContainer, {
												[useStyle.correct]: isCorrect,
												[useStyle.incorrect]: !isCorrect && isCorrect !== null,
											})}
										>
											<Button
												disabled={isCorrect !== null}
												className={clsx(useStyle.completeSentenceButton, {
													[useStyle.correctButton]: word.isCorrect,
													[useStyle.incorrectButton]: !word.isCorrect && word.isCorrect !== null,
												})}
												color='primary'
												variant='outlined'
												size='small'
												onClick={() => {
													if (!isExample) {
														handleUnSelectWord(word);
													}
												}}
											>
												{word.text}
											</Button>
										</div>
								  ))}
							<div
								className={clsx(useStyle.emptyItemQuestion, {
									[useStyle.correct]: isCorrect,
									[useStyle.incorrect]: !isCorrect && isCorrect !== null,
								})}
							>
								{typeComponent === 'question' ||
								(typeComponent === 'question-answer' && question) ? (
									<>
										<div>?</div>
									</>
								) : (
									<div />
								)}
							</div>
						</Grid>
						{typeComponent === 'question-answer' && question ? (
							<Grid item xs={11} className={useStyle.completeSentence}>
								<DivSanitized text={question.replace('|', '')} className='question' />
							</Grid>
						) : null}
					</Grid>
				</Typography>

				<Grid container justifyContent='center'>
					<Grid item sm={11} className={useStyle.buttonContainerDrag}>
						{reactiveWords.map((reactiveWord: WordSenteceOption, index: number) => (
							<Button
								disabled={isExample || (!isExample && !nextDisabled)}
								className={clsx(useStyle.emptyItemQuestion, {
									[useStyle.hiidenButton]: isExample && exampleNoHidden(reactiveWord.text),
								})}
								key={uuid()}
								color='primary'
								variant='contained'
								size='small'
								onClick={() => {
									if (!isExample && nextDisabled) {
										handleSelectWord(reactiveWord);
									}
								}}
							>
								{reactiveWord.text}
							</Button>
						))}
						<div className={useStyle.emptyItem} />
					</Grid>
				</Grid>
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { CompleteSentenceLetter };
export default CompleteSentenceLetter;
