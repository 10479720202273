import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';

import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Container,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { CheckCircle, FiberManualRecord } from '@material-ui/icons';
import { usePeticiones } from '../../lib';
import Iconsvg from '../Iconsvg';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},
	actions: {
		justifyContent: 'end',
		display: 'flex',
	},

	title: { fontSize: '15px !important' },
	content: { display: 'flex' },
}));

interface PropsUnitProgress {
	lesson: any[];
	icon: string;
	progress: number;
}

export function UnitProgress(props: PropsUnitProgress) {
	const { lesson, icon, progress } = props;

	return (
		<Grid container>
			<Grid item xs={6}>
				<div style={{ position: 'relative', width: '90px', height: '90px' }}>
					<CircularProgress
						style={{
							width: '90px',
							height: '90px',
							color: '#ccc',
							position: 'absolute',
							left: '0px',
							top: '0px',
						}}
						value={100}
						variant='determinate'
					/>
					<div
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Iconsvg name={icon} fontSize='small' />
					</div>
					<CircularProgress
						style={{ width: '90px', height: '90px' }}
						value={progress}
						color='primary'
						variant='determinate'
					/>
				</div>
			</Grid>
			<Grid item xs={6}>
				{lesson.map(({ name, completed }) => (
					<div key={uuid()}>
						{completed ? (
							<CheckCircle color='secondary' style={{ fontSize: '12px' }} />
						) : (
							<FiberManualRecord style={{ fontSize: '12px' }} />
						)}
						{name}
					</div>
				))}
			</Grid>
		</Grid>
	);
}
export function CourseProgress() {
	const classes = useStyles();
	const history = useHistory();
	const { peticion } = usePeticiones();
	const [mount, setMount] = useState<boolean>(false);
	const [units, setUnits] = useState<any[]>([]);
	useEffect(() => {
		const getData = async () => {
			setMount(true);
			const { data } = await peticion({ formData: {}, accion: 'kardex-unit' });
			setUnits(data);
		};

		if (!mount) {
			getData();
		}
	}, [peticion, mount]);
	return (
		<Container>
			<Typography variant='h3' color='primary'>
				Course
			</Typography>
			<Grid container spacing={2}>
				{units.map(({ text, icon, lesson, progress, link }) => (
					<Grid item xs={12} md={3} key={uuid()}>
						<Card
							style={{
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
							}}
						>
							<CardHeader
								className={classes.header}
								title={
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<b className={classes.title}>{text}</b>
										{/* <div style={{ fontSize: '12px', color: '#888' }}>
											Studying time{' '}
											<Typography
												color='primary'
												style={{ fontSize: '10px', display: 'inline-block' }}
											>
												48 h 25min
											</Typography>
										</div> */}
									</div>
								}
							/>
							<CardContent style={{ flex: 1 }}>
								<UnitProgress lesson={lesson} icon={icon} progress={progress} />
							</CardContent>
							<CardActions disableSpacing className={classes.actions}>
								<Button
									color='primary'
									variant='contained'
									onClick={() => {
										history.push(link);
									}}
								>
									Start
								</Button>
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
export default CourseProgress;
