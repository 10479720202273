import React from 'react';
import clsx from 'clsx';
import { v4 as key } from 'uuid';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Theme,
	makeStyles,
	LinearProgress,
} from '@material-ui/core';

import { Check, Cancel } from '@material-ui/icons';
import { usePeticiones, useStyleGuide } from '../../lib';

import { ContentGuide, Guide } from './Unit-interface';
import { DivSanitized } from '..';

export interface guideContentProps {
	guides: Guide[];
	activated: boolean;
	update: any;
}

const useStyles = makeStyles((theme: Theme) => ({
	showGuide: {
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		'& .MuiDialogTitle-root': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			'& h2': {
				textAlign: 'center',
			},
		},
		'& .MuiDialogContent-root': {
			'&.scrollCssV': {
				'&::-webkit-scrollbar': {
					width: '12px',
				},
				'&::-webkit-scrollbar-track': {
					background: theme.palette.grey[100],
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: theme.palette.primary.light,
					borderRadius: '20px',
					border: `3px solid ${theme.palette.grey[100]}`,
				},
			},
			'& h3': {
				color: theme.palette.primary.main,
			},
			'& li': {
				listStyle: 'none',
				'& .ej_guia': {
					color: theme.palette.primary.main,
					fontStyle: 'italic',
					fontWeight: 'bold',
				},
			},
			'& li .apuntador': {
				cursor: 'pointer',
				color: theme.palette.primary.main,
				border: `1px solid ${theme.palette.primary.main}`,
				padding: '6px 16px',
				borderRadius: '4px',
			},
			'& li .apuntador:hover': {
				backgroundColor: 'rgba(156, 39, 176, 0.04)',
			},
		},
	},
}));

interface IGuideExample {
	content: ContentGuide[];
	title: string | null;
}

interface IGuideExampleProps {
	guide: IGuideExample;
}

function GuideExample({ guide }: IGuideExampleProps) {
	const guideContent = useStyleGuide();
	const { content, title } = guide;

	return (
		<>
			{content && title ? (
				<div>
					<h3>
						<DivSanitized text={title} component='span' />
					</h3>
					{guide.content?.map(info => (
						<DivSanitized
							className={clsx(guideContent.contentGuide, info.style)}
							text={info.text}
						/>
					))}
				</div>
			) : null}
		</>
	);
}

function GuideContent(props: guideContentProps) {
	const { activated, guides, update } = props;
	const [open, setOpen] = React.useState(false);
	const [newGuide, setNewGuide] = React.useState<IGuideExample>({ content: [], title: null });
	const [loader, setLoader] = React.useState(false);
	const { peticion } = usePeticiones();
	const classes = useStyles();
	const guideContent = useStyleGuide();
	const contentNewGuide = React.useRef<HTMLDivElement>(null);

	const executeScroll = () =>
		contentNewGuide?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	const getData = (guide: Guide) => ({
		activity: guide.activity,
		unit: guide.unit,
		lesson: guide.lesson,
		block: guide.block,
		type: guide.type,
		average: 100,
	});
	const getGuide = (_unit: any, _lesson: any, _block: any) => {
		setLoader(true);
		peticion({
			accion: 'guide',
			formData: { unit: _unit, lesson: _lesson, block: _block },
		})
			.then(result => {
				setTimeout(executeScroll, 500);
				setLoader(false);
				setNewGuide(result.data);
			})
			.catch(err => setLoader(false));
	};
	const getBody = () => {
		const body: any = {
			data: [],
		};
		if (guides) {
			for (const guide of guides) {
				const item = getData(guide);
				body.data.push(item);
			}
		}
		return body;
	};
	const saveProgress = () => {
		peticion({
			accion: 'progress/many',
			formData: getBody(),
		})
			.then(result => {
				if (activated === false) {
					update();
				}
			})
			.catch(err => {});
	};
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		saveProgress();
		setOpen(false);
		setNewGuide({ content: [], title: null });
	};
	return (
		<>
			{guides && guides.length > 0 ? (
				<>
					<Typography align='right' component='div'>
						<Button
							disabled={!activated}
							variant='outlined'
							size='small'
							color='primary'
							onClick={handleOpen}
							endIcon={guides[0].completed ? <Check /> : ''}
						>
							Spanish Guide
						</Button>
					</Typography>
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'
						className={classes.showGuide}
					>
						<DialogTitle id='alert-dialog-title'>Spanish Guide</DialogTitle>
						<DialogContent className='scrollCssV'>
							{guides.map(guide => (
								<React.Fragment key={key()}>
									<h3>
										<DivSanitized text={guide.name} />
										{process.env.NODE_ENV === 'development' ? ` - ${guide.id}` : ' '}
									</h3>
									{guide.content?.map(info => (
										<React.Fragment key={key()}>
											<DivSanitized
												className={clsx(guideContent.contentGuide, info.style)}
												text={info.text}
												onClick={e => {
													if (e.target.attributes.getNamedItem('aria-value')) {
														const attr = e.target.attributes.getNamedItem('aria-value').value;
														const arg = attr.replace('(', '').replace(')', '').split(',');
														getGuide(arg[0], arg[1], arg[4]);
													}
												}}
											/>
										</React.Fragment>
									))}
								</React.Fragment>
							))}

							<div ref={contentNewGuide}>
								{loader ? (
									<LinearProgress />
								) : (
									<div>
										<GuideExample guide={newGuide} />
									</div>
								)}
							</div>
						</DialogContent>
						<DialogActions>
							<Button
								variant='outlined'
								onClick={handleClose}
								color='primary'
								startIcon={<Cancel />}
							>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</>
			) : null}
		</>
	);
}

export { GuideContent };
export default GuideContent;
