import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { TextField, makeStyles, Theme, Typography } from '@material-ui/core';

import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';
import AudioExercise from '../audio-exercise';
import Options from './Options';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '70vh',
		maxHeight: '70vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		lineHeight: '2',
		marginTop: theme.spacing(1),
	},
	rootLine: {
		display: 'inline-block',
		marginTop: 0,
		borderColor: theme.palette.primary.main,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		'& .MuiInputBase-root': {
			fontSize: 'inherit',
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
			borderBottomStyle: 'dotted',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
			borderColor: theme.palette.primary.main,
			borderBottomStyle: 'inset',
		},
		'& .MuiInput-underline:not(.Mui-disabled)::before': {
			borderBottomStyle: 'inset',
		},
		'& .MuiInputBase-input': {
			padding: '6px 0 2px',
		},
	},
	correct: {
		backgroundColor: '#4caf500d',
		'& .MuiInputBase-root,.MuiInputBase-root p': {
			color: theme.palette.success.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
		},
	},
	incorrect: {
		backgroundColor: '#f443360d',
		'& .MuiInputBase-root, .MuiInputBase-root p': {
			color: theme.palette.error.light,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.error.light}`,
		},
	},
}));

export function WriteCompleteLine(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent } = typeActivity;
	const { question, multiAnswer, img, reactives, media, localMedia, options, time } = reactive;
	const classes = useStyles();
	const [userAnswer, setUserAnswer] = useState<any[]>([]);
	const [correctAnswer, setCorrectAnswer] = useState<string>('');
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [mount, setMount] = useState<boolean>(false);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [sentence, setSentence] = useState<any>([]);

	const checkAnswer = () => {
		const inputAnswer = userAnswer;
		let correctAnswersNumber = 0;

		for (const ia of inputAnswer) {
			let indexMultiAnswer = 0;
			ia.isCorrect = false;
			for (const ma of multiAnswer) {
				const correct = ma.includes(ia.value);
				if (correct) {
					correctAnswersNumber += 1;
					ia.isCorrect = correct;
					multiAnswer.splice(indexMultiAnswer, 1);
				}
				indexMultiAnswer += 1;
			}
		}

		setUserAnswer(inputAnswer);

		const isTrue = correctAnswersNumber === inputAnswer.length;

		setIsCorrect(isTrue);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const nextAnswer = () => {
		onNext();

		let ua = `<div>${question}</div>`;
		userAnswer.reduce((array, b) => {
			// eslint-disable-next-line
			console.log(b);
			array.push(b.value);
			ua = ua.replace(
				'|',
				`<b><u  class='${b.isCorrect ? 'correct' : 'incorrect'}'>${b.value}</u></b>`
			);
			return array;
		}, []);

		onCheck(isCorrect ?? false, [ua], '', media, localMedia, img, activaAnswer);
	};
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const answerInput: any = userAnswer;
		answerInput[index] = { value: `${e.currentTarget.value}`, isCorrect: null };
		setUserAnswer(answerInput);
		setCheckDisabled(false);
	};

	useEffect(() => {
		const createInputReactives = () => {
			let inputCount = 0;
			let ca = '';
			const reactiveSentence = question.split(' ').reduce((array: any[], b: string) => {
				const replace = b.split('').includes('|');
				if (replace) {
					ca += ' ';
					const wordReplace = b.split('');
					for (const word of wordReplace) {
						if (word === '|') {
							array.push({ index: inputCount, width: multiAnswer[inputCount][0].length });
							ca += `<b><u>${multiAnswer[inputCount][0]}</u></b>`;
							inputCount += 1;
						} else {
							array.push(word);
							ca += `${b.replaceAll('|', '')}`;
						}
					}
				} else {
					ca += ` ${b}`;
					array.push(b);
				}
				return array;
			}, []);
			setSentence(reactiveSentence);
			setCorrectAnswer(ca);
		};
		if (!mount) {
			setMount(true);
		}
		if (mount && sentence.length === 0) {
			createInputReactives();
		}
	}, [
		mount,
		setMount,
		setSentence,
		reactives,
		question,
		sentence,
		classes,
		isExample,
		userAnswer,
		multiAnswer,
	]);

	const keyInput: any = sentence.reduce((arr: string[], b: any, index: any) => {
		arr.push(`inputs-keys-${index.toString()}`);
		return arr;
	}, []);

	return (
		<>
			<div className={classes.reactive}>
				{options || reactives ? <Options options={reactives ?? options} /> : null}

				{typeComponent ? '' : null}
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				<Typography component='div' className={classes.question}>
					{sentence.map((input: any, index: any) => (
						<Fragment key={keyInput[index]}>
							{typeof input === 'string' ? (
								<DivSanitized component='span' text={`${input} `} />
							) : (
								<>
									<TextField
										variant='standard'
										fullWidth
										autoFocus
										style={{ width: `${input.width}em ` }}
										className={clsx(classes.rootLine, {
											[classes.correct]: userAnswer[input.index]?.isCorrect,
											[classes.incorrect]: userAnswer[input.index]?.isCorrect === false,
										})}
										disabled={
											isExample ||
											userAnswer[input.index]?.isCorrect === true ||
											userAnswer[input.index]?.isCorrect === false
										}
										defaultValue={
											isExample ? multiAnswer[input.index][0] : userAnswer[input.index]?.value
										}
										type='text'
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											handleChange(e, input.index);
										}}
									/>{' '}
								</>
							)}
						</Fragment>
					))}
				</Typography>
			</div>
			<CheckButtons
				answer={[correctAnswer]}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export default WriteCompleteLine;
