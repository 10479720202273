import React from 'react';
import { Md5 } from 'md5-typescript';
import useLocalstorage from './use-Localstorage';

interface PState {
	accion: string;
	formData: any;
	host?: string;
	method?: string;
}

interface State {
	success: boolean | null;
	isLoading: boolean;
}

function usePeticiones() {
	const { getToken, isActivate } = useLocalstorage();
	/* const baseApi =
    process.env.NODE_ENV === 'production'
      ? 'https://api.winningwithenglish.com/'
      : 'http://api2.winningwithenglish.com/';
      */

	const baseApi = process.env.REACT_APP_LOCAL_API
		? 'https://apideveloper.winningwithenglish.com/'
		: 'https://api.winningwithenglish.com/';

	const apiKey = 'API-1023-210429';

	const [statePeteiciones, setStatePeteiciones] = React.useState<State>({
		success: false,
		isLoading: false,
	});

	function peticion(dato: PState) {
		let token = getToken();
		const { formData, host, method } = dato;

		if (!isActivate()) {
			token = '';
		}

		if (formData.segurityToken !== undefined) {
			if (formData.segurityToken) {
				token = formData.segurityToken;
			}
			delete formData.segurityToken;
		}

		if (formData.password !== undefined) {
			formData.password = btoa(Md5.init(formData.password));
		}

		if (formData.codigoUsuario !== undefined) {
			formData.codigoUsuario = btoa(formData.codigoUsuario);
		}

		const credenciales: any = {
			method: method ?? 'POST',
			body: JSON.stringify(dato.formData),
			headers: {
				APIKEY: apiKey,
				TOKEN: token,
				'Content-Type': 'application/json',
				'ACCESS-SHELL': 'true',
			},
		};
		if ((method as string) === 'GET') {
			delete credenciales.body;
		}
		const urlApi = (host ?? baseApi) + dato.accion;

		setStatePeteiciones({ ...statePeteiciones, success: false });
		setStatePeteiciones({ ...statePeteiciones, isLoading: true });

		return fetch(urlApi, credenciales)
			.then(response => response.json())
			.then(data => {
				setStatePeteiciones({ ...statePeteiciones, isLoading: false });
				return data;
			})
			.catch(e => {
				throw e;
			});
	}

	async function peticionAsync(dato: PState) {
		let token = getToken();
		const { formData } = dato;

		if (!isActivate()) {
			token = '';
		}

		if (formData.segurityToken !== undefined) {
			if (formData.segurityToken) {
				token = formData.segurityToken;
			}
			delete formData.segurityToken;
		}

		if (formData.password !== undefined) {
			formData.password = btoa(Md5.init(formData.password));
		}
		if (formData.codigoUsuario !== undefined) {
			formData.codigoUsuario = btoa(formData.codigoUsuario);
		}

		const credenciales = {
			method: 'POST',
			body: JSON.stringify(dato.formData),
			headers: {
				APIKEY: apiKey,
				TOKEN: token,
				'Content-Type': 'application/json',
			},
		};

		const urlApi = baseApi + dato.accion;

		setStatePeteiciones({ ...statePeteiciones, success: false });
		setStatePeteiciones({ ...statePeteiciones, isLoading: true });
		const response = await fetch(urlApi, credenciales);
		const data = await response.json();
		setStatePeteiciones({ ...statePeteiciones, isLoading: false });
		return data;
	}

	async function peticionLocalAsync(dato: string) {
		const urlApi = dato;
		const result = await fetch(urlApi)
			.then(response => {
				const type = response.headers.get('content-type');
				if (type === 'image/png' || type === 'image/jpg') {
					return response.blob();
				}
				throw response;
			})
			.then(imageBlob => {
				const imageObjectURL = URL.createObjectURL(imageBlob);
				return imageObjectURL;
			})
			.catch(() => null);
		return result;
	}

	async function getTextRecognition(dato: PState) {
		let token = getToken();
		const { formData } = dato;

		if (!isActivate()) {
			token = '';
		}

		const credenciales = {
			method: 'POST',
			body: formData,
			headers: {
				APIKEY: apiKey,
				TOKEN: token,
			},
		};

		const urlApi = baseApi + dato.accion;

		setStatePeteiciones({ ...statePeteiciones, success: false });
		setStatePeteiciones({ ...statePeteiciones, isLoading: true });
		const response = await fetch(urlApi, credenciales);
		const data = await response.json();
		setStatePeteiciones({ ...statePeteiciones, isLoading: false });
		return data;
	}

	function peticionEspecial(dato: PState) {
		let token = getToken();
		const { formData } = dato;

		if (!isActivate()) {
			token = '';
		}

		const credenciales = {
			method: 'POST',
			body: formData,
			headers: {
				APIKEY: apiKey,
				TOKEN: token,
			},
		};

		const urlApi = baseApi + dato.accion;

		setStatePeteiciones({ ...statePeteiciones, success: false });
		setStatePeteiciones({ ...statePeteiciones, isLoading: true });

		return fetch(urlApi, credenciales)
			.then(response => response.json())
			.then(data => {
				setStatePeteiciones({ ...statePeteiciones, isLoading: false });
				return data;
			})
			.catch(e => {
				throw e;
			});
	}

	return {
		peticion,
		peticionAsync,
		peticionEspecial,
		getTextRecognition,
		statePeteiciones,
		setStatePeteiciones,
		peticionLocalAsync,
	};
}

export { usePeticiones };
export default usePeticiones;
