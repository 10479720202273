import React, { useState } from 'react';
import clsx from 'clsx';
import {
	InputAdornment,
	TextField,
	makeStyles,
	Theme,
	Typography,
	Button,
} from '@material-ui/core';
import { CheckCircle, HighlightOff } from '@material-ui/icons';
import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx
import { AudioExercise } from '../audio-exercise';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '70vh',
		maxHeight: '70vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},

	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},

	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},

	respuesta: {
		marginTop: theme.spacing(2),
		borderColor: theme.palette.primary.main,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		'& .MuiInputBase-root': {
			fontSize: 'inherit',
			fontFamily: 'inherit',
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
			borderBottomStyle: 'dotted',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			borderBottomStyle: 'inset',
		},
		'& .MuiInputBase-root:not(.Mui-disabled)::before': {
			borderBottomStyle: 'inset',
		},
		'&.correct': {
			borderColor: theme.palette.success.main,
			'& label + .MuiInput-formControl': {
				marginTop: theme.spacing(3),
			},
			'& .MuiInputBase-root::before': {
				borderBottom: `2px solid ${theme.palette.success.main}`,
				transition: 'none',
			},
			'& .MuiFormLabel-root.Mui-disabled, .MuiInputBase-root.Mui-disabled': {
				color: theme.palette.success.main,
			},
		},
		'&.incorrect': {
			borderColor: theme.palette.success.main,
			'& label + .MuiInput-formControl': {
				marginTop: theme.spacing(3),
			},
			'& .MuiInputBase-root::before': {
				borderBottom: `2px solid ${theme.palette.error.main}`,
				transition: 'none',
			},
			'& .MuiFormLabel-root.Mui-disabled, .MuiInputBase-root.Mui-disabled': {
				color: theme.palette.error.main,
			},
		},
	},
	hidden: {
		visibility: 'hidden',
	},
	containerOtions: {
		marginTop: theme.spacing(1),
	},
	boton: {
		fontSize: 'inherit',
		fontFamily: 'inherit',
		marginRight: theme.spacing(1),
		'&.MuiButton-contained.incorrect': {
			'&.Mui-disabled': {
				border: `1px solid`,
				borderColor: `${theme.palette.error.main} `,
				color: theme.palette.error.main,
				backgroundColor: '#f443360d',
				'& .MuiButton-startIcon': {
					backgroundColor: '#f443360d',
				},
			},
		},
		'&.MuiButton-contained.correct': {
			'&.Mui-disabled': {
				border: `1px solid`,
				borderColor: `${theme.palette.success.main} `,
				color: theme.palette.success.main,
				backgroundColor: '#4caf500d',
				'& .MuiButton-startIcon': {
					backgroundColor: '#4caf500d',
				},
			},
		},
	},
	answer: {
		marginTop: theme.spacing(1),
	},
}));

export function SelectWriteAnswer(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;

	const { typeComponent, component } = typeActivity;
	const { question, answer, img, media, localMedia, reactives, time } = reactive;
	const classes = useStyles();
	const [userAnswer, setUserAnswer] = useState('');
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [hiddenText, setHiddenText] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [buttonSelected, setButtonSelected] = useState<string>('');
	const questionClean = question?.replace('|.', '').replace('|?', '').replace('|!', '');

	const checkAnswer = () => {
		const correct = answer.includes(userAnswer);
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
	};

	const updateAnswer = () =>
		`<u style="min-width:120px"> ${userAnswer}${
			userAnswer ? '' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
		}</u>`;

	let endChar = '';
	let answerActivity = '';
	if (typeComponent === 'signo') {
		const chars = question.split('|');
		endChar = `${chars[1]}`;
	} else if (typeComponent === 'question') {
		endChar = '?';
		answerActivity = question;
	}

	const nextAnswer = () => {
		onNext();

		const ua = !hiddenText
			? [`Selection :<u>${buttonSelected}</u> `]
			: [`Selection :<u>${buttonSelected}</u><br>${userAnswer}${endChar} `];

		let questionTmp = question;
		if (typeComponent === 'signo' && question) {
			questionTmp = question?.replace('|.', '').replace('|?', '').replace('|!', '');
		}
		onCheck(isCorrect ?? false, ua, questionTmp, media, localMedia, img, activaAnswer);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = `${event.target.value}`;
		setUserAnswer(value);
		if (value !== '') {
			setCheckDisabled(false);
		}
	};

	return (
		<>
			<div className={classes.reactive}>
				{component === 'Write-Number' ? (
					<>
						<AudioExercise
							view='Circulo'
							media={media as string}
							localMedia={localMedia as string}
							totalAttempts={totalAttempts}
						/>
						<ImageExercise img={img as string} alt={question} text={question} isZoom={isZoom} />
						{isExample ? (
							<Typography component='div' color='primary' variant='h6' className={classes.example}>
								Example:
							</Typography>
						) : null}
					</>
				) : (
					<>
						<AudioExercise
							view='Circulo'
							media={media as string}
							localMedia={localMedia as string}
							totalAttempts={totalAttempts}
						/>
						<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
						{isExample ? (
							<Typography component='div' color='primary' variant='h6' className={classes.example}>
								Example:
							</Typography>
						) : null}
						{typeComponent === 'signo' && question ? (
							<Typography component='div' className={classes.question}>
								<DivSanitized
									text={question?.replace('|.', '').replace('|?', '').replace('|!', '')}
								/>
							</Typography>
						) : null}
						{typeComponent !== 'signo' && question ? (
							<Typography component='div' className={classes.question}>
								<DivSanitized text={question?.replace('|', updateAnswer())} />
							</Typography>
						) : null}
					</>
				)}

				<TextField
					className={clsx(classes.respuesta, {
						[classes.hidden]:
							(isExample && answer[0] === question) ||
							(!isExample && buttonSelected === '') ||
							(!isExample &&
								!hiddenText &&
								(buttonSelected === 'True' || buttonSelected === 'correct')),
						correct: isCorrect,
						incorrect: !isCorrect && isCorrect !== null,
					})}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								{isCorrect || isExample ? <CheckCircle fontSize='small' /> : null}
								{!isCorrect && isCorrect !== null ? <HighlightOff fontSize='small' /> : null}
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								<b>{endChar}</b>
							</InputAdornment>
						),
					}}
					fullWidth
					variant='standard'
					disabled={isExample || isCorrect !== null}
					value={isExample ? answer[0] : userAnswer}
					type='text'
					onChange={handleChange}
				/>
				<div className={classes.containerOtions}>
					{reactives.map((option, index) => (
						<Button
							key={`Select-Option-${index + 10}`}
							size='small'
							disabled={isCorrect !== null || isExample}
							className={clsx(classes.boton, {
								correct: isCorrect,

								incorrect: !isCorrect && isCorrect !== null,
							})}
							variant={
								option === buttonSelected ||
								(isExample && option === (answer[0] === question ? 'True' : 'False')) ||
								(isExample && option === (answer[0] === question ? 'correct' : 'incorrect'))
									? 'contained'
									: 'outlined'
							}
							color='primary'
							startIcon={
								(isCorrect !== null && buttonSelected === option) || isExample ? (
									<>
										{isCorrect ||
										(isExample && option === (answer[0] === question ? 'True' : 'False')) ? (
											<CheckCircle fontSize='small' />
										) : null}
										{!isCorrect && isCorrect !== null ? <HighlightOff fontSize='small' /> : null}
									</>
								) : null
							}
							onClick={() => {
								if (!isExample && isCorrect === null) {
									setButtonSelected(option);
									if (option === 'True' || option === 'correct') {
										setUserAnswer(questionClean);
										setHiddenText(false);
										setCheckDisabled(false);
									} else {
										setUserAnswer('');
										setHiddenText(true);
										setCheckDisabled(false);
									}
								}
							}}
						>
							{option}
						</Button>
					))}
				</div>
				{answerActivity ? <DivSanitized className={classes.answer} text={answerActivity} /> : null}
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export default SelectWriteAnswer;
