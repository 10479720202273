import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Theme,
	makeStyles,
} from '@material-ui/core';

import { Cancel } from '@material-ui/icons';
import DivSanitized from './DivSanitized';

interface DialogSimpleProps {
	handleClose: () => void;
	open: boolean;
	content?: string;
	title?: string;
	children?: any;
	action?: any;
}

DialogSimple.defaultProps = {
	title: null,
	children: null,
	content: '',
	action: null,
};

const useStyles = makeStyles((theme: Theme) => ({
	showInformacion: {
		fontFamily: "'Lato', sans-serif;",
		'& .MuiDialogTitle-root': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			'& h2': {
				fontFamily: 'inherit',
				textAlign: 'center',
				fontSize: 'calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)));',
			},
		},
		'& .MuiDialogContent-root': {
			fontSize: 'calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)));',
		},
	},
	boton: {
		fontFamily: "'Lato', sans-serif;",
	},
}));

export function DialogSimple(props: DialogSimpleProps) {
	const { open, handleClose, content, title, children, action } = props;
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={handleClose} fullWidth className={classes.showInformacion}>
			{title ? <DialogTitle id='alert-dialog-title'>{title}</DialogTitle> : null}
			<DialogContent>
				{children ? <>{children}</> : <DivSanitized text={content ?? ''} />}
			</DialogContent>
			<DialogActions>
				{action ? (
					<>{action}</>
				) : (
					<Button
						className={classes.boton}
						variant='outlined'
						onClick={handleClose}
						color='primary'
						autoFocus
						endIcon={<Cancel />}
					>
						close
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default DialogSimple;
