import React from 'react';
import clsx from 'clsx';
import Vimeo from '@u-wave/react-vimeo';
import { makeStyles, withStyles, Theme, Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fab from '@material-ui/core/Fab';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import ReplayIcon from '@material-ui/icons/Replay';

const useStyles = makeStyles((theme: Theme) => ({
	mediaContainer: {
		width: '100px',
		margin: '16px auto 16px',
		padding: '8px',
		textAlign: 'center',
		'& .MuiButton-root ': {
			margin: theme.spacing(2),
			padding: theme.spacing(1, 2),
			boxSizing: 'content-box',
			borderRadius: theme.shape.borderRadius,
		},
	},
	fabProgress: {
		color: theme.palette.primary.light,
		position: 'absolute',
		top: -7,
		left: -7,
		zIndex: 1,
	},
	fabProgressDisable: {
		color: theme.palette.grey[500],
	},
	disableBar: {
		'& .MuiLinearProgress-bar': {
			backgroundColor: theme.palette.grey[500],
		},
	},
	iconButton: {
		'& .MuiButton-label svg': {
			fontSize: '24px',
		},
	},
	correct: {
		'&.Mui-disabled': {
			border: `1px solid`,
			borderColor: `${theme.palette.success.main} `,
			color: theme.palette.success.main,
			backgroundColor: '#4caf500d',
			'& .MuiButton-startIcon': {
				backgroundColor: '#4caf500d',
			},
		},
	},
	incorrect: {
		'&.Mui-disabled': {
			border: `1px solid`,
			borderColor: `${theme.palette.error.main} `,
			color: theme.palette.error.main,
			backgroundColor: '#f443360d',
			'& .MuiButton-startIcon': {
				backgroundColor: '#f443360d',
			},
		},
	},
}));

const BorderLinearProgress = withStyles(theme => ({
	root: {
		height: 5,
		borderRadius: theme.shape.borderRadius,
		margin: theme.spacing(0, 2),
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.primary.main,
	},
}))(LinearProgress);

type View = 'Rectangulo' | 'Circulo' | 'Button';

export interface AudioExerciseProps {
	media: string;
	localMedia: string;
	view?: View | string | undefined;
	totalAttempts: number;
	disable?: boolean;
	fullWidth?: boolean;
	variant?: any;
	size?: 'medium' | 'large' | 'small' | undefined;
	className?: string;
	text?: string | undefined;
	endIcon?: any;
	onClick?: () => void;
}

interface controlAudio {
	pause: boolean;
	currentTime: number;
	autoStart: boolean;
	currentAttempts: number;
}

interface AudioFile {
	fileName: string;
	view: View | string | undefined;
	totalAttempts: number;
	disable?: boolean;
	fullWidth?: boolean;
	variant?: any;
	size?: 'medium' | 'large' | 'small' | undefined;
	className?: string;
	text?: string | undefined;
	endIcon?: any;
	onClick?: () => void;
}

Sound.defaultProps = {
	disable: true,
};

function Sound(soundFileName: AudioFile) {
	const classes = useStyles();
	const {
		fileName,
		view,
		totalAttempts,
		disable,
		fullWidth,
		variant,
		size,
		className,
		text,
		endIcon,
		onClick,
	} = soundFileName;
	const audioRef = React.useRef<HTMLAudioElement>(null);

	const [control, setControl] = React.useState<controlAudio>({
		pause: true,
		currentTime: 0,
		autoStart: false,
		currentAttempts: 0,
	});

	const updateCurrentTime = (timeupdate: number) => {
		setControl({ ...control, currentTime: timeupdate * 100 });
	};

	const handlePlay = (event: React.MouseEvent<HTMLElement>) => {
		if (audioRef.current) {
			if (control.currentTime === 100 && control.pause === false) {
				setControl({ ...control, currentTime: 0 });
			}
			setControl({ ...control, autoStart: true, pause: !control.pause });
			if (!control.pause === true) {
				audioRef.current.pause();
			} else {
				audioRef.current.play();
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		onClick ? onClick() : null;
	};

	return (
		<>
			<audio
				autoPlay={control.autoStart}
				ref={audioRef}
				onEnded={e => {
					setControl({
						...control,
						currentAttempts: control.currentAttempts + 1,
						currentTime: 100,
					});
				}}
				onTimeUpdate={(e: any) => {
					const progress =
						Math.floor(e.nativeEvent.target.currentTime) /
						Math.floor(e.nativeEvent.target.duration);
					updateCurrentTime(progress);
				}}
			>
				<source src={fileName} type='audio/mp3' />
				<track src='captions_en.vtt' kind='captions' label='english_captions' />
			</audio>
			{view === 'Button' ? (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Button
					fullWidth={fullWidth}
					variant={variant}
					color='primary'
					size={size}
					className={clsx(className, classes.iconButton)}
					endIcon={endIcon}
					onClick={handlePlay}
					disabled={control.currentAttempts >= totalAttempts || disable}
					startIcon={
						control.currentTime === 100 && control.pause === false ? (
							<ReplayIcon fontSize='large' />
						) : (
							<>
								{control.pause ? (
									<PlayArrowIcon fontSize='large' />
								) : (
									<PauseIcon fontSize='large' />
								)}
							</>
						)
					}
					// endIcon={endIcon}
				>
					{text}
				</Button>
			) : null}
			{view === 'Circulo' ? (
				<div className={clsx([classes.mediaContainer], ['AudioCss'])}>
					<Fab
						aria-label='save'
						color='primary'
						onClick={handlePlay}
						className='Guillermo'
						disabled={control.currentAttempts >= totalAttempts || disable}
					>
						{control.currentTime === 100 && control.pause === false ? (
							<ReplayIcon fontSize='large' />
						) : (
							<>
								{control.pause ? (
									<PlayArrowIcon fontSize='large' />
								) : (
									<PauseIcon fontSize='large' />
								)}
							</>
						)}
						<CircularProgress
							className={clsx(classes.fabProgress, {
								[classes.fabProgressDisable]: control.currentAttempts >= totalAttempts,
							})}
							variant='determinate'
							value={control.currentTime}
							size={70}
						/>
					</Fab>
				</div>
			) : null}
			{view === 'Rectangulo' ? (
				<div className={clsx([classes.mediaContainer], ['AudioCss'])}>
					<Button
						variant='contained'
						color='primary'
						onClick={handlePlay}
						disabled={control.currentAttempts >= totalAttempts}
					>
						{control.currentTime === 100 && control.pause === false ? (
							<ReplayIcon fontSize='large' />
						) : (
							<>
								{control.pause ? (
									<PlayArrowIcon fontSize='large' />
								) : (
									<PauseIcon fontSize='large' />
								)}
							</>
						)}
					</Button>
					<BorderLinearProgress
						className={clsx({
							[classes.disableBar]: control.currentAttempts >= totalAttempts,
						})}
						variant='determinate'
						value={control.currentTime}
					/>
				</div>
			) : null}
		</>
	);
}

function AudioExercise(props: AudioExerciseProps) {
	const classes = useStyles();
	const {
		media,
		localMedia,
		view,
		totalAttempts,
		disable,
		fullWidth,
		variant,
		size,
		className,
		text,
		endIcon,
		onClick,
	} = props;

	const [control, setControl] = React.useState<controlAudio>({
		pause: true,
		currentTime: 0,
		autoStart: false,
		currentAttempts: 0,
	});

	const updateCurrentTime = (timeupdate: number) => {
		setControl({ ...control, currentTime: timeupdate * 100 });
	};

	const handlePlay = (event: React.MouseEvent<HTMLElement>) => {
		setControl({ ...control, autoStart: true });
		setControl({ ...control, pause: !control.pause });
	};

	const isDev = window.location.hostname === 'localhost';

	return (
		<>
			{media || localMedia ? (
				<>
					{localMedia ? (
						<>
							<Sound
								fileName={localMedia}
								view={view}
								totalAttempts={totalAttempts}
								disable={disable}
								fullWidth={fullWidth}
								variant={variant}
								size={size}
								className={clsx(className, classes.iconButton)}
								endIcon={endIcon}
								onClick={onClick}
								text={text}
							/>
						</>
					) : (
						<>
							{view === 'Circulo' ? (
								<div className={clsx([classes.mediaContainer], ['AudioCss'])}>
									<Fab
										disabled={control.currentAttempts >= totalAttempts}
										aria-label='save'
										color='primary'
										onClick={handlePlay}
									>
										{control.currentTime === 100 && control.pause === false ? (
											<ReplayIcon fontSize='large' />
										) : (
											<>
												{control.pause ? (
													<PlayArrowIcon fontSize='large' />
												) : (
													<PauseIcon fontSize='large' />
												)}
											</>
										)}
										<CircularProgress
											className={clsx(classes.fabProgress, {
												[classes.fabProgressDisable]: control.currentAttempts >= totalAttempts,
											})}
											variant='determinate'
											value={control.currentTime}
											size={70}
										/>
									</Fab>
								</div>
							) : null}
							{view === 'Rectangulo' ? (
								<div className={clsx([classes.mediaContainer], ['AudioCss'])}>
									<Button
										disabled={control.currentAttempts >= totalAttempts}
										variant='contained'
										onClick={handlePlay}
									>
										{control.currentTime === 100 && control.pause === false ? (
											<ReplayIcon fontSize='large' />
										) : (
											<>
												{control.pause ? (
													<PlayArrowIcon fontSize='large' />
												) : (
													<PauseIcon fontSize='large' />
												)}
											</>
										)}
									</Button>

									<BorderLinearProgress
										className={clsx({
											[classes.disableBar]: control.currentAttempts >= totalAttempts,
										})}
										variant='determinate'
										value={control.currentTime}
									/>
								</div>
							) : null}

							<Vimeo
								width='1'
								height='1'
								video={isDev ? '560958612' : media}
								paused={control.pause}
								controls
								autoplay={control.autoStart}
								onEnd={e => {
									setControl({ ...control, currentAttempts: control.currentAttempts + 1 });
								}}
								onTimeUpdate={e => {
									updateCurrentTime(e.percent);
								}}
							/>
						</>
					)}
				</>
			) : null}
		</>
	);
}

AudioExercise.defaultProps = {
	view: 'Circulo',
	disable: false,
	fullWidth: false,
	variant: 'outlined',
	size: 'small',
	className: '',
	text: '',
	endIcon: '',
	onClick: () => {},
};

export { AudioExercise };
export default AudioExercise;
