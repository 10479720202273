import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SelectActivity, Loading } from '../components';
import { Activity } from '../components/Activity-Conten/Activity-interface';
import { usePeticiones } from '../lib';

function PageActivity(): JSX.Element {
	const { idActivity, activity } = useParams();
	const { peticion } = usePeticiones();

	const [activityContent, setActivityContent] = React.useState<Activity>();
	const [mount, setMount] = React.useState(true);

	useEffect(() => {
		const body = {};
		const fetchdata = async () => {
			const result: any = await peticion({
				accion: `activity/${idActivity}`,
				formData: body,
			});
			setActivityContent(result.data);
		};
		if (mount) {
			setMount(false);
			fetchdata();
		}
	}, [peticion, mount, idActivity]);

	return (
		<>
			{!activityContent ? (
				<Loading page='page' />
			) : (
				<SelectActivity type={activity} content={activityContent} />
			)}
		</>
	);
}

export { PageActivity };
export default PageActivity;
