import React, { Fragment, useState } from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
//  Button, import { CheckCircle, HighlightOff } from '@material-ui/icons';
import { ReactiveProps } from '../../Activity-interface';
import { ImageExercise } from '../Image-Exercise';
import { AudioExercise } from '../audio-exercise';
import { DivSanitized } from '../../../DivSanitized';
import { CheckButtons } from '../Check-Buttons';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '70vh',
		maxHeight: '70vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},

	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},

	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},

	options: {
		display: 'flex',
		marginTop: theme.spacing(2),
		flexDirection: 'column',
		'& .MuiGrid-root.SelectOption .AudioCss': {
			display: 'inline',
			margin: theme.spacing(1, 1, 0),
			width: 'auto',
			'& .MuiButtonBase-root': {
				width: '46px',
				height: '46px',
			},
		},
		'& .MuiCircularProgress-root': {
			width: '135% !important',
			height: '135% !important',
		},
		'&.disabe-AudioCss .AudioCss button': {},
	},

	button: {
		fontSize: 'inherit',
		fontFamily: 'inherit',
		flex: 1,
		height: '100%',
		marginBottom: theme.spacing(1),
		textTransform: 'none',
		'&.Multiple-Option-Img .MuiButton-startIcon': {
			position: 'absolute',
			right: '0px',
			top: '0px',
			background: '#fff',
			borderRadius: '50%',
			margin: 0,
			zIndex: '1',
		},
		'&.Multiple-Option-Img': {
			padding: theme.spacing(1),
			margin: 0,
		},
		'&.MuiButtonBase-root .MuiButton-label': {
			textAlign: 'left',
		},
	},
	correct: {
		'&.Mui-disabled': {
			border: `1px solid`,
			borderColor: `${theme.palette.success.main} `,
			color: theme.palette.success.main,
			backgroundColor: '#4caf500d',
			'& .MuiButton-startIcon': {
				backgroundColor: '#4caf500d',
			},
		},
	},
	incorrect: {
		'&.Mui-disabled': {
			border: `1px solid`,
			borderColor: `${theme.palette.error.main} `,
			color: theme.palette.error.main,
			backgroundColor: '#f443360d',
			'& .MuiButton-startIcon': {
				backgroundColor: '#f443360d',
			},
		},
	},
}));

function MultipleOptionMultipleAudio(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { answer, reactives, question, img, media, localMedia, time } = reactive;
	// const { component } = typeActivity;
	const [userAnswer, setUserAnswer] = useState(isExample ? answer[0] : '');
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const listmedia = media as string[];
	const listlocalMedia = localMedia as string[];
	const [userAnswerAudio, setUserAnswerAudio] = useState<number>(0);

	const [buttonSelected, setButtonSelected] = useState(isExample ? answer[0] : userAnswer);
	const classes = useStyles();
	const lengtGrid = reactives.length % 2 === 0 ? 6 : 4;

	const handleSelect = (answerUser: string, index: number) => {
		if (!isExample && isCorrect === null) {
			setCheckDisabled(false);
			setButtonSelected(answerUser);
			setUserAnswer(answerUser);
			setUserAnswerAudio(index);
		}
	};

	const checkAnswer = () => {
		const correct = answer.includes(userAnswer);
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
	};

	const nextAnswer = () => {
		onNext();
		const ua = [`<u>${userAnswer}</u>`];

		onCheck(
			isCorrect ?? false,
			ua,
			question,
			media[userAnswerAudio],
			localMedia[userAnswerAudio],
			img,
			activaAnswer
		);
	};

	const updateAnswer = () =>
		`<u style="min-width:120px"> ${userAnswer}${
			userAnswer ? '' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
		}</u>`;

	return (
		<>
			<div className={classes.reactive}>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				{question ? (
					<Typography component='div' className={classes.question} gutterBottom>
						<DivSanitized text={question?.replace('|', updateAnswer())} />
					</Typography>
				) : null}

				<div className={classes.options}>
					<Grid container spacing={1} justifyContent='center' className={clsx(['SelectOption'])}>
						{reactives.map((item: string, index) => (
							<Fragment key={`button-select-${index + 1}`}>
								<Grid
									item
									xs={12}
									md={lengtGrid}
									style={{ textAlign: 'center', display: 'flex' }}
									className={clsx({ 'disabe-AudioCss': isCorrect !== null || isExample })}
								>
									<AudioExercise
										view='Button'
										media={listmedia[index]}
										localMedia={listlocalMedia[index]}
										totalAttempts={totalAttempts}
										disable={isCorrect !== null || isExample}
										fullWidth
										variant={buttonSelected === item ? 'contained' : 'outlined'}
										className={clsx([classes.button], {
											[classes.correct]:
												(isCorrect && buttonSelected === item) || (isExample && answer[0] === item),
											[classes.incorrect]:
												!isCorrect && isCorrect !== null && buttonSelected === item,
										})}
										onClick={() => {
											handleSelect(item, index);
										}}
										text={item}
									/>
								</Grid>
							</Fragment>
						))}
					</Grid>
				</div>
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { MultipleOptionMultipleAudio };
export default MultipleOptionMultipleAudio;
