import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { CheckButtons } from '../Check-Buttons';

import { ReactiveProps } from '../../Activity-interface';

interface MatchButton {
	text: string;
	id: number;
	match: number;
	isCorrect: boolean | null;
	selected: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
	},
	buttons: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		maxWidth: '800px',
		margin: '0 auto',
		'& .MuiButton-root': {
			fontFamily: 'inherit',
			fontSize: 'calc(9px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
			padding: theme.spacing(1),
			border: `2px solid ${theme.palette.primary.main}`,
			margin: '1%',
			flex: 1,
			[theme.breakpoints.up('xs')]: {
				minWidth: '29%',
			},
			[theme.breakpoints.up('sm')]: {
				minWidth: '15%',
			},
		},
	},
	correct: {
		'&.Mui-disabled': {
			color: theme.palette.success.main,
			border: `2px solid ${theme.palette.success.main}`,
			backgroundColor: '#4caf500d',
		},
	},
	incorrect: {
		'&.Mui-disabled': {
			color: theme.palette.error.main,
			border: `2px solid ${theme.palette.error.main}`,
			backgroundColor: '#f443360d',
		},
	},
	selected: {
		'&.Mui-disabled': {
			color: theme.palette.primary.main,
			border: `2px solid ${theme.palette.primary.main}`,
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
		},
	},
}));

export function Match(props: ReactiveProps) {
	const { reactive, isExample, typeActivity, onNext, onCheck } = props;
	const { question, answer, reactives, time } = reactive;
	const classes = useStyles();
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [buttonMatch, setButtonMatch] = useState<MatchButton[]>([]);
	const [buttonSelected, setButtonSelected] = useState<any>({ btn1: null, btn2: null });
	const [isMount, setIsMount] = useState<boolean>(false);
	const [countMatches, setCountMatches] = useState<number>(0);

	const [userMatch, setUserMatch] = useState<MatchButton[]>([]);

	const checkAnswer = () => {
		const correct = true;
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const nextAnswer = () => {
		onCheck(isCorrect ?? false, ['<strong>Congratulations!</strong>'], question, '', '', '', false);
		onNext();
	};

	const activeMatch = (id: number) => {
		const buttons = buttonMatch.reduce((array: MatchButton[], item: MatchButton) => {
			if (item.id === id) {
				item.selected = true;
			}
			array.push(item);
			return array;
		}, []);
		setButtonMatch(buttons);
	};
	const checkMatch = (id1: number, id2: number, correct: boolean) => {
		const buttons = buttonMatch.reduce((array: MatchButton[], item: MatchButton) => {
			if (item.id === id1 || item.id === id2) {
				item.isCorrect = correct;
			}
			array.push(item);
			return array;
		}, []);
		setButtonMatch(buttons);
	};
	const cleanMatches = (matches: any) => {
		const buttons = buttonMatch.reduce((array: MatchButton[], item: MatchButton) => {
			if (item.id === matches.btn1.id || item.id === matches.btn2.id) {
				item.isCorrect = null;
				item.selected = false;
			}
			array.push(item);
			return array;
		}, []);
		setButtonMatch(buttons);
	};
	const handleSelect = (button: any) => {
		if (buttonSelected.btn1 === null) {
			activeMatch(button.id);
			const updateValue = buttonSelected;
			updateValue.btn1 = button;
			setButtonSelected({ ...updateValue });
		} else if (buttonSelected.btn2 === null) {
			activeMatch(button.id);
			const updateValue = buttonSelected;
			updateValue.btn2 = button;
			setButtonSelected({ ...updateValue });
			checkMatch(buttonSelected.btn1.id, button.id, buttonSelected.btn1.match === button.match);
			setTimeout(() => {
				if (buttonSelected.btn1.match !== button.match) {
					cleanMatches(buttonSelected);
				} else {
					if (countMatches + 1 === answer.length) {
						// setCheckDisabled(false);
						checkAnswer();
					}
					setCountMatches(countMatches + 1);

					setUserMatch(userMatch.concat(buttonSelected));
				}

				setButtonSelected({ btn1: null, btn2: null });
			}, 1000);
		}
	};
	useEffect(() => {
		// eslint-disable-next-line
		console.log(userMatch);
		const createButtonMatch = () => {
			let i = 0;
			let n = 0;
			const buttons = reactives.reduce((array: any[], word: string) => {
				array.push({ text: word, id: n, match: i, isCorrect: null, selected: false });
				n += 1;
				array.push({ text: answer[i], id: n, match: i, isCorrect: null, selected: false });
				n += 1;
				i += 1;
				return array.sort((a, b) => 0.5 - Math.random());
			}, []);

			setButtonMatch(buttons);
			setIsMount(true);
		};

		if (!isMount) {
			createButtonMatch();
		}
	}, [setButtonMatch, isMount, setIsMount, answer, reactives, buttonMatch, userMatch]);

	return (
		<>
			<div className={classes.reactive}>
				<div className={classes.buttons}>
					{isMount
						? buttonMatch.map((button: MatchButton) => (
								<Button
									key={uuid()}
									color='primary'
									className={clsx({
										[classes.selected]: button.selected && button.isCorrect === null,
										[classes.correct]: button.isCorrect,
										[classes.incorrect]: button.isCorrect === false,
									})}
									variant='contained'
									disabled={button.selected}
									onClick={() => {
										if (!button.selected) {
											handleSelect(button);
										}
									}}
								>
									{button.text}
								</Button>
						  ))
						: null}
				</div>
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={false}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export default Match;
