import React, { Fragment } from 'react';
import { v4 as key } from 'uuid';
import { Typography, Divider } from '@material-ui/core';

import { dictionaryItem } from '.';

export interface ListdefinitionProps {
	filtro?: dictionaryItem[] | undefined;
	page: number;
}

Listdefinition.defaultProps = {};

function Listdefinition(props: ListdefinitionProps) {
	const { filtro, page } = props;

	return (
		<>
			<div className='ListDefinition'>
				{filtro
					? filtro.map((Item, index) =>
							index >= page * 10 - 10 && index < page * 10 ? (
								<React.Fragment key={`palabra-${key()}`}>
									<Typography variant='h4' color='primary'>
										{Item.PALABRA}
									</Typography>
									<Typography variant='body1' color='textSecondary'>
										{Item.CONTENIDO}
									</Typography>
									{index !== page * 10 - 1 ? <Divider /> : null}
								</React.Fragment>
							) : null
					  )
					: null}
			</div>
		</>
	);
}

Listdefinition.defaultProps = {
	filtro: undefined,
};

export { Listdefinition };
export default Listdefinition;
