import React from 'react';
// import { v4 as uuid } from 'uuid';
import {
	Container,
	Grid,
	Paper,
	TextField,
	Typography,
	Button,
	InputAdornment,
	IconButton,
} from '@material-ui/core';

import clsx from 'clsx';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InfoIcon from '@material-ui/icons/Info';

import { TituloLogin, ToastBar } from '../components';
import { useStyles, useMessage, useRegister } from '../lib';

interface State {
	account: string;
	nstudent: string;
	email: string;
	user: string;
	password: string;
	code: string;
	showPassword: boolean;
}

function Pageregister() {
	const classes = useStyles();

	const { registerIn, statePeteiciones, menssage, setMenssage } = useRegister();
	const [success, setSuccess] = React.useState<string | null>(null);

	const [values, setValues] = React.useState<State>({
		account: '',
		nstudent: '',
		email: '',
		user: '',
		password: '',
		code: '',
		showPassword: false,
	});

	const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handClickregister = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		registerIn(values, setSuccess);
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClose = () => {
		setMenssage(useMessage.ini[0]);
	};

	return (
		<>
			{!success ? (
				<>
					<Container maxWidth='xs'>
						<Grid container justifyContent='center'>
							<Grid item xs={12}>
								<Paper className={clsx(classes.containerLogin, classes.containerRegister)}>
									<TituloLogin
										titulo='Create account'
										iconName='AccountCircleOutlined'
										align='center'
									/>
									<form className='form-login'>
										<TextField
											id='account'
											value={values.account}
											onChange={handleChange('account')}
											autoComplete='i-account'
											label='Contract number.'
											required
											margin='normal'
											type='text'
											fullWidth
											variant='outlined'
										/>
										<TextField
											id='nstudent'
											value={values.nstudent}
											onChange={handleChange('nstudent')}
											autoComplete='i-nstudent'
											label='Student number.'
											required
											margin='normal'
											type='text'
											fullWidth
											variant='outlined'
										/>
										<TextField
											id='email'
											value={values.email}
											onChange={handleChange('email')}
											autoComplete='i-email'
											label='Email address.'
											required
											margin='normal'
											type='text'
											fullWidth
											variant='outlined'
										/>
										<TextField
											id='user'
											value={values.user}
											onChange={handleChange('user')}
											label='username'
											required
											margin='normal'
											type='text'
											fullWidth
											variant='outlined'
										/>
										<TextField
											id='password'
											value={values.password}
											onChange={handleChange('password')}
											label='Password'
											required
											margin='normal'
											type={values.showPassword ? 'text' : 'password'}
											fullWidth
											variant='outlined'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<label htmlFor='password'>
															<IconButton
																aria-label='password-visibility'
																component='span'
																edge='end'
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
															>
																{values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
															</IconButton>
														</label>
													</InputAdornment>
												),
											}}
										/>
										<Grid container spacing={1}>
											<Grid item xs={6}>
												<Button href='/signin' fullWidth variant='outlined' color='default'>
													Back
												</Button>
											</Grid>
											<Grid item xs={6}>
												<Button
													disabled={statePeteiciones.isLoading}
													fullWidth
													variant='contained'
													color='primary'
													onClick={handClickregister}
												>
													Register
												</Button>
											</Grid>
										</Grid>
									</form>
								</Paper>
							</Grid>
						</Grid>
					</Container>
				</>
			) : (
				<>
					<Container maxWidth='xs'>
						<Grid container justifyContent='center'>
							<Grid item xs={12}>
								<Paper className={classes.containerLogin}>
									<>
										<TituloLogin
											titulo='Activate your account'
											text=' '
											iconName='MailOutlineOutlined'
											align='center'
										/>
										<form className='form-login'>
											<Grid container spacing={1}>
												<Grid item xs={1}>
													<InfoIcon color='primary' />
												</Grid>
												<Grid item xs={11}>
													<Typography align='center' color='primary'>
														Check your email to complete your activation process. Check spam email.
													</Typography>
												</Grid>
											</Grid>

											<Grid container spacing={1}>
												<Grid item xs={12}>
													<Button href='/' fullWidth variant='outlined' color='default'>
														Login
													</Button>
												</Grid>
											</Grid>
										</form>
									</>
								</Paper>
							</Grid>
						</Grid>
					</Container>
				</>
			)}
			{menssage.message ? (
				<ToastBar
					color={menssage.accion}
					autoHideDuration={3000}
					anchorOriginV='bottom'
					anchorOriginH='right'
					onClose={handleClose}
					message={menssage.message}
				/>
			) : null}
		</>
	);
}

export { Pageregister };
export default Pageregister;
