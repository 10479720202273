import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
	TextField,
	Theme,
	AppBar,
	IconButton,
	Toolbar,
	Fab,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	Button,
	MenuItem,
	InputLabel,
	Select,
	Snackbar,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	makeStyles,
	Grid,
	Typography,
	FormControl,
} from '@material-ui/core';
import {
	Add,
	AddCircle,
	CheckCircle,
	Delete,
	Error,
	NewReleases,
	RadioButtonUnchecked,
	Sync,
} from '@material-ui/icons';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { Activity, ActivityProgress } from '../Activity-interface';
import { useLocalstorage, usePeticiones } from '../../../lib';
import { CommmentsRevision, SelectActivity } from '../..';
import { MenuUnit } from './Menu-Unit';
import AddComponent from './Add-Component';
import AddTypeComponent from './Add-TypeComponent';

export function Alert(props: AlertProps) {
	const { severity, onClose, children } = props;
	return (
		<MuiAlert elevation={6} variant='filled' severity={severity} onClose={onClose}>
			{children}
		</MuiAlert>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	formControl: {
		margin: theme.spacing(2),
	},
	formControlSelect: {
		margin: theme.spacing(1),
		minWidth: '100%',
	},
	reactive: {
		marginBottom: theme.spacing(8),
		padding: theme.spacing(4),
		background: '#fff',
		listStyle: 'none',
		'&:nth-child(odd)': {
			background: '#eee',
		},
	},
	fab: { position: 'fixed', top: '16px', right: '16px', zIndex: 2 },
	appBar: { top: 'auto', bottom: 0, justifyContent: 'flex-end' },
	list: {
		padding: theme.spacing(1),
		minWidth: '94px',
		display: 'inline-block',
		'&.exercise': { background: theme.palette.primary.light },
		'&.reading': { background: theme.palette.warning.light },
		'&.writing': { background: theme.palette.error.light },
		'&.quiz': { background: theme.palette.secondary.light },
		'&.evaluation': { background: theme.palette.success.light },
		'&.pronunciation': { background: '#2196f3' },
		'&.audio-podcast': { background: '#867cff' },
	},
	title: {
		backgroundColor: theme.palette.divider,
		padding: theme.spacing(1),
	},
	infoActivity: {
		padding: '0px',
	},
	textComponet: {
		display: 'inline-flex',
		minWidth: '115px',
		flex: '0 0 auto',
		'& span': {
			fontSize: '.75rem',
		},
	},
	status: {
		'&.nuevo': { background: theme.palette.info.main },
		'&.pendiente': { background: theme.palette.warning.main },
		'&.listo': { background: theme.palette.success.main },
		'&.nuevo-icon': { color: theme.palette.info.main },
		'&.pendiente-icon': { color: theme.palette.warning.main },
		'&.listo-icon': { color: theme.palette.success.main },
	},
	statusIcon: {
		display: 'inline-block',
		verticalAlign: 'middle',
		fontSize: '18px',
	},
}));

interface CreateReactiveProps {
	activity: any;
	onClick: () => void;
	onClose: () => void;
	updateActivity: (reactive: any) => void;
	componentList: any;
	typeActivity: any;
}
export function CreateReactive(props: CreateReactiveProps) {
	const { activity, updateActivity, onClick, onClose, componentList, typeActivity } = props;

	const { reactives } = activity;
	const order: number = reactives.length + 1;
	const model: any = reactives[0]
		? reactives[0]
		: { activityId: null, component: null, instruction: null, typeComponent: null };
	const { activityId, component, instruction, typeComponent } = model;

	const newReactive = {
		id: `12-${order}`,
		question: '',
		answer: '',
		reactives: '',
		img: '',
		media: '',
		localMedia: '',
		order: `${order}`,
		titleText: '',
		contentText: '',
		instruction: instruction === undefined ? '' : `${instruction}`,
		activityId: `${activityId}`,
		componentId: component === undefined ? '' : `${component}`,
		typeComponentId: typeComponent === undefined ? '' : `${typeComponent}`,
	};
	const [reactive, setReactive] = useState<any>(newReactive);
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
		onClick();
	};

	const handleClose = () => {
		setReactive(newReactive);
		setOpen(false);
		onClose();
	};
	// gcg
	const updateModel = (key: any, e: any) => {
		const copyReactive: any = reactive;
		copyReactive[key] = e.currentTarget.value;
		setReactive(copyReactive);
	};
	const { peticion } = usePeticiones();
	const createReactive = async () => {
		peticion({
			accion: 'proccess-activity/add-reactive/',
			formData: reactive,
		})
			.then(() => {
				setReactive(newReactive);
				updateActivity(reactive);
				handleClose();
			})
			.catch(error => {});
	};

	const classes = useStyles();
	return (
		<>
			{activity.route === 'update-activity' ? (
				<Fab className={classes.fab} color='secondary' onClick={handleClickOpen}>
					<Add />
				</Fab>
			) : null}

			<Dialog
				fullWidth
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'>Agregar nuevo reactivo</DialogTitle>
				<DialogContent>
					{Object.keys(reactive).map(key => (
						<div key={uuid()}>
							{key !== 'id' && key !== 'activityId' ? (
								<div className={classes.formControl}>
									{key !== 'id' &&
									key !== 'activityId' &&
									key !== 'componentId' &&
									key !== 'instruction' &&
									key !== 'titleText' &&
									key !== 'contentText' &&
									key !== 'typeComponentId' ? (
										<TextField
											label={key}
											fullWidth
											variant='outlined'
											multiline={key === 'contentText'}
											rows={key === 'contentText' ? 12 : 1}
											defaultValue={reactive[key]}
											onChange={e => {
												updateModel(key, e);
											}}
										/>
									) : null}

									{(key === 'instruction' || key === 'titleText' || key === 'contentText') &&
									typeActivity === 'quiz' ? (
										<TextField
											label={key}
											fullWidth
											variant='outlined'
											multiline={key === 'contentText'}
											rows={key === 'contentText' ? 12 : 1}
											defaultValue={reactive[key]}
											onChange={e => {
												updateModel(key, e);
											}}
										/>
									) : null}

									{(key === 'componentId' || key === 'typeComponentId') &&
									typeActivity === 'quiz' ? (
										<SelectComponent
											selected={reactive[key]}
											list={key === 'componentId' ? componentList : typeComponent}
											label={key}
											onChange={e => {
												updateModel(key, e);
											}}
										/>
									) : null}
								</div>
							) : null}
						</div>
					))}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary' autoFocus>
						cancelar
					</Button>
					<Button onClick={createReactive} color='primary'>
						Crear
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export function Admin() {
	const history = useHistory();
	const { getTokenScopes } = useLocalstorage();
	const classes = useStyles();
	const { peticion } = usePeticiones();
	const [activityData, setActivityData] = useState<ActivityProgress | null>(null);
	const [type, setType] = useState<string>('');
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const [list, setList] = useState<any[]>([]);
	const [mount, setMount] = React.useState(true);
	const [activityTypeSelected, setActivityTypeSelected] = React.useState<string>('');

	const [listComponets, setlistComponets] = React.useState<string[]>([]);
	const [listTypeComponet, setlistTypeComponet] = React.useState<string[]>([]);
	const [Componets, setComponets] = React.useState('');
	const [selectedTypeComponet, setSelectedTypeComponet] = React.useState('');

	const [statusSelected, setStatusSelected] = React.useState('');

	const getUnit = useCallback(
		async (unit: number) => {
			const url = `proccess-activity/unit/${unit}`;
			const { permisos } = getTokenScopes();
			const body = {};
			if ((permisos as number) > 2) history.push('/');
			const result: any = await peticion({
				accion: url,
				formData: body,
			});

			setList(result.data.activities);
			setlistComponets(result.data.listComponent);
			setlistTypeComponet(result.data.listTypeComponent);
		},
		[setList, peticion, history, getTokenScopes]
	);

	useEffect(() => {
		if (mount) {
			setMount(false);
			getUnit(1);
		}
	}, [mount, getUnit]);

	const loadActivity = async (activity: any, typeActivity: any) => {
		const url = `proccess-activity/activity/${activity}/${typeActivity}`;

		const result: any = await peticion({
			accion: url,
			formData: {},
		});
		setActivityData(result.data);
		setType(typeActivity);
	};

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setComponets(event.target.value as string);
	};
	const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedTypeComponet(event.target.value as string);
	};

	return (
		<>
			<Typography
				variant='h4'
				component='div'
				color='primary'
				gutterBottom
				className={classes.title}
			>
				<Grid container spacing={2}>
					<Grid item md={3}>
						<MenuUnit
							onChange={unit => {
								setList([]);
								setActivityData(null);
								getUnit(unit);
							}}
						/>
					</Grid>
					<Grid item md={9}>
						<Grid container spacing={2}>
							<Grid item md={2}>
								<FormControl className={classes.formControlSelect}>
									<InputLabel>Actividad</InputLabel>
									<Select
										// native
										value={activityTypeSelected}
										onChange={e => {
											setActivityTypeSelected(`${e.target.value}`);
										}}
										inputProps={{
											name: 'Componente',
											id: 'Componente-native',
										}}
									>
										<MenuItem value=''>None</MenuItem>
										{[
											'writing',
											'exercise',
											'reading',
											'quiz',
											'evaluation',
											'audio-podcast',
											'pronunciation',
										].map((activityType: any) => (
											<MenuItem key={uuid()} value={activityType}>
												{activityType}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item md={3}>
								<Grid container justifyContent='space-between' alignItems='center'>
									<Grid item md={9}>
										<FormControl className={classes.formControlSelect}>
											<InputLabel>Component</InputLabel>
											<Select
												// native
												value={Componets}
												onChange={handleChange}
												inputProps={{
													name: 'Componente',
													id: 'Componente-native',
												}}
											>
												<MenuItem value=''>None</MenuItem>
												{listComponets.map(({ component }: any) => (
													<MenuItem key={uuid()} value={component}>
														{component}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item>
										<AddComponent
											onChange={component => {
												setlistComponets(listComponets.concat([component]));
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item md={3}>
								<Grid container justifyContent='space-between' alignItems='center'>
									<Grid item md={9}>
										<FormControl className={classes.formControlSelect}>
											<InputLabel>TypeComponent</InputLabel>
											<Select
												// native
												value={selectedTypeComponet}
												onChange={handleTypeChange}
												inputProps={{
													name: 'Componente',
													id: 'Componente-native',
												}}
											>
												<MenuItem value=''>None</MenuItem>
												{listTypeComponet.map(({ typeComponent }: any) => (
													<MenuItem key={uuid()} value={typeComponent}>
														{typeComponent}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item>
										<AddTypeComponent
											onChange={component => {
												setlistComponets(listComponets.concat([component]));
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item md={2}>
								<FormControl className={classes.formControlSelect}>
									<InputLabel>status</InputLabel>
									<SelectStatus selected={statusSelected} onChange={setStatusSelected} />
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<List component='nav' style={{ height: 'calc(100vh - 180px)', overflow: 'auto' }}>
						{list
							? list.map(
									(
										{
											link,
											activity,
											name,
											oldPath,
											component,
											typeActivity,
											linklocal,
											typeComponent,
											updated,
											statusRevision,
										},
										index
									) =>
										(activityTypeSelected === '' || typeActivity === activityTypeSelected) &&
										(Componets === '' || component === Componets) &&
										(selectedTypeComponet === '' || typeComponent === selectedTypeComponet) &&
										(statusSelected === '' || statusSelected === statusRevision) ? (
											<ListItem
												button
												key={uuid()}
												selected={selectedIndex === index}
												onClick={() => {
													setActivityData(null);
													loadActivity(activity, typeActivity);
													setSelectedIndex(index);
												}}
											>
												<ListItemText
													primary={
														<>
															<div className={clsx(classes.list, [typeActivity])}>
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	{updated === '1' ? (
																		<CheckCircle style={{ fontSize: '14px', marginRight: '8px' }} />
																	) : (
																		<RadioButtonUnchecked
																			style={{ fontSize: '14px', marginRight: '5px' }}
																		/>
																	)}
																	{typeActivity}
																</div>
															</div>

															<span className={classes.list}>
																Actividad: {activity} {name}
															</span>
														</>
													}
													secondary={
														<>
															<span className={classes.textComponet}>Ruta: {oldPath}</span>
															<span className={classes.textComponet}>{component}</span>
														</>
													}
												/>
												<ListItemIcon color=''>
													{updated === '1' ? <Sync /> : <AddCircle />}
													<span className={clsx(classes.status, [`${statusRevision}-icon`])}>
														{statusRevision === 'nuevo' ? <NewReleases /> : null}
														{statusRevision === 'pendiente' ? <Error /> : null}
														{statusRevision === 'listo' ? <CheckCircle /> : null}
													</span>
												</ListItemIcon>
											</ListItem>
										) : null
							  )
							: null}
					</List>
				</Grid>
				<Grid item xs={9}>
					{activityData ? (
						<ProccessDetail
							data={activityData}
							typeActivity={type}
							onUpdate={(data: ActivityProgress) => {
								const activities: any[] = list;
								for (const item of activities) {
									if (data.activity === item.activity) {
										item.statusRevision = data.statusRevision;
									}
								}
								setList([]);
								setList(activities);
							}}
						/>
					) : null}
				</Grid>
			</Grid>
		</>
	);
}

interface ProccessDetailProps {
	data: ActivityProgress;
	typeActivity: string;
	onUpdate: (data: ActivityProgress) => void;
}

interface SelectComponentProps {
	list: any[];
	label: string;
	selected: number | string;
	onChange: (value: number | string) => void;
}

export function SelectStatus(props: { selected: string; onChange: (value: string) => void }) {
	const classes = useStyles();
	const { onChange, selected } = props;
	const [open, setOpen] = useState(false);
	const [valueSelected, setValueSelected] = useState<string>(selected);
	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as string;
		setValueSelected(value);
		onChange(value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};
	return (
		<Select
			open={open}
			onClose={handleClose}
			onOpen={handleOpen}
			value={valueSelected}
			onChange={handleChange}
			className={clsx(classes.status, [`${valueSelected}-icon`])}
		>
			<MenuItem className={classes.status} value='' color='primary'>
				None
			</MenuItem>
			<MenuItem
				className={clsx(classes.status, ['pendiente-icon'])}
				value='pendiente'
				color='primary'
			>
				<Error className={classes.statusIcon} /> Pendiente
			</MenuItem>
			<MenuItem className={clsx(classes.status, ['listo-icon'])} value='listo'>
				<CheckCircle className={classes.statusIcon} /> Listo
			</MenuItem>
			<MenuItem className={clsx(classes.status, ['nuevo-icon'])} value='nuevo'>
				<NewReleases className={classes.statusIcon} /> Nuevo
			</MenuItem>
		</Select>
	);
}

export function SelectComponent(props: SelectComponentProps) {
	const { list, label, onChange, selected } = props;
	const [open, setOpen] = useState(false);
	const [valueSelected, setValueSelected] = useState((selected ?? 0) as number);
	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as number;
		setValueSelected(value);
		onChange(value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};
	return (
		<>
			<InputLabel>{label}</InputLabel>
			<Select
				open={open}
				onClose={handleClose}
				onOpen={handleOpen}
				value={valueSelected}
				onChange={handleChange}
			>
				<MenuItem value={0}>
					<em>None</em>
				</MenuItem>

				{label === 'componentId' && list && list?.length > 0
					? list?.map(({ id, component }: any) => (
							<MenuItem key={uuid()} value={id}>
								{component}
							</MenuItem>
					  ))
					: list?.map(({ id, typeComponent }: any) => (
							<MenuItem key={uuid()} value={id}>
								{typeComponent}
							</MenuItem>
					  ))}
			</Select>
		</>
	);
}
export function SelectTypeComponent(props: SelectComponentProps) {
	const { list, label, onChange, selected } = props;
	const [open, setOpen] = useState(false);
	const [valueSelected, setValueSelected] = useState((selected ?? 0) as number);
	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const value = event.target.value as number;
		setValueSelected(value);
		onChange(value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};
	return (
		<>
			<InputLabel>{label}</InputLabel>
			<Select
				open={open}
				onClose={handleClose}
				onOpen={handleOpen}
				value={valueSelected}
				onChange={handleChange}
			>
				<MenuItem value={0}>
					<em>None</em>
				</MenuItem>

				{label === 'componentId' && list && list?.length > 0
					? list?.map(({ id, component }: any) => (
							<MenuItem key={uuid()} value={id}>
								{component}
							</MenuItem>
					  ))
					: list?.map(({ id, typeComponent }: any) => (
							<MenuItem key={uuid()} value={id}>
								{typeComponent}
							</MenuItem>
					  ))}
			</Select>
		</>
	);
}
export function ProccessDetail(props: ProccessDetailProps) {
	const ref: any = useRef();
	const classes = useStyles();
	const { data, typeActivity, onUpdate } = props;
	const { activityId, componentList, typeComponentList } = data;
	const [newActivity, setNewActivity] = useState<ActivityProgress>(data);
	const [modalOpen, setModalOpen] = useState(false);
	const [message, setMessage] = useState<any>(null);
	const [change, setChange] = useState(false);

	const [activityContent, setActivityContent] = React.useState<Activity>();
	const [filtertypeComponentList, setFiltertypeComponentList] = React.useState(
		typeComponentList.filter(typeComponent =>
			typeComponent.components.find((item: any) => item.component === newActivity.componentId)
		)
	);

	const { peticion } = usePeticiones();
	const launchToast = (msg: any) => {
		setMessage(msg);
	};
	const saveActivity = async () => {
		try {
			const result = await peticion({
				accion: `proccess-activity/${newActivity?.route}/${activityId}/${typeActivity}`,
				formData: newActivity,
			});
			setNewActivity(result.data);
		} catch (error: any) {
			launchToast(error.msg);
		}
	};

	const updateActivity = (key: string, event: any) => {
		const activityCopy: any = newActivity;
		activityCopy[key] = event?.currentTarget?.value ?? event;
		setNewActivity(activityCopy);
	};

	const updateListTypeComponent = (key: string) => {
		if (key === 'componentId') {
			const filtertypeComponent = typeComponentList.filter(typeComponent =>
				typeComponent.components.find((item: any) => item.component === newActivity.componentId)
			);
			setFiltertypeComponentList(filtertypeComponent);
		}
	};

	const updateReactive = (key: string, index: number, event: any) => {
		const activityCopy: any = newActivity;
		activityCopy.reactives[index][key] = event?.currentTarget?.value ?? event;
		setNewActivity(activityCopy);
	};
	const addReactive = (reactive: any) => {
		const activityCopy: any = newActivity;
		activityCopy.reactives.push(reactive);
		setNewActivity(activityCopy);
	};
	const getExercise = async () => {
		const body = {};
		const result: any = await peticion({
			accion: `activity/${activityId}`,
			formData: body,
		});
		setActivityContent(result.data);
	};

	const scrollToBottom = () => {
		setTimeout(() => {
			ref?.current?.scrollIntoView({ behavior: 'smooth' });
		}, 1000);
	};
	const getData = (key: string, dataActivity: ActivityProgress) => {
		const activityCopy: any = dataActivity;
		return activityCopy[key];
	};
	const deleteReactive = async (reactive: any) => {
		const { id } = reactive;
		const activityCopy: any = newActivity;

		try {
			const result = await peticion({
				accion: `proccess-activity/delete-reactive/${id}`,
				formData: {},
			});
			if (result.success) {
				setChange(true);
				const index = activityCopy.reactives.indexOf(reactive);
				activityCopy.reactives.splice(index, 1);
				setNewActivity(activityCopy);
				setChange(false);
				setMessage(result.data.msg);
			}
		} catch (error: any) {
			launchToast(error.msg);
		}
	};
	const closeToastBar = () => {
		setMessage(null);
	};

	const updateStatus = async (status: string) => {
		try {
			const result = await peticion({
				accion: `proccess-activity/update-status/${activityId}`,
				formData: { statusRevision: status },
			});
			if (result && result.data) {
				const activityCopy: any = newActivity;
				activityCopy.statusRevision = status;
				setNewActivity(activityCopy);
				onUpdate(activityCopy);
				launchToast('se actualizo con exito');
			}
		} catch (error: any) {
			launchToast(error.msg);
		}
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={8}>
					<div style={{ overflow: 'auto', height: '100%' }}>
						<div>
							{message ? (
								<>
									<Snackbar open={message !== null} autoHideDuration={2500} onClose={closeToastBar}>
										<Alert onClose={closeToastBar} severity='success'>
											{message}
										</Alert>
									</Snackbar>
								</>
							) : null}
							<CreateReactive
								onClick={() => {
									setModalOpen(true);
								}}
								activity={newActivity}
								updateActivity={addReactive}
								componentList={componentList}
								typeActivity={typeActivity}
								onClose={() => {
									setModalOpen(false);
									scrollToBottom();
								}}
							/>
							<div className={classes.infoActivity}>
								<Typography
									variant='h6'
									component='div'
									color='primary'
									gutterBottom
									className={classes.title}
								>
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<span>Actividad {newActivity.activityId}</span>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											{newActivity.statusRevision ? (
												<SelectStatus
													selected={newActivity.statusRevision}
													onChange={(value: any) => {
														updateStatus(value);
													}}
												/>
											) : null}
											<CommmentsRevision
												activity={newActivity.activity}
												comments={newActivity.comments}
												onUpdateComment={newComments => {
													const activityCopy: any = newActivity;
													activityCopy.statusRevision = null;
													setNewActivity(activityCopy);
													activityCopy.comments = newComments;
													if (newComments > 0) {
														updateStatus('pendiente');
														activityCopy.statusRevision = 'pendiente';
													}
													setNewActivity(activityCopy);
												}}
											/>
										</div>
									</div>
								</Typography>

								<div className={classes.reactive}>
									{Object.keys(newActivity).map(key => (
										<div key={uuid()}>
											{change}
											{key !== 'reactives' &&
											key !== 'route' &&
											key !== 'statusRevision' &&
											key !== 'table' &&
											key !== 'componentList' &&
											key !== 'componentId' &&
											key !== 'typeComponentId' &&
											key !== 'random' &&
											key !== 'example' &&
											key !== 'zoom' &&
											key !== 'typeComponentList' ? (
												<div key={uuid()} className={classes.formControl}>
													<TextField
														label={key}
														fullWidth
														variant='outlined'
														multiline={key === 'contentText'}
														rows={key === 'contentText' ? 12 : 1}
														defaultValue={getData(key, newActivity)}
														onChange={e => {
															updateActivity(key, e);
														}}
													/>
												</div>
											) : null}
											{key === 'componentId' || key === 'typeComponentId' ? (
												<div className={classes.formControl}>
													Actividad
													<SelectComponent
														selected={getData(key, newActivity)}
														list={key === 'componentId' ? componentList : filtertypeComponentList}
														label={key}
														onChange={value => {
															updateActivity(key, value);
															updateListTypeComponent(key);
														}}
													/>
												</div>
											) : null}
											{key === 'example' || key === 'random' || key === 'zoom' ? (
												<div className={classes.formControl}>
													<SelectComponent
														selected={getData(key, newActivity)}
														list={[
															{ id: 0, typeComponent: 'false' },
															{ id: 1, typeComponent: 'true' },
														]}
														label={key}
														onChange={value => {
															updateActivity(key, value);
														}}
													/>
												</div>
											) : null}
										</div>
									))}

									<hr className={classes.formControl} />
								</div>
							</div>
						</div>

						<ul className={classes.infoActivity}>
							<Typography
								variant='h6'
								component='h1'
								color='primary'
								gutterBottom
								className={classes.title}
							>
								Lista de reactivos ({newActivity.table}) {newActivity.route}
							</Typography>
							{newActivity && !modalOpen ? (
								<>
									{newActivity.reactives.map((reactive: any, index: number) => (
										<li className={classes.reactive} key={uuid()}>
											{newActivity.route === 'update-activity' ? (
												<div style={{ display: 'flex', justifyContent: 'space-between' }}>
													<div>
														<b> id : {reactive.id}</b>{' '}
													</div>
													<Button
														onClick={() => {
															deleteReactive(reactive);
														}}
													>
														{' '}
														<Delete /> Eliminar Reactivo
													</Button>
												</div>
											) : null}
											{Object.keys(reactive).map(key => (
												<div key={uuid()}>
													{key !== 'id' &&
													key !== 'activityId' &&
													key !== 'componentId' &&
													key !== 'typeComponentId' ? (
														<div className={classes.formControl}>
															<TextField
																label={key}
																fullWidth
																variant='outlined'
																multiline={key === 'contentText'}
																rows={key === 'contentText' ? 12 : 1}
																defaultValue={reactive[key]}
																onChange={e => {
																	updateReactive(key, index, e);
																}}
															/>
														</div>
													) : null}
													{key === 'componentId' || key === 'typeComponentId' ? (
														<div className={classes.formControl}>
															Reactivos
															<SelectComponent
																selected={reactive[key]}
																list={key === 'componentId' ? componentList : typeComponentList}
																label={key}
																onChange={value => {
																	updateReactive(key, index, value);
																	updateListTypeComponent(key);
																}}
															/>
														</div>
													) : null}
												</div>
											))}

											<hr className={classes.formControl} />
										</li>
									))}
									<li ref={ref} key={uuid()} />
								</>
							) : null}
						</ul>

						<AppBar position='fixed' color='primary' className={classes.appBar}>
							<Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<IconButton color='inherit' onClick={saveActivity}>
									{newActivity.route === 'save-activity' ? 'Crear' : 'Actualizar'}
								</IconButton>
							</Toolbar>
						</AppBar>
					</div>
				</Grid>
				<Grid item xs={4}>
					<Typography variant='body1' component='div' align='center' gutterBottom>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Button onClick={getExercise} variant='outlined' fullWidth>
									Ver Ejercicio
								</Button>
							</Grid>
							<Grid item xs={6}>
								<Button
									variant='outlined'
									href={`/activity/${newActivity.activity}/${typeActivity}`}
									target='_blank'
									fullWidth
								>
									Ver en otra ventana
								</Button>
							</Grid>
						</Grid>
					</Typography>

					{activityContent ? (
						<SelectActivity type={typeActivity} content={activityContent} />
					) : null}
				</Grid>
			</Grid>
		</>
	);
}

export default Admin;
