import React from 'react';
import { v4 as uuid } from 'uuid';
import { makeStyles, Theme, Chip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
	options: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(0.5, 0.5, 3),
	},
	option: {
		margin: theme.spacing(0.5),
		padding: theme.spacing(1.5, 0.5),
		fontFamily: 'inherit',
		fontSize: 'inherit',
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
	},
}));

interface OptionsProps {
	options: string[];
}
export function Options(props: OptionsProps) {
	const { options } = props;
	const classes = useStyles();
	return (
		<div className={classes.options}>
			{options ? (
				<>
					{options.map((option: string, index: number) => (
						<Chip
							key={uuid()}
							// key={option}
							className={classes.option}
							variant='outlined'
							size='small'
							color='primary'
							label={option}
						/>
					))}
				</>
			) : null}
		</div>
	);
}
export default Options;
