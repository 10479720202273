import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyleGuide = makeStyles((theme: Theme) => ({
	contentGuide: {
		margin: theme.spacing(0.5, 0),
		'& .ctext': { textAlign: 'center' },
		'& .dflex': { display: 'flex' },
		'& .dflexAro': { display: 'flex', justifyContent: 'space-around' },
		'& .dflexCJC': { display: 'flex', flexDirection: 'column', justifyContent: 'center' },
		'& .ej_guia': {
			color: theme.palette.primary.main,
			fontStyle: 'italic',
		},
		'& .aparrafo': {
			margin: '10px 0',
		},
		'& .w25': {
			width: '27%',
		},
		'& .va': {
			verticalAlign: 'top',
		},
		'& .imagenContenido': {
			textAlign: 'center',
		},
		'& .tabla_review_mobil': {
			width: '100%',
			margin: '16px auto',
			overflow: 'auto',
			maxWidth: '540px',
			overflowY: 'hidden',
			'& .ctext': {
				textAlign: 'center',
			},
			'& .tabla_review_3,& .tabla_review_2': {
				minWidth: '520px',
				'& td': {
					padding: theme.spacing(1),
				},
			},
			'& .tabla_review_2': {
				backgroundColor: theme.palette.primary.main,
			},
			'& .title_table': {
				backgroundColor: theme.palette.grey[100],
			},
			'& .row': {
				backgroundColor: theme.palette.common.white,
			},
		},

		'& .bw200': {
			textAlign: 'center',
			width: '200px',
		},
		'&.displaynone': {
			display: 'none',
		},
		'& li.borde': {
			display: 'flex',
			'& span.pressim': {
				color: theme.palette.info.contrastText,
				float: 'left',
				width: '275px',
				textAlign: 'center',
				border: `1px solid ${theme.palette.info.dark}`,
				background: theme.palette.info.main,
			},
			'& span.pressim.bordeb': {
				color: theme.palette.common.black,
				background: theme.palette.info.light,
			},
		},
		'&.tabla_review': {
			// background: theme.palette.info.light,
			margin: '16px auto',
			width: '100%',
			maxWidth: '540px',
			overflowY: 'hidden',
			overflow: 'auto',
			'& .wn_311_e_1': {
				justifyContent: 'space-between',
				display: 'flex',
				width: '538px',
				textAlign: 'center',
				'& div li.fila1,& div li.fila2': {
					background: theme.palette.info.main,
					border: `1px solid ${theme.palette.info.dark}`,
					color: theme.palette.info.contrastText,
					Height: '45px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					'&.h50': {
						height: '52px',
					},
					'&.h60': {
						height: '70px',
					},
				},
				'& div li.fila2': {
					background: theme.palette.info.light,
					color: theme.palette.common.black,
				},
			},
		},
		'&.Ejemplo': {
			background: '#FFFFA0',
			padding: '10px',
			margin: '16px auto',
			width: '100%',
			maxWidth: '515px',
			overflowY: 'hidden',
			overflow: 'auto',
			'& .wAuto': {
				width: 'auto',
			},
			'& .w20': {
				width: '20px',
			},
			'& .w40': {
				width: '40px',
			},
			'& .corcheh58': {
				height: '58px',
			},
			'& .h180': {
				height: '180px',
			},
			'& strong,& .bold': {
				color: theme.palette.info.main,
				fontWeight: 'bold',
			},
			'& .bold .normal': {
				fontWeight: 'normal',
				color: theme.palette.common.black,
			},
			'& .apptcenter': {
				textAlign: 'center',
			},
			'&.wx130,&.wx150,&.wx193,&.wx200,&.wx215,&.wx240,&.wx250,&.wx269,&.wx270,&.wx280,&.wx290,&.wx300,&.wx315,&.wx330,&.wx350,&.wx360,&.wx367,&.wx390':
				{
					maxWidth: '400px',
					'& li': {
						display: 'block',
						width: '100%',
						'&.tAlC': {
							textAlign: 'center',
						},
						'&.tAlR': {
							textAlign: 'right',
						},
						'&.wcol1,&.wcol11,&.wcol12,&.wcol13': {
							textAlign: 'center',
						},
						'&.w40': {
							width: '40px',
						},
					},
					'& .wn_311_e_1': {
						justifyContent: 'space-between',
						display: 'flex',
						width: '380px',
						textAlign: 'center',
						'& li, & .s_311_e_1,& .s-412-1-e': {
							width: '100%',
							'&.w20': {
								width: '20px',
							},
							'&.w30': {
								width: '30px',
							},
						},
					},
					'& .ejem': {
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						margin: '0',
						padding: '0',
						'& .s_313_e_1': {
							color: theme.palette.info.main,
							fontWeight: 'bold',
							width: '100%',
							textAlign: 'center',
							'& li': {
								width: '100%',
								'&.w20': {
									width: '20px',
								},
								'&.displaynone': {
									display: 'none',
								},
							},
						},
					},
				},

			'&.wx270': {
				justifyContent: 'space-between',
				display: 'flex',
				'& div': {
					width: '100%',
					textAlign: 'center',
				},
			},
			'&.wx400,&.wx420,&.wx450,&.wx479,&.wx465,&.wx390': {
				'& .wn_311_e_1': {
					justifyContent: 'space-between',
					display: 'flex',
					width: '495px',
					'& div': {
						width: 'unset',
					},
					'&.centrar li': {
						textAlign: 'center',
					},
					'& .s_311_e_1': {
						textAlign: 'center',
						width: '100%',
					},
				},
			},
			'&.wx550': {
				'& li': {
					display: 'flex',
					width: '495px',
					'&.wcol2122 span': {
						width: '100%',
					},
					'&.wcol2122 strong': {
						width: '100%',
					},
					'&.wcol2124 span': {
						width: '100%',
					},
					'&.wcol2124 strong': {
						width: '100%',
					},
				},
				'& .wcol2122': {
					width: '243px',
					textAlign: 'center',
				},
				'& .wcol2124': {
					width: '231px',
					textAlign: 'center',
				},
			},
			'&.ej_g_ejemplo1,&.app_ejemplo1': {
				'& div': {
					width: '100%',
					textAlign: 'center',
					'&.w20': {
						width: '20px',
					},
				},
				'& .wn_311_e_1': {
					width: '495px',
					maxWidth: '495px',
					textAlign: 'center',
					display: 'flex',
					'& .wAuto': {
						width: 'auto',
					},
					'& li,& .s_311_e_1': {
						width: '100%',
						'&.w20': {
							width: '20px',
						},
					},
				},
			},
		},
		'&.Dialogo': {
			'& .wn_311_e_1': {
				justifyContent: 'space-between',
				display: 'flex',
				textAlign: 'center',
				'&.jContentStar': {
					justifyContent: 'flex-start',
				},
			},
		},
	},
}));

export { useStyleGuide };
export default useStyleGuide;
