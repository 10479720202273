import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { BlockActivity } from '.';
import { PronunciationProps } from './Unit-interface';

function Pronunciation(props: PronunciationProps): JSX.Element {
	const { pronunciation } = props;

	return (
		<>
			<Grid item xs={12} sm={6} className='MuiPronun'>
				<Typography className='MuiTitleLesson' align='center' variant='h5'>
					{pronunciation.name}
				</Typography>
				<Grid item xs={12} className='MuiBloques'>
					<BlockActivity
						activated={pronunciation.activated}
						activities={pronunciation.activities}
					/>
				</Grid>
			</Grid>
		</>
	);
}

export { Pronunciation };
export default Pronunciation;
