/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Theme,
	makeStyles,
	Grid,
	Typography,
	Container,
	TextField,
	IconButton,
	Modal,
	Avatar,
	InputAdornment,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { AppContext } from '../providers/AppContext';
import { useLocalstorage } from '../lib/use-Localstorage';

import Pageresetpassword from '../pages/Page-Reset-Password';
import usePeticiones from '../lib/use-Peticiones';

import useMessage from '../lib/use-message';

import { ToastBar } from './ToastBar';

interface EditprofilesProps {
	open: boolean;
	handleEditProfile?: () => void;
}

Editprofiles.defaultProps = {
	handleEditProfile: () => {},
};

const useStyles = makeStyles((theme: Theme) => ({
	profile: {
		fontFamily: "'Lato', sans-serif;",
		'& .MuiDialogTitle-root': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			'& h2': {
				fontFamily: 'inherit',
				textAlign: 'center',
				fontSize: 'calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)));',
			},
		},
		'& .MuiDialogContent-root': {
			fontSize: 'calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)));',
		},
		'& .separador': {
			borderRight: `1px solid ${theme.palette.grey[400]}`,
		},
		'& .AddText': {
			display: 'flex',
			'& .MuiTypography-root': {
				display: 'contents',
			},
		},
	},
	SubTile: {
		color: theme.palette.primary.main,
		borderBottom: `1px solid ${theme.palette.primary.main}`,
		'& p': {
			display: 'flex',
			alignItems: 'center',
			'& svg': {
				marginRight: '8px',
			},
		},
	},
	aling: {
		textAlign: 'right',
		'& .MuiButtonBase-root.mr ': {
			marginRight: '8px',
		},
	},
	bordeDown: {
		borderBottom: '1px solid #bdbdbd',
		marginBottom: '16px',
		flexDirection: 'column-reverse',
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'row-reverse',
		},
		'& p': {
			padding: '4px 8px',
		},
	},
	bordeDownPI: {
		borderBottom: '1px solid #bdbdbd',
		marginBottom: '16px',
		'& p': {
			padding: '4px 8px',
		},
	},
	UploadAvatar: {
		'&.inputFile': {
			width: '0.1px',
			height: '0.1px',
			opacity: '0',
			overflow: 'hidden',
			position: 'absolute',
			zIndex: '-1',
		},
		'&.buttonUpload': {
			cursor: 'pointer',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: '4px',
			'& .sizeAvatar': {
				width: '80px',
				height: '80px',
			},
			'& .sizeAvatarSvg': {
				width: '80px',
				height: '80px',
				'& svg': {
					fontSize: '60px',
				},
			},
			'& figure': {
				marginBlockStart: '0.5em',
				marginBlockEnd: '0.5em',
				marginInlineStart: '20px',
				marginInlineEnd: '20px',
				position: 'relative',
			},
		},
		'& .iconCamara': {
			position: 'absolute',
			bottom: '0px',
			right: '-7px',
			padding: '6px',
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			borderRadius: '50%',
		},
	},
}));

export interface UploadavatarProps {
	changeFile: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
}

function Uploadavatar(props: UploadavatarProps) {
	const { changeFile } = props;
	const classes = useStyles();
	const { activeAvatar } = React.useContext<any>(AppContext);

	return (
		<>
			<input
				type='file'
				name='file-avatar'
				id='file-avatar'
				className={clsx(classes.UploadAvatar, 'inputFile')}
				data-multiple-caption='{count} files selected'
				onChange={event => changeFile(event)}
			/>
			<label htmlFor='file-avatar' className={clsx(classes.UploadAvatar, 'buttonUpload')}>
				<figure>
					{activeAvatar() ? (
						<Avatar src={activeAvatar()} className='sizeAvatar' />
					) : (
						<Avatar className='sizeAvatarSvg'>
							<UploadIcon />
						</Avatar>
					)}
					<CameraAltIcon fontSize='medium' className={clsx(classes.UploadAvatar, 'iconCamara')} />
				</figure>
			</label>
		</>
	);
}

function Editprofiles({ open, handleEditProfile }: EditprofilesProps) {
	const { peticion, peticionEspecial } = usePeticiones();
	const { getToken, updateAvatar, getTokenScopes, updateLoginLS } = useLocalstorage();
	const [menssage, setMenssage] = React.useState(useMessage.ini[0]);
	const { updateAvatarDinamic } = React.useContext<any>(AppContext);
	const [openPassword, setOpenPassword] = React.useState<boolean>(false);
	const info = getTokenScopes();

	const classes = useStyles();

	const [inputCapt, setInputCapt] = React.useState<any>({
		name: 'Cliente',
		initialValue: [
			{
				id: uuid(),
				etiqueta: 'Name',
				valor: `${info.nombre}`,
				campo: 'nombre',
				activo: true,
			},
			{
				id: uuid(),
				etiqueta: 'last name',
				valor: `${info.apellidoPaterno}`,
				campo: 'apellidoPaterno',
				activo: true,
			},
			{
				id: uuid(),
				etiqueta: 'last name',
				valor: `${info.apellidoMaterno}`,
				campo: 'apellidoMaterno',
				activo: true,
			},
			{
				id: uuid(),
				etiqueta: 'Email',
				valor: `${info.email}`,
				campo: 'email',
				activo: true,
			},
		],
	});

	const tmpToken = getToken();

	const handleEdit = (idx: string) => {
		const newinitialValue = inputCapt.initialValue.map((i: any) => {
			if (idx === i.id) {
				i.activo = !i.activo;
				if (i.activo) {
					grabarInfo(`{ "${i.campo}" : "${i.valor}" }`);
				}
			}
			return i;
		});
		setInputCapt({ ...inputCapt, initialValue: newinitialValue });
	};

	const handleMesagge = () => {
		setMenssage(useMessage.ini[0]);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newinitialValue = inputCapt.initialValue.map((i: any) => {
			if (event.target.name === i.id) {
				i.valor = event.target.value;
			}
			return i;
		});
		setInputCapt({ ...inputCapt, initialValue: newinitialValue });
	};

	const handleClose = () => {
		setOpenPassword(false);
	};

	const visbleChangePassword = (
		<Modal
			open={openPassword}
			onClose={handleClose}
			aria-labelledby='Change-Password'
			aria-describedby='Change-Password'
		>
			<Pageresetpassword actToken={tmpToken} handle={handleClose} />
		</Modal>
	);

	const grabarInfo = (data: string) => {
		const body = JSON.parse(`${data}`);

		setMenssage(useMessage.ini[0]);
		peticion({ accion: 'profile', formData: body }).then(
			datos => {
				if (datos.success) {
					updateLoginLS(datos.data);
					setMenssage({ message: 'successful update', accion: 'success' });
				} else {
					setMenssage({ message: datos.message, accion: 'error' });
				}
			},
			error => {}
		);
	};

	const updateAvata = (event: any) => {
		const body = new FormData();
		updateAvatarDinamic(true);

		if (event.target?.files[0]) {
			body.append('file_tmp', event.target.files[0]);
			peticionEspecial({ accion: 'profile/avatar', formData: body }).then(
				(datos: any) => {
					if (datos.success) {
						updateAvatar(datos.data.Avatar);
						updateAvatarDinamic(false);
					} else {
						setMenssage({ message: datos.message, accion: 'error' });
					}
				},
				(error: any) => {
					// eslint-disable-next-line
					console.log(error);
				}
			);
		}
	};

	return (
		<>
			<Dialog open={open} onClose={handleEditProfile} className={classes.profile}>
				<DialogTitle id='alert-dialog-title'>Edit Profile</DialogTitle>
				<DialogContent>
					<Container>
						<Grid container>
							<Grid item xs={12}>
								<Grid item xs={12} className={classes.SubTile}>
									<Typography className='info'>
										<AccountBoxIcon /> Account info
									</Typography>
								</Grid>
								<Grid container className={classes.bordeDown}>
									<Grid xs={12} sm={9} spacing={0} container>
										<Grid item xs={6} className='separador'>
											<Typography variant='body2'>Nº contract</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography variant='body2'>{info.cuenta}</Typography>
										</Grid>
										<Grid item xs={6} className='separador'>
											<Typography variant='body2'>Nº Student</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography variant='body2'>{info.estudiante}</Typography>
										</Grid>
										<Grid item xs={6} className='separador'>
											<Typography variant='body2'>License</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography variant='body2'>1</Typography>
										</Grid>
										<Grid item xs={6} className='separador'>
											<Typography variant='body2'>Validity period</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography variant='body2'>{info.vigencia}</Typography>
										</Grid>
									</Grid>
									<Grid item xs={12} sm={3}>
										<Uploadavatar changeFile={updateAvata} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Grid item xs={12} className={classes.SubTile}>
									<Typography className='info'>
										<PersonIcon /> Personal Information
									</Typography>
								</Grid>
								<Grid container className={classes.bordeDownPI}>
									<Grid xs={12} spacing={0} container>
										{inputCapt.initialValue.map((name: any, index: number) => (
											<React.Fragment key={name.id}>
												<Grid item xs={5} sm={3} className='separador'>
													<Typography variant='body2'>{`${name.etiqueta}`}</Typography>
												</Grid>
												<Grid item xs={7} sm={9} className='AddText'>
													<Typography variant='body2'>
														<TextField
															fullWidth
															variant='standard'
															name={`${name.id}`}
															onChange={event => handleChange(event)}
															disabled={name.activo}
															value={`${name.valor}`}
															type='text'
															InputProps={{
																endAdornment: (
																	<InputAdornment position='end'>
																		<IconButton
																			onClick={() => {
																				handleEdit(`${name.id}`);
																			}}
																		>
																			{!name.activo ? <SaveIcon /> : <EditIcon />}
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</Typography>
												</Grid>
											</React.Fragment>
										))}
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} className={classes.aling}>
								<Button
									size='small'
									variant='outlined'
									className='mr'
									onClick={() => setOpenPassword(true)}
								>
									Change Password
								</Button>
								<Button onClick={handleEditProfile} size='small' variant='contained'>
									Close
								</Button>
							</Grid>
						</Grid>
					</Container>
				</DialogContent>
				<DialogActions />
			</Dialog>

			{visbleChangePassword}
			{menssage.message ? (
				<ToastBar
					color={menssage.accion}
					autoHideDuration={3000}
					anchorOriginV='bottom'
					anchorOriginH='right'
					onClose={handleMesagge}
					message={menssage.message}
				/>
			) : null}
		</>
	);
}

export { Editprofiles };
export default Editprofiles;

// !item.Active ? <SaveIcon /> : <EditIcon />
