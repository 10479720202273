import React from 'react';
import { Container, Grid } from '@material-ui/core';
import Vimeo from '@u-wave/react-vimeo';
import { Activity } from '../Activity-interface';
import { SegmentActivity } from './Segment-Activity';
import useActivity from '../use-Activity';
import { usePeticiones } from '../../../lib';

interface VideoActivityComponentProps {
	activity: Activity;
}

function MediaActivity(props: VideoActivityComponentProps) {
	const { activity } = props;
	const classes = useActivity();
	const isDev = window.location.hostname === 'localhost';
	const { peticion } = usePeticiones();
	const saveProgress = () => {
		peticion({
			accion: 'progress',
			formData: {
				activity: activity.activity,
				unit: activity.unit,
				lesson: activity.lesson,
				block: activity.block,
				type: activity.type,
				average: 100,
			},
		});
	};
	return (
		<>
			<Container className={classes.PageActVideo} maxWidth='lg'>
				<Grid container justifyContent='center' spacing={1}>
					{activity && activity.media ? (
						<Grid item xs={12} className='Video'>
							<Vimeo
								onEnd={saveProgress}
								width='320%'
								video={isDev ? '208191580' : activity.media}
								autoplay
							/>
						</Grid>
					) : null}
					{activity.content ? (
						<Grid item xs={12} className='Contenido'>
							<SegmentActivity content={activity.content} />
						</Grid>
					) : null}
				</Grid>
			</Container>
		</>
	);
}

export { MediaActivity };
export default MediaActivity;
