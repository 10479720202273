import React from 'react';
import clsx from 'clsx';
import { SnackbarContent, Grid, Button } from '@material-ui/core';

import * as MuiIcons from '@material-ui/icons/';
import { useStyles } from '../lib';

type Color = 'success' | 'error' | 'warning' | 'info';
type Vertical = 'top' | 'bottom';
type Horizontal = 'left' | 'right';

type IconName =
	| 'CheckCircleOutlineOutlined'
	| 'WarningOutlined'
	| 'InfoOutlined'
	| 'ErrorOutlineOutlined';

export interface ToastProps {
	message: string;
	color: Color;
	autoHideDuration: number;
	onClose: () => void;
	ontextButton?: string;
	anchorOriginV: Vertical;
	anchorOriginH: Horizontal;
}

function ToastBar(props: ToastProps): JSX.Element {
	const classes = useStyles();
	const { message, color, autoHideDuration, onClose, ontextButton, anchorOriginV, anchorOriginH } =
		props;

	let icons: IconName = 'CheckCircleOutlineOutlined';
	if (color === 'error') {
		icons = 'ErrorOutlineOutlined';
	} else if (color === 'warning') {
		icons = 'WarningOutlined';
	} else if (color === 'info') {
		icons = 'InfoOutlined';
	}

	React.useEffect(() => {
		if (autoHideDuration >= 1000 && Boolean(ontextButton) === false) {
			if (onClose) {
				const timeout = setTimeout(() => {
					onClose();
				}, autoHideDuration);

				return () => clearTimeout(timeout);
			}
		}
		return undefined;
	}, [ontextButton, autoHideDuration, onClose]);

	const icon = React.createElement(MuiIcons[icons]);

	const action = (
		<Button variant='outlined' color='secondary' size='small' onClick={onClose}>
			ontextButton
		</Button>
	);

	const msg = (
		<>
			<Grid container justifyContent='center' spacing={1}>
				<Grid item>{icon}</Grid>
				<Grid item>{message}</Grid>
			</Grid>
		</>
	);

	return (
		<>
			<div
				className={clsx(classes.SnackbarAlert, {
					[classes.SnackbarAlertB]: anchorOriginV === 'bottom',
					[classes.SnackbarAlertR]: anchorOriginH === 'right',
				})}
			>
				<SnackbarContent className={color} message={msg} action={ontextButton ? action : null} />
			</div>
		</>
	);
}

ToastBar.defaultProps = {
	ontextButton: '',
};

export { ToastBar };
export default ToastBar;
