import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
	IconButton,
	Badge,
	List,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	TextField,
	Button,
} from '@material-ui/core';
import { Add, Check, CheckCircle, Comment } from '@material-ui/icons';
import DialogSimple from './Dialog-Simple';
import { usePeticiones } from '../lib';

function AddComponnent(props: { activity: number; onChange: (comment: any) => void }) {
	const { activity, onChange } = props;
	const [comment, setComment] = useState<string>('');
	const [showInput, setShowInput] = useState(false);
	const { peticion } = usePeticiones();
	const addComment = () => {
		peticion({
			accion: `proccess-activity/add-comment/${activity}`,
			formData: { observacion: comment },
		})
			.then(result => {
				setShowInput(false);
				onChange(result.data);
			})
			.catch(error => {});
	};

	return (
		<>
			<div style={{ display: 'flex' }}>
				{showInput ? (
					<div
						style={{ width: '100%', justifyContent: 'flex-end', display: 'flex', flexWrap: 'wrap' }}
					>
						<TextField
							variant='outlined'
							rows={5}
							multiline
							label='Observacion'
							fullWidth
							onChange={e => {
								setComment(e.currentTarget.value);
							}}
						/>
						<div
							style={{
								display: 'flex',
								alignContent: 'flex-end',
								marginTop: '16px',
								marginBottom: '16px',
							}}
						>
							<Button
								variant='contained'
								onClick={() => {
									setShowInput(false);
									setComment('');
								}}
								style={{
									marginRight: '16px',
								}}
							>
								cancelar
							</Button>{' '}
							<Button variant='contained' color='primary' onClick={addComment}>
								guardar
							</Button>
						</div>
					</div>
				) : (
					<IconButton
						onClick={() => {
							setShowInput(true);
						}}
					>
						Agregar observación <Add />
					</IconButton>
				)}
			</div>
		</>
	);
}
function CommentList(props: { list: any[]; onUpdateCommponent: (component: any) => void }) {
	const { list, onUpdateCommponent } = props;
	const { peticion } = usePeticiones();
	const updateComments = (item: any) => {
		peticion({
			accion: `proccess-activity/update-comment/${item.id}`,
			formData: {},
		})
			.then(result => {
				onUpdateCommponent(item);
			})
			.catch(error => {});
	};
	return (
		<>
			<List style={{ background: '#ccc', padding: '2%' }}>
				{list?.map(item => (
					<Card key={uuid()} style={{ margin: '8px' }}>
						<CardHeader title='Observacion' subheader={item.user} />
						<CardContent>{item.observacion}</CardContent>
						<CardActions disableSpacing style={{ justifyContent: 'flex-end' }}>
							<IconButton
								onClick={() => {
									if (item.status !== 'listo') {
										updateComments(item);
									}
								}}
							>
								{item.status === 'listo' ? <CheckCircle color='secondary' /> : <Check />}
							</IconButton>
						</CardActions>
					</Card>
				))}
			</List>
		</>
	);
}

export function CommmentsRevision(props: {
	activity: number;
	comments: number;
	onUpdateComment: (comment: number) => void;
}) {
	const { activity, comments, onUpdateComment } = props;
	const [commentNumber, setCommentNumber] = useState<number>(comments);
	const [openDialog, setOpenDialog] = useState(false);
	const [commentList, setCommentList] = useState<any[]>([]);
	const handleOpenComments = () => {
		setOpenDialog(true);
		getComments();
	};
	const { peticion } = usePeticiones();

	const getComments = () => {
		peticion({
			accion: `proccess-activity/comments/${activity}`,
			formData: {},
		})
			.then(result => {
				setCommentList(result.data);
			})
			.catch(error => {});
	};

	return (
		<>
			<IconButton onClick={handleOpenComments}>
				<Badge badgeContent={commentNumber} color='error'>
					<Comment />
				</Badge>
			</IconButton>
			<DialogSimple open={openDialog} content='' handleClose={() => setOpenDialog(false)}>
				<AddComponnent
					onChange={newComment => {
						const list = commentList;
						list.unshift(newComment);
						setCommentList([]);
						setCommentList(list);
						let noRealized = 0;
						for (const comment of list) {
							if (comment.status !== 'listo') {
								noRealized += 1;
							}
						}
						onUpdateComment(noRealized);
						setCommentNumber(noRealized);
					}}
					activity={activity}
				/>
				{commentList ? (
					<CommentList
						list={commentList}
						onUpdateCommponent={(item: any) => {
							const list = commentList;
							let noRealized = 0;
							for (const comment of list) {
								if (item.id === comment.id) {
									comment.status = 'listo';
								}
								if (comment.status !== 'listo') {
									noRealized += 1;
								}
							}
							onUpdateComment(noRealized);
							setCommentNumber(noRealized);
							setCommentList([]);
							setCommentList(list);
						}}
					/>
				) : null}
			</DialogSimple>
		</>
	);
}

export default CommmentsRevision;
