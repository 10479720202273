import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { makeStyles, Theme, Typography, Chip } from '@material-ui/core';

import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';
import AudioExercise from '../audio-exercise';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
		verticalAlign: 'baseline',
		alignItems: 'center',
		flexWrap: 'wrap',
		lineHeight: '1.8',
	},
	containerDroppable: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	droppable: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		border: `solid 2px `,
		borderBlockColor: `${theme.palette.primary.main} `,
		borderStyle: 'dashed',
		borderRadius: '30px',
		color: theme.palette.primary.main,
		boxSizing: 'content-box',
		margin: '0 5px',
		padding: theme.spacing(0.2, 2),
		minHeight: '24px',
		minWidth: `90px`,
		lineHeight: 'normal',
	},
	over: { border: `2px dashed ${theme.palette.grey[900]} !important` },
	correct: {
		borderColor: `${theme.palette.success.main} `,
		color: theme.palette.success.main,
		backgroundColor: '#4caf500d',
	},
	incorrect: {
		borderColor: `${theme.palette.error.main} `,
		color: theme.palette.error.main,
		backgroundColor: '#f443360d',
	},
	optionContenedor: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
		},
	},
	options: {
		margin: theme.spacing(0.5),
		cursor: 'pointer',
		display: 'inline-block',
		'& .MuiChip-root': {
			boxSizing: 'content-box',
			border: `2px solid `,
			borderBlockColor: `${theme.palette.primary.main} `,
			borderStyle: 'dashed',
			fontSize: 'inherit',
			fontFamily: 'inherit',
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
			color: theme.palette.primary.main,
			minHeight: '24px',
		},
		'& .options-disable': {
			borderBlockColor: `${theme.palette.grey[500]}`,
			color: theme.palette.grey[500],
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
		},
	},
}));

function DragAndDrop(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent } = typeActivity;
	const { question, answer, img, reactives, media, localMedia, time } = reactive;
	const classes = useStyles();
	const [userAnswer, setUserAnswer] = useState<any[]>([]);
	const [correctAnswer, setCorrectAnswer] = useState<string>('');
	const [multipleAnswer, setMultipleAnswer] = useState<string[][]>([]);
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [mount, setMount] = useState<boolean>(false);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [sentence, setSentence] = useState<any>([]);

	const [updated, setUpdated] = React.useState(1);

	const checkAnswer = () => {
		const multiAnswers = multipleAnswer;
		const inputAnswer = userAnswer;
		let correctAnswersNumber = 0;

		for (const ia of inputAnswer) {
			let indexMultiAnswer = 0;
			ia.isCorrect = false;
			for (const ma of multiAnswers) {
				const correct = ma.includes(ia.value);
				if (correct) {
					correctAnswersNumber += 1;
					ia.isCorrect = correct;
					multiAnswers.splice(indexMultiAnswer, 1);
				}
				indexMultiAnswer += 1;
			}
		}

		setUserAnswer(inputAnswer);

		const isTrue = correctAnswersNumber === inputAnswer.length;

		setIsCorrect(isTrue);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const nextAnswer = () => {
		onNext();
		let ua = question;
		userAnswer.reduce((array, b) => {
			array.push(b.value);
			ua = ua.replace('|', `<b><u>${b.value}</u></b>`);
			return array;
		}, []);
		onCheck(isCorrect ?? false, [ua], '', media, localMedia, img, activaAnswer);
	};

	const dropChange = (valueAnswer: string, index: number) => {
		const answerInput: any = userAnswer;
		answerInput[index] = { value: valueAnswer, isCorrect: null };
		setUserAnswer(answerInput);
		setCheckDisabled(false);
		setUpdated(updated + 1);
	};

	useEffect(() => {
		const createMultipleAnswers = () => {
			const answers = answer.reduce((array: any[], b: string) => {
				const a = b.split('=');
				array.push(a);

				return array;
			}, []);
			setMultipleAnswer(answers);
		};

		const createInputReactives = () => {
			let inputCount = 0;
			let ca = ''; // correctAnswer
			const emptyUserAnswer: any[] = [];
			const reactiveSentence = question.split(' ').reduce((array: any[], b: string) => {
				const replace = b.split('').includes('|');
				if (replace) {
					ca += ' ';
					const wordReplace = b.split('');
					for (const word of wordReplace) {
						if (word === '|') {
							array.push({
								index: inputCount,
								width: multipleAnswer[inputCount][0].length,
							});
							emptyUserAnswer.push({ value: '', isCorrect: null });
							ca += `<b><u>${multipleAnswer[inputCount][0]}</u></b>`;
							inputCount += 1;
						} else {
							array.push(word);
							ca += `${b.replaceAll('|', '')}`;
						}
					}
				} else {
					ca += ` ${b}`;
					array.push(b);
				}
				return array;
			}, []);
			setSentence(reactiveSentence);
			setUserAnswer(emptyUserAnswer);
			setCorrectAnswer(ca);
		};
		if (!mount) {
			createMultipleAnswers();

			setMount(true);
		}
		if (mount && sentence.length === 0) {
			createInputReactives();
		}
	}, [
		mount,
		setMount,
		setSentence,
		reactives,
		answer,
		question,
		sentence,
		classes,
		isExample,
		multipleAnswer,
		userAnswer,
	]);
	return (
		<DragDropContext
			// eslint-disable-next-line react/jsx-props-no-spreading
			onDragEnd={(result: any) => {
				if (result.destination) {
					dropChange(reactives[result.source.index], result.destination.droppableId as number);
				}
			}}
		>
			<div className={classes.reactive}>
				{typeComponent ? '' : null}
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				<Typography component='div' className={classes.question}>
					{sentence.map((input: any, indexWord: any) => (
						<Fragment key={uuid()}>
							{typeof input === 'string' ? (
								<DivSanitized component='span' text={`${input} `} />
							) : (
								<>
									{updated ? (
										<div className={classes.containerDroppable}>
											<Droppable droppableId={`${input.index}`} direction='horizontal'>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														className={clsx(classes.droppable, {
															[classes.over]: snapshot.isDraggingOver,
															[classes.correct]: userAnswer[input.index]?.isCorrect || isExample,
															[classes.incorrect]: userAnswer[input.index]?.isCorrect === false,
														})}
													>
														{[userAnswer[input.index]].map((item, index) => (
															<Draggable
																key={uuid()}
																draggableId={`answer-${input.index}`}
																index={input.index}
																isDragDisabled
															>
																{(dragProvided, DragSnapshot) => (
																	<div
																		ref={dragProvided.innerRef}
																		// eslint-disable-next-line react/jsx-props-no-spreading
																		{...dragProvided.draggableProps}
																		// eslint-disable-next-line react/jsx-props-no-spreading
																		{...dragProvided.dragHandleProps}
																	>
																		{snapshot.isDraggingOver ? null : (
																			<>{isExample ? multipleAnswer[input.index][0] : item.value}</>
																		)}
																	</div>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</div>
									) : null}
								</>
							)}
						</Fragment>
					))}
				</Typography>

				<Droppable droppableId='answer-option' direction='horizontal'>
					{(provided, snapshot) => (
						<div ref={provided.innerRef} className={classes.optionContenedor}>
							{reactives.map((item, index) => (
								<Draggable
									key={item}
									draggableId={`option-${index}`}
									index={index}
									isDragDisabled={isCorrect !== null || isExample}
								>
									{(dragProvided, DragSnapshot) => (
										<div
											className={classes.options}
											ref={dragProvided.innerRef}
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...dragProvided.draggableProps}
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...dragProvided.dragHandleProps}
										>
											<Chip
												className={clsx({
													'options-disable': isExample || !nextDisabled,
												})}
												color='primary'
												label={item}
											/>
										</div>
									)}
								</Draggable>
							))}
						</div>
					)}
				</Droppable>
			</div>
			<CheckButtons
				time={time}
				answer={[correctAnswer]}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</DragDropContext>
	);
}

export { DragAndDrop };
export default DragAndDrop;
