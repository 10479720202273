import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import clsx from 'clsx';
import { Button, makeStyles, Theme, createStyles, Chip } from '@material-ui/core';
import { VoiceChat } from '@material-ui/icons';
import { VirtualClassContext, ILiveClass } from '../providers/VirtualClassContext';
import DivSanitized from './DivSanitized';
import { AppContext } from '../providers/AppContext';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			fontSize: '12px',
			alignItems: 'center',
			color: theme.palette.primary.main,
			'& > *': { margin: ' 0 2px' },
		},
		title: {
			fontSize: '10px',
		},
		buttonClass: {
			textAlign: 'left',
			lineHeight: 'normal',
		},
		classIcon: { opacity: 1, transition: 'all .5s ease-in' },
		hidden: { opacity: '0 !important' },
	})
);

export function NextClassButton() {
	const classes = useStyles();
	const { virtualClassList, getVirtualClassList, getVirtualClassUrl } =
		React.useContext<any>(VirtualClassContext);
	const { me } = React.useContext<any>(AppContext);
	const { permisos } = me;
	const [currentClass, setCurrentClass] = React.useState<ILiveClass | null>(null);
	const [isActive, setIsActive] = React.useState(false);
	const [flashing, setFlashing] = React.useState(false);
	const [timeRemaining, setTimeRemaining] = React.useState(0);

	const tolerance = permisos === '1' ? 45 : 15;
	const updateCurrentClass = (virtualClass: any) => {
		setCurrentClass(virtualClass);
	};

	const getNextClass = useCallback(() => {
		let totalClasses = 0;
		if (virtualClassList)
			for (const virtualClass of virtualClassList) {
				const a = moment(virtualClass.date).utc();
				const b = moment();
				const diff = a.diff(b, 'minutes');
				// condicion alumno (diff < 60 - tolerance && diff > -tolerance)
				if (diff < 60 - tolerance && diff > -tolerance) {
					updateCurrentClass(virtualClass);
					setFlashing(false);
					setIsActive(diff <= 0);
				} else if (diff < 0) {
					totalClasses += 1;
					if (totalClasses === virtualClassList.length) {
						getVirtualClassList();
					}
				}
			}
	}, [virtualClassList, getVirtualClassList, tolerance]);

	useEffect(() => {
		if (currentClass === null && virtualClassList) {
			getNextClass();
		}
		if (virtualClassList?.length === 0) {
			if (!process.env.REACT_APP_LOCAL_API) getVirtualClassList();
		}
	}, [virtualClassList, getVirtualClassList, currentClass, getNextClass]);
	const openVirtualClass = async (dataClass: ILiveClass) => {
		const link = await getVirtualClassUrl(dataClass);
		const { userLink } = link.result[0];
		window.open(userLink, '_blank');
	};
	useEffect(() => {
		const interval = setInterval(() => {
			setTimeRemaining(timeRemaining + 1);

			if (isActive) {
				setFlashing(!flashing);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [timeRemaining, flashing, currentClass, isActive]);

	return (
		<>
			{currentClass ? (
				<Button
					color='secondary'
					variant='contained'
					size='small'
					className={classes.buttonClass}
					onClick={() => {
						if (isActive) {
							openVirtualClass(currentClass as ILiveClass);
						}
					}}
				>
					<div className={classes.container}>
						<VoiceChat
							color='primary'
							className={clsx(classes.classIcon, { [classes.hidden]: flashing })}
						/>
						<DivSanitized text={currentClass?.name as string} />
						<>
							<Chip
								color='primary'
								label={
									<Moment
										durationFromNow
										format='mm:ss'
										onChange={val => {
											const time = val.split(':');
											const minutes = parseInt(time[0], 10);
											if (minutes >= -1 && minutes < tolerance) {
												setIsActive(true);
											} else if (minutes < 0) {
												setIsActive(false);
											} else {
												setIsActive(false);
												getNextClass();
											}
										}}
									>
										{currentClass?.date}
									</Moment>
								}
							/>
						</>
					</div>
				</Button>
			) : null}
		</>
	);
}

export default NextClassButton;
