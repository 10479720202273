type accionSuccess = 'success' | 'error' | 'warning' | 'info';

interface StateMenssage {
	ini: sMenssage[];
	login: sMenssage[];
	recovery: sMenssage[];
	resetpassword: sMenssage[];
	register: sMenssage[];
	bloques: sMenssage[];
}

interface sMenssage {
	message: string;
	accion: accionSuccess;
}

const useMessage: StateMenssage = {
	ini: [{ message: '', accion: 'success' }],
	login: [
		{ message: 'Enter email address or your username.', accion: 'info' },
		{ message: 'Enter your password.', accion: 'info' },
	],
	recovery: [
		{ message: 'Enter an email address.', accion: 'info' },
		{ message: 'The email address is not invalid.', accion: 'warning' },
	],
	resetpassword: [
		{
			message:
				'Enter your new password. Use uppercase, lowercase and numbers at least once (6 to 20 characters)',
			accion: 'info',
		},
		{
			message:
				'Use uppercase, lowercase, numbers, and a special character at least once (6 to 20 characters [! @ # $% ^ & *])',
			accion: 'warning',
		},
		{
			message: 'Add a lowercase characters to your password',
			accion: 'warning',
		},
		{
			message: 'Add uppercase characters to your password',
			accion: 'warning',
		},
		{
			message: 'Add a number to your password',
			accion: 'warning',
		},
		{
			message: 'Add a special character your password. examples. [! @ # $% ^ & *]',
			accion: 'warning',
		},
		{
			message: 'Enter confirmation of your new password',
			accion: 'info',
		},
		{
			message: 'Verify that your password and confirmation are identical',
			accion: 'warning',
		},
	],
	register: [
		{ message: 'Enter an account number.', accion: 'info' },
		{ message: 'Enter a student number.', accion: 'info' },
		{ message: 'Enter user name', accion: 'info' },
		{ message: 'Enter your activation code', accion: 'info' },
	],
	bloques: [{ message: 'Do the above activities to unlock.', accion: 'info' }],
};

export { useMessage };
export default useMessage;
