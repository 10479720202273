import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Theme,
	makeStyles,
} from '@material-ui/core';

import { Cancel, Description } from '@material-ui/icons';

import { ReadingText } from '../Activity-interface';
import { DivSanitized } from '../..';

interface TextReadingProps {
	data: ReadingText;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		position: 'relative',
	},
	boton: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: '.75rem',
	},
	showText: {
		fontFamily: "'Lato', sans-serif;",
		'& .MuiDialogTitle-root': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			'& .MuiTypography-root.MuiTypography-h6': {
				fontFamily: 'inherit',
				textAlign: 'left',
				fontSize: 'calc(16px + (24 - 16) * ((100vw - 320px) / (1280 - 320)))',
			},
		},
		'& .MuiDialogContent-root': {
			fontSize: 'calc(14px + (16 - 14) * ((100vw - 320px) / (1280 - 320)))',
			'& strong': {
				color: theme.palette.primary.main,
			},
			'& .char': {
				border: `1px solid ${theme.palette.primary.main}`,
				borderRadius: theme.shape.borderRadius,
				margin: theme.spacing(1),
				'& .titulo_char': {
					// backgroundColor: theme.palette.primary.light,
					color: theme.palette.primary.main,
					fontWeight: 'bold',
					margin: '0px',
					padding: theme.spacing(1),
					'& span': {
						fontSize: '24px',
						// lineHeight: '28px',
						fontWeight: 'bold',
						width: theme.spacing(5),
						height: theme.spacing(5),
						textAlign: 'center',
						display: 'inline-block',
						border: `2px solid ${theme.palette.primary.dark}`,
						borderRadius: theme.spacing(1),
						marginRight: theme.spacing(1),
					},
				},
			},
		},

		'& .exem_char': {
			margin: theme.spacing(1, 2, 1),
		},
	},
}));

function TextReading(props: TextReadingProps) {
	const { data } = props;
	const { title, content } = data;
	const classes = useStyles();

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className={classes.root}>
			<Button
				className={classes.boton}
				variant='outlined'
				color='primary'
				onClick={handleClickOpen}
				endIcon={<Description />}
			>
				Show text
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				className={classes.showText}
			>
				<DialogTitle id='alert-dialog-title'>
					<DivSanitized text={title} />
				</DialogTitle>
				<DialogContent>
					<DivSanitized text={content} />
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.boton}
						variant='outlined'
						onClick={handleClose}
						color='primary'
						autoFocus
						endIcon={<Cancel />}
					>
						close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export { TextReading };
export default TextReading;
