import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';

import {
	Avatar,
	Badge,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { AppContext } from '../../providers/AppContext';
import { ChatUser } from './chat-interface';

const useStyles = makeStyles(theme => ({
	root: {
		width: '96%',
		height: '100%',
		padding: '2%',
		margin: '0 2%',
		backgroundColor: theme.palette.background.paper,
		position: 'fixed',
		top: '68px',
		right: '0px',
		zIndex: 9999,
		borderRadius: '8px',
		boxShadow: '1px 1px 3px #000',
		[theme.breakpoints.up('md')]: {
			width: '360px',
			padding: '0px',
		},
	},
	header: {
		display: 'flex',
		padding: theme.spacing(2),
		paddingTop: 0,
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	chatContainer: {
		display: 'flex',
		position: 'fixed',
		bottom: '0px',
		right: '0px',
		width: '100%',
		zIndex: 9999,
		justifyContent: 'flex-end',
		marginRight: theme.spacing(2),
	},
	chat: {
		borderRadius: '8px 8px 0px  0px',
		border: theme.palette.grey[800],
		background: theme.palette.background.paper,
		boxShadow: '1px 1px 3px #000',
		marginRight: theme.spacing(1),
	},
}));

interface UserListProps {
	open?: boolean;
	onClose: () => void;
}

export function UserList(props: UserListProps) {
	const { users, selectUser, newMessage, updateMessage } = useContext<any>(AppContext);
	const classes = useStyles();
	const { open, onClose } = props;

	return (
		<>
			{newMessage}
			{open ? (
				<div className={classes.root}>
					<div className={classes.header}>
						<div>Teachers online</div>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</div>
					<List dense>
						{users.map((user: ChatUser) => (
							<ListItem
								key={uuid()}
								button
								onClick={() => {
									updateMessage(user.room);
									selectUser(user);
									onClose();
								}}
							>
								<ListItemAvatar>
									<Badge badgeContent={user.online ? 1 : 0} variant='dot' color='secondary'>
										<Avatar src={user.avatar} />
									</Badge>
								</ListItemAvatar>
								<ListItemText
									primary={
										<>
											<span>{user.user}</span>
										</>
									}
								/>
								<ListItemIcon>
									<Badge badgeContent={user.newMessage} color='secondary' />
								</ListItemIcon>
							</ListItem>
						))}
					</List>
				</div>
			) : null}
		</>
	);
}
UserList.defaultProps = {
	open: false,
};
export default UserList;
