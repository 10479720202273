import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { usePeticiones } from '../../../lib';

const ITEM_HEIGHT = 48;
interface MenuUnitsProps {
	onChange: (unit: number) => void;
}
export function MenuUnit(props: MenuUnitsProps) {
	const { onChange } = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleSelectUnit = ({ unit, name }: any) => {
		setUnitSelected(`${unit} ${name}`);
		onChange(unit);
		handleClose();
	};
	const http = usePeticiones();
	const [list, setList] = useState<any[]>([]);
	const [mount, setMount] = useState(true);
	const [unitSelected, setUnitSelected] = useState<string>('');

	const url = 'proccess-activity';
	useEffect(() => {
		const { peticion } = http;

		const fetchdata = async () => {
			const result: any = await peticion({
				accion: url,
				formData: {},
			});

			setList(result.data);
			setUnitSelected(`${result.data[0].unit} ${result.data[0].name}`);
		};
		if (mount) {
			setMount(false);
			fetchdata();
		}
	}, [setList, setUnitSelected, mount, http, url]);
	return (
		<div>
			<IconButton
				aria-label='more'
				aria-controls='long-menu'
				aria-haspopup='true'
				onClick={handleClick}
			>
				<MoreVertIcon />
				{unitSelected}
			</IconButton>
			<Menu
				id='long-menu'
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '20ch',
					},
				}}
			>
				{list.map((unit: any) => (
					<MenuItem key={unit.name} onClick={() => handleSelectUnit(unit)}>
						{unit.unit} {unit.name}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
export default MenuUnit;
