import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	makeStyles,
	Theme,
} from '@material-ui/core';
import { usePeticiones } from '../../lib';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},

	title: { fontSize: '15px !important' },
}));

export function FamousQuotes() {
	const classes = useStyles();

	const [author, setAuthor] = useState<string>('');
	const [phrase, setPhrase] = useState<string>('');
	const [mount, setMount] = useState<boolean>(false);
	const { peticion } = usePeticiones();
	useEffect(() => {
		const getData = async () => {
			const { data } = await peticion({ formData: {}, accion: 'home/famous-quotes' });
			setAuthor(data.author);
			setPhrase(data.phrase);
		};
		if (!mount) {
			getData();
			setMount(true);
		}
	}, [mount, peticion]);

	return (
		<Card
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<CardHeader
				className={classes.header}
				title={<b className={classes.title}>Famous Quotes</b>}
			/>
			<CardContent>
				<p>{phrase}</p>
				<div style={{ textAlign: 'right' }}>
					<b>{author}</b>
				</div>
			</CardContent>
			<CardActions style={{ display: 'flex', justifyContent: 'end' }}>
				<Button size='small' color='primary' variant='outlined'>
					see more
				</Button>
			</CardActions>
		</Card>
	);
}
export default FamousQuotes;
