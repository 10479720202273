import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles, TextField, Theme, Typography } from '@material-ui/core';

import { CheckButtons } from '../Check-Buttons';

import { ReactiveProps } from '../../Activity-interface';
import AudioExercise from '../audio-exercise';
import ImageExercise from '../Image-Exercise';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'flex-end',
		flexWrap: 'wrap',
		verticalAlign: 'baseline',
	},
	reactiveContainer: {
		marginTop: '8px',
		display: 'flex',
		justifyContent: 'center',
	},
	dropContainer: {
		display: 'flex',
		alignItems: 'end',
	},

	optionContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(4),
		display: 'flex',
		justifyContent: 'space-between',
	},
	options: {
		padding: '0px',
		display: 'inline-block',
		fontWeight: 'bold',
		fontSize: 'inherit',
		fontFamily: 'inherit',
	},
	inputContainer: {
		'& .MuiInputBase-root.MuiInput-root': {
			fontSize: 'inherit',
			fontFamily: 'inherit',
		},
		'& .MuiInput-underline:before': { borderBottom: '0px', display: 'none' },
		'& .MuiInputBase-input.MuiInput-input': {
			borderBottomStyle: 'double',
		},
	},
	listStyle: {
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		display: 'flex',
		width: '27px',
		height: '36px',
		padding: '0px',
		margin: '0px 3px',
		textAlign: 'center',
		alignItems: 'center',
		borderBottom: `2px solid ${theme.palette.primary.main}`,
		color: `rgba(0, 0, 0, 0.38)`,
		borderBottomStyle: 'dotted',
		boxSizing: 'content-box',
		fontSize: 'inherit',
		border: '2px solid #fff0',
		'&.correct': {
			borderBottomColor: `${theme.palette.success.main} `,
			color: theme.palette.success.main,
			backgroundColor: '#4caf500d',
		},
		'&.incorrect': {
			borderBottomColor: `${theme.palette.error.main} `,
			color: theme.palette.error.main,
			backgroundColor: '#f443360d',
		},
		'&.line': {
			borderBottomStyle: 'double',
		},
		[theme.breakpoints.up('md')]: {
			minHeight: '60px',
			minWidth: '43px',
		},
	},
	optNoListStyle: {
		color: 'rgba(0, 0, 0, 0.38)',
		borderStyle: 'dotted !important',
		border: `2px solid ${theme.palette.grey[900]}`,
		borderRadius: '8px',
	},
	noListStyle: {
		color: 'unset',
	},
}));

function WriteCompleteWord(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		totalAttempts,
		onNext,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent } = typeActivity;
	const { question, answer, img, reactives, media, localMedia, time } = reactive;

	const classes = useStyles();
	// const [userAnswer, setUserAnswer] = useState<any[]>([]);
	const [reactiveOptions, setReactiveOptions] = useState<string[]>([]);
	// const [correctAnswer, setCorrectAnswer] = useState<string>('');
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [mount, setMount] = useState<boolean>(false);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [questionReactive, setQuestionReactive] = useState<any[]>([]);

	const checkAnswer = () => {
		let isTrue = true;
		const qr = questionReactive;

		// eslint-disable-next-line
		console.log('paso');
		for (const r of qr) {
			if (r.reactives) {
				for (const chart of r.reactives) {
					if (chart.answer === chart.userAnswer && chart.isInput) {
						chart.isCorrect = true;
					} else if (chart.isInput) {
						chart.isCorrect = false;
						isTrue = false;
					}
				}
			}
		}
		setQuestionReactive(qr);
		setIsCorrect(isTrue);
		setNextDisabled(false);
		setCheckDisabled(true);
	};

	const nextAnswer = () => {
		onNext();
		let ua = '';
		const qr = questionReactive;
		for (const r of qr) {
			if (r.reactives) {
				for (const chart of r.reactives) {
					if (chart.isInput) {
						ua = `${ua} <u class='sample line'>${chart.answer}</u> `;
					} else {
						ua = `${ua} <u class='sample'>${chart.answer}</u> `;
					}
				}
			}
		}

		onCheck(isCorrect ?? false, [ua], '', media, localMedia, img, activaAnswer);
	};

	const onChange = (input: any, indexWord: number, indexChart: number) => {
		const qr = questionReactive;
		qr[indexWord].reactives[indexChart].userAnswer = input;
		setQuestionReactive(qr);

		let paso: boolean = true;
		for (const r of qr) {
			if (r.reactives.isInput && !r.userAnswer) paso = false;
		}
		setCheckDisabled(false);
		if (!paso) {
			setCheckDisabled(false);
		}
	};

	useEffect(() => {
		const createQuestionReactive = () => {
			setReactiveOptions(reactives);

			const q: any = question.split(' ').reduce((acc: any[], current: string, index) => {
				const isReactive = current.includes('|');
				const correctAnswer = answer[0].split('');
				const r = isReactive ? current.split('') : null;
				const a = isReactive ? answer[0] : null;
				acc.push({
					content: current,
					reactives: r
						? r.reduce((arrayR: any[], currentR, indexNumber) => {
								arrayR.push({
									userAnswer: isExample ? correctAnswer[indexNumber] : null,
									answer: correctAnswer[indexNumber],
									chart: currentR,
									isInput: currentR === '|',
									id: indexNumber,
									isCorrect: null,
								});
								return arrayR;
						  }, [])
						: null,
					answer: a,
				});

				return acc;
			}, []);
			setQuestionReactive(q);
		};
		if (!mount) {
			createQuestionReactive();
			setMount(true);
		}
	}, [question, mount, answer, reactives, isExample]);

	return (
		<>
			<div className={classes.reactive}>
				{typeComponent ? '' : null}

				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />

				{!isExample ? (
					<div className={classes.optionContainer}>
						{reactiveOptions.map((item, index) => (
							<div
								key={`options-${index.toString()}`}
								className={clsx(classes.options, classes.listStyle, classes.optNoListStyle)}
							>
								{item}
							</div>
						))}
					</div>
				) : null}

				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				<div className={classes.reactiveContainer}>
					{questionReactive?.map((word: any, indexWord) => (
						<React.Fragment key={`word-${indexWord + 1}-reactive`}>
							{word.reactives ? (
								<div className={classes.dropContainer}>
									{word.reactives.map((chart: any, index: number) => (
										<React.Fragment key={`word-input-${index + 1}-reactive`}>
											{chart.isInput ? (
												<>
													<TextField
														variant='standard'
														className={classes.inputContainer}
														inputProps={{
															maxLength: 1,
															className: clsx(classes.listStyle, classes.noListStyle, [
																{
																	correct: chart.isCorrect,
																	incorrect: chart.isCorrect !== null && !chart.isCorrect,
																},
															]),
														}}
														disabled={isExample || (!isExample && !nextDisabled)}
														defaultValue={isExample ? chart.userAnswer : null}
														type='text'
														onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
															// eslint-disable-next-line
															onChange(e.currentTarget.value, indexWord, index);
														}}
													/>
												</>
											) : (
												<div className={classes.listStyle}> {chart.chart}</div>
											)}
										</React.Fragment>
									))}
								</div>
							) : (
								<div>{word?.content}&nbsp;</div>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { WriteCompleteWord };
export default WriteCompleteWord;
