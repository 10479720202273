import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';

import clsx from 'clsx';

import { Iconactivity } from '.';
import { ActivityProps } from './Unit-interface';

export interface ActivityPropsUpt extends ActivityProps {
	activated: boolean;
}

function Activity(props: ActivityPropsUpt): JSX.Element {
	const { activity, activated } = props;
	const [progress, setProgress] = React.useState(0);

	React.useEffect(() => {
		if (activity.promedio > 0 && activated === true) {
			setProgress(activity.promedio);
		}
	}, [activity, activated]);

	return (
		<>
			<div style={{ width: '100%' }}>
				<div
					className={clsx('MiuProgress', {
						minimo: Math.floor(activity.promedio) > 0 && Math.floor(activity.promedio) <= 45,
						medium: Math.floor(activity.promedio) > 45 && Math.floor(activity.promedio) < 80,
						maximo: Math.floor(activity.promedio) >= 80 && Math.floor(activity.promedio) <= 99,
						full: Math.floor(activity.promedio) >= 100,
					})}
				>
					<CircularProgress variant='determinate' size='100%' value={Math.floor(progress)} />
					<Iconactivity name={activity.icon ? activity.icon : 'home'} fontSize='small' />
				</div>

				<Typography noWrap variant='body2'>
					{activity.name}
				</Typography>
			</div>
		</>
	);
}

export { Activity };
export default Activity;
