import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles, Theme, Typography, Select, MenuItem } from '@material-ui/core';

import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';
import AudioExercise from '../audio-exercise';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		verticalAlign: 'baseline',
		marginTop: theme.spacing(1),
	},
	selectText: {
		textAlign: 'center',
		display: 'inline-block',
		fontSize: 'inherit',
		verticalAlign: 'bottom',
		margin: '0 5px',
		minWidth: '3em',
		borderColor: theme.palette.primary.main,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		'&::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
		},
		'&::hover:not(.Mui-disabled):before': {
			transition: 'none',
			borderBottom: `2px solid ${theme.palette.primary.main}`,
		},
		'& .MuiInputBase-input': {
			padding: '6px 0 2px',
		},
		'& .MuiSelect-root.MuiSelect-select': {
			marginLeft: '-10px',
			marginRight: '60px',
			[theme.breakpoints.up('md')]: {
				marginRight: '60px',
			},
		},
	},
	correct: {
		'&::before': {
			borderBottom: `2px solid ${theme.palette.success.main} !important`,
		},
		'& .MuiSvgIcon-root': {
			color: theme.palette.success.main,
		},
		'& .MuiSelect-root.Mui-disabled': {
			color: theme.palette.success.main,
		},
	},
	incorrect: {
		'&::before': {
			borderBottom: `2px solid ${theme.palette.error.light} !important`,
		},
		'& .MuiSvgIcon-root': {
			color: theme.palette.error.main,
		},
		'& .MuiSelect-root.Mui-disabled': {
			color: theme.palette.error.light,
		},
	},
}));

function ComboOption(props: ReactiveProps) {
	const classes = useStyles();

	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent } = typeActivity;
	const { question, answer, img, reactives, media, localMedia, time } = reactive;

	const [userAnswer, setUserAnswer] = useState<any[]>([]);
	const [correctAnswer, setCorrectAnswer] = useState<string>('');
	const [multipleAnswer, setMultipleAnswer] = useState<string[][]>([]);
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [mount, setMount] = useState<boolean>(false);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [sentence, setSentence] = useState<any>([]);

	const [updated, setUpdated] = React.useState(1);

	const checkAnswer = () => {
		const multiAnswers = multipleAnswer;
		const inputAnswer = userAnswer;
		let correctAnswersNumber = 0;

		let counter = 0;
		for (const ia of inputAnswer) {
			ia.isCorrect = false;
			for (const ma of multiAnswers[counter]) {
				const correct = ma === ia.value;

				if (correct) {
					correctAnswersNumber += 1;
					ia.isCorrect = correct;
				}
			}
			counter += 1;
		}

		setUserAnswer(inputAnswer);

		const isTrue = correctAnswersNumber === inputAnswer.length;

		setIsCorrect(isTrue);
		setNextDisabled(false);
		setCheckDisabled(true);
	};

	const nextAnswer = () => {
		onNext();

		let ua = question;
		userAnswer.reduce((array, b) => {
			array.push(b.value);

			ua = ua.replace('|', `<u class='${b.isCorrect ? 'correct' : 'incorrect'}'>${b.value}</u>`);

			return array;
		}, []);
		onCheck(isCorrect ?? false, [ua], '', media, localMedia, img, activaAnswer);
	};

	const handleChange = (e: React.ChangeEvent<{ value: unknown }>, index: number) => {
		const answerInput: any = userAnswer;
		answerInput[index] = { value: `${e.target.value}`, isCorrect: null };
		setUserAnswer(answerInput);
		setCheckDisabled(false);
		setUpdated(updated + 1);
	};

	useEffect(() => {
		const createMultipleAnswers = () => {
			const answers = answer.reduce((array: any[], b: string) => {
				const a = b.split('=');
				array.push(a);

				return array;
			}, []);

			setMultipleAnswer(answers);
		};

		const createInputReactives = () => {
			let inputCount = 0;
			let ca = ''; // correctAnswer
			const emptyUserAnswer: any[] = [];
			const reactiveSentence = question.split(' ').reduce((array: any[], b: string) => {
				const replace = b.split('').includes('|');
				if (replace) {
					ca += ' ';
					const wordReplace = b.split('');
					for (const word of wordReplace) {
						if (word === '|') {
							array.push({ index: inputCount, width: multipleAnswer[inputCount][0].length });
							emptyUserAnswer.push({ value: '', isCorrect: null });
							ca += `<b><u>${multipleAnswer[inputCount][0]}</u></b>`;
							inputCount += 1;
						} else {
							array.push(word);
							ca += `${b.replaceAll('|', '')}`;
						}
					}
				} else {
					ca += ` ${b}`;
					array.push(b);
				}
				return array;
			}, []);
			setSentence(reactiveSentence);
			setUserAnswer(emptyUserAnswer);
			setCorrectAnswer(ca);
		};
		if (!mount) {
			createMultipleAnswers();
			setMount(true);
		}
		if (mount && sentence.length === 0) {
			createInputReactives();
		}
	}, [
		mount,
		setMount,
		setSentence,
		reactives,
		answer,
		question,
		sentence,
		classes,
		isExample,
		multipleAnswer,
		userAnswer,
	]);

	return (
		<>
			<div className={clsx(classes.reactive)}>
				{typeComponent ? '' : null}
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>

				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />

				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				<Typography component='div' className={classes.question}>
					{sentence.map((input: any, index: number) => (
						<Fragment key={`inputs-keys-${index.toString()}`}>
							{typeof input === 'string' ? (
								<DivSanitized component='span' text={`${input} `} />
							) : (
								<>
									{updated ? (
										<Select
											className={clsx(classes.selectText, {
												[classes.correct]: userAnswer[input.index]?.isCorrect || isExample,
												[classes.incorrect]: userAnswer[input.index]?.isCorrect === false,
											})}
											disabled={
												isExample ||
												userAnswer[input.index]?.isCorrect === true ||
												userAnswer[input.index]?.isCorrect === false
											}
											value={
												isExample ? multipleAnswer[input.index][0] : userAnswer[input.index]?.value
											}
											onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
												handleChange(e, input.index);
											}}
										>
											{reactives.map((option: any, optionIndex: number) => (
												<MenuItem key={`option-keys-${optionIndex.toString()}`} value={option}>
													{option}
												</MenuItem>
											))}
										</Select>
									) : null}
								</>
							)}
						</Fragment>
					))}
				</Typography>
			</div>
			<CheckButtons
				time={time}
				answer={[correctAnswer]}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { ComboOption };
export default ComboOption;
