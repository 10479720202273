import React from 'react';
import { Container, Grid } from '@material-ui/core';

import { useStyles } from '../lib';
import { Navbar, Scrolltop } from '../components';
import {
	CourseProgress,
	CurrentUnitClasses,
	DailyGoal,
	ExpressionOfTheDay,
	FamousQuotes,
	PastClasses,
	SuggestedActivity,
	TeslProgress,
	TodayClasses,
	TravelerPhrases,
	WordOfTheDay,
} from '../components/Home-content';

export interface PagehomeProps {
	modeTheme?: boolean;
	changeTheme?: any;
}

function Pagehome(props: PagehomeProps) {
	const classes = useStyles();
	const { modeTheme, changeTheme } = props;

	return (
		<>
			<Navbar modeTheme={modeTheme} changeTheme={changeTheme} />
			<Container>
				<Grid container className={classes.root} spacing={2}>
					<Grid item xs={12} md={8}>
						<SuggestedActivity />
					</Grid>

					<Grid item xs={12} md={4}>
						<DailyGoal />
					</Grid>
					<Grid item xs={12} md={4}>
						<PastClasses />
					</Grid>
					<Grid item xs={12} md={4}>
						<CurrentUnitClasses />
					</Grid>
					<Grid item xs={12} md={4}>
						<TodayClasses />
					</Grid>
				</Grid>
			</Container>
			<div style={{ background: '#cfcfcf', margin: '32px 0', padding: '16px 0' }}>
				<Container>
					<Grid container className={classes.root} spacing={2}>
						<Grid item xs={12} md={3}>
							<TravelerPhrases />
						</Grid>
						<Grid item xs={12} md={3}>
							<WordOfTheDay />
						</Grid>
						<Grid item xs={12} md={3}>
							<FamousQuotes />
						</Grid>
						<Grid item xs={12} md={3}>
							<ExpressionOfTheDay />
						</Grid>
					</Grid>
				</Container>
			</div>

			<CourseProgress />
			<TeslProgress />

			<Scrolltop />
		</>
	);
}

Pagehome.defaultProps = {
	modeTheme: null,
	changeTheme: undefined,
};

export { Pagehome };
export default Pagehome;
