import React from 'react';
import usePeticiones from './use-Peticiones';
import useMessage from './use-message';

interface State {
	account: string;
	nstudent: string;
	email: string;
	user: string;
	password: string;
	code: string;
	showPassword: boolean;
}

interface Stateactivate {
	email: string;
	user: string;
	segurityToken: string;
	showPassword: boolean;
	success: null | string;
}

function useRegister() {
	const { peticion, statePeteiciones, setStatePeteiciones } = usePeticiones();

	const [menssage, setMenssage] = React.useState(useMessage.ini[0]);

	function registerIn(frm: State, setfrm: any) {
		const body = {
			account: frm.account,
			nstudent: frm.nstudent,
			email: frm.email,
			user: frm.user,
			password: frm.password,
			codigoUsuario: frm.password,
		};

		const regex = new RegExp('^[A-Za-z][A-Za-z0-9_.-]*@[A-Za-z0-9_-]+.[A-Za-z0-9_.]+[A-za-z]$');

		const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,20})');
		const clowRegex = new RegExp('^(?=.*[a-z])');
		const cUpperRegex = new RegExp('^(?=.*[A-Z])');
		const numRegex = new RegExp('^(?=.*[0-9])');

		const nMail = frm.email
			? {
					name: frm.email.split('@')[0],
					hostname: frm.email.split('@')[1].split('.')[0],
					ext: frm.email.split('@')[1].split('.')[1],
			  }
			: { name: '', hostname: '', ext: '' };

		setMenssage(useMessage.ini[0]);

		if (frm.account === '') {
			setMenssage(useMessage.register[0]);
		} else if (frm.nstudent === '') {
			setMenssage(useMessage.register[1]);
		} else if (frm.user === '') {
			setMenssage(useMessage.register[2]);
		} else if (frm.email === '') {
			setMenssage(useMessage.recovery[0]);
		} else if (
			!regex.test(frm.email) ||
			(regex.test(frm.email) && nMail.name.length < 2) ||
			(regex.test(frm.email) && nMail.hostname.length < 2) ||
			(regex.test(frm.email) && nMail.ext.length < 2)
		) {
			setMenssage(useMessage.recovery[1]);
		} else if (frm.password === '') {
			setMenssage(useMessage.resetpassword[0]);
		} else if (!strongRegex.test(frm.password)) {
			if (frm.password.length < 6 || frm.password.length > 20) {
				setMenssage(useMessage.resetpassword[1]);
			} else if (!clowRegex.test(frm.password)) {
				setMenssage(useMessage.resetpassword[2]);
			} else if (!cUpperRegex.test(frm.password)) {
				setMenssage(useMessage.resetpassword[3]);
			} else if (!numRegex.test(frm.password)) {
				setMenssage(useMessage.resetpassword[4]);
			} else {
				setMenssage(useMessage.resetpassword[5]);
			}
		} else {
			peticion({ accion: 'register', formData: body }).then(
				datos => {
					if (datos.success) {
						setfrm('success');
					} else {
						setMenssage({ message: datos.message, accion: 'error' });
					}
				},
				error => {}
			);
		}
	}

	function resendIn(frm: Stateactivate) {
		const body = {
			email: frm.email,
			user: frm.user,
			segurityToken: frm.segurityToken,
		};

		setMenssage(useMessage.ini[0]);

		peticion({ accion: 'register/resend', formData: body }).then(
			datos => {
				if (datos.success) {
					setMenssage({ message: datos.data.message, accion: 'success' });
				} else {
					setMenssage({ message: datos.message, accion: 'error' });
				}
			},
			error => {
				setStatePeteiciones({ ...statePeteiciones, success: false });
			}
		);
	}

	return { registerIn, resendIn, statePeteiciones, menssage, setMenssage };
}

export { useRegister };
export default useRegister;
