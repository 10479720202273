import React from 'react';
import {
	Container,
	Grid,
	Paper,
	TextField,
	Button,
	InputAdornment,
	IconButton,
	CircularProgress,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useStyles, useMessage, useLogin } from '../lib';
import { Copyright, TituloLogin, ToastBar } from '../components';

interface State {
	user: string;
	password: string;
	showPassword: boolean;
}

function Pagelogin(): JSX.Element {
	const classes = useStyles();

	const { signIn, statePeteiciones, menssage, setMenssage } = useLogin();

	const [values, setValues] = React.useState<State>({
		user: '',
		password: '',
		showPassword: false,
	});

	const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handClickLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		signIn(values);
	};

	const handleClose = () => {
		setMenssage(useMessage.ini[0]);
	};

	return (
		<>
			<Container maxWidth='xs'>
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<Paper className={classes.containerLogin}>
							<TituloLogin titulo='Sign in' iconName='LockOutlined' align='center' />
							<form className='form-login'>
								<TextField
									id='user'
									value={values.user}
									onChange={handleChange('user')}
									autoComplete='i-user'
									label='Enter your username.'
									required
									margin='normal'
									type='text'
									fullWidth
									variant='outlined'
								/>

								<TextField
									id='password'
									value={values.password}
									onChange={handleChange('password')}
									label='Password'
									required
									margin='normal'
									type={values.showPassword ? 'text' : 'password'}
									fullWidth
									variant='outlined'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<label htmlFor='password'>
													<IconButton
														aria-label='password-visibility'
														component='span'
														edge='end'
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
													>
														{values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
													</IconButton>
												</label>
											</InputAdornment>
										),
									}}
								/>

								<Button
									disabled={statePeteiciones.isLoading}
									onClick={handClickLogin}
									type='submit'
									fullWidth
									variant='contained'
									endIcon={<CircularProgress color='primary' size={20} />}
									color='primary'
								>
									Sign In
								</Button>

								<Grid container>
									<Grid item xs={6}>
										<Button className='btn-text' href='/recovery'>
											Forgot password?
										</Button>
									</Grid>
									<Grid item xs={6} className='contenido-text-align-r'>
										<Button className='btn-text btn-text-r ' href='/register'>
											Create Account.
										</Button>
									</Grid>
								</Grid>
							</form>
							<Copyright />
						</Paper>
					</Grid>
				</Grid>
			</Container>
			{menssage.message ? (
				<ToastBar
					color={menssage.accion}
					autoHideDuration={3000}
					anchorOriginV='bottom'
					anchorOriginH='right'
					onClose={handleClose}
					message={menssage.message}
				/>
			) : null}
		</>
	);
}

export { Pagelogin };
export default Pagelogin;
