import React, { Fragment, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CheckCircle, HighlightOff } from '@material-ui/icons';
import { ReactiveProps } from '../../Activity-interface';
import { ImageExercise } from '../Image-Exercise';
import { AudioExercise } from '../audio-exercise';
import { DivSanitized } from '../../../DivSanitized';
import { CheckButtons } from '../Check-Buttons';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '70vh',
		maxHeight: '70vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},

	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},

	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},

	containerQuestion: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},

	button: {
		fontSize: 'inherit',
		fontFamily: 'inherit',
		height: '100%',
		textTransform: 'none',
		'&.Multiple-Option-Img .MuiButton-startIcon': {
			position: 'absolute',
			right: '0px',
			top: '0px',
			background: '#fff',
			borderRadius: '50%',
			margin: 0,
			zIndex: '1',
		},
		'&.Multiple-Option-Img': {
			padding: theme.spacing(1),
			margin: 0,
		},
	},
	correct: {
		'&.Mui-disabled': {
			border: `1px solid`,
			borderColor: `${theme.palette.success.main} `,
			color: theme.palette.success.main,
			backgroundColor: '#4caf500d',
			'& .MuiButton-startIcon': {
				backgroundColor: '#4caf500d',
			},
		},
	},
	incorrect: {
		'&.Mui-disabled': {
			border: `1px solid`,
			borderColor: `${theme.palette.error.main} `,
			color: theme.palette.error.main,
			backgroundColor: '#f443360d',
			'& .MuiButton-startIcon': {
				backgroundColor: '#f443360d',
			},
		},
	},

	ajustable: {
		marginBottom: 'auto',
	},
}));

function MultipleOptionInline(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { answer, reactives, question, img, media, localMedia, time } = reactive;
	// const { component } = typeActivity;
	const [userAnswer, setUserAnswer] = useState(isExample ? answer[0] : '');
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);

	const [buttonSelected, setButtonSelected] = useState(isExample ? answer[0] : userAnswer);
	const classes = useStyles();
	const handleSelect = (answerUser: string) => {
		if (!isExample && isCorrect === null) {
			setCheckDisabled(false);
			setButtonSelected(answerUser);
			setUserAnswer(answerUser);
		}
	};
	const checkAnswer = () => {
		const correct = answer.includes(userAnswer);
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const nextAnswer = () => {
		onNext();
		const ua = [question.replace('|', `<b><u>${userAnswer}</u></b>`)];

		onCheck(isCorrect ?? false, ua, question, media, localMedia, img, activaAnswer);
	};

	return (
		<>
			<div className={classes.reactive}>
				<>
					<AudioExercise
						view='Circulo'
						media={media as string}
						localMedia={localMedia as string}
						totalAttempts={totalAttempts}
					/>
					<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
					{isExample ? (
						<Typography component='div' color='primary' variant='h6' className={classes.example}>
							Example:
						</Typography>
					) : null}
				</>

				<div className={classes.containerQuestion}>
					<Typography component='div' className={classes.question} gutterBottom>
						{question.split(' ').map(word => (
							<Fragment key={uuid()}>
								{word === '|' ? (
									<>
										{reactives.map((item: string, index: number) => (
											<Fragment key={uuid()}>
												{index > 0 ? ' / ' : ' '}
												<Button
													startIcon={
														(isCorrect !== null && buttonSelected === item) ||
														(isExample && answer[0] === item) ? (
															<>
																{isCorrect || (isExample && answer[0] === item) ? (
																	<CheckCircle />
																) : (
																	<HighlightOff />
																)}
															</>
														) : null
													}
													size='small'
													disabled={isCorrect !== null || isExample}
													color='primary'
													className={clsx([classes.button], {
														[classes.correct]:
															(isCorrect && buttonSelected === item) ||
															(isExample && answer[0] === item),
														[classes.incorrect]:
															!isCorrect && isCorrect !== null && buttonSelected === item,
													})}
													variant={buttonSelected === item ? 'contained' : 'outlined'}
													onClick={() => {
														handleSelect(item);
													}}
												>
													{item}
												</Button>
											</Fragment>
										))}
									</>
								) : (
									<DivSanitized text={` ${word}`} component='span' />
								)}
							</Fragment>
						))}
					</Typography>
				</div>
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { MultipleOptionInline };
export default MultipleOptionInline;
