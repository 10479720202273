import { createStyles, alpha, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		offset: {
			...theme.mixins.toolbar, // min-height: 56px;
			marginBottom: '1rem', // margen opcional
		},
		sectionDesktop: {
			display: 'block',
			// [theme.breakpoints.up('md')]: {
			//   display: 'block',
			// },
		},
		hideMobile: {
			[theme.breakpoints.down('md')]: {
				display: 'none !important',
			},
		},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		SnackbarAlert: {
			zIndex: 999999,
			position: 'absolute',
			top: '0px',
			margin: theme.spacing(1, 2, 2),
			'& .MuiSnackbarContent-root.success': {
				backgroundColor: theme.palette.success.dark,
			},
			'& .MuiSnackbarContent-root.error': {
				backgroundColor: theme.palette.error.dark,
			},
			'& .MuiSnackbarContent-root.warning': {
				backgroundColor: theme.palette.warning.dark,
			},
			'& .MuiSnackbarContent-root.info': {
				backgroundColor: theme.palette.info.dark,
			},
		},
		SnackbarAlertB: {
			bottom: '0px',
			top: 'auto',
		},
		SnackbarAlertR: {
			right: '0px',
		},
		containerLogin: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			height: '100vh',
			paddingTop: theme.spacing(4),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				margin: '30% auto',
				height: 'auto',
			},
			'& .MuiAvatar-root': {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(1),
				color: theme.palette.getContrastText(theme.palette.primary.main),
				backgroundColor: theme.palette.primary.main,
			},
			'& .form-login': {
				width: '90%',
				margin: theme.spacing(2, 1),
			},
			'& .message-error ': {
				minHeight: '40px',
			},
			'& .MuiButton-root ': {
				marginTop: theme.spacing(2),
			},
			'& .MuiButton-root.btn-text ': {
				color: theme.palette.text.secondary,
				textTransform: 'none',
			},
			'& .contenido-text-align-r ': {
				textAlign: 'right',
			},
		},
		containerRegister: {
			paddingTop: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				margin: '10% auto',
				height: 'auto',
			},
			'& .message-error ': {
				minHeight: '20px',
			},
			'& .form-login': {
				marginTop: '0px',
			},
		},
		containerRegisterH: {
			[theme.breakpoints.down('sm')]: {
				height: 'auto',
				paddingTop: theme.spacing(1),
				'& .MuiAvatar-root , .MuiTypnpm sography-h6 ': {
					display: 'none',
				},
			},
		},
		// Sidenav
		SwipDrawerRoot: {
			'& a': {
				textDecoration: 'none',
				color: theme.palette.text.secondary,
				width: '100%',
			},
		},
		svgIconRoot: {
			'& svg': {
				fill: theme.palette.text.secondary,
				width: '1em',
				height: '1em',
				display: 'inline-block',
				fontSize: '1.5rem',
				transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				userSelect: 'none',
				margin: theme.spacing(2),
			},
			'& svg.small': {
				fontSize: '1.25rem',
			},
			'& svg.large': {
				fontSize: '2.1875rem',
			},
			'& svg.xlarge': {
				fontSize: '54px',
				[theme.breakpoints.up('sm')]: {
					fontSize: '72px',
				},
				[theme.breakpoints.up('md')]: {
					fontSize: '90px',
				},
				[theme.breakpoints.up('lg')]: {
					fontSize: '108px',
				},
			},
			'& svg.xxlarge': {
				fontSize: '96px',
			},
			'& svg.xxxlarge': {
				fontSize: '140px',
			},
		},
		scrollTop: {
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(2),
		},
		// Copyright
		Copyright: {
			position: 'absolute',
			bottom: '0px',
			width: '80%',
			marginBottom: theme.spacing(3),
			'& a ': {
				fontWeight: theme.typography.button.fontWeight,
			},
		},
		loading: {
			'& .MuiContenido-Load': {
				position: 'relative',
				'& .Mui-logo': {
					position: 'absolute',
					top: '0px',
					left: '0px',
					'& svg': {
						fontSize: '3.8rem',
					},
				},
			},
			'& .MuiContenido-Load.Mui-Page': {
				marginTop: theme.spacing(20),
			},
		},
		PopoverMessage: {
			'& .Popover-contenido': {
				textAlign: 'center',
				padding: theme.spacing(4),
				backgroundColor: theme.palette.warning.dark,
				color: theme.palette.warning.contrastText,
			},
		},
		PageUnit: {
			'& .MiuLesson': {
				backgroundColor: theme.palette.divider,
				marginBottom: theme.spacing(6),
			},
			'& .MiuBloques ': {
				display: 'flex',
			},
			'& .MuiBarProgress': {
				backgroundColor: theme.palette.background.default,
				'& .MuiLinearProgress-root': {
					borderRadius: theme.shape.borderRadius,
					height: theme.spacing(1),
				},
				'& .Miubar': {
					margin: theme.spacing(3),
				},
				'& .MiubarLock': {
					textAlign: 'right',
				},
			},
			'& .Introduction': {
				marginBottom: theme.spacing(3),
			},
			'& .MuiPronun': {
				display: 'flex',
				flexFlow: 'column',
				backgroundColor: theme.palette.divider,
				marginBottom: theme.spacing(3),

				'& .MuiTitleLesson': {
					maxHeight: '34px',
				},
				'& .MuiBloques': {
					display: 'grid',
				},
			},
			'& .MiuBloque': {
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				width: '100%',
				backgroundColor: theme.palette.background.default,
				'& .MuiCardContent-root': {
					height: '100%',
				},
				'& .MuiCardActions-root': {
					minHeight: '46px',
					backgroundColor: theme.palette.background.default,
					display: 'block',
					[theme.breakpoints.down('xs')]: {
						'& .MuiButton-root': {
							width: '100%',
						},
					},
				},
				'& .IconButon': {
					width: '100%',
					'& .MiuProgress': {
						position: 'relative',
						'& .MuiCircularProgress-colorPrimary': {
							color: theme.palette.background.default,
						},
						'& .MiuIconProgress': {
							height: 'calc(100% - 8.5714%);',
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							position: 'absolute',
							left: '0px',
							top: '0px',
						},
					},
					'& .svgIcon ': {
						display: 'contents',
						'& svg ': {
							height: '100%',
							width: '100%',
							filter: 'grayscale(1) opacity(.8)',
						},
					},
				},
			},
			'& .MiuBloque.MiuBloqueActivo': {
				backgroundColor: theme.palette.background.paper,

				'& .IconButon': {
					'& .MiuProgress.minimo': {
						'& .MuiCircularProgress-colorPrimary': {
							color: theme.palette.warning.light,
						},
					},
					'& .MiuProgress.medium': {
						'& .MuiCircularProgress-colorPrimary': {
							color: theme.palette.warning.main,
						},
					},
					'& .MiuProgress.maximo': {
						'& .MuiCircularProgress-colorPrimary': {
							color: theme.palette.primary.light,
						},
					},
					'& .MiuProgress.full': {
						'& .MuiCircularProgress-colorPrimary': {
							color: theme.palette.primary.dark,
						},
					},
					'& .svgIcon ': {
						'& svg ': {
							filter: 'none',
						},
					},
				},
			},
		},
		PageActVideo: {
			// ...theme.mixins.toolbar, // min-height: 56px;
			// marginBottom: "1rem", // margen opcional

			'& h1': {
				[theme.breakpoints.down('sm')]: {
					display: 'none',
				},
			},

			'& .Video': {
				top: '52px',
				right: '0',
				left: '0',
				overflow: 'hidden',
				zIndex: '1',
				'& .MuiSkeleton-rect': {
					width: '100%',
					height: '40vh',
				},
				[theme.breakpoints.down('sm')]: {
					position: 'fixed',
				},
				'& iframe': {
					width: '100%',
					backgroundColor: theme.palette.common.black,
					[theme.breakpoints.down('sm')]: {
						height: 'calc(56.4394vw)',
					},
					[theme.breakpoints.up('md')]: {
						height: 'calc(56.4394vw)',
						maxHeight: '690px',
					},
				},
			},

			'& .Contenido': {
				backgroundColor: theme.palette.divider,
				[theme.breakpoints.down('sm')]: {
					marginTop: 'calc(56.4394vw)',
				},
				'& .Miu-bloque': {
					marginTop: theme.spacing(1),
					paddingBottom: theme.spacing(2),
					'& .MuiTypography-h4.Titulo': {
						padding: theme.spacing(2, 2),
						fontSize: 'calc(34px + (46 - 34) * ((100vw - 320px) / (1920 - 320)));',
					},
					'& .contenedor': {
						padding: theme.spacing(1),
					},
				},
			},
			'& .MuiCard-root.information-seg-imagens': {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: '100%',
				'& .MuiMenu-list': {
					fontSize: 'calc(14px + (26 - 14) * ((100vw - 320px) / (1920 - 320)));',
					fontWeight: '600',
					'& span,  p ': {
						fontWeight: '600',
					},
				},
			},
			'& .MuiCard-root.information-seg-reglas, .MuiCard-root.information-seg-Dialogo': {
				padding: theme.spacing(1),
				margin: theme.spacing(1),
				width: '100%',
				'& .MuiMenu-list': {
					fontSize: 'calc(14px + (26 - 14) * ((100vw - 320px) / (1920 - 320)));',
					fontWeight: 'normal ',
				},
				'& .alphabetContainer': {
					fontSize: '1.25rem',
					display: 'flex',
					flexWrap: 'wrap',
					'& .alphabet': {
						width: '66px',
						'& .mayuscula': {
							fontWeight: '600',
						},
					},
				},
			},

			'& .MuiCard-root.information-seg-reglas': {
				backgroundColor: theme.palette.info.light,
			},
			'& .MuiCard-root': {
				'& .media': {
					width: '100%',
					padding: '1rem',
				},
				'& .MuiCardHeader-root .MuiTypography-root.MuiTypography-body1': {
					fontSize: 'calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));',
					'& span,  p ': {
						fontWeight: '600',
					},
					'&  p': {
						color: theme.palette.primary.dark,
						margin: theme.spacing(1),
					},
				},
			},
		},
		PageDictionary: {
			'& .titleDic': {
				backgroundColor: theme.palette.divider,
				color: theme.palette.text.secondary,
			},
			'& .search': {
				marginTop: theme.spacing(2),
				position: 'relative',
				boxShadow: theme.shadows[3],
				borderRadius: theme.spacing(3),
				backgroundColor: alpha(theme.palette.common.white, 0.55),
				'&:hover': {
					backgroundColor: alpha(theme.palette.common.white, 0.35),
				},
				width: '100%',
				[theme.breakpoints.up('sm')]: {
					marginLeft: 'auto',
					marginRight: 'auto',
					width: '40ch',
				},
				'& .MuiInputBase-root': {
					width: '100%',
					paddingLeft: theme.spacing(1),
					paddingRight: theme.spacing(1),
					color: theme.palette.text.secondary,
				},
				'& input': {
					color: theme.palette.text.secondary,
					padding: theme.spacing(1, 1, 1, 0),
					/*  vertical padding + font size from searchIcon */
					paddingLeft: 'calc(1em )',
					transition: theme.transitions.create('width'),
					/*  width: "100%", */
				},
			},
			'& .ListDefinition': {
				marginBottom: '50px',
				'& hr': {
					margin: theme.spacing(1, 1, 2),
				},
			},
			'& .MuiPagination-root': {
				backgroundColor: theme.palette.divider,
				textAlign: 'center',
				position: 'fixed',
				bottom: '0',
				left: '0',
				right: '0',
				'& .MuiPagination-ul': {
					display: 'inline-flex',
				},
			},
		},
		CopyrightH: {
			[theme.breakpoints.down('sm')]: {
				position: 'relative',
			},
		},
	})
);

const useStylesReactive = makeStyles((theme: Theme) => createStyles({}));
export { useStyles, useStylesReactive };
export default useStyles;
