import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Typography,
	makeStyles,
	Theme,
} from '@material-ui/core';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import useLocalstorage from '../lib/use-Localstorage';

const useStyles = makeStyles((theme: Theme) => ({
	alingInfo: {
		display: 'flex',
		alignItems: 'center',
	},
}));

function Pagesession() {
	const { logOut } = useLocalstorage();
	const classes = useStyles();
	const open = true;

	const history = useHistory();
	const handleRoutes = (path: string) => {
		logOut();
		history.push(path);
	};

	return (
		<Dialog open={open}>
			<DialogTitle id='alert-dialog-title'>Session message</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					<Typography component='div' className={classes.alingInfo}>
						<NoEncryptionIcon fontSize='large' /> Your session time has expired.
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleRoutes('/')} color='primary' autoFocus>
					Closes
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export { Pagesession };
export default Pagesession;
