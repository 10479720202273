import React, { Fragment, useContext } from 'react';

import { makeStyles } from '@material-ui/core';
import { IApp, ChatUser } from './chat-interface';
import { AppContext } from '../../providers/AppContext';
import WindowChat from './WindowChat';

const useStyles = makeStyles(theme => ({
	chatContainer: {
		display: 'flex',
		position: 'fixed',
		bottom: '0px',
		right: '0px',
		width: 'auto',
		zIndex: 9999,
		justifyContent: 'flex-end',
		marginRight: theme.spacing(2),
	},
}));

interface WindowsContainerProps {}

export function WindowsContainer(props: WindowsContainerProps) {
	const classes = useStyles();
	const { users } = useContext<IApp>(AppContext);

	return (
		<div className={classes.chatContainer}>
			{users?.map((user: ChatUser) => (
				<Fragment key={user.user}>
					<WindowChat user={user} />
				</Fragment>
			))}
		</div>
	);
}

export default WindowsContainer;
