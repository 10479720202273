import React from 'react';
import { IconButton, InputBase, Typography, Menu, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export interface BarrasearchProps {
	diccionary: number;
	setDiccionary: any;
	search: string;
	setSearch: any;
	handleClick: any;
}

Barrasearch.defaultProps = {};

function Barrasearch(props: BarrasearchProps): JSX.Element {
	const { search, setSearch, diccionary, setDiccionary, handleClick } = props;

	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

	const idiomMenu = 'idiom-Menu';
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleidiomMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleidiomMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const handclickidiom = (event: React.MouseEvent<HTMLElement>) => {
		const { myValue } = event.currentTarget.dataset;
		if (myValue) {
			setDiccionary(parseInt(myValue, 10));
			handleidiomMenuClose();
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			handleClick();
		}
	};

	const renderidiomMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={idiomMenu}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleidiomMenuClose}
		>
			<MenuItem data-my-value={0} onClick={handclickidiom}>
				Spanish
			</MenuItem>
			<MenuItem data-my-value={1} onClick={handclickidiom}>
				English
			</MenuItem>
		</Menu>
	);

	return (
		<>
			<div>
				<Typography variant='body1' align='center' className='titleDic'>
					<b>{diccionary === 0 ? 'Spanish - English' : 'English - Spanish'}</b>
				</Typography>
				<Typography align='center' component='div'>
					<div className='search'>
						<InputBase
							id='search'
							value={search}
							autoComplete='i-search'
							onChange={handleChange}
							onKeyDown={handleKeyDown}
							startAdornment={
								<IconButton
									aria-label='show more'
									aria-controls={idiomMenu}
									aria-haspopup='true'
									onClick={handleidiomMenuOpen}
									color='inherit'
								>
									<MoreVertIcon />
								</IconButton>
							}
							endAdornment={
								<IconButton
									onClick={handleClick}
									aria-label='Search...'
									aria-haspopup='true'
									color='inherit'
								>
									<SearchIcon />
								</IconButton>
							}
							placeholder='Search…'
							inputProps={{ 'aria-label': 'search' }}
						/>
					</div>
				</Typography>
			</div>
			{renderidiomMenu}
		</>
	);
}

export { Barrasearch };
export default Barrasearch;
