import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { Iconactivity } from '../Unit-Conten';
import { usePeticiones } from '../../lib';
import DivSanitized from '../DivSanitized';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},
	actions: {
		justifyContent: 'end',
		display: 'flex',
	},
	image: {
		border: `solid 8px ${theme.palette.primary.main}`,
		borderRadius: '50%',
		width: ' 144px',
		height: '144px',
		marginRight: theme.spacing(),
	},
	title: { fontSize: '15px !important' },
	content: { display: 'flex' },
}));

interface ISuggestedActivity {
	unit: string;
	lesson: string;
	activity: string;
	activityName: string;
	image: string;
	lessonName: string;
	unitName: string;
}

export function SuggestedActivity() {
	const classes = useStyles();
	const { peticion } = usePeticiones();

	const [userActivity, setUserActivity] = useState<null | ISuggestedActivity>(null);
	const [mount, setMount] = useState(false);

	useEffect(() => {
		const getDataSuggest = async () => {
			const user = await peticion({ formData: {}, accion: 'home/suggested-activity' });
			setUserActivity(user.data);
		};
		if (!mount) {
			getDataSuggest();
			setMount(true);
		}
	}, [mount, peticion]);

	return (
		<Card>
			<CardHeader
				className={classes.header}
				title={<b className={classes.title}>Suggested activity</b>}
			/>

			<CardContent>
				<Grid container>
					<Grid item md={3}>
						<div className={classes.image}>
							<Iconactivity
								fontSize='xxlarge'
								name={`${userActivity?.image.replace('menu', 'course-icon').replace('.jpg', '')}`}
							/>
						</div>
					</Grid>
					<Grid item md={9}>
						<div className='description'>
							<div>
								Unit {userActivity?.unit}
								<Typography color='primary' variant='h6'>
									{userActivity?.unitName}
								</Typography>
							</div>
							<div>
								Lesson {userActivity?.lesson}
								<Typography color='primary' variant='h6'>
									{userActivity?.lessonName}
								</Typography>
							</div>
							<hr />
							<div>
								<Typography color='primary'>Activity: </Typography>
								<Typography variant='h3'>
									<DivSanitized text={userActivity?.activityName as string} />
								</Typography>
							</div>
						</div>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions disableSpacing className={classes.actions}>
				<Button
					color='primary'
					variant='contained'
					href={`unit/${userActivity?.unit}/${userActivity?.unitName
						.replace(/ /g, '-')
						.toLocaleLowerCase()}`}
				>
					Start
				</Button>
			</CardActions>
		</Card>
	);
}
export default SuggestedActivity;
