import React from 'react';
import { v4 as uuid } from 'uuid';
import { Box, Grid, Typography } from '@material-ui/core';
import { BlockSegment } from './Block-Segment';
import { DivSanitized } from '../..';

import { ActivityContent, ActivityContentItem } from '../Activity-interface';

interface SegmentProp {
	content: ActivityContent[];
}

function SegmentActivity(props: SegmentProp) {
	const { content } = props;
	const getKey = () => uuid();

	return (
		<>
			{content.map((contenido: ActivityContent) => (
				<React.Fragment key={getKey()}>
					<Box mb={2}>
						<Grid item xs={12} className='Miu-bloque'>
							<Grid item xs={12}>
								{contenido.title ? (
									<Typography gutterBottom variant='h4' component='p' className=' Titulo '>
										<DivSanitized text={contenido.title} />
									</Typography>
								) : null}
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={1} className='contenedor'>
									{contenido.content.map((item: ActivityContentItem) => (
										<React.Fragment key={getKey()}>
											<BlockSegment data={item} />
										</React.Fragment>
									))}
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</React.Fragment>
			))}
		</>
	);
}

export { SegmentActivity };
export default SegmentActivity;
