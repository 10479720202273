import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Pagesession } from '../pages';
import { AppContext } from '../providers/AppContext';

interface PrivaterouteProps {
	path: string;
	children: React.ReactNode;
}

function Privateroute(props: PrivaterouteProps) {
	const { path, children } = props;

	const { acceso } = React.useContext<any>(AppContext);

	return (
		<Route exact path={path}>
			{acceso.isAutorizate ? (
				<>{!acceso.isActivate ? <Redirect to='/activate/' /> : children}</>
			) : (
				<>{acceso.isAutorizate === undefined ? <Redirect to='/' /> : <Pagesession />}</>
			)}
		</Route>
	);
}

export { Privateroute };
export default Privateroute;
