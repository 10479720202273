import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { BlockActivity } from '.';
import { EvaluationProps } from './Unit-interface';

function Evaluation(props: EvaluationProps): JSX.Element {
	const { evaluation } = props;

	return (
		<>
			<Grid item xs={12} sm={6} className='MuiPronun'>
				<Grid item xs={12}>
					<Typography className='MuiTitleLesson' align='center' variant='h5'>
						{evaluation.name}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<BlockActivity activated={evaluation.activated} activities={evaluation.activities} />
				</Grid>
			</Grid>
		</>
	);
}

export { Evaluation };
export default Evaluation;
