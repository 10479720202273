import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	makeStyles,
	Theme,
} from '@material-ui/core';
import { VolumeUp } from '@material-ui/icons';
import { usePeticiones } from '../../lib';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},

	title: { fontSize: '15px !important' },
}));

export function WordOfTheDay() {
	const classes = useStyles();

	const [word, setWord] = useState<string>('');
	const [example, setExample] = useState<string>('');
	// const [audio, setAudio] = useState<string>('');
	const [mount, setMount] = useState<boolean>(false);
	const { peticion } = usePeticiones();
	useEffect(() => {
		const getData = async () => {
			const { data } = await peticion({ formData: {}, accion: 'home/word-of-the-day' });
			setWord(data.word);
			setExample(data.example);
			// setAudio(data.audio);
		};
		if (!mount) {
			getData();
			setMount(true);
		}
	}, [mount, peticion]);

	return (
		<Card
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<CardHeader
				className={classes.header}
				title={<b className={classes.title}>Word of The Day</b>}
			/>
			<CardContent>
				<div>
					<IconButton>
						<VolumeUp />
					</IconButton>
					<b>{word}</b>
				</div>
				<p>{example}</p>
			</CardContent>
			<CardActions style={{ display: 'flex', justifyContent: 'end' }}>
				<Button size='small' color='primary' variant='outlined'>
					see more
				</Button>
			</CardActions>
		</Card>
	);
}
export default WordOfTheDay;
