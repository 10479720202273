import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { google, outlook, office365, yahoo, ics, CalendarEvent } from 'calendar-link';

import moment from 'moment';
import {
	Card,
	CardContent,
	CardHeader,
	Container,
	Button,
	Grid,
	Theme,
	makeStyles,
} from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import Calendar from 'react-calendar';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-calendar/dist/Calendar.css';
import '../lib/calendar.css';
import Moment from 'react-moment';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { Navbar } from '../components';
import { usePeticiones } from '../lib';
import { PinnedSubheaderList } from '../components/Home-content/CurrentUnitClasses';

export interface PageProps {
	modeTheme: boolean;
	changeTheme: any;
}
const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},

	title: { fontSize: '15px !important' },
}));
function PageCalendar(props: PageProps): JSX.Element {
	const { modeTheme, changeTheme } = props;
	const { peticion } = usePeticiones();
	const classes = useStyles();

	const [virtualClasses, setClasses] = useState<any[]>([]);
	const [currentDate, setCurrrentDate] = useState<any>(moment());
	const [mount, setMount] = useState<boolean>(false);
	const nextDate = async () => {
		const date = moment(currentDate).add(1, 'day');
		getClasses(date.format('yyyy-MM-DD'));
	};
	const prevDate = async () => {
		const date = moment(currentDate).add(-1, 'day');

		getClasses(date.format('yyyy-MM-DD'));
	};
	const getClasses = useCallback(
		async formatDate => {
			setCurrrentDate(moment(formatDate));

			const result = await peticion({
				accion: `virtual-class/${formatDate}`,
				formData: {},
			});
			const dates = await result.data.reduce((arr: any[], b: any) => {
				b.date = moment(b.date);
				if (moment(formatDate).isSame(b.date, 'day')) {
					const event: CalendarEvent = {
						title: b.name,
						description: '<a href="https://course.winningwithenglish.com/home"> open class</a>',
						start: b.date,
						duration: [1, 'hour'],
					};
					b.google = google(event);
					b.outlook = outlook(event);
					b.office365 = office365(event);
					b.yahoo = yahoo(event);
					b.ics = ics(event);
					b.date = moment(b.date).format('yyyy-MM-DD HH:mm:ss');
					b.date = Date.parse(b.date);
					arr.push(b);
				}
				return arr;
			}, []);

			setClasses(dates);
		},
		[peticion]
	);
	useEffect(() => {
		if (!mount) {
			setMount(true);
			const date = new Date();
			const formatDate = moment(date as Date).format('yyyy-MM-DD');
			getClasses(formatDate);
		}
	}, [getClasses, mount]);
	return (
		<>
			<Navbar modeTheme={modeTheme} changeTheme={changeTheme} />
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<CalendariO value={currentDate} getClasses={getClasses} />
					</Grid>

					<Grid item xs={12} md={9}>
						<Card style={{ height: '100%' }}>
							<CardHeader
								className={classes.header}
								title={
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<Button onClick={prevDate}>
											<ChevronLeft />
										</Button>
										<b className={classes.title}>
											<Moment format='yyyy MM DD'>{currentDate}</Moment>
										</b>
										<Button onClick={nextDate}>
											<ChevronRight />
										</Button>
									</div>
								}
							/>
							<CardContent>
								<PinnedSubheaderList isFull calendarLink data={virtualClasses} type='today' />
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
interface CalendarProps {
	getClasses: (formatDate: any) => void;
	value: Value;
}

const CalendariO = (props: CalendarProps) => {
	const { getClasses, value } = props;

	return (
		<Calendar
			onChange={(date: Value) => {
				const formatDate = moment(date as Date).format('yyyy-MM-DD');
				getClasses(formatDate);
			}}
			value={value}
		/>
	);
};
export { PageCalendar };
export default PageCalendar;

// https://calendar.google.com/calendar/u/0/r/eventedit?
// text=My+Custom+Event&
// dates=20180512T230000Z/20180513T030000Z&
// details=For+details,+link+here:+https://example.com/tickets-43251101208&location=Garage+Boston+-+20+Linden+Street+-+Allston,+MA+02134
