import React from 'react';
import {
	Container,
	Grid,
	Paper,
	TextField,
	Button,
	List,
	ListItem,
	ListItemText,
} from '@material-ui/core';

import { Copyright, TituloLogin, ToastBar, DialogSimple } from '../components';
import { useRecovery, useStyles, useMessage } from '../lib';

interface State {
	email: string;
}

function Pagerecovery() {
	const classes = useStyles();
	const {
		RecoveryIn,
		statePeteiciones,
		menssage,
		setMenssage,
		recoveryList,
		setRecoveryList,
		Recoveryuser,
	} = useRecovery();

	const [values, setValues] = React.useState<State>({
		email: '',
	});

	const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handClickrecovery = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		RecoveryIn(values, setValues);
	};

	const handleClose = () => {
		setMenssage(useMessage.ini[0]);
	};

	const handleCloseList = () => {
		setRecoveryList([]);
	};

	const handleClickList = (item: any) => {
		Recoveryuser(item);
	};

	const ListaUser = (
		<List>
			{recoveryList.map((item: any, index: any) => (
				<ListItem
					key={`item-${index.toString()}-id `}
					button
					aria-haspopup='true'
					aria-controls='lock-menu'
					aria-label='when device is locked'
					onClick={() => handleClickList(item)}
				>
					<ListItemText primary={item.userNick} />
				</ListItem>
			))}
		</List>
	);

	return (
		<>
			<Container maxWidth='xs'>
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<Paper className={classes.containerLogin}>
							<TituloLogin
								titulo='Find your email address'
								text='Enter your phone number or your recovery email address'
								iconName='MailOutlineOutlined'
								align='center'
							/>
							<form className='form-login'>
								<TextField
									id='email'
									value={values.email}
									onChange={handleChange('email')}
									autoComplete='i-email'
									label='Email Address'
									required
									margin='normal'
									type='email'
									fullWidth
									variant='outlined'
								/>

								<Grid container spacing={1}>
									<Grid item xs={6}>
										<Button href='/signin' fullWidth variant='outlined' color='default'>
											Back
										</Button>
									</Grid>
									<Grid item xs={6}>
										<Button
											disabled={statePeteiciones.isLoading}
											fullWidth
											variant='contained'
											color='primary'
											onClick={handClickrecovery}
										>
											Recovery
										</Button>
									</Grid>
								</Grid>
							</form>
							<Copyright />
						</Paper>
					</Grid>
				</Grid>
			</Container>
			{recoveryList.length > 0 ? (
				<DialogSimple open title='Select user' handleClose={handleCloseList}>
					{ListaUser}
				</DialogSimple>
			) : null}
			{menssage.message ? (
				<ToastBar
					color={menssage.accion}
					autoHideDuration={3000}
					anchorOriginV='bottom'
					anchorOriginH='right'
					onClose={handleClose}
					message={menssage.message}
				/>
			) : null}
		</>
	);
}

export { Pagerecovery };
export default Pagerecovery;
