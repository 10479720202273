import React from 'react';

import { useHistory } from 'react-router-dom';
import {
	Card,
	CardContent,
	CardHeader,
	Container,
	Grid,
	Button,
	makeStyles,
	Theme,
} from '@material-ui/core';

import { Navbar } from '../components';
import { usePeticiones } from '../lib';

export interface PageDashboardProps {
	modeTheme?: boolean;
	changeTheme?: any;
}
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		marginTop: theme.spacing(2),
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'stretch',
	},
	card: {
		height: '100%',
	},
}));
function PageDashboard(props: PageDashboardProps) {
	const classes = useStyles();
	const { modeTheme, changeTheme } = props;
	const history = useHistory();
	const { peticion } = usePeticiones();
	return (
		<>
			<Navbar modeTheme={modeTheme} changeTheme={changeTheme} />
			<Container>
				<Grid container className={classes.root} spacing={2}>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Actualizar Api' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={async () => peticion({ accion: 'update-project', formData: {} })}
									>
										Actualizar Api
									</Button>
								</>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={async () =>
											peticion({ accion: 'update-project-developer', formData: {} })
										}
									>
										Actualizar Api Developer
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Administrador de contenido' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() => history.push('/admin')}
									>
										Administrar contenido
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Generar google Calendar' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() => history.push('/generate-google-calendar')}
									>
										Generar Google calendar
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Kardex Clientes' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() => history.push('/admin')}
									>
										Kardex Clientes
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Kardex Usuarios Demo' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() => history.push('/admin')}
									>
										Kardex Usuarios Demo
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Contacto' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() => history.push('/admin')}
									>
										Contacto
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Examenes con respuestas' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() => history.push('/admin')}
									>
										Examenes con respuestas
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Quiz con respuestas' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() => history.push('/admin')}
									>
										Quiz con respuestas
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Temas wwe' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() => history.push('/admin')}
									>
										Temas wwe
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12} md={3}>
						<Card className={classes.card}>
							<CardHeader title='Generar clases' />
							<CardContent>
								<>
									<Button
										color='primary'
										variant='contained'
										onClick={() =>
											peticion({ accion: 'virtual-class-admin/create-class-day/wwe', formData: {} })
										}
									>
										Generar clases WWR
									</Button>
									<Button
										color='primary'
										variant='contained'
										onClick={() =>
											peticion({
												accion: 'virtual-class-admin/create-class-day/kids',
												formData: {},
											})
										}
									>
										Generar clases Kids
									</Button>
								</>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

PageDashboard.defaultProps = {
	modeTheme: null,
	changeTheme: undefined,
};

export { PageDashboard };
export default PageDashboard;
