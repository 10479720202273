import React, { Fragment } from 'react';
import { v4 as key } from 'uuid';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Check, Clear } from '@material-ui/icons';

interface ProgressExerciseActivityProps {
	current: number;
	steps: StepProgress[];
}
export interface StepProgress {
	number: number;
	isCorrect: boolean | null;
	media: string | string[];
	localMedia: string | string[];
	imagen: string | string[];
	question: string;
	userAnswer: string[];
	activeAnswer: boolean | null;
	typeActivity: any;
}
export interface StepProgressA extends StepProgress {
	alingAnswers: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: `0px ${theme.spacing(1)}px`,
	},
	dot: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '10px',
		height: '10px',
		background: theme.palette.grey[400],
		borderRadius: '50%',
		[theme.breakpoints.up('sm')]: {
			width: '20px',
			height: '20px',
		},
	},

	activated: {
		background: theme.palette.primary.light,
	},
	correct: {
		background: theme.palette.success.light,
	},
	incorrect: {
		background: theme.palette.error.light,
	},
	icon: {
		display: 'none',
		color: '#fff',
		fontSize: '.8rem',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	},
	conector: {
		flex: '1',
		borderTop: `solid 1px ${theme.palette.grey[200]}`,
		borderBottom: `solid 1px ${theme.palette.divider}`,
	},
}));

function ProgressExerciseActivity(props: ProgressExerciseActivityProps) {
	const { current, steps } = props;
	const classes = useStyles();
	return (
		<>
			<div className={classes.root}>
				{steps.map((step: StepProgress, index: number) => (
					<Fragment key={key()}>
						<div
							key={key()}
							className={clsx([
								classes.dot,
								{ [classes.activated]: step.number === current },
								{ [classes.correct]: step.isCorrect !== null && step.isCorrect },
								{ [classes.incorrect]: step.isCorrect !== null && !step.isCorrect },
							])}
						>
							{step.isCorrect !== null ? (
								<>
									{step.isCorrect ? (
										<Check className={classes.icon} />
									) : (
										<Clear className={classes.icon} />
									)}
								</>
							) : null}
						</div>
						{index < steps.length - 1 ? <div className={classes.conector} /> : null}
					</Fragment>
				))}
			</div>
		</>
	);
}

export { ProgressExerciseActivity };
export default ProgressExerciseActivity;
