import React from 'react';
import { useIndexedDBStore } from 'use-indexeddb';
import { useHistory } from 'react-router-dom';
import { v4 as key } from 'uuid';

import {
	SwipeableDrawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
} from '@material-ui/core';

import { Iconsvg } from '.';
import { useStyles, usePeticiones, useLocalstorage } from '../lib';

export interface SidenavProps {
	open: boolean;
	toggleLocation: any;
}

function Sidenav(props: SidenavProps) {
	const classes = useStyles();
	const history = useHistory();
	const { toggleLocation, open } = props;

	const { peticionAsync } = usePeticiones();
	const { getMenulocation, updateUnits } = useLocalstorage();
	const { getOneByIndex, update } = useIndexedDBStore('Content');

	const [menuOption, setMenuOption] = React.useState({
		home: [{}],
		calendar: [{}],
		course: [{}],
		tools: [{}],
		tesl: [{}],
	});

	const [mount, setMount] = React.useState(true);
	const [selectedIndex, setSelectedIndex] = React.useState('0_0');

	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: string,
		link: string
	) => {
		setSelectedIndex(index);
		toggleLocation(false);
		history.push(link);
	};

	const handleOpen = () => {};

	const handleClose = () => {
		toggleLocation();
	};

	React.useEffect(() => {
		const fetchdata = async (info: any) => {
			const result: any = await peticionAsync({
				accion: 'menu',
				formData: {},
			});
			updateUnits(result.data.course);
			if (info !== JSON.stringify(result.data)) {
				setMenuOption(result.data);

				update({
					id: 2,
					keysearch: 'Menu',
					contenido: JSON.stringify(result.data),
				}).then(event => {});
			}
		};
		if (mount) {
			setMount(false);
			getOneByIndex('keysearch', 'Menu').then((infoDB: any) => {
				if (infoDB) {
					setMenuOption(JSON.parse(infoDB.contenido));
				}
				fetchdata(infoDB?.contenido ?? infoDB);
			});
		}
	}, [peticionAsync, mount, updateUnits, getOneByIndex, update]);

	const anchor = 'SwipeableDrawer-location';
	return (
		<React.Fragment key={anchor}>
			<SwipeableDrawer
				className={classes.SwipDrawerRoot}
				anchor={getMenulocation()}
				open={open}
				onClose={handleClose}
				onOpen={handleOpen}
			>
				<div role='presentation'>
					<List>
						{Object.values(menuOption).map((option: any, grupo: number) => (
							<List key={key()}>
								{option.map((Item: any, index: number) => (
									<div key={key()}>
										<ListItem
											button
											key={key()}
											selected={selectedIndex === `${grupo}_${index}`}
											onClick={event => handleListItemClick(event, `${grupo}_${index}`, Item.link)}
										>
											<ListItemIcon>
												<Iconsvg name={Item.icon} fontSize='small' />
											</ListItemIcon>
											<ListItemText disableTypography primary={Item.text} />
										</ListItem>
									</div>
								))}
								{Object.values(menuOption).length - 1 !== grupo ? <Divider /> : null}
							</List>
						))}
					</List>
				</div>
			</SwipeableDrawer>
		</React.Fragment>
	);
}

export { Sidenav };
export default Sidenav;
