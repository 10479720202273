import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	makeStyles,
	Theme,
} from '@material-ui/core';
import { usePeticiones } from '../../lib';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},

	title: { fontSize: '15px !important' },
}));

interface Iphrase {
	type: string;
	phrase: string;
	definition: string;
	see: string;
	manualAccent: string;
}

export function TravelerPhrases() {
	const classes = useStyles();

	// const phrase =
	// 	'It is not the strength, but the duration, of great sentiments that makes great men.';
	const [mount, setMount] = useState<boolean>(false);
	const [phrase, setPhrase] = useState<Iphrase>({
		type: '',
		phrase: '',
		definition: '',
		see: '',
		manualAccent: '',
	});
	const { peticion } = usePeticiones();

	useEffect(() => {
		const getData = async () => {
			const { data } = await peticion({ formData: {}, accion: 'home/traveler-phrases' });
			setPhrase(data);
		};
		if (!mount) {
			getData();
			setMount(true);
		}
	}, [mount, peticion]);
	return (
		<Card
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<CardHeader
				className={classes.header}
				title={<b className={classes.title}>Traveler&apos;s Phrases</b>}
			/>
			<CardContent>
				<p>phrase {phrase.phrase}</p>
				<p>see {phrase.see}</p>
				<p>type{phrase.type}</p>
				<p>definition {phrase.definition}</p>
				<p>manualAccent {phrase.manualAccent}</p>
			</CardContent>
			<CardActions style={{ display: 'flex', justifyContent: 'end' }}>
				<Button size='small' color='primary' variant='outlined'>
					see more
				</Button>
			</CardActions>
		</Card>
	);
}
export default TravelerPhrases;
