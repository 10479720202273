import React from 'react';
import { Redirect } from 'react-router-dom';
import { useLocalstorage } from '../lib';

function RootRedirect() {
	const { isAutorizate } = useLocalstorage();
	const to = isAutorizate() ? '/home' : 'signin';

	return <Redirect to={to} />;
}

export { RootRedirect };
export default RootRedirect;
