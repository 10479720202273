import React from 'react';
import usePeticiones from './use-Peticiones';
import useMessage from './use-message';

interface StateRecovery {
	email: string;
}

interface StateResetpassword {
	password: string;
	confirmation: string;
	segurityToken: string;
	showPassword: boolean;
}

interface ListUser {
	IdUsuarios: string;
	Nombre: string;
	UserNick: string;
	email: string;
}

function useRcovery() {
	const { peticion, statePeteiciones } = usePeticiones();

	const [menssage, setMenssage] = React.useState(useMessage.ini[0]);
	const [recoveryList, setRecoveryList] = React.useState<ListUser[]>([]);

	function RecoveryIn(frm: StateRecovery, setfrm: any) {
		const regex = new RegExp('^[A-Za-z][A-Za-z0-9_.-]*@[A-Za-z0-9_-]+.[A-Za-z0-9_.]+[A-za-z]$');
		const { email } = frm;

		const nMail = frm.email
			? {
					name: email.split('@')[0],
					hostname: email.split('@')[1].split('.')[0],
					ext: email.split('@')[1].split('.')[1],
			  }
			: { name: '', hostname: '', ext: '' };

		const body = { email: frm.email };
		setMenssage(useMessage.ini[0]);

		if (frm.email === '') {
			setMenssage(useMessage.recovery[0]);
		} else if (
			!regex.test(frm.email) ||
			(regex.test(frm.email) && nMail.name.length < 2) ||
			(regex.test(frm.email) && nMail.hostname.length < 2) ||
			(regex.test(frm.email) && nMail.ext.length < 2)
		) {
			setMenssage(useMessage.recovery[1]);
		} else {
			peticion({ accion: 'recovery', formData: body }).then(
				(datos: any) => {
					if (datos.success) {
						setfrm({ email: '' });
						if (datos.data.userList) {
							setRecoveryList(datos.data.userList);
						} else {
							setMenssage({
								message: datos.data.message,
								accion: 'success',
							});
						}
					} else {
						setMenssage({
							message: datos.message,
							accion: 'error',
						});
					}
				},
				error => {}
			);
		}
	}

	function Recoveryuser(frm: any) {
		const body = { user: frm };

		peticion({ accion: 'recovery', formData: body }).then(
			(datos: any) => {
				if (datos.success) {
					if (datos.data.userList) {
						setRecoveryList(datos.data.userList);
					} else {
						setMenssage({
							message: datos.data.message,
							accion: 'success',
						});
					}
				} else {
					setMenssage({
						message: datos.message,
						accion: 'error',
					});
				}
			},
			error => {}
		);
	}

	function ResetpasswordIn(frm: StateResetpassword, setfrm: any) {
		const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,20})');

		const clowRegex = new RegExp('^(?=.*[a-z])');
		const cUpperRegex = new RegExp('^(?=.*[A-Z])');
		const numRegex = new RegExp('^(?=.*[0-9])');

		const body = {
			codigoUsuario: frm.password,
			password: frm.password,
			confirmation: frm.confirmation,
			segurityToken: frm.segurityToken,
		};

		setMenssage(useMessage.ini[0]);

		if (frm.password === '') {
			setMenssage(useMessage.resetpassword[0]);
		} else if (!strongRegex.test(frm.password)) {
			if (frm.password.length < 6 || frm.password.length > 20) {
				setMenssage(useMessage.resetpassword[1]);
			} else if (!clowRegex.test(frm.password)) {
				setMenssage(useMessage.resetpassword[2]);
			} else if (!cUpperRegex.test(frm.password)) {
				setMenssage(useMessage.resetpassword[3]);
			} else if (!numRegex.test(frm.password)) {
				setMenssage(useMessage.resetpassword[4]);
			} else {
				setMenssage(useMessage.resetpassword[5]);
			}
		} else if (frm.confirmation === '') {
			setMenssage(useMessage.resetpassword[6]);
		} else if (frm.password !== frm.confirmation) {
			setMenssage(useMessage.resetpassword[7]);
		} else {
			peticion({ accion: 'reset-password', formData: body }).then(
				(datos: any) => {
					if (datos.success) {
						setMenssage({ message: datos.data.message, accion: 'success' });
						setfrm({ ...frm, success: 'success' });
					} else {
						setMenssage({ message: datos.message, accion: 'error' });
					}
				},
				error => {}
			);
		}
	}

	return {
		RecoveryIn,
		ResetpasswordIn,
		statePeteiciones,
		menssage,
		setMenssage,
		recoveryList,
		setRecoveryList,
		Recoveryuser,
	};
}

export { useRcovery };
export default useRcovery;
