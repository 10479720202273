import React, { useState } from 'react';
// import clsx from 'clsx';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx
import { AudioExercise } from '../audio-exercise';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
	},
}));

export function ReactiveBase(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent, component } = typeActivity;
	const { question, answer, img, media, localMedia, time } = reactive;
	const classes = useStyles();
	const [userAnswer, setUserAnswer] = useState('');
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);

	const checkAnswer = () => {
		const correct = answer.includes(userAnswer);
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
		onCheck(correct, [userAnswer], question, media, localMedia, img, activaAnswer);
	};
	const nextAnswer = () => {
		onNext();
	};
	const handleSelect = () => {
		setUserAnswer('respuesta del usuario');
	};
	return (
		<>
			<div className={classes.reactive}>
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
				{isExample ? (
					<Typography component='div' color='primary' variant='h6'>
						Example:
					</Typography>
				) : null}
				<Typography component='div' className={classes.question}>
					<DivSanitized text={question} />
				</Typography>
				aun no hay componente para los reactivos {component} {typeComponent}
				fabor de agregar
				<Button onClick={handleSelect}> seleccionar respuesta</Button>
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export default ReactiveBase;
