import React, { useEffect, useState } from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	LinearProgress,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { EmojiEvents } from '@material-ui/icons';
import { usePeticiones } from '../../lib';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},
	actions: {
		justifyContent: 'end',
		display: 'flex',
	},
	image: {
		border: `solid 8px ${theme.palette.primary.main}`,
		borderRadius: '50%',
		width: ' 144px',
		height: '144px',
		marginRight: theme.spacing(),
	},
	title: { fontSize: '15px !important' },
	content: { display: 'flex' },
}));

export function Chart({ dailyPoints }: any) {
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: true,
				text: 'Daily points',
			},
		},
	};
	return <Line options={options} data={dailyPoints} />;
}

export function DailyGoal() {
	const data = {
		labels: [],
		datasets: [
			{
				label: 'Points',
				data: [],
				borderColor: '#9c27b0',
				backgroundColor: '#9c27b0',
			},
		],
	};
	const [dailyPoints, setDailyPoints] = useState<any>(data);
	const [goal, setGoal] = useState<number>(0);
	const [currentPoints, setCurrentPoints] = useState<number>(0);
	const [mount, setMount] = useState<boolean>(false);

	const { peticion } = usePeticiones();
	useEffect(() => {
		const getDailyPoints = async () => {
			const currentDate = new Date();
			const cDay = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate();
			const cMonth =
				currentDate.getMonth() + 1 < 10
					? `0${currentDate.getMonth() + 1}`
					: currentDate.getMonth() + 1;
			const cYear = currentDate.getFullYear();
			const userPoints = await peticion({
				formData: { time: `${cDay}-${cMonth}-${cYear}` },
				accion: 'home/daily-points',
			});
			const dataPoints = {
				labels: userPoints.data.fields,
				datasets: [
					{
						label: 'Points',
						data: userPoints.data.points,
						borderColor: '#9c27b0',
						backgroundColor: '#9c27b0',
					},
				],
			};
			setDailyPoints(dataPoints);
			setCurrentPoints(userPoints.data.current);
			setGoal(userPoints.data.goal);
		};
		if (!mount) {
			getDailyPoints();
			setMount(true);
		}
	}, [mount, peticion]);
	const classes = useStyles();

	return (
		<Card style={{ height: '100%' }}>
			<CardHeader
				className={classes.header}
				title={
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<b className={classes.title}>Daily Goal</b>
						<div style={{ flex: 1, padding: '4px', display: 'flex', alignItems: 'center' }}>
							<div style={{ flex: 1 }}>
								<LinearProgress
									color='primary'
									value={(currentPoints / goal) * 100}
									variant='determinate'
								/>
							</div>
							<div>
								<Typography style={{ fontSize: '.8rem' }}>
									<EmojiEvents
										style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '1.4rem' }}
									/>
									{currentPoints}
								</Typography>
							</div>
						</div>
					</div>
				}
			/>

			<CardContent>
				<Chart dailyPoints={dailyPoints} />
			</CardContent>
		</Card>
	);
}
export default DailyGoal;
