import React, { createContext, ReactNode, useEffect } from 'react';

import { usePeticiones } from '../lib';

interface IVirtualClass {
	virtualClassList: any[];
	getVirtualClassList: () => void;
	getVirtualClassUrl: () => void;
}
export const VirtualClassContext: any = createContext<IVirtualClass>({
	virtualClassList: [],
	getVirtualClassList: () => {},
	getVirtualClassUrl: () => {},
});
interface VirtualClassProviderPropr {
	children: ReactNode;
}
export interface ILiveClass {
	IdSessionRTC: string;
	wiziqRTC: string;
	date: string;
	idprograma: string;
	lesson: string;
	name: string;
	type: string;
	unit: string;
	classId: string;
	commonParticipantLink: string;
	hostLink: string;
}
export const VirtualClassProvider = ({ children }: VirtualClassProviderPropr) => {
	// const { getClasses, setClasses } = useLocalstorage();
	const { peticion } = usePeticiones();
	const [virtualClassList, setVirtualClassList] = React.useState<any>(null);
	const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
	const getVirtualClassUrl = async (liveClass: ILiveClass) => {
		const result = await peticion({
			accion: 'virtual-class-admin/add-user-to-class',
			formData: liveClass,
		});
		// eslint-disable-next-line no-console
		console.log(result);
		return result.data;
	};
	const getVirtualClassList = async () => {
		const { data }: { data: ILiveClass[] } = await peticion({
			accion: 'virtual-class',
			formData: {},
		});
		// setClasses(data);
		setVirtualClassList(data);
	};
	useEffect(() => {
		const getUpdatedClasses = async () => {
			setIsLoaded(true);
			const { data }: { data: IVirtualClass[] } = await peticion({
				accion: 'virtual-class',
				formData: {},
			});
			// setClasses(data);
			setVirtualClassList(data);
		};
		if (!isLoaded) {
			// const vcl = getClasses();
			// if (vcl) {
			// 	setVirtualClassList(vcl);
			// } else {
			// }
			getUpdatedClasses();
		}
	}, [peticion, isLoaded, setIsLoaded, virtualClassList]);
	return (
		<VirtualClassContext.Provider
			value={{
				virtualClassList,
				getVirtualClassList,
				getVirtualClassUrl,
			}}
		>
			{children}
		</VirtualClassContext.Provider>
	);
};

export default VirtualClassProvider;
