import React from 'react';
// import { useHistory } from 'react-router-dom';
import {
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Grid,
	IconButton,
	LinearProgress,
	Typography,
	Box,
} from '@material-ui/core';
import { v4 as key } from 'uuid';
import clsx from 'clsx';
import { Iconsvg } from '..';
import { BlockActivityProps, Activities } from './Unit-interface';
import { Activity } from '.';
import { GuideContent } from './Guide-Content';

interface LinearLabel {
	progress: number;
	label: string;
}

function getProgress(activities: Activities[], guides: Activities[], label: boolean) {
	const length: number = activities.length + guides.length;
	const getCompleted = (activity: Activities) => activity.completed;
	const completedActivities = activities.filter(getCompleted);
	const completedGuides = guides.filter(getCompleted);

	if (label) {
		const completedGuidesLabel = completedGuides.length > 0 ? 1 : 0;
		const lengthLabel = activities.length + (guides.length > 0 ? 1 : 0);

		return ` ${completedActivities.length + completedGuidesLabel} / ${lengthLabel} `;
	}
	return ((completedActivities.length + completedGuides.length) / length) * 100;
}

function LinearProgresLabel(props: LinearLabel) {
	const { progress, label } = props;

	return (
		<Box display='flex' className='Miubar'>
			<Box width='100%' mr={1}>
				<LinearProgress variant='determinate' value={progress} />
			</Box>
			<Box minWidth={35}>
				<Typography variant='caption' component='div' color='textSecondary'>
					{label}
				</Typography>
			</Box>
		</Box>
	);
}

function LinearLock() {
	return (
		<Box display='flex' className='MiubarLock'>
			<Box width='100%'>
				<Iconsvg name='lock' fontSize='small' />
			</Box>
		</Box>
	);
}

function BlockActivity(props: BlockActivityProps) {
	// const history = useHistory();

	const { activities, activated, guides, update } = props;
	const progress: number = getProgress(activities, guides, false) as number;
	const progressLabel: string = getProgress(activities, guides, true) as string;

	return (
		<>
			<Card
				className={clsx('MiuBloque', {
					MiuBloqueActivo: activated === true,
				})}
			>
				<CardHeader
					className={clsx('MuiBarProgress', {
						MuiBarProgress: activated === true,
					})}
					title={
						activated ? (
							<LinearProgresLabel label={progressLabel} progress={progress} />
						) : (
							<LinearLock />
						)
					}
				/>
				<CardContent>
					<Grid container justifyContent='center' spacing={2}>
						{activities.map((activity: Activities) => (
							<React.Fragment key={key()}>
								<Grid item xs={6} sm={4} md={3}>
									<IconButton
										// eslint-disable-next-line
										// onClick={() => history.push(activity.url)}
										href={activity.url}
										disabled={!activated}
										className={clsx('IconButon', {})}
									>
										<Activity activity={activity} activated={activated} />
									</IconButton>
								</Grid>
							</React.Fragment>
						))}
					</Grid>
				</CardContent>
				<CardActions disableSpacing>
					<GuideContent guides={guides} activated={activated} update={update} />
				</CardActions>
			</Card>
		</>
	);
}

BlockActivity.defaultProps = {
	guides: [],
};

export { BlockActivity };
export default BlockActivity;
