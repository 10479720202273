import React from 'react';

import { Iconsvg } from '..';

type Name = 'default' | string;
type FontSize = 'xxlarge' | 'xlarge' | 'large' | 'small';

export interface IconactivityProps {
	name: Name;
	fontSize: FontSize;
}

function Iconactivity(props: IconactivityProps): JSX.Element {
	const { name, fontSize } = props;
	return (
		<div className='MiuIconProgress'>
			<Iconsvg name={name} fontSize={fontSize} />
		</div>
	);
}

export { Iconactivity };
export default Iconactivity;
