import React from 'react';
import { useParams } from 'react-router-dom';
import {
	Container,
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';

import InfoIcon from '@material-ui/icons/Info';
import { Copyright, TituloLogin, ToastBar } from '../components';
import { useStyles, useMessage, useLocalstorage, useRecovery } from '../lib';

interface State {
	password: string;
	confirmation: string;
	segurityToken: string;
	showPassword: boolean;
	success: string | null;
}

export interface PageresetpasswordProps {
	actToken?: string | null;
	handle?: () => void;
}

Pageresetpassword.defaultProps = {
	actToken: null,
	handle: () => {},
};

function Pageresetpassword(props: PageresetpasswordProps): JSX.Element {
	const { actToken, handle } = props;
	const classes = useStyles();

	const { token } = useParams();

	const { isAutorizateTmp } = useLocalstorage();

	const { ResetpasswordIn, statePeteiciones, menssage, setMenssage } = useRecovery();

	const [values, setValues] = React.useState<State>(
		actToken === null
			? {
					password: '',
					confirmation: '',
					segurityToken: token,
					showPassword: false,
					success: null,
			  }
			: {
					password: '',
					confirmation: '',
					segurityToken: actToken,
					showPassword: false,
					success: null,
			  }
	);

	const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handClickresetpassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		ResetpasswordIn(values, setValues);
	};

	const handleClose = () => {
		setMenssage(useMessage.ini[0]);
	};

	const TokenValid = isAutorizateTmp(values.segurityToken);

	return TokenValid ? (
		<>
			<Container maxWidth='xs'>
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<Paper className={classes.containerLogin}>
							{!values.success ? (
								<>
									<TituloLogin
										titulo='Reset your acount'
										text='Choose your new password'
										iconName='MailOutlineOutlined'
										align='center'
									/>
									<form className='form-login'>
										<TextField
											id='password'
											value={values.password}
											onChange={handleChange('password')}
											label='New password'
											required
											margin='normal'
											type={values.showPassword ? 'text' : 'password'}
											fullWidth
											variant='outlined'
										/>
										<TextField
											id='confirmation'
											value={values.confirmation}
											onChange={handleChange('confirmation')}
											label='Confirmation password'
											required
											margin='normal'
											type={values.showPassword ? 'text' : 'password'}
											fullWidth
											variant='outlined'
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={values.showPassword}
													onClick={handleClickShowPassword}
													color='default'
												/>
											}
											label='Show password'
										/>

										<Grid container spacing={1}>
											<Grid item xs={6}>
												{actToken === null ? (
													<Button href='/' fullWidth variant='outlined' color='default'>
														Back
													</Button>
												) : (
													<Button onClick={handle} fullWidth variant='outlined' color='default'>
														Back
													</Button>
												)}
											</Grid>
											<Grid item xs={6}>
												<Button
													disabled={statePeteiciones.isLoading}
													fullWidth
													variant='contained'
													color='primary'
													onClick={handClickresetpassword}
												>
													Save
												</Button>
											</Grid>
										</Grid>
									</form>
								</>
							) : (
								<>
									<TituloLogin
										titulo='Successful operation!'
										text='The password change was successful.'
										iconName='MailOutlineOutlined'
										align='center'
									/>
									<form className='form-login'>
										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Button href='/signin' fullWidth variant='contained' color='primary'>
													Sign in
												</Button>
											</Grid>
										</Grid>
									</form>
								</>
							)}

							<Copyright />
						</Paper>
					</Grid>
				</Grid>
			</Container>
			{menssage.message ? (
				<ToastBar
					color={menssage.accion}
					autoHideDuration={3000}
					anchorOriginV='bottom'
					anchorOriginH='right'
					onClose={handleClose}
					message={menssage.message}
				/>
			) : null}
		</>
	) : (
		<>
			<Container maxWidth='xs'>
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<Paper className={classes.containerLogin}>
							<TituloLogin
								titulo='Reset your acount'
								text='Token Invalido'
								iconName='MailOutlineOutlined'
								align='center'
							/>
							<form className='form-login'>
								<Grid container spacing={1}>
									<Grid item xs={1}>
										<InfoIcon color='error' />
									</Grid>
									<Grid item xs={11}>
										<Typography align='center' color='error'>
											Your time has expired, make a new request to change your password
										</Typography>
									</Grid>
								</Grid>

								<Grid container spacing={1}>
									<Grid item xs={6}>
										<Button href='/' fullWidth variant='outlined' color='default'>
											Login
										</Button>
									</Grid>
									<Grid item xs={6}>
										<Button href='/recovery' fullWidth variant='contained' color='primary'>
											Recovery
										</Button>
									</Grid>
								</Grid>
							</form>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export { Pageresetpassword };
export default Pageresetpassword;
