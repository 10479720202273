import React, { Fragment } from 'react';
import { Exercise, MediaActivity } from '.';
import { Activity } from './Activity-interface';

interface propSelectActivity {
	type: string;
	content: Activity;
}

function SelectActivity(props: propSelectActivity) {
	const { type, content } = props;
	return (
		<>
			{type === 'video' || type === 'introduction' || type === 'listen' ? (
				<>
					<MediaActivity activity={content} />
				</>
			) : null}

			{type === 'exercise' ||
			type === 'evaluation' ||
			type === 'reading' ||
			type === 'writing' ||
			type === 'pronunciation' ||
			type === 'audio-podcast' ||
			type === 'quiz' ? (
				<>
					<Exercise activity={content} />
				</>
			) : (
				<>
					{process.env.NODE_ENV === 'development' ? (
						<div> Todavia no hay nada para este tipo de actividad.</div>
					) : null}
				</>
			)}
		</>
	);
}

export { SelectActivity };
export default SelectActivity;
