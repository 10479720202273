import React, { Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TypeActivity } from '../Activity-interface';
import ImageExercise from './Image-Exercise';
import DivSanitized from '../../DivSanitized';

interface CheckAnswerProps {
	isCorrect: boolean | null;
	answers: string[];
	activaAnswer: boolean;
	typeActivity: TypeActivity;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flex: '1',
		color: theme.palette.success.main,
		marginRight: theme.spacing(1),
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
	},
	noselect: {
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
	},
	answerContainer: {
		position: 'absolute',
		bottom: '30px',
		width: '80%',
		background: '#fff',
		borderRadius: '8px',
		padding: '2px',
		border: '0.5px solid #ccc',
		borderStyle: 'dashed',
	},
	answer: {
		fontSize: 'inherit',
		lineHeight: '1',
		fontWeight: 'normal',
		marginTop: '4px',
		[theme.breakpoints.up('md')]: {
			fontWeight: 'lighter',
			fontStyle: 'italic',
		},
	},
	simpleAnswer: {
		margin: '5px 0',
	},
	title: {
		fontSize: 'inherit',
		fontWeight: 'bold',
	},
	image: {
		width: '150px',
		'& .makeStyles-imageContainer-39': {
			width: '60px',
			height: '60px',
			margin: '0px',
		},
	},
}));

function MultipleLine({ answers }: any) {
	return (
		<>
			{answers.map((answer: string, index: number) => (
				<DivSanitized key={uuid()} text={answer} />
			))}
		</>
	);
}
function SimpleAnwser({ answers, separador }: any) {
	const classes = useStyles();

	return (
		<>
			{answers.map((answer: string, index: number) => (
				<Fragment key={uuid()}>
					{index > 0 ? separador : ' '}
					<DivSanitized key={uuid()} text={answer} className={classes.simpleAnswer} />
				</Fragment>
			))}
		</>
	);
}
function MultipleOptionImage({ answers }: any) {
	const classes = useStyles();
	return (
		<>
			{answers.map((answer: string, index: number) => (
				<div className={classes.image} key={uuid()}>
					<ImageExercise isZoom={false} img={answer} alt='correct answer' />
				</div>
			))}
		</>
	);
}
function MultipleOptionImageWrite({ answers }: any) {
	const classes = useStyles();
	return (
		<>
			<div className={classes.image} key={uuid()}>
				<ImageExercise
					isZoom={false}
					img={`/imagen/activity/${answers[0]}.png`}
					alt='correct answer'
				/>
			</div>
			<div> {answers[1]}</div>
		</>
	);
}
function SelectWordSentence({ answers }: any) {
	return (
		<>
			{answers.map((answer: string, index: number) => (
				<DivSanitized
					component='span'
					key={uuid()}
					text={index + 1 === answers.length ? `${answer}` : `${answer}, `}
				/>
			))}
		</>
	);
}

export function CheckAnswer(props: CheckAnswerProps) {
	const { isCorrect, answers, typeActivity, activaAnswer } = props;
	const { component } = typeActivity;
	const classes = useStyles();
	type Reactive = 'Write-Multiple-Line' | 'Write-Multiple-Line-Label' | 'Multiple-Option-Img';

	const components = {
		'Write-Multiple-Line': <MultipleLine answers={answers} />,
		'Write-Complete-Line': <MultipleLine answers={answers} />,
		'Select-Word-Sentence': <SelectWordSentence answers={answers} />,
		'Write-Multiple-Line-Label': <MultipleLine answers={answers} />,
		'Multiple-Option-Img': <MultipleOptionImage answers={answers} />,
		'Multiple-Option-Img-Write': <MultipleOptionImageWrite answers={answers} />,
	};

	const Separador = () => {
		if (typeActivity.component === 'Drag-List-To-List') {
			return 'and';
		}
		return 'or';
	};

	return (
		<div className={classes.root}>
			{isCorrect !== null && !isCorrect && activaAnswer ? (
				<div className={clsx(classes.answerContainer)}>
					<div className={clsx(classes.title, classes.noselect)}>Correct answer :</div>
					<Typography className={clsx(classes.answer, classes.noselect)} component='div'>
						{components[component as Reactive] ?? (
							<SimpleAnwser answers={answers} separador={Separador()} />
						)}
					</Typography>
				</div>
			) : null}
		</div>
	);
}

export default CheckAnswer;
