import React, { Fragment, useState, useRef } from 'react';
import {
	Button,
	Grid,
	makeStyles,
	Theme,
	Typography,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import clsx from 'clsx';
import { Cancel, CheckCircle, HighlightOff } from '@material-ui/icons';
import { ReactiveProps } from '../../Activity-interface';
import { ImageExercise } from '../Image-Exercise';
import { AudioExercise } from '../audio-exercise';
import { DivSanitized } from '../../../DivSanitized';
import { CheckButtons } from '../Check-Buttons';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '70vh',
		maxHeight: '70vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},

	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},

	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
		'&.textcenter': {
			textAlign: 'center',
		},
	},
	options: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(2),
		borderColor: theme.palette.primary.main,
		fontSize: 'inherit',
		'& .MuiButton': {
			fontFamily: 'inherit',
		},
		'& .MuiButtonBase-root.MuiButton-root': {
			fontSize: 'inherit',
			fontWeight: 'normal',
		},
		'& .MuiButton-contained.Mui-disabled': {
			border: `1px solid`,
			borderColor: `${theme.palette.success.main} `,
			color: theme.palette.success.main,
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
			'& .MuiButton-startIcon': {
				backgroundColor: '#4caf500d',
			},
			'& .ImageExercise ': {
				borderColor: `${theme.palette.success.main} `,
			},
		},
		'& .option-disabled': {
			border: `1px solid`,
			borderColor: `${theme.palette.grey[500]} `,
			color: theme.palette.success.main,
			backgroundColor: '#4caf500d',
			'& .MuiButton-startIcon': {
				backgroundColor: '#4caf500d',
			},
			'& .ImageExercise ': {
				borderColor: `${theme.palette.grey[500]} `,
			},
		},
	},
	button: {
		flex: 1,
		height: '100%',
		marginBottom: theme.spacing(1),
		textTransform: 'none',
		'&.Multiple-Option-Img .MuiButton-startIcon': {
			position: 'absolute',
			right: '0px',
			top: '0px',
			background: '#fff',
			borderRadius: '50%',
			margin: 0,
			zIndex: '1',
		},
		'&.Multiple-Option-Img-Write .MuiButton-startIcon': {
			position: 'absolute',
			right: '0px',
			top: '0px',
			background: '#fff',
			borderRadius: '50%',
			margin: 0,
			zIndex: '1',
		},
		'&.Multiple-Option-Img': {
			padding: theme.spacing(1),
			margin: 0,
		},
		'&.Multiple-Option-Img-Write': {
			padding: theme.spacing(1),
			margin: 0,
		},
		'& .MuiButton-label': {
			lineHeight: 'initial',
		},
		'&.Mui-disabled': {
			'& .MuiButton-startIcon': {
				backgroundColor: '#4caf500d',
			},
			'& .ImageExercise ': {
				borderColor: `${theme.palette.grey[500]} `,
			},
		},
		'&.correct': {
			'&.Mui-disabled': {
				border: `1px solid`,
				borderColor: `${theme.palette.success.main} `,
				color: theme.palette.success.main,
				backgroundColor: '#4caf500d',
				'& .MuiButton-startIcon': {
					backgroundColor: '#4caf500d',
				},
				'& .ImageExercise ': {
					borderColor: `${theme.palette.success.main} `,
				},
			},
		},
		'&.incorrect': {
			'&.Mui-disabled': {
				border: `1px solid`,
				borderColor: `${theme.palette.error.main} `,
				color: theme.palette.error.main,
				backgroundColor: '#f443360d',
				'& .MuiButton-startIcon': {
					backgroundColor: '#f443360d',
				},
				'& .ImageExercise ': {
					borderColor: `${theme.palette.error.main} `,
				},
			},
		},
	},
	rootLine: {
		marginTop: theme.spacing(1),
		borderColor: theme.palette.primary.main,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		'& .MuiInputBase-root': {
			fontSize: 'inherit',
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
			borderBottomStyle: 'dotted',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
			borderColor: theme.palette.primary.main,
			borderBottomStyle: 'inset',
		},
		'& .MuiInputBase-root:not(.Mui-disabled)::before': {
			borderBottomStyle: 'inset',
		},
	},
	correct: {
		backgroundColor: '#4caf500d',
		'& .MuiInputBase-root,.MuiInputBase-root p': {
			color: theme.palette.success.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
		},
	},
	incorrect: {
		backgroundColor: '#f443360d',
		'& .MuiInputBase-root, .MuiInputBase-root p': {
			color: theme.palette.error.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.error.main}`,
		},
	},
	answer: {
		marginTop: theme.spacing(1),
	},
	adornment: {
		fontSize: '2rem',
	},
}));

function MultipleOption(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { answer, reactives, question, img, media, localMedia, time } = reactive;
	const { typeComponent, component } = typeActivity;
	const [userAnswer, setUserAnswer] = useState(isExample ? answer[0] : '');
	const [arrayUserAnswer, setArrayUserAnswer] = useState(isExample ? answer : []);
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [inputDisable, setInputDisabled] = useState<boolean>(false);
	const InputRef = useRef<any>();
	const [buttonSelected, setButtonSelected] = useState(isExample ? answer[0] : userAnswer);
	const classes = useStyles();
	const longText = reactives.filter(item => item.length > 25);

	const lengtGrid =
		(reactives.length % 2 === 0 && reactives.length === 4) ||
		component === 'Multiple-Option-Img' ||
		component === 'Multiple-Option-Img-Write'
			? 3
			: 6;
	const lengtGridXs = longText.length > 0 ? 12 : 6;
	const lengtGridMd = longText.length > 0 ? 12 : lengtGrid;

	const handleSelect = (answerUser: string) => {
		if (!isExample && isCorrect === null) {
			setCheckDisabled(false);
			setButtonSelected(answerUser);
			setUserAnswer(answerUser);
		}
	};
	const handleActiveText = (answerUser: string) => {
		const arrayAnswer = [answerUser];
		if (!isExample && isCorrect === null) {
			setButtonSelected(answerUser);
			setArrayUserAnswer(arrayAnswer);
		}
		setInputDisabled(true);
		setTimeout(() => {
			InputRef.current.focus();
		}, 100);
	};
	const checkAnswer = () => {
		const correct = answer.includes(userAnswer);
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const checkArrayAnswer = () => {
		const lengthAnswer = answer.length;
		let correctAnswer = 0;
		for (const itemAnswer of arrayUserAnswer) {
			const isTrue = answer.includes(itemAnswer);
			if (isTrue) {
				correctAnswer += 1;
			}
		}

		setIsCorrect(lengthAnswer === correctAnswer);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = `${event.target.value}`;
		arrayUserAnswer[1] = value;
		setArrayUserAnswer(arrayUserAnswer);
		if (value !== '') {
			setCheckDisabled(false);
		}
	};
	const nextAnswer = () => {
		onNext();

		let ua = [userAnswer];
		if (component === 'Multiple-Option-Img') {
			ua = [`/imagen/activity/${userAnswer}.png`];
		} else if (component === 'Multiple-Option-Img-Write') {
			ua = [`/imagen/activity/${arrayUserAnswer[0]}.png`, arrayUserAnswer[1]];
		} else if (component !== 'Multiple-Option-Number' && question) {
			if (typeComponent === 'question-answer') {
				ua = [question.replace('|', `<br/> <b><u>${userAnswer}</u></b>`)];
			} else if (question.includes('|') === true) {
				ua = [question.replace('|', `<b><u>${userAnswer}</u></b>`)];
			} else {
				ua = [`${question} <br> <b><u>${userAnswer}</u></b>`];
			}
		} else {
			ua = [`<u>${userAnswer}</u>`];
		}

		const tmpLocalMedia = reactives.reduce((array: string[], item: string) => {
			if (component === 'Multiple-Option-Img' || component === 'Multiple-Option-Img-Write') {
				array.push(`/imagen/activity/${item}.png`);
			}
			return array;
		}, []);

		onCheck(
			isCorrect ?? false,
			ua,
			question,
			media,
			localMedia,
			tmpLocalMedia.length > 0 ? tmpLocalMedia : img,
			activaAnswer
		);
	};
	const updateAnswer = () =>
		`<u style="min-width:120px"> ${userAnswer}${
			userAnswer ? '' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
		}</u>`;

	const option = (item: any, index: number) => (
		<Grid
			key={`options-img-${index}`}
			item
			xs={
				component === 'Multiple-Option-Img' || component === 'Multiple-Option-Img-Write'
					? lengtGridXs
					: lengtGridXs
			}
			md={
				component === 'Multiple-Option-Img' || component === 'Multiple-Option-Img-Write'
					? lengtGridMd
					: lengtGridMd
			}
			style={{ textAlign: 'center' }}
		>
			<Button
				fullWidth={component !== 'Multiple-Option-Img' && component !== 'Multiple-Option-Img-Write'}
				startIcon={
					(isCorrect !== null && buttonSelected === item) || (isExample && answer[0] === item) ? (
						<>
							{isCorrect || (isExample && answer[0] === item) ? <CheckCircle /> : <HighlightOff />}
						</>
					) : null
				}
				size='small'
				disabled={isCorrect !== null || isExample}
				color='primary'
				className={clsx([classes.button, component], {
					correct: (isCorrect && buttonSelected === item) || (isExample && answer[0] === item),
					incorrect: !isCorrect && isCorrect !== null && buttonSelected === item,
				})}
				variant={buttonSelected === item ? 'contained' : 'outlined'}
				onClick={() => {
					if (component === 'Multiple-Option-Img-Write') {
						handleActiveText(item);
					} else {
						handleSelect(item);
					}
				}}
			>
				{component === 'Multiple-Option-Img' ? (
					<ImageExercise
						img={`/imagen/activity/${item}.png`}
						alt={question}
						isZoom={isZoom}
						mImagen={false}
					/>
				) : (
					<>
						{component === 'Multiple-Option-Img-Write' ? (
							<>
								<ImageExercise
									img={`/imagen/activity/${item}.png`}
									alt={question}
									isZoom={isZoom}
									mImagen={false}
								/>
							</>
						) : (
							<DivSanitized text={item} component='span' />
						)}
					</>
				)}
			</Button>
		</Grid>
	);

	return (
		<>
			<div className={classes.reactive}>
				<>
					<AudioExercise
						view='Circulo'
						media={media as string}
						localMedia={localMedia as string}
						totalAttempts={totalAttempts as number}
					/>
					<ImageExercise
						img={img as string}
						alt={question}
						text={component === 'Multiple-Option-Number' ? question : undefined}
						isZoom={isZoom}
					/>
					{isExample ? (
						<Typography component='div' color='primary' variant='h6' className={classes.example}>
							Example:
						</Typography>
					) : null}
					{component !== 'Multiple-Option-Number' && question ? (
						<Typography
							component='div'
							className={clsx(classes.question, { textcenter: question.length <= 20 })}
							gutterBottom
						>
							{typeComponent === 'question-answer' ? (
								<DivSanitized text={question?.replace('|', `<br/> ${updateAnswer()}`)} />
							) : (
								<>
									<DivSanitized text={question?.replace('|', updateAnswer())} />
								</>
							)}
						</Typography>
					) : null}
				</>

				<div className={classes.options}>
					<Grid container spacing={1} justifyContent='center'>
						{reactives.map((item: string, index) => option(item, index))}
					</Grid>
					{component === 'Multiple-Option-Img-Write' ? (
						<TextField
							inputRef={InputRef}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										{isCorrect || isExample ? <CheckCircle /> : null}
										{!isCorrect && isCorrect !== null ? <Cancel /> : null}
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position='end' className={classes.adornment}>
										<b>.</b>
									</InputAdornment>
								),
							}}
							autoFocus
							fullWidth
							variant='standard'
							className={clsx(classes.rootLine, {
								[classes.correct]: isCorrect,
								[classes.incorrect]: !isCorrect && isCorrect !== null,
							})}
							disabled={isExample || isCorrect !== null || !inputDisable}
							defaultValue={isExample ? answer[1] : userAnswer}
							type='text'
							onChange={handleChange}
						/>
					) : null}
				</div>
			</div>

			<CheckButtons
				answer={component === 'Multiple-Option-Img' ? [`/imagen/activity/${answer}.png`] : answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={component === 'Multiple-Option-Img-Write' ? checkArrayAnswer : checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { MultipleOption };
export default MultipleOption;
