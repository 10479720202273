import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
	TextField,
	makeStyles,
	Theme,
	Grid,
	Typography,
	FormControl,
	InputAdornment,
} from '@material-ui/core';
import { CheckCircle, Cancel as CancelIcon } from '@material-ui/icons';

import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx
import { AudioExercise } from '../audio-exercise';
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';
import Options from './Options';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},
	formControl: {
		display: 'block',
		marginTop: theme.spacing(1),
	},
	rootLine: {
		marginTop: theme.spacing(1),
		borderColor: theme.palette.primary.main,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		'& .MuiInputBase-root': {
			fontSize: 'inherit',
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
			borderBottomStyle: 'dotted',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: theme.palette.primary.main,
			borderBottomStyle: 'inset',
		},
		'& .MuiInputBase-root:not(.Mui-disabled)::before': {
			borderBottomStyle: 'inset',
		},
	},
	correct: {
		backgroundColor: '#4caf500d',
		'& .MuiInputBase-root,.MuiInputBase-root p': {
			color: theme.palette.success.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
		},
	},
	incorrect: {
		backgroundColor: '#f443360d',
		'& .MuiInputBase-root, .MuiInputBase-root p': {
			color: theme.palette.error.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.error.main}`,
		},
	},
	adornment: {
		fontSize: '2rem',
	},
	sample: {
		'& .MuiInputBase-root::before': {
			borderBottomStyle: 'dotted',
			borderBottom: `2px solid rgba(0, 0, 0, 0.42)`,
		},
	},
}));

export function WriteMultipleLine(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent, component } = typeActivity;
	const { question, answer, img, reactives, media, localMedia, options, time } = reactive;
	const classes = useStyles();
	const [userAnswer, setUserAnswer] = useState<any[]>([]);
	const [multipleAnswer, setMultipleAnswer] = useState<string[][]>([]);
	const [correctAnswer, setCorrectAnswer] = useState<string[]>([]);

	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [mount, setMount] = useState<boolean>(false);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);

	const keyInput: any = answer.reduce((arr: string[], b, index) => {
		arr.push(`inputs-keys-${index.toString()}`);
		return arr;
	}, []);

	const checkAnswer = () => {
		const multiAnswers = multipleAnswer;
		const inputAnswer = userAnswer;
		for (let i = 0; i < keyInput.length; i += 1) {
			if (!inputAnswer[i]) {
				inputAnswer[i] = { value: ``, isCorrect: false };
			}
		}
		let correctAnswersNumber = 0;
		for (const ia of inputAnswer) {
			let index = null;
			let correct = false;
			ia.isCorrect = false;
			for (const i in multiAnswers) {
				if (multiAnswers[i]) {
					correct = multiAnswers[i].includes(ia.value);
					if (correct) {
						correctAnswersNumber += 1;
						ia.isCorrect = correct;
						index = parseInt(i, 10);
					}
				}
			}
			if (index) {
				delete multiAnswers[index];
			}
		}
		setUserAnswer(inputAnswer);
		const isTrue = correctAnswersNumber === answer.length;
		setIsCorrect(isTrue);
		setNextDisabled(false);
		setCheckDisabled(true);
	};

	const typeComponentFunction = (valor: string) => {
		let valorTmp: string = valor;
		if (typeComponent === 'signo') {
			valorTmp = `${valor}.`;
		} else if (typeComponent === 'question') {
			valorTmp = `${valor}?`;
		}
		return valorTmp;
	};

	const nextAnswer = () => {
		onNext();

		const ua = [];

		if (component === 'Write-Random-Answer') {
			ua.push(`<u class='sample' >${question}</u> `);
			userAnswer.reduce((array, b) => {
				ua.push(`<u class='${b.isCorrect ? 'correct' : 'incorrect'}' >${b.value}</u> `);
				return array;
			}, []);
		} else {
			userAnswer.reduce((array, b) => {
				ua.push(
					`<u class='${b.isCorrect ? 'correct' : 'incorrect'}' >${typeComponentFunction(
						b.value
					)}</u> `
				);
				return array;
			}, []);
		}

		onCheck(isCorrect ?? false, ua, question, media, localMedia, img, activaAnswer);
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const answerInput: any = userAnswer;
		answerInput[index] = { value: `${e.currentTarget.value}`, isCorrect: null };
		setUserAnswer(answerInput);
		setCheckDisabled(false);
	};
	useEffect(() => {
		const createMultipleAnswers = () => {
			const answers = answer.reduce((array: any[], b: string) => {
				const a = b.split('=');
				array.push(a);
				return array;
			}, []);
			setMultipleAnswer(answers);
		};
		if (!mount) {
			createMultipleAnswers();
			setMount(true);
		}

		if (mount && multipleAnswer.length > 0 && correctAnswer.length === 0) {
			const ca = reactives.reduce((array: any, item: any, index: number) => {
				array.push(`${item}`);
				return array;
			}, []);
			setCorrectAnswer(ca);
		}
	}, [mount, setMount, reactives, answer, correctAnswer, multipleAnswer]);

	let endChar = '';
	if (typeComponent === 'signo') {
		endChar = '.';
	} else if (typeComponent === 'question') {
		endChar = '?';
	}
	return (
		<>
			<div className={classes.reactive}>
				{options ? <Options options={options} /> : null}
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				{question && component !== 'Write-Random-Answer' ? (
					<Typography component='div' className={classes.question}>
						<DivSanitized text={question} />
					</Typography>
				) : null}
				{mount ? (
					<>
						<FormControl className={classes.formControl}>
							<Grid container justifyContent='center' spacing={1}>
								{component === 'Write-Random-Answer' ? (
									<Grid item xs={answer.length > 1 ? 12 : 6} md={3}>
										<TextField
											InputProps={{
												endAdornment: (
													<InputAdornment position='end' className={classes.adornment}>
														<b>{endChar}</b>
													</InputAdornment>
												),
											}}
											fullWidth
											variant='standard'
											className={clsx(classes.rootLine, classes.sample)}
											disabled
											defaultValue={question}
											type='text'
										/>
									</Grid>
								) : null}
								{answer.map((reactiveInput, index) => (
									// se qui los de 2 columnas xs{6} md{3}
									<Grid item xs={12} key={`row-keys-${index.toString()}`}>
										<TextField
											key={`inputs-keys-${index.toString()}`}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														{userAnswer[index]?.isCorrect || isExample ? <CheckCircle /> : null}
														{!userAnswer[index]?.isCorrect && isCorrect !== null ? (
															<CancelIcon />
														) : null}
													</InputAdornment>
												),
												endAdornment: (
													<InputAdornment position='end' className={classes.adornment}>
														<b>{endChar}</b>
													</InputAdornment>
												),
											}}
											fullWidth
											variant='standard'
											className={clsx(classes.rootLine, {
												[classes.correct]: userAnswer[index]?.isCorrect,
												[classes.incorrect]:
													(userAnswer[index]?.isCorrect === false && !isExample) ||
													(!userAnswer[index] && !nextDisabled && !isExample),
											})}
											disabled={
												isExample ||
												userAnswer[index]?.isCorrect === true ||
												userAnswer[index]?.isCorrect === false
											}
											defaultValue={isExample ? multipleAnswer[index][0] : userAnswer[index]?.value}
											type='text'
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												handleChange(e, index);
											}}
										/>
									</Grid>
								))}
							</Grid>
						</FormControl>
					</>
				) : null}
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export default WriteMultipleLine;
