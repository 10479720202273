import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Theme, makeStyles, Typography } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import CheckButtons from '../Check-Buttons';
import ImageExercise from '../Image-Exercise';
import AudioExercise from '../audio-exercise';
import DivSanitized from '../../../DivSanitized';

import { ReactiveProps } from '../../Activity-interface';

export interface WordSenteceOption {
	text: string;
	id: number;
	isCorrect: boolean | null;
}
const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},

	emptyItemQuestion: {
		flex: '10',
		width: '100%',
		'& div': {
			margin: '4px',
			minHeight: '24px',
			width: '100%',
			textAlign: 'right',
		},
	},
	iconContainer: {
		width: '25px',
		// height: '25px',
		'&.correct': {
			color: theme.palette.success.main,
		},
		'&.inCorrect': { color: theme.palette.error.main },
	},
	itemStyle: {
		userSelect: 'none',
		padding: '2px 7px',
		margin: `0 2px`,
		borderRadius: theme.shape.borderRadius,
		border: 'solid 2px transpartent',
		color: 'gray',
		'&.isDragging': {
			border: `solid 2px ${theme.palette.primary.main}`,
			background: 'white ',
			color: theme.palette.primary.main,
		},
		'&.correct': { color: theme.palette.success.dark },
		'&.inCorrect': { color: theme.palette.error.light },
		display: 'inline-block',
	},
	listStyle: {
		marginTop: theme.spacing(2),
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		borderRadius: theme.shape.borderRadius,
		borderBottom: '2px solid gray',
		padding: theme.spacing(1),
		flexWrap: 'wrap',
		minHeight: '50px',
		display: 'flex',
		alignItems: 'center',
		'&.isDraggingOver': {
			border: '2px dashed gray',
		},
		'&.correct': { border: `2px solid ${theme.palette.success.dark}` },
		'&.inCorrect': { border: `2px solid ${theme.palette.error.dark}` },
	},
}));

function DragMultipleAnswer(props: ReactiveProps) {
	const classes = useStyles();
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { answer, reactives, question, img, media, localMedia, time } = reactive;
	const { typeComponent } = typeActivity;
	const [userAnswer, setUserAnswer] = useState<string[]>([]);
	const [completeLineWords, setCompleteLineWords] = useState<string[]>([]);

	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [reactiveWords, setReactiveWords] = useState<WordSenteceOption[]>([]);
	const [selectedWords, setSelectedWords] = useState<WordSenteceOption[]>([]);
	const [mount, setMount] = useState(false);

	const checkAnswer = () => {
		const arrayWords = selectedWords;
		let isTrue = true;
		const answerUser: string[] = [];
		for (const word of arrayWords) {
			answerUser.push(word.text);
			if (answer.includes(word.text)) {
				word.isCorrect = true;
			} else {
				word.isCorrect = false;
				isTrue = false;
			}
		}
		setUserAnswer(answerUser);
		setSelectedWords(arrayWords);
		setNextDisabled(false);
		setCheckDisabled(true);
		const correct = isTrue && arrayWords.length === answer.length;
		setIsCorrect(correct);
	};

	const nextAnswer = () => {
		onNext();
		onCheck(isCorrect ?? false, userAnswer, question, media, localMedia, img, activaAnswer);
	};

	const handleSelectWord = (wordSelected: WordSenteceOption, destinationIndex: number) => {
		setCheckDisabled(false);
		const sentence = selectedWords;
		sentence.splice(destinationIndex, 0, wordSelected);
		setSelectedWords(sentence);
		const buttonWords = reactiveWords.reduce(
			(array: WordSenteceOption[], word: WordSenteceOption) => {
				const exist = sentence.includes(word);
				if (!exist) {
					array.push(word);
				}
				return array;
			},
			[]
		);
		setReactiveWords(buttonWords);
	};

	const onDragEnd = (result: any) => {
		const { source, destination } = result;
		const { index } = source;
		// dropped outside the list
		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			if (destination.droppableId === 'selectedWords') {
				reorderWords(selectedWords[index], source.droppableId, index, destination.index);
			}
			if (destination.droppableId === 'reactiveWords') {
				reorderWords(reactiveWords[index], source.droppableId, index, destination.index);
			}
		} else {
			if (source.droppableId === 'reactiveWords' && destination.droppableId === 'selectedWords') {
				handleSelectWord(reactiveWords[index], destination.index);
			}
			if (source.droppableId === 'selectedWords' && destination.droppableId === 'reactiveWords') {
				handleUnSelectWord(selectedWords[index], destination.index);
			}
		}
	};
	const reorderWords = (
		item: WordSenteceOption,
		droppableId: string,
		sourceIndex: number,
		destinationIndex: number
	) => {
		const array = droppableId === 'reactiveWords' ? reactiveWords : selectedWords;

		array.splice(sourceIndex, 1);
		array.splice(destinationIndex, 0, item);

		droppableId === 'reactiveWords' ? setReactiveWords(array) : setSelectedWords(array);
	};
	const handleUnSelectWord = (wordSelected: WordSenteceOption, destinationIndex: number) => {
		const sentence = selectedWords.reduce((array: WordSenteceOption[], word: WordSenteceOption) => {
			if (wordSelected !== word) {
				array.push(word);
			}
			return array;
		}, []);
		setSelectedWords(sentence);
		const buttonWords = reactiveWords;
		buttonWords.splice(destinationIndex, 0, wordSelected);
		setReactiveWords(buttonWords);
	};
	useEffect(() => {
		const createButtonWords = () => {
			if (typeComponent === 'complete-line') {
				const completeWords = question.split('|')[0].split(' ');
				completeWords.pop();
				setCompleteLineWords(completeWords);
			}
			let i = 0;

			const buttonWords = reactives.reduce((array: WordSenteceOption[], word: string) => {
				array.push({ text: word, id: i, isCorrect: null });
				i += 1;
				return array;
			}, []);

			setReactiveWords(buttonWords);
		};
		if (!mount) {
			createButtonWords();
			setMount(true);
		}
	}, [mount, setMount, reactives, question, typeComponent, setCompleteLineWords]);
	return (
		<>
			<div className={classes.reactive}>
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
				<div>
					{isExample ? (
						<Typography component='div' color='primary' variant='h6' className={classes.example}>
							Example:
						</Typography>
					) : null}
					<Typography component='div' className={classes.question} gutterBottom>
						<DivSanitized text={question} />
					</Typography>
				</div>
				<DragDropContext onDragEnd={onDragEnd}>
					<Typography component='div' className={classes.question}>
						<Droppable droppableId='selectedWords' direction='horizontal'>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									className={clsx(classes.listStyle, [
										{
											isDraggingOver: snapshot.isDraggingOver,
											correct: isCorrect,
											inCorrect: !isCorrect && isCorrect !== null,
										},
									])}
								>
									<div
										className={clsx(classes.iconContainer, [
											{ correct: isCorrect, inCorrect: isCorrect !== null && !isCorrect },
										])}
									>
										{isCorrect !== null ? <> {!isCorrect ? <Close /> : <Check />}</> : null}
									</div>

									<>
										{completeLineWords.map((word, index) => (
											<div key={uuid()} className={classes.itemStyle}>
												{word}
											</div>
										))}
										{isExample
											? answer.map((word: string, index: number) => (
													<div key={uuid()} className={classes.itemStyle}>
														{word}
													</div>
											  ))
											: null}
										{selectedWords.map((item, index) => (
											<Draggable
												key={item.id}
												draggableId={`${item.id}`}
												index={index}
												isDragDisabled={isExample || isCorrect !== null}
											>
												{(providedDrag, snapshotDrag) => (
													<div
														ref={providedDrag.innerRef}
														// eslint-disable-next-line react/jsx-props-no-spreading
														{...providedDrag.draggableProps}
														// eslint-disable-next-line react/jsx-props-no-spreading
														{...providedDrag.dragHandleProps}
														className={clsx(classes.itemStyle, [
															{
																isDragging: snapshotDrag.isDragging,
																correct: item.isCorrect,
																inCorrect: !item.isCorrect && item.isCorrect !== null,
															},
														])}
														// eslint-disable-next-line react/jsx-props-no-spreading
														style={{ ...providedDrag.draggableProps.style }}
													>
														{item.text}
													</div>
												)}
											</Draggable>
										))}
									</>

									{provided.placeholder}

									<div className={classes.emptyItemQuestion}>
										{typeComponent === 'question' || typeComponent === 'question-answer' ? (
											<div>?</div>
										) : (
											<div />
										)}

										{typeComponent === 'question-answer' ? <DivSanitized text={question} /> : null}
									</div>
								</div>
							)}
						</Droppable>
					</Typography>
					<Droppable droppableId='reactiveWords' direction='horizontal'>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								className={clsx(classes.listStyle, [{ isDraggingOver: snapshot.isDraggingOver }])}
							>
								{reactiveWords.map((item: WordSenteceOption, index: number) => (
									<Draggable
										key={item.id}
										draggableId={`${item.id}`}
										index={index}
										isDragDisabled={isExample || isCorrect !== null}
									>
										{(providedDrag, snapshotDrag) => (
											<div
												ref={providedDrag.innerRef}
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...providedDrag.draggableProps}
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...providedDrag.dragHandleProps}
												className={clsx(classes.itemStyle, [
													{ isDragging: snapshotDrag.isDragging },
												])}
												// eslint-disable-next-line react/jsx-props-no-spreading
												style={{ ...providedDrag.draggableProps.style }}
											>
												{item.text}
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { DragMultipleAnswer };
export default DragMultipleAnswer;
