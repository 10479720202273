import React from 'react';

interface StateScreen {
	width: number;
	height: number;
}

function useScreenSize() {
	const [windowDimensions, setWindowDimensions] = React.useState<StateScreen>({
		width: 0,
		height: 0,
	});

	React.useEffect(() => {
		function handleResize() {
			const hasWindow = typeof window !== 'undefined';
			if (hasWindow) {
				setWindowDimensions({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			} else {
				setWindowDimensions({
					width: 320,
					height: 568,
				});
			}
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return { windowDimensions };
}

export { useScreenSize };
export default useScreenSize;
