import React from 'react';
import { useHistory } from 'react-router-dom';

import useLocalstorage from './use-Localstorage';
import usePeticiones from './use-Peticiones';
import useMessage from './use-message';
import { AppContext } from '../providers/AppContext';

interface State {
	user: string;
	password: string;
	showPassword: boolean;
}

function useLogin() {
	const { peticion, statePeteiciones } = usePeticiones();
	const { updateLoginLS, updateAvatar } = useLocalstorage();
	const [menssage, setMenssage] = React.useState(useMessage.ini[0]);
	const { onLogIn, setAvatar } = React.useContext<any>(AppContext);
	const history = useHistory();

	function signIn(frm: State) {
		const body = { user: frm.user, password: frm.password };

		setMenssage(useMessage.ini[0]);
		if (frm.user === '') {
			setMenssage(useMessage.login[0]);
		} else if (frm.password === '') {
			setMenssage(useMessage.login[1]);
		} else {
			peticion({ accion: 'authenticate', formData: body }).then(
				datos => {
					if (datos.success) {
						updateLoginLS(datos.data);
						updateAvatar(datos.data.Avatar);
						setAvatar(datos.data.Avatar);
						onLogIn();
						history.push('/home');
					} else {
						setMenssage({ message: datos.message, accion: 'error' });
					}
				},
				error => {}
			);
		}
	}

	return { signIn, statePeteiciones, menssage, setMenssage };
}

export { useLogin };
export default useLogin;
