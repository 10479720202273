import { LinearProgress } from '@material-ui/core';
import { Timer } from '@material-ui/icons';
import React, { createContext, ReactNode } from 'react';
import Moment from 'react-moment';

Moment.startPooledTimer(1000);

interface IGlobalTime {
	time: number;
	timeEnded: boolean;
}

export const GlobalTimeContext: any = createContext<IGlobalTime>({
	time: 0,
	timeEnded: false,
});

interface IGlobalTimeProp {
	time: number;
	children: ReactNode;
	endTime: any;
}
export const GlobalTimeProvider = ({ children, endTime, time }: IGlobalTimeProp) => {
	const [timeEnded, setTimeEnded] = React.useState(false);
	const [timeRemaining, setTimeRemaining] = React.useState<number>(time);

	return (
		<>
			{endTime !== '0' ? (
				<>
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}
					>
						<Moment
							durationFromNow
							style={{ display: 'none' }}
							format='mm:ss'
							onChange={value => {
								const ended = value.split('00');
								setTimeRemaining(timeRemaining - 1);
								if (ended[0] !== '-' && ended[0] === '') {
									setTimeEnded(true);
								}
							}}
						>
							{endTime}
						</Moment>
						<Timer color='primary' />
						<div style={{ flex: 1 }}>
							<LinearProgress
								value={(timeRemaining / time) * 100}
								color='secondary'
								variant='determinate'
							/>
						</div>
					</div>
				</>
			) : null}
			<GlobalTimeContext.Provider
				value={{
					time: endTime,
					timeEnded,
				}}
			>
				{children}
			</GlobalTimeContext.Provider>
		</>
	);
};
