import React, { createContext, ReactNode, useCallback, useEffect } from 'react';
import io from 'socket.io-client';
import moment from 'moment';
import { getPushManager } from '../serviceWorkerRegistration';

import { IApp, ChatUser, Message, Accesos } from '../components/Chat/chat-interface';
import { useLocalstorage, usePeticiones } from '../lib';

const chatUrl = process.env.REACT_APP_LOCAL_API
	? 'http://localhost:3001'
	: 'https://chat.winningwithenglish.com';

export const AppContext = createContext<IApp>({
	users: [],
	messageNotification: 0,
	me: null,
	avatar: '',
	socket: null,
	newMessage: false,
	isConnected: false,
	openCHatList: false,
	token: '',
	messages: [],
	setMessages: () => {},
	setNewMessage: () => {},
	setMessageNotification: () => {},
	sendMessage: () => {},
	setUsers: () => {},
	closeWindow: () => {},
	selectUser: () => {},
	handleChatList: () => {},
	updateNotification: () => {},
	clearNotification: () => {},
	updateAvatarDinamic: () => {},
	activeAvatar: () => {},
	subscribeNotification: () => {},
	setToken: () => {},
	setAvatar: () => {},
	playNotification: () => {},
	socketConnect: () => {},
	socketDisconnect: () => {},
	onLogOut: () => {},
	onLogIn: () => {},
	updateMessage: () => {},
	acceso: { isAutorizate: false, isActivate: false },
});
interface PropsAppProvider {
	children: ReactNode;
}

export const AppProvider = ({ children }: PropsAppProvider) => {
	const [isConnected, setIsConnected] = React.useState<boolean>(false);
	const [socket, setSocket] = React.useState<any>(null);
	const [users, setUsers] = React.useState<ChatUser[]>([]);
	const [isGettingUsers, setIssGettingUsers] = React.useState<boolean>(false);
	const [messageNotification, setMessageNotification] = React.useState<number>(0);
	const [newMessage, setNewMessage] = React.useState<boolean>(false);
	const [messages, setMessages] = React.useState<Message[]>([]);
	const { getAvatar, getTokenScopes } = useLocalstorage();
	const [token, setToken] = React.useState<string>('');
	const { peticion } = usePeticiones();

	const [avatar, setAvatar] = React.useState<string | null>(null);

	const [openCHatList, setOpenCHatList] = React.useState<boolean>(false);
	const handleChatList = () => {
		setOpenCHatList(!openCHatList);
	};
	const saveSubscriptionNotification = async (device: any) => {
		const result = peticion({ accion: 'chat/register-device', formData: device });
		// eslint-disable-next-line no-console
		console.log(result);
	};
	const subscribeNotification = async () => {
		const pushSubscription = await getPushManager();
		saveSubscriptionNotification(pushSubscription);
		return pushSubscription;
	};
	const sendMessage = (msg: Message) => {
		setNewMessage(!newMessage);
		socket.emit('private message', {
			msg,
		});
	};
	const { isAutorizate, isActivate } = useLocalstorage();

	const acceso: Accesos = {
		isAutorizate: isAutorizate(),
		isActivate: isActivate(),
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const me = acceso.isAutorizate
		? getTokenScopes()
		: {
				activated: '',
				id: null,
				user: '',
				nombre: '',
				apellidoPaterno: '',
				apellidoMaterno: '',
				vigencia: '',
				email: '',
				cuenta: '',
				estudiante: '',
				permisos: '',
				home: '',
		  };
	const closeWindow = (user: ChatUser) => {
		const usersCopy = users;

		for (const item of usersCopy) {
			if (item.id === user.id) {
				item.open = false;
			}
		}
		setUsers(usersCopy);
		setNewMessage(!newMessage);
	};
	const updateMessage = async (room: string) => {
		const result = await peticion({
			accion: `chat/update-room/${room}`,
			formData: {},
		});
		// eslint-disable-next-line no-console
		console.log(result);
	};

	const getMessages = useCallback(
		async (room: string) => {
			const result = await peticion({
				accion: `chat/room/${room}`,
				formData: {},
			});
			const copyMsgs = messages;
			result.data.reduce((array: Message[], b: Message) => {
				const time = moment.utc(b.createdAt).toDate();
				b.createdAt = moment(time).local().format();
				array.push(b);
				copyMsgs.push(b);
				return array;
			}, []);

			setMessages(copyMsgs);
			setNewMessage(!newMessage);
		},
		[messages, peticion, newMessage]
	);
	const updateNotification = (user: ChatUser) => {
		const usersCopy = users;

		for (const item of usersCopy) {
			if (item.id === user.id) {
				item.newMessage += 1;
			}
		}
		setUsers(usersCopy);

		setMessageNotification(messageNotification + 1);
	};
	const clearNotification = () => {
		setMessageNotification(0);
	};

	const activeAvatar = () => avatar;

	const updateAvatarDinamic = (ini: boolean) => {
		if (ini) {
			setAvatar('');
		} else {
			setAvatar(getAvatar());
		}
	};

	const selectUser = (user: ChatUser) => {
		const usersCopy = users;
		const totalNotification = messageNotification - user.newMessage;

		setMessageNotification(totalNotification > 0 ? totalNotification : 0);
		for (const item of usersCopy) {
			if (item.id === user.id) {
				item.open = true;
				item.newMessage = 0;
			}
		}
		setUsers(usersCopy);
	};

	const addUsers = useCallback(
		(usersConnected: ChatUser[]) => {
			const usersListCopy = users;
			for (const user of usersConnected) {
				for (const userList of usersListCopy) {
					if (userList.id === user.id) {
						userList.online = true;
					}
				}
			}
			setUsers(usersListCopy);
			setNewMessage(!newMessage);
		},
		[newMessage, users]
	);
	const userExist = useCallback(
		(userConnected: ChatUser) => {
			const usersListCopy = users;

			for (const userList of usersListCopy) {
				if (userList.id === userConnected.id) {
					return true;
				}
			}
			return false;
		},
		[users]
	);
	const userDisconnected = useCallback(
		(id: number) => {
			const usersListCopy = users;
			for (const userList of usersListCopy) {
				if (userList.id === id) {
					userList.online = false;
				}
			}
			setUsers(usersListCopy);
			setNewMessage(!newMessage);
		},
		[newMessage, users]
	);
	const playNotification = useCallback(() => {
		const notification = '/audio/notification.wav';
		const audio = new Audio(notification);
		audio.play();
	}, []);
	const socketConnect = () => {
		socket.connect();
	};
	const socketDisconnect = () => {
		socket.disconnect();
	};
	const onLogOut = () => {
		socket.disconnect();
		setSocket(null);
		setToken('');
		setAvatar(null);
		setIsConnected(false);
		setIssGettingUsers(false);
	};
	const onLogIn = () => {
		setSocket(null);
	};
	useEffect(() => {
		if (getAvatar() !== null) {
			setAvatar(getAvatar());
		}
	}, [getAvatar]);

	useEffect(() => {
		const getUsers = async () => {
			const { data }: { data: ChatUser[] } = await peticion({
				accion: `chat/users`,
				formData: {},
			});
			let newNotifications = 0;
			socket.auth.avatar = avatar;
			socket.connect();
			for (const item of data) {
				newNotifications += item.newMessage;
				getMessages(item.room);
			}
			setMessageNotification(newNotifications);
			setUsers(data);
		};

		if (!isGettingUsers && socket) {
			getUsers();
			setIssGettingUsers(true);
		}
		socket?.on('connect', async () => {
			setIsConnected(true);
			setSocket(socket);
		});

		socket?.on('users connected', (usersConnected: ChatUser[]) => {
			addUsers(usersConnected);
		});

		socket?.on('user connected', (userConnected: ChatUser) => {
			addUsers([userConnected]);
		});
		socket?.on('user disconnected', (id: number) => {
			userDisconnected(id);
		});

		socket?.on('disconnect', () => {
			setIsConnected(false);
			socket.connect();
		});
		socket?.on('private message', (data: any) => {
			// eslint-disable-next-line no-console
			const { user, permission, msg } = data;
			const { room, from } = msg;
			const usersCopy = users;
			const userConnected = {
				user,
				avatar: data.avatar,
				id: from,
				newMessage: 1,
				permission,
				room,
				open: false,
				online: true,
			};
			setMessageNotification(messageNotification + 1);
			playNotification();
			if (!userExist(userConnected)) {
				usersCopy.push(userConnected);
			}
			messages.push(msg);
			setMessages(messages);
			setNewMessage(!newMessage);
		});

		return () => {
			// turning of socket listner on unmount
			socket?.off('connect');
			socket?.off('users connected');
			socket?.off('user connected');
			socket?.off('user disconnected');
			socket?.off('private message');
		};
	}, [
		isConnected,
		newMessage,
		messageNotification,
		me,
		users,
		avatar,
		token,
		socket,
		isGettingUsers,
		messages,
		peticion,
		getAvatar,
		addUsers,
		userDisconnected,
		userExist,
		getMessages,
		playNotification,
	]);

	useEffect(() => {
		const newSocket: any =
			token && avatar ? io(chatUrl, { auth: { token, avatar }, autoConnect: false }) : null;
		setSocket(newSocket);
		return () => newSocket?.close();
	}, [setSocket, avatar, token]);
	return (
		<AppContext.Provider
			value={{
				avatar: avatar as string,
				users,
				me,
				acceso,
				messages,
				newMessage,
				isConnected,
				openCHatList,
				socket,
				token,
				setUsers,
				setMessages,
				handleChatList,
				setNewMessage,
				messageNotification,
				closeWindow,
				setMessageNotification,
				sendMessage,
				selectUser,
				updateAvatarDinamic,
				activeAvatar,
				subscribeNotification,
				updateNotification,
				clearNotification,
				setToken,
				setAvatar,
				playNotification,
				socketConnect,
				socketDisconnect,
				onLogOut,
				onLogIn,
				updateMessage,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppProvider;
