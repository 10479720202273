import React, { useEffect, useState } from 'react';
import { Button, makeStyles, Theme, LinearProgress, Typography } from '@material-ui/core';

import { CheckCircle, PlayCircleFilled, Timer } from '@material-ui/icons';

import Moment from 'react-moment';
import moment from 'moment';
import CheckAnswer from './Check-Answer';
import { TypeActivity } from '../Activity-interface';
import { GlobalTimeContext } from '../../../providers/GlobalTimeContext';

Moment.startPooledTimer(1000);
const Style = makeStyles((theme: Theme) => ({
	root: {
		marginTop: theme.spacing(1),
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},
	boton: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(12px + (18 - 12) * ((100vw - 320px) / (1280 - 320)));',
	},
	timer: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(12px + (18 - 12) * ((100vw - 320px) / (1280 - 320)));',
	},
}));

interface CheckButtonsProps {
	isExample: boolean;
	checkDisabled: boolean;
	nextDisabled: boolean;
	typeActivity: TypeActivity;
	time: number;
	answer: string[];
	isCorrect: boolean | null;
	activaAnswer: boolean;
	nextAnswer: () => void;
	checkAnswer: () => void;
}

function CheckButtons(props: CheckButtonsProps) {
	const classes = Style();
	const {
		isExample,
		checkDisabled,
		nextDisabled,
		typeActivity,
		nextAnswer,
		checkAnswer,
		answer,
		time,
		isCorrect,
		activaAnswer,
	} = props;
	const [start, setStart] = useState<boolean>(false);
	const [timeRemaining, setTimeRemaining] = useState<number>(time);
	const [countdown, setCountdown] = useState<any>(null);
	const { timeEnded } = React.useContext<any>(GlobalTimeContext);
	const [checkedAnswer, setCheckedAnswer] = useState<boolean>(false);
	useEffect(() => {
		if (timeEnded && !checkedAnswer) {
			nextAnswer();
			checkAnswer();
			setCheckedAnswer(true);
		}
		if (!start) {
			setStart(true);
			const endTime = time > 0 ? moment().add(time, 'seconds') : 0;
			setCountdown(endTime);
		}
	}, [time, start, timeEnded, nextAnswer, checkAnswer, checkedAnswer]);

	return (
		<>
			<div className={classes.root}>
				{(checkDisabled && nextDisabled && !isExample && time > 0) ||
				(!checkDisabled && time > 0) ? (
					<>
						<div
							style={{
								flex: '1',
								marginTop: '5px',
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<Timer color='primary' />
							<div style={{ flex: 1 }}>
								<LinearProgress
									value={(timeRemaining / time) * 100}
									color='secondary'
									variant='determinate'
								/>
							</div>
							{}
							<Typography style={{ display: 'none' }}>
								<Moment
									durationFromNow
									format='mm:ss'
									onChange={val => {
										const ended = val.split('00');
										setTimeRemaining(timeRemaining - 1);
										if (ended[0] !== '-' && ended[0] === '') {
											checkAnswer();
										}
									}}
								>
									{countdown}
								</Moment>
							</Typography>
						</div>
					</>
				) : null}
				<CheckAnswer
					answers={answer}
					isCorrect={isCorrect}
					typeActivity={typeActivity}
					activaAnswer={activaAnswer}
				/>
				{(checkDisabled && nextDisabled && !isExample) || !checkDisabled ? (
					<>
						<Button
							className={classes.boton}
							color='primary'
							variant='contained'
							disabled={checkDisabled}
							onClick={checkAnswer}
							endIcon={<CheckCircle />}
						>
							check
						</Button>
					</>
				) : (
					<Button
						className={classes.boton}
						color='secondary'
						variant='contained'
						onClick={nextAnswer}
						endIcon={<PlayCircleFilled />}
					>
						next
					</Button>
				)}
			</div>
		</>
	);
}

export { CheckButtons };
export default CheckButtons;
