import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useIndexedDBStore } from 'use-indexeddb';
import { Container, Typography, Grid, Button } from '@material-ui/core';
import { Lock, LockOpen } from '@material-ui/icons';
import { Navbar, Loading, Scrolltop } from '../components';
import { useStyles, usePeticiones, useLocalstorage } from '../lib';

import { Introduccion, Lessson, Pronunciation, Evaluation } from '../components/Unit-Conten';
import { Unit } from '../components/Unit-Conten/Unit-interface';

export interface PageunitProps {
	modeTheme?: boolean;
	changeTheme?: any;
}

Pageunit.defaultProps = {
	modeTheme: null,
	changeTheme: undefined,
};

function Pageunit(props: PageunitProps): JSX.Element {
	const { unity } = useParams();
	const classes = useStyles();
	const { modeTheme, changeTheme } = props;

	const { peticion } = usePeticiones();
	const { getUnits, getTokenScopes } = useLocalstorage();

	const controlDB = useIndexedDBStore('Control');

	const [menuConenido, setMenuConenido] = React.useState<Unit>();
	const [currentUnit, setcurrentUnit] = React.useState(0);
	const [mount, setMount] = React.useState<Boolean>(true);
	const { permisos } = getTokenScopes();

	const unlockUnit = async (reset: string) => {
		const result = await peticion({
			accion: `complete-unit/${unity}${reset}`,
			formData: {},
		});
		const { success } = result;
		if (success) {
			setMount(true);
			setcurrentUnit(0);
		}
	};

	const handleUpdate = () => {
		setMount(true);
	};

	React.useEffect(() => {
		const body = {};
		const fetchdata = async () => {
			const result = await peticion({
				accion: `unit/${unity}`,
				formData: body,
			});
			setMenuConenido(result.data);

			controlDB.getOneByIndex('unit', unity).then((infoDB: any) => {
				if (infoDB) {
					controlDB
						.update({
							id: unity,
							unit: unity,
							contenido: JSON.stringify(result.data),
						})
						.then(event => {});
				}
			});
		};

		if (currentUnit !== unity || mount) {
			setMount(false);
			// setMenuConenido(undefined);
			setcurrentUnit(unity);
			fetchdata();
		}
		return () => {
			setMount(false);
		};
	}, [unity, peticion, mount, currentUnit, menuConenido, controlDB]);

	const units = getUnits();
	const defaultRoute = Object.values(units).find(valor => valor.unit === unity);
	const search = Object.values(units).find(valor => valor.unit === unity);

	return defaultRoute ? (
		<>
			<Navbar modeTheme={modeTheme} changeTheme={changeTheme} />
			{!menuConenido ? (
				<Loading page='page' />
			) : (
				<>
					<Container maxWidth='lg' className={classes.PageUnit}>
						<Typography gutterBottom variant='h3' color='textSecondary' align='center'>
							{search?.text}
							<>
								{permisos <= 2 ? (
									<div>
										<Button onClick={() => unlockUnit('')} color='primary' variant='contained'>
											<LockOpen /> Desbloquear Unidad
										</Button>
										{'  '}
										<Button
											onClick={() => unlockUnit('/reset')}
											color='default'
											variant='contained'
										>
											<Lock /> Reestablecer Unidad
										</Button>
									</div>
								) : null}
							</>
						</Typography>
						<Introduccion introduction={menuConenido.introduction} />
						<Lessson lessons={menuConenido.lessons} update={handleUpdate} />
						<Grid container justifyContent='center' spacing={4}>
							<Pronunciation pronunciation={menuConenido.pronunciation} />
							<Evaluation evaluation={menuConenido.evaluation} />
						</Grid>
					</Container>
					<Scrolltop />
				</>
			)}
		</>
	) : (
		<Redirect to='/error-404' />
	);
}

export { Pageunit };
export default Pageunit;

//
