import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Grid, Paper, Button, Typography, CircularProgress } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { TituloLogin, ToastBar } from '../components';
import { useStyles, useMessage, useLocalstorage, useRegister, usePeticiones } from '../lib';

interface State {
	email: string;
	user: string;
	segurityToken: string;
	showPassword: boolean;
	success: null | string;
}

function Pageactivate(): JSX.Element {
	const classes = useStyles();
	const { peticionAsync } = usePeticiones();
	const { token } = useParams();
	const history = useHistory();

	const { isAutorizateTmp, datosAutorizateTmp, updateLoginLS } = useLocalstorage();
	const { resendIn, statePeteiciones, menssage, setMenssage } = useRegister();

	const TokeDatos: any = datosAutorizateTmp(token);

	const [values, setValues] = React.useState<State>({
		email: TokeDatos ? TokeDatos.email : '',
		user: TokeDatos ? TokeDatos.user : '',
		segurityToken: token,
		showPassword: false,
		success: null,
	});

	const [mount, setMount] = React.useState(true);

	const handClickResendIn = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setValues(values);
		resendIn(values);
	};

	const handleClose = () => {
		setMenssage(useMessage.ini[0]);
	};

	const TokenValid = isAutorizateTmp(values.segurityToken);

	React.useEffect(() => {
		const body = {
			segurityToken: values.segurityToken,
		};

		const fetchdata = async () => {
			const result: any = await peticionAsync({
				accion: 'activate',
				formData: body,
			});
			if (result.success) {
				updateLoginLS(result.data);
				history.push('/home');
			} else {
				setMenssage({ message: result.message, accion: 'info' });
			}
		};

		if (mount) {
			setMount(false);
			if (TokenValid === true) {
				fetchdata();
			}
		}
	}, [peticionAsync, values, TokenValid, updateLoginLS, history, mount, setMenssage]);

	return values.segurityToken ? (
		<>
			<Container maxWidth='xs'>
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<Paper className={classes.containerLogin}>
							{TokenValid ? (
								<>
									<TituloLogin
										titulo='Processing activation'
										text='Your activation is being processed. Hang on a minute.'
										iconName='MailOutlineOutlined'
										align='center'
									/>

									<form className='form-login'>
										{menssage ? (
											<Grid container spacing={1}>
												{menssage.message ? (
													<>
														<Grid item xs={1}>
															<InfoIcon color='primary' />
														</Grid>
														<Grid item xs={11}>
															<Typography align='center' color='primary'>
																{menssage.message}
															</Typography>
														</Grid>
													</>
												) : null}
											</Grid>
										) : (
											<Grid item xs={12}>
												<CircularProgress
													variant='indeterminate'
													disableShrink
													size={40}
													thickness={4}
												/>
											</Grid>
										)}

										<Grid container spacing={1}>
											<Grid item xs={12}>
												<Button href='/' fullWidth variant='outlined' color='default'>
													Login
												</Button>
											</Grid>
										</Grid>
									</form>
								</>
							) : (
								<>
									<TituloLogin
										titulo='Activacion your acount'
										text='Token Invalido'
										iconName='MailOutlineOutlined'
										align='center'
									/>
									<form className='form-login'>
										<Grid container spacing={1}>
											<Grid item xs={1}>
												<InfoIcon color='error' />
											</Grid>
											<Grid item xs={11}>
												<Typography align='center' color='error'>
													Your time has expired, make a new request activacion.
												</Typography>
											</Grid>
										</Grid>

										<Grid container spacing={1}>
											<Grid item xs={6}>
												<Button href='/' fullWidth variant='outlined' color='default'>
													Login
												</Button>
											</Grid>
											<Grid item xs={6}>
												<Button
													disabled={statePeteiciones.isLoading}
													fullWidth
													variant='outlined'
													color='default'
													onClick={handClickResendIn}
												>
													Resend
												</Button>
											</Grid>
										</Grid>
									</form>
								</>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</>
	) : (
		<>
			<Container maxWidth='xs'>
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<Paper className={classes.containerLogin}>
							<>
								<TituloLogin
									titulo='Activacion your acount'
									text='Token Invalido'
									iconName='MailOutlineOutlined'
									align='center'
								/>
								<form className='form-login'>
									<Grid container spacing={1}>
										<Grid item xs={1}>
											<InfoIcon color='error' />
										</Grid>
										<Grid item xs={11}>
											<Typography align='center' color='error'>
												There is no information to complete your activation.
											</Typography>
										</Grid>
									</Grid>

									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Button href='/' fullWidth variant='outlined' color='default'>
												Login
											</Button>
										</Grid>
									</Grid>
								</form>
							</>
						</Paper>
					</Grid>
				</Grid>
			</Container>
			{menssage.message ? (
				<ToastBar
					color={menssage.accion}
					autoHideDuration={9000}
					anchorOriginV='bottom'
					anchorOriginH='right'
					onClose={handleClose}
					message={menssage.message}
				/>
			) : null}
		</>
	);
}

export { Pageactivate };
export default Pageactivate;
