import React, { Fragment } from 'react';
import clsx from 'clsx';
import { useStyles } from '../lib';

type Name = 'default' | string;
type FontSize = 'xxxlarge' | 'xxlarge' | 'xlarge' | 'large' | 'small';

export interface IconsvgProps {
	name: Name;
	fontSize: FontSize;
}

function Iconsvg(props: IconsvgProps) {
	const classes = useStyles();
	const { name, fontSize } = props;
	return (
		<>
			<div className={`svgIcon ${classes.svgIconRoot}`}>
				<svg
					viewBox='0 0 24 24'
					focusable='false'
					aria-hidden='true'
					className={clsx({
						small: fontSize === 'small',
						large: fontSize === 'large',
						xlarge: fontSize === 'xlarge',
						xxlarge: fontSize === 'xxlarge',
						xxxlarge: fontSize === 'xxxlarge',
					})}
				>
					<image href={`/${process.env.PUBLIC_URL}imagen/icon/${name}.svg`} height='100%' />
				</svg>
			</div>
		</>
	);
}

export { Iconsvg };
export default Iconsvg;
