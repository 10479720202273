import React, { useState } from 'react';
import clsx from 'clsx';
import { InputAdornment, TextField, makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import { CheckCircle, Cancel as CancelIcon } from '@material-ui/icons';
import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx
import { AudioExercise } from '../audio-exercise';
import Options from './Options';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},
	container: {
		marginTop: theme.spacing(1),
	},
	rootLine: {
		marginTop: theme.spacing(1),
		borderColor: theme.palette.primary.main,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		'& .MuiInputBase-root': {
			fontSize: 'inherit',
			fontFamily: "'Lato', sans-serif;",
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
			borderBottomStyle: 'dotted',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: theme.palette.primary.main,
			borderBottomStyle: 'inset',
		},
		'& .MuiInputBase-root:not(.Mui-disabled)::before': {
			borderBottomStyle: 'inset',
		},
	},
	sample: {
		'& .MuiInputBase-root::before': {
			borderBottomStyle: 'dotted',
			borderBottom: `2px solid rgba(0, 0, 0, 0.42)`,
		},
	},
	correct: {
		backgroundColor: '#4caf500d',
		'& .MuiInputBase-root,.MuiInputBase-root p': {
			color: theme.palette.success.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
		},
	},
	incorrect: {
		backgroundColor: '#f443360d',
		'& .MuiInputBase-root, .MuiInputBase-root p': {
			color: theme.palette.error.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.error.main}`,
		},
	},
	answer: {
		marginTop: theme.spacing(1),
	},
	adornment: {
		fontSize: '2rem',
	},
}));

export function WriteMutipleImagen(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent } = typeActivity;
	const { question, answer, img, media, localMedia, options, time } = reactive;
	const classes = useStyles();
	const [userAnswer, setUserAnswer] = useState('');
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	// eslint-disable-next-line

	const listimg = img as string[];

	const checkAnswer = () => {
		const correct = answer.includes(userAnswer);
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const nextAnswer = () => {
		onNext();
		const ua = [];
		ua.push(`<u class='sample line'>${question}</>`);
		ua.push(`<u class='${isCorrect ? 'correct' : 'incorrect'}'>${userAnswer}</>`);

		onCheck(isCorrect ?? false, ua, question, media, localMedia, img, activaAnswer);
	};

	let endChar = '';
	let answerActivity = '';
	if (typeComponent === 'signo') {
		const chars = question.split('|');
		endChar = `${chars[1]}`;
	} else if (typeComponent === 'question') {
		endChar = '?';
		answerActivity = question;
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.target.value) setCheckDisabled(false);
		setUserAnswer(event.target.value);
	};

	return (
		<>
			<div className={classes.reactive}>
				{options ? <Options options={options} /> : null}
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				<Grid container justifyContent='center' spacing={1} className={classes.container}>
					{listimg.map((imagen: string, index) => (
						<Grid item xs={6} md={3} key={`row-keys-${index.toString()}`}>
							<ImageExercise img={imagen} alt={question} isZoom={isZoom} />
							{index === 0 ? (
								<TextField
									fullWidth
									variant='standard'
									className={clsx(classes.rootLine, classes.sample)}
									disabled={index === 0}
									defaultValue={question}
									type='text'
								/>
							) : (
								<TextField
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												{isCorrect || isExample ? <CheckCircle /> : null}
												{!isCorrect && isCorrect !== null ? <CancelIcon /> : null}
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end' className={classes.adornment}>
												<b>{endChar}</b>
											</InputAdornment>
										),
									}}
									name={`inputs-keys-${index.toString()}`}
									fullWidth
									autoComplete='off'
									variant='standard'
									className={clsx(classes.rootLine, {
										[classes.correct]: isCorrect,
										[classes.incorrect]: !isCorrect && isCorrect !== null,
									})}
									disabled={isExample || isCorrect !== null}
									type='text'
									onChange={event => handleChange(event)}
									value={`${isExample ? answer[0] : userAnswer}`}
								/>
							)}
						</Grid>
					))}
				</Grid>
				<DivSanitized className={classes.answer} text={answerActivity} />
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export default WriteMutipleImagen;
