import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles, Theme, Typography } from '@material-ui/core';

import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';
import AudioExercise from '../audio-exercise';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		verticalAlign: 'baseline',
		marginTop: theme.spacing(1),
	},
	word: {
		cursor: 'pointer',
		'&.selected': {
			color: theme.palette.primary.main,
			fontWeight: 'bold',
		},
		'&.correct': {
			color: `${theme.palette.success.main} !important`,
		},
		'&.incorrect': {
			color: `${theme.palette.error.main} !important`,
		},
	},
}));

function SelectWordSentence(props: ReactiveProps) {
	const classes = useStyles();

	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent } = typeActivity;
	const { question, answer, img, reactives, media, localMedia, time } = reactive;

	const [buttons, setButtons] = useState<any[]>([]);
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [mount, setMount] = useState<boolean>(false);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);

	const [updated, setUpdated] = React.useState(false);

	const checkAnswer = async () => {
		let count = 0;
		for (const asw of buttons) {
			if (asw.selected) {
				asw.isCorrect = answer.includes(asw.text);
				count += asw.isCorrect ? 1 : 0;
			} else if (answer.includes(asw.text)) {
				asw.isCorrect = false;
				asw.selected = true;
			}
		}
		setIsCorrect(answer.length === count);
		setNextDisabled(false);
		setCheckDisabled(true);

		setUpdated(!updated);
	};

	const nextAnswer = () => {
		onNext();

		let userAnswer = '';
		for (const b of buttons) {
			userAnswer =
				b.isCorrect === null
					? `${userAnswer}${['.', ',', '"', '."', '!', '?'].includes(b.text) ? '' : ' '}${b.text}`
					: `${userAnswer} <b><u  class='${b.isCorrect ? 'correct' : 'incorrect'}'>${
							b.text
					  }</u></b>`;
		}

		onCheck(isCorrect ?? false, [userAnswer], '', media, localMedia, img, activaAnswer);
	};
	const selectWord = (word: any) => {
		if (isCorrect === null) {
			for (const button of buttons) {
				if (button.id === word.id) {
					button.selected = !word.selected;
				}
			}
		}
		setUpdated(!updated);
		setCheckDisabled(false);
	};
	useEffect(() => {
		const createButtonWords = () => {
			let i = 0;
			const buttonWords = reactives.reduce((array: any[], word: string) => {
				array.push({ text: word, id: i, isCorrect: null, selected: false });
				i += 1;
				return array;
			}, []);
			setButtons(buttonWords);
		};
		if (!mount) {
			createButtonWords();
			setMount(true);
		}
	}, [mount, setMount, reactives, question, typeComponent]);
	return (
		<>
			<div className={clsx(classes.reactive)}>
				{typeComponent ? '' : null}
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>

				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />

				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				<Typography component='div' className={classes.question} />
				<div>
					{updated ? '' : ''}

					{buttons.map((item: any, wordIndex: number) => (
						<>
							{item.text.includes('<') || (item.text.includes('&') && item.text.includes(';')) ? (
								<>
									<DivSanitized component='span' text={item.text} />
								</>
							) : (
								<>
									<span>
										{['.', ',', '"', '."', '!', '?'].includes(item.text) ? (
											<>{`${item.text}`} </>
										) : (
											<span
												role='presentation'
												onClick={() => {
													selectWord(item);
												}}
												className={clsx([classes.word], {
													selected: item.selected,
													correct:
														(item.isCorrect && item.selected) || (isExample && answer[0] === item),
													incorrect: !item.isCorrect && isCorrect !== null && item.selected,
												})}
											>{` ${item.text}`}</span>
										)}
									</span>
								</>
							)}
						</>
					))}
				</div>
			</div>
			<CheckButtons
				time={time}
				answer={answer}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { SelectWordSentence };
export default SelectWordSentence;
