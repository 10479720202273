import React from 'react';

import {
	AppBar,
	Badge,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
	Grid,
	Switch,
	Avatar,
	makeStyles,
	Theme,
	createStyles,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import UploadIcon from '@material-ui/icons/CloudUpload';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { Brightness4, BrightnessHigh, ChatBubble } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useLocalstorage } from '../lib';
import { Sidenav, UserList, WindowsContainer, NextClassButton } from '.';
import { AppContext } from '../providers/AppContext';
import { VirtualClassProvider } from '../providers/VirtualClassContext';
import { Editprofiles } from './EditProfiles';
import { IApp } from './Chat/chat-interface';

export interface NavbarProps {
	modeTheme?: boolean | undefined;
	changeTheme?: () => void | undefined;
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		toolbar: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		offset: {
			...theme.mixins.toolbar, // min-height: 56px;
			marginBottom: '4rem', // margen opcional
		},
		sectionDesktop: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},

		brand: {
			[theme.breakpoints.down(760)]: {
				display: 'none !important',
			},
		},
		rest: {
			[theme.breakpoints.down(900)]: {
				display: 'none !important',
			},
		},
		nextClassTop: {
			display: 'flex',
			[theme.breakpoints.down(670)]: {
				display: 'none !important',
			},
		},
		nextClassBottom: {
			display: 'none',
			[theme.breakpoints.down(670)]: {
				background: theme.palette.divider,
				display: 'flex !important',
				justifyContent: 'center',
			},
		},
		nameUser: {
			[theme.breakpoints.down(670)]: {
				display: 'none !important',
			},
		},
		profile: {},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		iconContainer: { display: 'flex' },
	})
);

const locationOptions = [
	{
		value: 'top',
		label: 'Top',
		icon: <KeyboardArrowUpIcon fontSize='inherit' />,
	},
	{
		value: 'left',
		label: 'Left',
		icon: <KeyboardArrowLeftIcon fontSize='inherit' />,
	},
	{
		value: 'bottom',
		label: 'Bottom',
		icon: <KeyboardArrowDownIcon fontSize='inherit' />,
	},
	{
		value: 'right',
		label: 'Right',
		icon: <KeyboardArrowRightIcon fontSize='inherit' />,
	},
];

function Navbar(props: NavbarProps) {
	const { setSetting, logOut } = useLocalstorage();

	const [menulocation, setmenulocation] = React.useState<boolean>(false);
	const [openProfile, setOpenProfile] = React.useState<boolean>(false);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
	const [locationanchorEl, setlocationanchorEl] = React.useState<null | HTMLElement>(null);
	const history = useHistory();
	const classes = useStyles();
	const { modeTheme, changeTheme } = props;

	const {
		messageNotification,
		isConnected,
		handleChatList,
		openCHatList,
		me,
		avatar,
		subscribeNotification,
	} = React.useContext<IApp>(AppContext);
	const { permisos, user } = me;

	const handleSidenavrOpen = () => {
		setmenulocation(true);
	};

	const handleSidenavrClose = () => {
		setmenulocation(false);
	};

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const andlelocationMenuClose = () => {
		setlocationanchorEl(null);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
		andlelocationMenuClose();
	};

	const handClickMenu = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
		andlelocationMenuClose();
		logOut();
		history.go(0);
	};

	const handclicklocation = (event: React.MouseEvent<HTMLElement>) => {
		const { myValue } = event.currentTarget.dataset;
		if (myValue) {
			setSetting({ Menulocation: myValue });
			setAnchorEl(null);
			handleMobileMenuClose();
			andlelocationMenuClose();
		}
	};
	const handleProfile = () => {
		setOpenProfile(!openProfile);
	};
	const menuId = 'primary-setting-menu';
	const renderMenu = (
		<Menu
			id={menuId}
			anchorEl={anchorEl}
			keepMounted
			open={isMenuOpen}
			onClose={handleMenuClose}
			PaperProps={{
				style: {
					maxHeight: 80 * 4.5,
					width: '28ch',
				},
			}}
		>
			<MenuItem onClick={handleProfile}>
				<IconButton aria-label='show 11 new notifications' color='inherit'>
					<Avatar src={avatar} />
				</IconButton>
				<span>{user}</span>
			</MenuItem>

			<MenuItem>
				<IconButton aria-label='show 11 new notifications' color='inherit'>
					{modeTheme ? <BrightnessHigh /> : <Brightness4 />}
				</IconButton>
				<Typography component='div'>
					<Grid component='label' container alignItems='center' spacing={1}>
						<Grid item>Dark mode</Grid>
						<Grid item>
							<Switch color='default' size='small' checked={modeTheme} onChange={changeTheme} />
						</Grid>
					</Grid>
				</Typography>
			</MenuItem>
			<MenuItem onClick={handClickMenu}>
				<IconButton aria-label='show 11 new notifications' color='inherit'>
					<ExitToAppIcon />
				</IconButton>
				logout
			</MenuItem>
		</Menu>
	);

	const locationMenuId = 'primary-menu-location';

	const renderMenulocation = (
		<Menu
			id={locationMenuId}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			anchorEl={locationanchorEl}
			keepMounted
			open={Boolean(locationanchorEl)}
			onClose={andlelocationMenuClose}
		>
			{locationOptions.map(option => (
				<MenuItem key={option.value} data-my-value={option.value} onClick={handclicklocation}>
					{option.icon}
					{option.label}
				</MenuItem>
			))}
		</Menu>
	);

	const mobileMenuId = 'primary-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton aria-label='show 11 new notifications' color='inherit'>
					<Badge badgeContent={11} color='secondary'>
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<p>Notifications</p>
			</MenuItem>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					aria-label='account of current user'
					aria-controls='primary-account-menu'
					aria-haspopup='true'
					color='inherit'
				>
					<SettingsIcon />
				</IconButton>
				<p>Setting user</p>
			</MenuItem>
		</Menu>
	);

	const modProfile = (
		<>
			{openProfile ? <Editprofiles open={openProfile} handleEditProfile={handleProfile} /> : null}
		</>
	);

	React.useEffect(() => {
		if (messageNotification >= 1) {
			document.title = `Message (${messageNotification}) Winning With English`;
		} else {
			document.title = `Winning With English`;
		}
	}, [messageNotification]);

	// }, [messageNotification, getAvatar]);

	return (
		<VirtualClassProvider>
			<AppBar position='fixed'>
				<Toolbar className={classes.toolbar}>
					<span style={{ display: 'flex', alignItems: 'center' }}>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='open drawer'
							onClick={handleSidenavrOpen}
						>
							<MenuIcon />
						</IconButton>
						<Typography className={classes.brand} variant='h6' noWrap>
							<span> W</span>
							<span className={classes.rest}>inning With English</span>
						</Typography>
					</span>
					{/* <div className={classes.root} /> */}
					<div className={classes.sectionDesktop}>
						<div className={classes.nextClassTop}>
							<NextClassButton />
						</div>

						<div className={classes.iconContainer}>
							{isConnected ? (
								<>
									<IconButton
										aria-label='Message Notification'
										color='inherit'
										onClick={() => {
											subscribeNotification();
											handleChatList();
										}}
									>
										<Badge badgeContent={messageNotification} color='secondary'>
											<ChatBubble />
										</Badge>
									</IconButton>
								</>
							) : null}
							{permisos > 0 && permisos <= 2 ? (
								<>
									<IconButton
										edge='end'
										aria-label='setting current user'
										aria-controls={menuId}
										aria-haspopup='true'
										color='inherit'
										onClick={() => {
											history.push('/dashboard');
										}}
									>
										<SettingsIcon />
									</IconButton>
								</>
							) : null}

							<IconButton onClick={handleProfile}>
								<span
									className={classes.nameUser}
									style={{
										marginRight: '5px',
										color: '#fff',
										fontSize: '16px',
									}}
								>
									{user}
								</span>
								<Avatar src={avatar}>{avatar ? null : <UploadIcon />}</Avatar>
							</IconButton>
						</div>
						<IconButton
							edge='end'
							aria-label='setting current user'
							aria-controls={menuId}
							aria-haspopup='true'
							onClick={handleProfileMenuOpen}
							color='inherit'
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
				<Toolbar className={classes.nextClassBottom}>
					<NextClassButton />
				</Toolbar>
			</AppBar>
			<div className={classes.offset} id='back-to-top-anchor' />
			{renderMobileMenu}
			{renderMenu}
			{renderMenulocation}
			{modProfile}
			<Sidenav open={menulocation} toggleLocation={handleSidenavrClose} />

			<UserList open={openCHatList} onClose={handleChatList} />
			<WindowsContainer />
		</VirtualClassProvider>
	);
}
Navbar.defaultProps = {
	modeTheme: undefined,
	changeTheme: undefined,
};

export { Navbar };
export default Navbar;
