import React from 'react';
import { Grid, Button } from '@material-ui/core';

import { introductionProps } from './Unit-interface';

function Introduccion(props: introductionProps): JSX.Element {
	const { introduction } = props;

	return (
		<>
			<Grid container justifyContent='flex-end' spacing={4} className='Introduction'>
				<Grid item xs={12} sm={3} md={2}>
					<Button
						disabled={!introduction.activated}
						fullWidth
						disableElevation
						variant='contained'
						color='secondary'
						href={introduction.activities[0].url}
					>
						{introduction.name}
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

export { Introduccion };
export default Introduccion;

/*
<Grid item xs={12} className="MiuLesson">
  <Typography variant="h4">{introduction.name}</Typography>
  <BlockActivity
    activated={introduction.activated}
    activities={introduction.activities}
  />
</Grid>
*/
