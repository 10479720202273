// eslint-disable-next-line camelcase
import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography,
	makeStyles,
	Theme,
} from '@material-ui/core';
import clsx from 'clsx';

import { ActivityContentItem } from '../Activity-interface';
import { DivSanitized } from '../..';

interface BlockSegmentProps {
	data: ActivityContentItem;
}

const useStyles = makeStyles((theme: Theme) => ({
	segmento: {
		'& ul': {
			listStyle: 'none',
		},
		'& table': {
			borderSpacing: '0px',
			'&.ws100': {
				width: '100%',
			},
		},
		'& ul.tabla': {
			margin: '0px',
			padding: '0px',
			'& li': {
				borderBottom: `1px solid ${theme.palette.primary.main} `,
				borderLeft: `1px solid ${theme.palette.primary.main} `,
				borderRight: `1px solid ${theme.palette.primary.main} `,
				overflow: 'hidden',
				display: 'flex',
				flexWrap: 'wrap',
				boxSizing: 'border-box',
			},
			'& li.tabla_enc ': {
				color: theme.palette.primary.contrastText,
				borderTop: `1px solid ${theme.palette.primary.main} `,
				backgroundColor: theme.palette.primary.main,
			},
			'& li.tabla_enc_Line ': {
				borderTop: `1px solid ${theme.palette.primary.main} `,
			},
			'& li.tabla_enc .Quea,& li.tabla_enc .Afia, li.tabla_enc .Nega ': {
				margin: '0',
				boxSizing: 'border-box',
				flexGrow: '0',
				maxWidth: '25%',
				flexBasis: '25%',
				padding: theme.spacing(1),
			},
			'&  li.tabla_enc .col33 ': {
				maxWidth: '33.33%',
				flexBasis: '33.33%',
			},

			'&  li.tabla_enc .Nega,& li.tabla_enc_Line .Nega ': {
				maxWidth: '50%',
				flexBasis: '50%',
			},

			'& li span': {
				margin: '0',
				boxSizing: 'border-box',
				flexGrow: '0',
				maxWidth: '25%',
				flexBasis: '25%',
				padding: theme.spacing(1),
				'&.col33': {
					maxWidth: '33%',
					flexBasis: '33%',
				},
				'&.appAuto': {
					maxWidth: 'unset',
					flexBasis: 'auto',
				},
			},
			'& li.cx120 span': {
				maxWidth: '25%',
				flexBasis: '25%',
			},
			'& li.cx70 span': {
				maxWidth: '20%',
				flexBasis: '20%',
			},

			'&  li span.trig': {},
			'&  li span.fin': {
				borderRight: `1px solid ${theme.palette.primary.main} `,
			},
		},
		'& .wx193': {
			'& strong': {
				fontWeight: 600,
			},
		},
		'& .contenido': {
			fontSize: 'calc(12px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));',
			'&.appPis': {
				paddingInlineStart: '0px',
			},
			'&.apptcenter,& .apptcenter ': {
				textAlign: 'center',
			},
			'& strong': {
				color: theme.palette.primary.main,
				'&.green': {
					color: '#096',
				},
				'&.gris': {
					color: theme.palette.grey[700],
				},
			},
			'& .fS94r': {
				fontSize: '.94rem',
			},
			'& .w100': {
				width: '100%',
			},
			'& .wp200': {
				width: '200px',
			},
			'& .wp300': {
				width: '300px',
			},
			'&.appCard_50': {
				paddingLeft: 'unset',
			},
			'& .apptitle': {
				fontSize: 'x-large',
				borderBottom: '1px solid',
				marginBottom: '8px !important',
				display: 'block',
			},
			'& .aparrafo': {
				margin: '10px 0',
			},
			'& .aparrafo30': {
				margin: '30px 0 5px 0;',
			},
			'&.tablal6s6': {
				padding: '0',
				'& .wn_311_e_1': {
					display: 'flex',
					justifyContent: 'space-between',
					'& div': {
						width: '100%',
					},
					'& li ': {
						display: 'flex',
						justifyContent: 'space-between',

						'& .dia': {
							backgroundColor: theme.palette.grey[300],
							width: '100%',
							textAlign: 'center',
							border: '1px solid',
						},
						'& .color': {
							background: '#FAE5B0',
							textAlign: 'center',
							width: '100%',
							border: '1px solid',
						},
						'& .frec': {
							background: '#D1EAF8',
						},
					},
				},
			},
			'&.e_lineas': {
				'& li b': {
					paddingLeft: '40px',
				},
			},
			'&.e_auto.colum': {
				paddingInlineStart: '0',
				[theme.breakpoints.up('md')]: {
					paddingInlineStart: '40px',
				},
				'& li': {
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
					[theme.breakpoints.up('md')]: {
						width: '50%',
					},
					'& span': {
						width: '100%',
					},
				},
				'&.Afx90 ': {
					'& li': {
						width: '100%',
					},
				},
			},
			'&.tabla_appFlex': {
				padding: '0',
				width: '100%',
				overflowY: 'hidden',
				overflow: 'auto',
				fontSize: 'calc(12px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));',
				justifyContent: 'space-between',
				'&.ctext,& .ctext': {
					textAlign: 'center',
				},
				'&.ws400': {
					display: 'flex',
					maxWidth: '400px',
				},
				'&.appws200': {
					display: 'flex',
					maxWidth: '200px',
				},
				'&.appws300': {
					display: 'flex',
					maxWidth: '300px',
				},
				'&.appws400': {
					display: 'flex',
					maxWidth: '400px',
				},
				'&.appws500': {
					display: 'flex',
					maxWidth: '500px',
				},
				'&.appws600': {
					display: 'flex',
					maxWidth: '600px',
				},
				'&.dflex, .dflex': {
					display: 'flex',
				},
				'& .dflexBet': {
					display: 'flex',
					justifyContent: 'space-between',
				},
				'&.alignItemsCenter, .alignItemsCenter': {
					alignItems: 'center',
				},
				'& .wn_311_e_1': {
					display: 'flex',
					justifyContent: 'space-between',
					'&.ws400': {
						width: '400px',
					},
					'&.ws100': {
						width: '100%',
					},
				},
			},

			'&.tabla_app': {
				fontSize: 'calc(12px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));',
				padding: 0,
				display: 'flex',
				flexDirection: 'column',
				'&.wx269,&.wx87 ': {
					maxWidth: '300px',
				},
				'& .dflex': {
					display: 'flex',
					justifyContent: 'space-between',
				},
				'& .titulo': {
					textAlign: 'center',
					paddingBottom: '16px',
				},
				'& .h100': {
					height: '100%',
				},
			},
		},
		'& .tabla_review_mobil': {
			fontSize: 'calc(12px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));',
			width: '100%',
			margin: '16px auto',
			overflow: 'auto',
			overflowY: 'hidden',

			'&::-webkit-scrollbar': {
				width: '10px',
				height: '10px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '5px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
			'&.scrollOrange': {
				'&::-webkit-scrollbar-track': {
					background: '#efa73d',
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: theme.palette.warning.dark,
					border: `3px solid '#efa73d'`,
				},
			},
			'&.padunset': {
				padding: 'unset',
			},
			'& .ctext': {
				textAlign: 'center',
			},
			'& .aliVerTop': {
				verticalAlign: 'top',
			},
			'& .imgW100': {
				width: '100%',
			},
			'& .fleZ40': {
				fontSize: '40px',
			},
			'& .plS_33': {
				paddingLeft: 'calc(37px + (18 - 12) * ((100vw - 320px) / (1280 - 320)))',
			},
			'& .plS_73': {
				paddingLeft: 'calc(77px + (18 - 12) * ((100vw - 320px) / (1280 - 320)))',
			},
			'& .plS_140': {
				paddingLeft: 'calc(140px + (18 - 12) * ((100vw - 320px) / (1280 - 320)))',
			},
			'& .plS_160': {
				paddingLeft: 'calc(160px + (18 - 12) * ((100vw - 320px) / (1280 - 320)))',
			},
			'& .wSca_337': {
				width: 'calc(171px + (115 - 12) * ((100vw - 320px) / (1280 - 320)))',
			},
			'& .wSca_110': {
				width: 'calc(110px + (85 - 12) * ((100vw - 320px) / (1280 - 320)))',
			},
			'& .wSca_181': {
				width: 'calc(181px + (128 - 12) * ((100vw - 320px) / (1280 - 320)))',
			},
			'& .tabla_review_0': {
				'&.appws200': {
					display: 'flex',
					maxWidth: '200px',
				},
				'&.appws300': {
					display: 'flex',
					maxWidth: '300px',
				},
				'&.appws400': {
					display: 'flex',
					maxWidth: '400px',
				},
				'&.appws500': {
					display: 'flex',
					maxWidth: '500px',
				},
				'&.appws600': {
					display: 'flex',
					maxWidth: '600px',
				},
			},
			'& .tabla_review_3,& .tabla_review_2': {
				borderSpacing: '0px',
				width: '100%',
				minWidth: '500px',
				'&.pad8': {
					'& div': {
						padding: '0px 8px',
					},
				},
				'&.pad': {
					'& div,& td ': {
						padding: '0px 0px !important',
					},
				},
				'&.pad16': {
					'& div': {
						padding: '0px 16px',
					},
				},
				'&.mW100': {
					minWidth: 'unset',
				},
				'&.mW200': {
					width: '200px',
					minWidth: '200px',
				},
				'&.mW250': {
					width: '250px',
					minWidth: '250px',
				},
				'&.mW300': {
					width: '300px',
					minWidth: '300px',
				},
				'&.mW350': {
					width: '350px',
					minWidth: '350px',
				},
				'&.mW400': {
					width: '400px',
					minWidth: '400px',
				},
				'&.mW450': {
					width: '450px',
					minWidth: '450px',
				},
				'&.mW500': {
					width: '500px',
					minWidth: '500px',
				},
				'&.mW550': {
					width: '550px',
					minWidth: '550px',
				},
				'&.mW700': {
					width: '100%',
					minWidth: '700px',
				},
				'&.mW750': {
					width: '100%',
					minWidth: '750px',
				},
				'&.dflex,& .dflex': {
					display: 'flex',
				},
				'&.dflexColum,& .dflexColum': {
					display: 'flex',
					flexDirection: 'column',
				},
				'&.dflexBet,& .dflexBet': {
					display: 'flex',
					justifyContent: 'space-between',
				},
				'&.dflexAro,& .dflexAro': {
					display: 'flex',
					justifyContent: 'space-around',
				},
				'&.alignItemsCenter,& .alignItemsCenter': {
					alignItems: 'center',
				},
				'&.aCenter,& .aCenter': {
					textAlign: 'center',
				},
				'& b': {
					color: theme.palette.primary.main,
				},
				'& table': {
					borderSpacing: '0px',
					'&.ws100': {
						width: '100%',
					},
				},
				'& td': {
					padding: theme.spacing(1),

					'&.ws50_': {
						width: '50%',
					},
					'&.ws45_': {
						width: '45%',
					},
					'&.ws33_': {
						width: '33%',
					},
					'&.ws15_': {
						width: '15%',
					},
					'&.ws10_': {
						width: '10%',
					},
					'&.ws5_': {
						width: '5%',
					},
				},
			},
			'& .title_table': {
				backgroundColor: theme.palette.grey[100],
				'& .bini': {
					border: `1px solid ${theme.palette.primary.main}`,
					borderRight: 'unset',
				},
				'& .binit': {
					border: `1px solid ${theme.palette.primary.main}`,
					borderRight: 'unset',
					borderTop: 'unset',
				},
				'& .bcen': {
					borderBottom: `1px solid ${theme.palette.primary.main}`,
				},
				'& .bfin': {
					border: `1px solid ${theme.palette.primary.main}`,
				},
				'& .bfint': {
					border: `1px solid ${theme.palette.primary.main}`,
					borderTop: 'unset',
				},
				'& .tdR1': {
					background: theme.palette.grey[800],
					color: theme.palette.common.white,
				},
				'& .tdR2': {
					background: theme.palette.grey[400],
					color: theme.palette.common.white,
				},
				'& .white': {
					backgroundColor: theme.palette.common.white,
				},
				'& .subwhite': {
					backgroundColor: '#dcdcdc',
				},
			},
			'& .row': {
				backgroundColor: theme.palette.common.white,
				'&.subtitle_table': {
					backgroundColor: theme.palette.grey[100],
				},
				'& .bini': {
					border: `1px solid ${theme.palette.primary.main}`,
					borderRight: 'unset',
					borderTop: 'unset',
				},
				'& .biniL': {
					borderLeft: `1px solid ${theme.palette.primary.main}`,
				},
				'& .bcen': {
					borderBottom: `1px solid ${theme.palette.primary.main}`,
				},
				'& .bfin': {
					border: `1px solid ${theme.palette.primary.main}`,
					borderLeft: 'unset',
					borderTop: 'unset',
				},
				'& .bfinR': {
					borderRight: `1px solid ${theme.palette.primary.main}`,
				},
				'& .bfinc': {
					border: `1px solid ${theme.palette.primary.main}`,
					borderTop: 'unset',
				},
				'& .white': {
					backgroundColor: theme.palette.common.white,
				},
				'& .subwhite': {
					backgroundColor: '#dcdcdc',
				},
				'& .suborange': {
					backgroundColor: theme.palette.warning.light,
				},
			},
		},
		'&.information-seg-imagens': {
			'& strong': {
				color: theme.palette.primary.main,
				'&.green': {
					color: '#096',
				},
				'&.gris': {
					color: theme.palette.grey[700],
				},
			},
		},
		'&.information-seg-Dialogo': {
			'&.appCard_50': {
				width: 'calc(50% - 16px)  !important',
			},
		},
	},

	imageClassDer: {
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row-reverse',
		},
	},
}));

function BlockSegment(props: BlockSegmentProps) {
	const { data } = props;
	const { img, text, dialogo, imageClass } = data;
	const classes = useStyles();

	let textClean = <Typography />;

	if (dialogo === 'A') {
		textClean = (
			<Typography variant='body1' align='center' color='textSecondary' component='div'>
				<DivSanitized className='alphabetContainer' text={text} />
				{/* <div
          className="alphabetContainer"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        /> */}
			</Typography>
		);
	}
	// eslint-disable-next-line
	console.log(dialogo);

	if (dialogo === 'I' && text) {
		textClean = (
			<Typography component='div' variant='body1' align='center' color='textSecondary'>
				<DivSanitized text={text.replace('<p>', '<span>').replace('</p>', '</span>')} />
				{/* <div
        
          dangerouslySetInnerHTML={{
            __html: text.replace('<p>', '<span>').replace('</p>', '</span>'),
          }}
        /> */}
			</Typography>
		);
	}
	if (dialogo === 'C') {
		textClean = (
			<Typography component='div' variant='body1' align='center' color='textSecondary'>
				<DivSanitized text={text} />
				{/* <div
        <DivSanitized text={text.replace('<p>', '<span>').replace('</p>', '</span>')} />
          dangerouslySetInnerHTML={{
            __html: text.replace('<p>', '<span>').replace('</p>', '</span>'),
          }}
        /> */}
			</Typography>
		);
	}
	if (dialogo === 'E' || dialogo === 'D' || dialogo === 'T') {
		textClean = (
			<Typography component='div' variant='body1' color='textSecondary'>
				<DivSanitized
					text={text}
					component='ul'
					className={clsx(data.class, {
						contenido: true,
						tabla: dialogo === 'T',
						wx193: data.class === 'wx193',
					})}
				/>
			</Typography>
			// <ul
			//   className="MuiList-root MuiMenu-list MuiList-padding"
			//   dangerouslySetInnerHTML={{
			//     __html: text,
			//   }}
			// />
		);
	}

	return (
		<>
			{dialogo === 'E' ? (
				<>
					<Card
						className={clsx(classes.segmento, 'information-seg-reglas', {
							[`${data.class}`]: data.class,
							[`Vocabulario`]: data.subtype === 'V',
							[`Clases`]: data.subtype !== 'V',
						})}
					>
						<CardContent>
							<Grid container justifyContent='center' spacing={2}>
								{img ? (
									<>
										<Grid item xs={12} sm={5}>
											<img className='media' src={img} alt={text} />
										</Grid>
										<Grid item xs={12} sm={7} className={classes.segmento}>
											{textClean}
										</Grid>
									</>
								) : (
									<Grid item xs={12} className={classes.segmento}>
										{textClean}
									</Grid>
								)}
							</Grid>
						</CardContent>
					</Card>
				</>
			) : null}
			{dialogo === 'I' || dialogo === 'C' ? (
				<>
					{data.class ? (
						<>{data.class.includes('appClear') ? <Grid item xs={12} /> : null}</>
					) : null}
					{text ? (
						<>
							{data.class ? (
								<>
									{data.class.includes('cardW100') ||
									data.class.includes('cardW50') ||
									data.class.includes('cardW33') ||
									data.class.includes('cardW25') ? (
										<>
											{data.class.includes('cardW100') ? (
												<Grid item xs={12}>
													<Card
														className={clsx(classes.segmento, 'information-seg-imagens', {
															[`${data.class.replaceAll('cardW100', '')}`]: data.class,
															[`Vocabulario`]: data.subtype === 'V',
															[`Clases`]: data.subtype !== 'V',
														})}
													>
														<CardHeader title={textClean} />
														<img className='media' src={img} alt={text} />
													</Card>
												</Grid>
											) : null}
											{data.class.includes('cardW50') ? (
												<Grid item md={6}>
													<Card
														className={clsx(classes.segmento, 'information-seg-imagens', {
															[`${data.class.replaceAll('cardW50', '')}`]: data.class,
															[`Vocabulario`]: data.subtype === 'V',
															[`Clases`]: data.subtype !== 'V',
														})}
													>
														<CardHeader title={textClean} />
														<img className='media' src={img} alt={text} />
													</Card>
												</Grid>
											) : null}
											{data.class.includes('cardW33') ? (
												<Grid item xs={12} md={4}>
													<Card
														className={clsx(classes.segmento, 'information-seg-imagens', {
															[`${data.class.replaceAll('cardW33', '')}`]: data.class,
															[`Vocabulario`]: data.subtype === 'V',
															[`Clases`]: data.subtype !== 'V',
														})}
													>
														<CardHeader title={textClean} />
														<img className='media' src={img} alt={text} />
													</Card>
												</Grid>
											) : null}
											{data.class.includes('cardW25') ? (
												<Grid item xs={12} md={3}>
													<Card
														className={clsx(classes.segmento, 'information-seg-imagens', {
															[`${data.class.replaceAll('cardW25', '')}`]: data.class,
															[`Vocabulario`]: data.subtype === 'V',
															[`Clases`]: data.subtype !== 'V',
														})}
													>
														<CardHeader title={textClean} />
														<img className='media' src={img} alt={text} />
													</Card>
												</Grid>
											) : null}
										</>
									) : (
										<Grid item xs={6} sm={4} md={2}>
											<Card
												className={clsx(classes.segmento, 'information-seg-imagens', {
													[`${data.class}`]: data.class,
													[`Vocabulario`]: data.subtype === 'V',
													[`Clases`]: data.subtype !== 'V',
												})}
											>
												<CardHeader title={textClean} />
												<img className='media' src={img} alt={text} />
											</Card>
										</Grid>
									)}
								</>
							) : (
								<Grid item xs={6} sm={4} md={2}>
									<Card
										className={clsx(classes.segmento, 'information-seg-imagens', {
											[`${data.class}`]: data.class,
											[`Vocabulario`]: data.subtype === 'V',
											[`Clases`]: data.subtype !== 'V',
										})}
									>
										<CardHeader title={textClean} />
										<img className='media' src={img} alt={text} />
									</Card>
								</Grid>
							)}
						</>
					) : (
						<>
							{data.class ? (
								<>
									{data.class.includes('cardW50') || data.class.includes('cardW33') ? (
										<Grid item xs={data.class.includes('cardW50') ? 6 : 4}>
											<Card
												className={clsx(classes.segmento, 'information-seg-imagens', {
													[`${data.class.replaceAll('cardW50', '').replaceAll('cardW33', '')}`]:
														data.class,
													[`Vocabulario`]: data.subtype === 'V',
													[`Clases`]: data.subtype !== 'V',
												})}
											>
												<img className='media' src={img} alt={text} />
											</Card>
										</Grid>
									) : null}
								</>
							) : (
								<Grid item xs={12}>
									<Card
										className={clsx(classes.segmento, 'information-seg-imagens', {
											[`${data.class}`]: data.class,
											[`Vocabulario`]: data.subtype === 'V',
											[`Clases`]: data.subtype !== 'V',
										})}
									>
										<img className='media' src={img} alt={text} />
									</Card>
								</Grid>
							)}
						</>
					)}
				</>
			) : null}
			{dialogo === 'D' || dialogo === 'A' || dialogo === 'T' ? (
				<>
					{data.class ? (
						<>{data.class.includes('appClear') ? <Grid item xs={12} /> : null}</>
					) : null}
					<Card
						className={clsx(classes.segmento, 'information-seg-Dialogo', {
							[`${data.class}`]: data.class,
							[`Vocabulario`]: data.subtype === 'V',
							[`Clases`]: data.subtype !== 'V',
						})}
					>
						<CardContent>
							<Grid
								container
								spacing={2}
								className={clsx({
									[classes.imageClassDer]: imageClass === 'der',
								})}
							>
								{img ? (
									<>
										{text ? (
											<>
												<Grid item xs={12} sm={5}>
													<img className='media' src={img} alt={text} />
												</Grid>

												<Grid item xs={12} sm={7} className={classes.segmento}>
													{textClean}
												</Grid>
											</>
										) : (
											<Grid item xs={12}>
												<img className='media' src={img} alt={text} />
											</Grid>
										)}
									</>
								) : (
									<Grid item xs={12} className={classes.segmento}>
										{textClean}
									</Grid>
								)}
							</Grid>
						</CardContent>
					</Card>
				</>
			) : null}
		</>
	);
}

export { BlockSegment };
export default BlockSegment;
