import React from 'react';
import clsx from 'clsx';
import { Grid, CircularProgress } from '@material-ui/core';
import Iconsvg from './Iconsvg';
import { useStyles } from '../lib';

type Page = 'normal' | 'page';

export interface IconsvgProps {
	page: Page;
}

function Loading(props: IconsvgProps): JSX.Element {
	const classes = useStyles();
	const { page } = props;

	return (
		<>
			<Grid container justifyContent='center' spacing={2} className={classes.loading}>
				<Grid
					item
					className={clsx('MuiContenido-Load', {
						'Mui-Page': page === 'page',
					})}
				>
					<CircularProgress disableShrink size={80} />
					<div className='Mui-logo'>
						<Iconsvg name='logo' fontSize='small' />
					</div>
				</Grid>
			</Grid>
		</>
	);
}

export { Loading };
export default Loading;
