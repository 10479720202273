function Detectmovil() {
	const navegador = navigator.userAgent;

	if (
		navegador.match(/Android/i) ||
		navegador.match(/webOS/i) ||
		navegador.match(/iPhone/i) ||
		navegador.match(/iPad/i) ||
		navegador.match(/iPod/i) ||
		navegador.match(/BlackBerry/i) ||
		navegador.match(/Windows Phone/i)
	) {
		return true;
	}

	return false;
}

export { Detectmovil };
export default Detectmovil;
