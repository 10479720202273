import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';

import clsx from 'clsx';
import { Button, CircularProgress, makeStyles, Theme, Grid } from '@material-ui/core';
import { Sync, Visibility } from '@material-ui/icons';
// eslint-disable-next-line import/no-useless-path-segments
import { Iconsvg } from '../../../components';
import { usePeticiones } from '../../../lib';
import { StepProgress, StepProgressA } from './ProgressExerciseActivity';
import DivSanitized from '../../DivSanitized';
import { ImageExercise } from './Image-Exercise';
import { AudioExercise } from './audio-exercise';
import { ActivityReactives, TypeActivity } from '../Activity-interface';

interface EndDialogueProps {
	average: number;
	activity: number;
	unit: number;
	lesson: number;
	block: number;
	type: string;
	userAnswers: StepProgress[];
	typeActivity: TypeActivity;
	reactive: ActivityReactives[];
	imagenUnica: imgUnica;
	audioUnico: audioUni;
	ShowText?: boolean;
}
interface audioUni {
	media: string;
	localMedia: string;
}
interface imgUnica {
	imgUnica: string;
	zoom: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	containerEndActivity: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: theme.spacing(0.5),
		'&.ReducImgenUnica': {
			// height: 'calc(100% - 280px)',
		},
		'&.ReducAudioUnico': {
			// height: 'calc(100% - 176px)',
		},
	},
	progresContainer: {
		flex: 1,
		alignItems: 'center',
		display: 'flex',
	},
	progress: {
		width: '150px',
		height: '150px',
		position: 'relative',
		marginBottom: theme.spacing(4),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '3rem',
		color: theme.palette.primary.main,
	},
	circularProgress: {
		position: 'absolute',
		left: '0px',
		top: '0px',
		width: '100% !important',
		height: '100% !important',
		zIndex: 2,
		'&.back': {
			color: '#ccc',
			zIndex: 1,
		},
	},

	answersContainer: {
		height: '100%',
		maxHeight: 'calc(100vh - ( (132.7px + 43px) - 1.3px) )',
		overflowX: 'hidden',
		overflow: 'auto',
		display: 'block',
		width: '100%',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
		'&.ReducImgenUnica': {
			// maxHeight: 'calc(100vh - ( (340.7px + 43px) - 1.3px))',
		},
		'&.ReducAudioUnico': {
			// maxHeight: 'calc(100vh - ( (230.7px + 43px) - 1.3px) )',
		},
		'&.ReducShowText': {
			// maxHeight: 'calc(100vh - ( (211.7px + 43px) - 1.3px) )',
		},
	},

	answer: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginTop: theme.spacing(2),
		padding: '8px 0',
		'& .AudioCss': {
			marginBottom: '0',
			paddingTop: '8px',
		},
		'&.correct': {
			borderTop: `${theme.spacing(0.5)}px solid ${theme.palette.success.main}`,
			borderTopStyle: 'dotted',
			borderBottom: `${theme.spacing(0.5)}px solid ${theme.palette.success.main}`,
			borderBottomStyle: 'double',
			backgroundColor: '#4caf500d',
		},
		'&.incorrect': {
			borderTop: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`,
			borderTopStyle: 'dotted',
			borderBottom: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`,
			borderBottomStyle: 'double',
			backgroundColor: '#f443360d',
		},
	},

	aligConten: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},

	centerText: {
		textAlign: 'center',
	},

	imagen: {
		padding: theme.spacing(0.5),
		'& .MuiButton-outlined': {
			padding: '5px',
		},
		'& .ImageExercise': {
			border: `solid 2px ${theme.palette.grey[500]}`,
			width: '100%',
			marginBottom: '0',
		},
		'&.correct': {
			'& .select': {
				borderColor: '#4caf50',
				backgroundColor: '#4caf5030',
			},
		},
		'&.incorrect': {
			'& .select': {
				borderColor: theme.palette.error.main,
				backgroundColor: '#f4433636',
			},
		},
	},

	answerImagenBox: {
		'&.writeMutipleImagen': {
			'&.correct u': {
				color: theme.palette.success.main,
				textDecoration: 'unset',
			},
			'&.incorrect u': {
				color: theme.palette.error.main,
				textDecoration: 'unset',
				'&.correct': {
					color: theme.palette.success.main,
				},
			},
			'&.correct u.sample.line,&.incorrect u.sample.line': {
				color: theme.palette.grey[500],
			},
			'& .ImageExercise': {
				marginBottom: '20px',
			},
			'& .MuiButton-root': {
				textTransform: 'unset',
				'& .MuiButton-label': {
					display: 'flex',
					flexDirection: 'column',
					'& .noclass': {
						width: '100%',
						borderBottom: `2px solid ${theme.palette.grey[400]}`,
					},
				},
			},
		},
	},
	answerInfoBox: {
		padding: theme.spacing(1, 2),
		'&.correct u': {
			color: theme.palette.success.main,
			textDecoration: 'unset',
			'&.normal': {
				color: 'unset',
			},
		},
		'&.incorrect u': {
			color: theme.palette.error.main,
			textDecoration: 'unset',
			'&.correct': {
				color: theme.palette.success.main,
			},
			'&.normal': {
				color: 'unset',
			},
		},

		'&.correct u.sample,&.incorrect u.sample': {
			color: theme.palette.primary.main,
			textDecoration: 'unset',
			borderBottom: `1px solid ${theme.palette.primary.main}`,
			borderBottomStyle: 'dotted',
			padding: '2px 12px',
			margin: '3px 4px',
			display: 'inline-block',
		},
		'&.correct u.sample.line': {
			color: theme.palette.success.main,
			borderBottomStyle: 'solid',
			borderBottomColor: theme.palette.primary.main,
		},
		'&.incorrect u.sample.line': {
			color: theme.palette.success.main,
			borderBottomStyle: 'solid',
			borderBottomColor: theme.palette.error.main,
		},
		'&.writeMultipleLine': {
			display: 'flex',
			'& .noclass': {
				width: '100%',
				margin: '0 10px',
				borderBottom: `2px solid ${theme.palette.grey[400]}`,
			},
		},
		'&.writeMultipleLineLabel': {
			display: 'flex',
			flexDirection: 'column',
			'& .noclass': {
				display: 'flex',
				flexDirection: 'column',
				marginBottom: '8px',
				borderBottom: `2px solid ${theme.palette.grey[400]}`,
				'& b': {
					color: theme.palette.grey[800],
					fontWeight: 'normal',
				},
			},
		},
		'&.dragCompleteWord': {
			lineHeight: '1.85rem',
			'& u': {
				fontWeight: 'normal',
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
				border: `2px solid ${theme.palette.grey[400]}`,
				borderStyle: 'dashed',
				borderRadius: '8px',
				padding: '5px',
				marginRight: '2px',
				'&.disable': {
					color: theme.palette.common.black,
				},
			},
		},
		'&.dragListToList u': {
			fontWeight: 'normal',
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
			borderStyle: 'dashed',
			borderRadius: '8px',
			padding: '5px',
			width: '100%',
			display: 'block',
			marginBottom: '4px',
		},
	},
	answerInfo: {},
}));

const sanitizer = (html: string) => DOMPurify.sanitize(html, { FORBID_TAGS: ['u', 'b'] });

function AnswerUser({
	question,
	userAnswer,
	isCorrect,
	imagen,
	media,
	localMedia,
	typeActivity,
	alingAnswers,
}: StepProgressA) {
	const classes = useStyles();
	const showView = {
		normal: true,
		typeActivity: '',
		question: true,
		seeAnswers: true,
		answersAling: alingAnswers,
	};

	showView.typeActivity = typeActivity?.component;
	if (typeActivity?.component === 'Drag-And-Drop-Multiple-Imagen') {
		showView.normal = false;
	}
	if (
		typeActivity?.component === 'Drag-Complete-Sentence' ||
		typeActivity?.component === 'Drag-Sort-List' ||
		typeActivity?.component === 'Multiple-Option' ||
		typeActivity?.component === 'Multiple-Option-Inline' ||
		typeActivity?.component === 'Multiple-Option-Number' ||
		typeActivity?.component === 'Write' ||
		typeActivity?.component === 'Write-Mutiple-Imagen' ||
		typeActivity?.component === 'Write-Number' ||
		typeActivity?.component === 'Write-Random-Answer' ||
		typeActivity?.component === 'Complete-Sentence' ||
		typeActivity?.component === 'Record-Option'
	) {
		showView.question = false;
	}
	if (
		typeActivity?.component === 'Multiple-Option-Img' ||
		typeActivity?.component === 'Multiple-Option-Img-Write' ||
		typeActivity?.component === 'Write-Mutiple-Imagen' ||
		typeActivity?.component === 'Drag-And-Drop-Multiple-Imagen'
	) {
		showView.seeAnswers = false;
	}
	if (
		typeActivity?.component === 'Select-Write-Answer' ||
		typeActivity?.component === 'Write-Multiple-Line-Label' ||
		typeActivity?.component === 'Drag-List-To-List' ||
		typeActivity?.component === 'Drag-Sort-List'
	) {
		showView.answersAling = false;
	}

	return (
		<Grid
			container
			spacing={1}
			justifyContent='center'
			className={clsx(classes.answer, {
				correct: isCorrect,
				incorrect: !isCorrect,
			})}
		>
			{imagen ||
			media ||
			localMedia ||
			showView.typeActivity === 'Multiple-Option-Number' ||
			showView.typeActivity === 'Write-Number' ? (
				<>
					{media || localMedia ? (
						<Grid item xs={12} className={clsx('audiomedio', classes.imagen)}>
							<AudioExercise
								view='Circulo'
								media={media as string}
								localMedia={localMedia as string}
								totalAttempts={100}
							/>
						</Grid>
					) : null}
					{(imagen && typeof imagen !== 'object') ||
					((showView.typeActivity === 'Multiple-Option-Number' ||
						showView.typeActivity === 'Write-Number') &&
						question) ? (
						<Grid item xs={12} className={clsx(classes.imagen)}>
							<ImageExercise
								isZoom={false}
								img={imagen as string}
								alt={question}
								text={
									(showView.typeActivity === 'Multiple-Option-Number' ||
										showView.typeActivity === 'Write-Number') &&
									question
										? question
										: undefined
								}
							/>
						</Grid>
					) : null}
				</>
			) : null}
			{question && showView.question ? (
				<Grid item xs={12} className={classes.aligConten}>
					<Grid
						item
						xs={12}
						md={6}
						className={clsx(classes.answerInfoBox, {
							[classes.centerText]: question.length < 60,
							[classes.centerText]: showView.answersAling,
						})}
					>
						<DivSanitized text={question} />
					</Grid>
				</Grid>
			) : null}
			{imagen && typeof imagen === 'object' ? (
				<Grid item xs={12} className={classes.aligConten}>
					<Grid item xs={12} className={classes.aligConten}>
						{imagen.map((text: string, index) => (
							<Grid
								item
								xs={6}
								md={3}
								className={clsx(classes.imagen, classes.answerImagenBox, {
									correct: isCorrect,
									incorrect: !isCorrect,
									writeMutipleImagen:
										typeActivity?.component === 'Write-Mutiple-Imagen' ||
										typeActivity?.component === 'Drag-And-Drop-Multiple-Imagen',
								})}
							>
								<Button
									disabled
									variant='outlined'
									className={clsx(classes.imagen, { select: userAnswer[0] === text })}
								>
									<ImageExercise isZoom={false} img={text} alt={question} />
									{typeActivity?.component === 'Write-Mutiple-Imagen' ||
									typeActivity?.component === 'Drag-And-Drop-Multiple-Imagen' ? (
										<DivSanitized key={uuid()} text={userAnswer[index]} />
									) : null}
								</Button>
							</Grid>
						))}
					</Grid>

					{showView.seeAnswers && userAnswer[1] ? <DivSanitized text={userAnswer[1]} /> : null}
				</Grid>
			) : null}
			{userAnswer && showView.seeAnswers ? (
				<Grid item xs={12} className={classes.aligConten}>
					<Grid
						item
						xs={12}
						md={6}
						className={clsx(classes.answerInfoBox, {
							correct: isCorrect,
							incorrect: !isCorrect,
							[classes.centerText]: showView.answersAling,
							writeMultipleLine:
								typeActivity?.component === 'Write-Multiple-Line' ||
								typeActivity?.component === 'Write-Random-Answer',
							writeMultipleLineLabel: typeActivity?.component === 'Write-Multiple-Line-Label',
							dragCompleteWord: typeActivity?.component === 'Drag-Complete-Word',
							dragListToList:
								typeActivity?.component === 'Drag-List-To-List' ||
								typeActivity?.component === 'Drag-Sort-List',
						})}
					>
						{userAnswer.map((text: string) => (
							<React.Fragment key={uuid()}>
								<DivSanitized text={text} />
							</React.Fragment>
						))}
					</Grid>
				</Grid>
			) : null}
			{typeActivity?.component === 'Match' ? (
				<div>
					<Iconsvg name='congratulation' fontSize='xxxlarge' />
				</div>
			) : null}
		</Grid>
	);
}

EndActivity.defaultProps = {
	ShowText: false,
};

function EndActivity(props: EndDialogueProps) {
	const history = useHistory();
	const {
		average,
		userAnswers,
		activity,
		unit,
		lesson,
		block,
		type,
		typeActivity,
		reactive,
		imagenUnica,
		audioUnico,
		ShowText,
	} = props;

	const [seeAnswers, setSeeAnswers] = useState(false);
	const [completed, setCompleted] = useState(0);
	const { peticion } = usePeticiones();
	const classes = useStyles();

	const handleSeeAnswers = () => {
		setSeeAnswers(true);
	};

	const tryAgain = () => {
		history.go(0);
	};

	useEffect(() => {
		const data: any = {};

		data.average = average;
		data.activity = activity;
		data.unit = unit;
		data.block = block;
		data.lesson = lesson;
		data.type = type;

		const saveProgress = () => {
			peticion({ accion: 'progress', formData: data })
				.then(result => {
					// console.log('end');
				})
				.catch(err => {
					// console.log(err);
				});
		};
		if (completed === 0) {
			setCompleted(1);
			saveProgress();
		}
	}, [setCompleted, completed, peticion, activity, unit, lesson, block, type, average]);

	const tmpLong = userAnswers.reduce((arrayA: string[], answer: StepProgress) => {
		const tmpItem = answer.userAnswer;

		const longItem = tmpItem.reduce((array: string[], item: string) => {
			const tmpText = sanitizer(item);

			if (tmpText.length > 43) {
				array.push(tmpText);
			}
			if (tmpText.includes('<br>')) {
				const textBr = tmpText.split('<br>');
				const lItem = textBr.reduce((larray: string[], Iitem: string) => {
					const ltmpText = sanitizer(Iitem);
					if (ltmpText.length > 43) {
						larray.push(ltmpText);
					}
					return larray;
				}, []);

				if (lItem.length > 0) {
					array.push(tmpText);
				}
			}

			return array;
		}, []);

		if (longItem.length > 0) {
			arrayA.push('Encontro');
		}
		return arrayA;
	}, []);

	const alingAnswers = tmpLong.length === 0 ?? false;

	return (
		<Grid
			container
			className={clsx(classes.containerEndActivity, {
				ReducImgenUnica: imagenUnica,
				ReducAudioUnico: audioUnico,
				ReducShowText: ShowText,
			})}
		>
			{reactive ? null : null}
			{seeAnswers ? (
				<Grid
					item
					className={clsx(classes.answersContainer, 'scrollCss', {
						ReducImgenUnica: imagenUnica,
						ReducAudioUnico: audioUnico,
						ReducShowText: ShowText,
					})}
				>
					{audioUnico.media || audioUnico.localMedia ? (
						<AudioExercise
							view='Circulo'
							media={audioUnico.media}
							localMedia={audioUnico.localMedia}
							totalAttempts={100}
						/>
					) : null}
					{imagenUnica.imgUnica ? (
						<ImageExercise img={imagenUnica.imgUnica} alt='ImagenUnica' isZoom={imagenUnica.zoom} />
					) : null}
					{userAnswers.map((answer: StepProgress, index: number) => (
						<AnswerUser
							key={`AnswerUser-rac-${index.toString()}`}
							number={answer.number}
							media={answer.media}
							localMedia={answer.localMedia}
							imagen={answer.imagen}
							question={answer.question ?? ``}
							userAnswer={answer.userAnswer}
							isCorrect={answer.isCorrect}
							activeAnswer={answer.activeAnswer}
							typeActivity={reactive[index].type.component ? reactive[index].type : typeActivity}
							alingAnswers={alingAnswers}
						/>
					))}
				</Grid>
			) : (
				<div className={classes.progresContainer}>
					<div className={classes.progress}>
						<div>{Math.round(average)}%</div>
						<CircularProgress
							className={classes.circularProgress}
							variant='determinate'
							value={average}
						/>
						<CircularProgress
							className={clsx(classes.circularProgress, 'back')}
							variant='determinate'
							value={100}
						/>
					</div>
				</div>
			)}

			<Grid container justifyContent='space-between'>
				<Grid item>
					<Button startIcon={<Sync />} variant='text' color='primary' onClick={tryAgain}>
						Try again
					</Button>
				</Grid>
				<Grid item>
					<Button
						startIcon={<Visibility />}
						variant='contained'
						color='primary'
						onClick={handleSeeAnswers}
					>
						See Answers
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}

export { EndActivity };
export default EndActivity;
