import React from 'react';
// import { createMuiTheme } from '@material-ui/core/';
import createTheme from '@material-ui/core/styles/createTheme';

import teal from '@material-ui/core/colors/teal';
import purple from '@material-ui/core/colors/purple';

import useLocalstorage from './use-Localstorage';

function useThemeconfig() {
	const { getTheme, setSetting } = useLocalstorage();
	const [modoOscuro, setmodoOscuro] = React.useState(getTheme);

	const toggleTheme = () => {
		const change = !modoOscuro;
		setmodoOscuro(change);
		setSetting({ Theme: change });
	};

	const theme = React.useMemo(
		() =>
			createTheme({
				palette: {
					type: modoOscuro ? 'dark' : 'light',
					primary: purple,
					secondary: teal,
				},
				overrides: {
					MuiCssBaseline: {
						'@global': {
							body: {
								backgroundImage: modoOscuro
									? `url(${process.env.PUBLIC_URL}/imagen/logo-d.png})`
									: `url(${process.env.PUBLIC_URL}/imagen/logo-w.png})`,
							},
						},
					},
				},
			}),
		[modoOscuro]
	);

	return { theme, toggleTheme, modoOscuro, setmodoOscuro };
}

export { useThemeconfig };
export default useThemeconfig;
