import React, { useEffect, useState, useCallback } from 'react';
import { Card, CardContent, CardHeader, makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';
import { CalendarEvent, google, ics, office365, outlook, yahoo } from 'calendar-link';
import { usePeticiones } from '../../lib';
import { IVirtualClass, PinnedSubheaderList } from './CurrentUnitClasses';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},

	title: { fontSize: '15px !important' },
}));

export function TodayClasses() {
	const classes = useStyles();
	const { peticion } = usePeticiones();

	const [mount, setMount] = useState<boolean>(false);
	const [virtualClasses, SetVirtualClasses] = useState<IVirtualClass[]>([]);
	const getClasses = useCallback(
		async formatDate => {
			const result = await peticion({
				accion: `virtual-class/${formatDate}`,
				formData: {},
			});
			const dates = await result.data.reduce((arr: any[], b: any) => {
				b.date = moment(b.date);
				if (moment(formatDate).isSame(b.date, 'day')) {
					const event: CalendarEvent = {
						title: b.name,
						description: '<a href="https://course.winningwithenglish.com/home"> open class</a>',
						start: b.date,
						duration: [1, 'hour'],
					};
					b.google = google(event);
					b.outlook = outlook(event);
					b.office365 = office365(event);
					b.yahoo = yahoo(event);
					b.ics = ics(event);
					b.date = moment(b.date).format('yyyy-MM-DD HH:mm:ss');
					b.date = Date.parse(b.date);
					arr.push(b);
				}
				return arr;
			}, []);

			SetVirtualClasses(dates);
		},
		[peticion]
	);
	useEffect(() => {
		if (!mount) {
			setMount(true);
			const date = new Date();
			const formatDate = moment(date as Date).format('yyyy-MM-DD');
			getClasses(formatDate);
		}
	}, [getClasses, mount]);

	return (
		<Card style={{ height: '100%' }}>
			<CardHeader
				className={classes.header}
				title={<b className={classes.title}>Today Classes</b>}
			/>

			<CardContent>
				<PinnedSubheaderList data={virtualClasses} type='today' />
			</CardContent>
		</Card>
	);
}
export default TodayClasses;
