import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { usePeticiones } from '../../lib';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		background: '#efefef',
		color: '#9c27b0',
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},

	title: { fontSize: '15px !important' },
}));

export function ExpressionOfTheDay() {
	const classes = useStyles();
	const [expression, setExpression] = useState<string>('');
	const [meaning, setMeaning] = useState<string>('');
	const [image, setImage] = useState<string>('');
	const [mount, setMount] = useState<boolean>(false);
	const { peticion } = usePeticiones();
	useEffect(() => {
		const getData = async () => {
			const { data } = await peticion({ formData: {}, accion: 'home/expression-of-the-day' });
			setExpression(data.expression);
			setMeaning(data.meaning);
			setImage(data.image);
		};
		if (!mount) {
			getData();
			setMount(true);
		}
	}, [mount, peticion]);
	return (
		<Card
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<CardHeader
				className={classes.header}
				title={<b className={classes.title}>Expression of the Day</b>}
			/>
			<CardContent>
				<img src={image} alt={expression} />
				<Typography variant='body1'>
					<b>{expression}</b>
				</Typography>
				<p>{meaning}</p>
			</CardContent>
			<CardActions style={{ display: 'flex', justifyContent: 'end' }}>
				<Button size='small' color='primary' variant='outlined'>
					see more
				</Button>
			</CardActions>
		</Card>
	);
}
export default ExpressionOfTheDay;
