import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useActivity = makeStyles((theme: Theme) =>
	createStyles({
		PageActVideo: {
			// ...theme.mixins.toolbar, // min-height: 56px;
			// marginBottom: "1rem", // margen opcional

			'& h1': {
				[theme.breakpoints.down('sm')]: {
					display: 'none',
				},
			},

			'& .Video': {
				top: '0px',
				right: '0',
				left: '0',
				overflow: 'hidden',
				zIndex: '1',
				backgroundColor: '#000c',
				'& .MuiSkeleton-rect': {
					width: '100%',
					height: '40vh',
				},
				[theme.breakpoints.down('sm')]: {
					position: 'fixed',
				},
				'& iframe': {
					width: '100%',
					backgroundColor: theme.palette.common.black,
					[theme.breakpoints.down('sm')]: {
						height: 'calc(56.4394vw)',
					},
					[theme.breakpoints.up('md')]: {
						height: 'calc(56.4394vw)',
						maxHeight: '690px',
					},
				},
			},

			'& .Contenido': {
				backgroundColor: theme.palette.divider,
				[theme.breakpoints.down('sm')]: {
					marginTop: 'calc(56.4394vw)',
				},
				'& .Miu-bloque': {
					marginTop: theme.spacing(1),
					paddingBottom: theme.spacing(2),

					'& .MuiTypography-h4.Titulo': {
						padding: theme.spacing(2, 2),
						fontSize: 'calc(34px + (46 - 34) * ((100vw - 320px) / (1920 - 320)));',
					},
					'& .contenedor': {
						padding: theme.spacing(1),
					},
				},
			},
			'& .MuiCard-root.information-seg-imagens': {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: '100%',
				'&.cardW33': {
					width: 'calc((100%  /3) - 16px)',
					padding: theme.spacing(1),
					margin: theme.spacing(1),
					[theme.breakpoints.down('xs')]: {
						width: '100%',
					},
				},
				'&.cardW25': {
					'&.colum': {
						'& .MuiCardContent-root .MuiGrid-root.MuiGrid-container': {
							flexDirection: 'column',
							'& .MuiGrid-root.MuiGrid-item': {
								width: '100%',
								maxWidth: 'unset',
								flexBasis: 'unset',
							},
						},
					},
					width: 'calc((100%  /4) - 16px)',
					[theme.breakpoints.down('xs')]: {
						width: '100%',
					},
				},
				'&.cardW66': {
					'&.colum': {
						'& .MuiCardContent-root .MuiGrid-root.MuiGrid-container': {
							flexDirection: 'column',
							'& .MuiGrid-root.MuiGrid-item': {
								width: '100%',
								maxWidth: 'unset',
								flexBasis: 'unset',
							},
						},
					},
					width: 'calc(( 66% ) - 16px)',
					[theme.breakpoints.down('xs')]: {
						width: '100%',
					},
				},
				'&.cardW50': {
					width: 'calc((100%  /2) - 16px)',
					padding: theme.spacing(1),
					margin: theme.spacing(1),
					[theme.breakpoints.down('xs')]: {
						width: '100%',
					},
				},
				'&.Clases .nhidden,&.Vocabulario .hidden ': {
					display: 'none',
				},
				'&.cardW100': {},
				'& .MuiMenu-list': {
					fontSize: 'calc(14px + (26 - 14) * ((100vw - 320px) / (1920 - 320)));',
					fontWeight: '600',
					'& span,  p ': {
						fontWeight: '600',
					},
				},
			},
			'& .MuiCard-root.information-seg-reglas, .MuiCard-root.information-seg-Dialogo': {
				padding: theme.spacing(1),
				margin: theme.spacing(1),
				width: '100%',
				'&.cardW25': {
					'&.colum': {
						'& .MuiCardContent-root .MuiGrid-root.MuiGrid-container': {
							flexDirection: 'column',
							'& .MuiGrid-root.MuiGrid-item': {
								width: '100%',
								maxWidth: 'unset',
								flexBasis: 'unset',
							},
						},
					},
					width: 'calc((100%  /4) - 16px)',
					[theme.breakpoints.down('xs')]: {
						width: '100%',
					},
				},
				'&.cardW33': {
					'&.colum': {
						'& .MuiCardContent-root .MuiGrid-root.MuiGrid-container': {
							flexDirection: 'column',
							'& .MuiGrid-root.MuiGrid-item': {
								width: '100%',
								maxWidth: 'unset',
								flexBasis: 'unset',
							},
						},
					},
					width: 'calc((100%  /3) - 16px)',
					[theme.breakpoints.down('xs')]: {
						width: '100%',
					},
				},
				'&.cardW50': {
					'&.colum': {
						'& .MuiCardContent-root .MuiGrid-root.MuiGrid-container': {
							flexDirection: 'column',
							'& .MuiGrid-root.MuiGrid-item': {
								width: '100%',
								maxWidth: 'unset',
								flexBasis: 'unset',
							},
						},
					},
					width: 'calc((100%  /2) - 16px)',
					[theme.breakpoints.down('xs')]: {
						width: '100%',
					},
				},
				'&.cardW66': {
					'&.colum': {
						'& .MuiCardContent-root .MuiGrid-root.MuiGrid-container': {
							flexDirection: 'column',
							'& .MuiGrid-root.MuiGrid-item': {
								width: '100%',
								maxWidth: 'unset',
								flexBasis: 'unset',
							},
						},
					},
					width: 'calc(( 66% ) - 16px)',
					[theme.breakpoints.down('xs')]: {
						width: '100%',
					},
				},
				'&.Clases .nhidden,&.Vocabulario .hidden ': {
					display: 'none',
				},
				'& .MuiMenu-list': {
					fontSize: 'calc(14px + (26 - 14) * ((100vw - 320px) / (1920 - 320)));',
					fontWeight: 'normal ',
				},
				'& .alphabetContainer': {
					fontSize: '1.25rem',
					display: 'flex',
					flexWrap: 'wrap',
					'& .alphabet': {
						width: '66px',
						'& .mayuscula': {
							fontWeight: '600',
						},
					},
				},
			},
			'& .MuiCard-root.information-seg-reglas': {
				backgroundColor: theme.palette.warning.light,
			},
			'& .MuiCard-root': {
				'& .media': {
					width: '100%',
					padding: '1rem',
				},
				'& .MuiCardHeader-root .MuiTypography-root.MuiTypography-body1': {
					fontSize: 'calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));',
					'& span,  p ': {
						fontWeight: '600',
					},
					'&  p': {
						color: theme.palette.primary.dark,
						margin: theme.spacing(1),
					},
				},
			},
		},
		ExerciseActivity: {
			'& .MuiPaper-root': {
				minHeight: '96vh',
				display: 'flex',
				flexDirection: 'column',
				padding: theme.spacing(1),
				margin: '2vh 0',
				'& .Avance-Progress': {
					'& .MuiLinearProgress-root': {
						height: theme.spacing(2),
						borderRadius: theme.spacing(1),
						'& .MuiLinearProgress-bar': {
							borderRadius: theme.spacing(1),
						},
					},
				},
				'& .ReactivesActivity': {
					flex: '1',
				},
				'& .Reactives-contenedor': {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					'& .Reactive': {
						display: 'none',
					},
					'& .Reactive-current': {
						display: 'block',
					},
					'& .reactiveContainer': {
						flex: '1',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						'& .check-answer': {
							padding: theme.spacing(1),
							position: 'absolute',
							width: '100%',
							height: '156px',
							zIndex: '9',
						},
						'& .Reactive-options': {
							display: 'flex',
							'& .option': {
								margin: theme.spacing(2),
							},
						},
					},
				},
				'& .ReactivesActivity.Quiz': {
					display: 'flex',
					flexDirection: 'column',
					'& .Instruction': {
						fontSize: 'calc(13px + (32 - 13) * ((100vw - 320px) / (1920 - 320)));',
						marginBottom: theme.spacing(1),
					},
					'& .Question': {
						fontSize: 'calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));',
						marginTop: theme.spacing(2),
						marginBottom: theme.spacing(2),
					},
					'& .Reactives-contenedor': {
						flex: '1',
						'& .Reactive-current': {
							flex: '1',
							display: 'flex',
							flexDirection: 'column',
						},
						'& .reactiveContainer': {
							justifyContent: 'flex-end',
							[theme.breakpoints.up('sm')]: {
								justifyContent: 'center',
							},
							position: 'relative',
							'& .check-answer ': {
								fontSize: 'calc(24px + (42 - 24) * ((100vw - 320px) / (1920 - 320)));',
								'& svg': {
									fontSize: 'calc(32px + (42 - 32) * ((100vw - 320px) / (1920 - 320)));',
								},
								'& .MuiTypography-body1': {
									fontSize: 'unset',
								},
							},
							'& .check-answer.Error ': {
								backgroundColor: theme.palette.error.main,
								color: theme.palette.common.white,
							},
							'& .check-answer.success ': {
								backgroundColor: theme.palette.success.main,
								color: theme.palette.common.white,
							},
							'& .Reactive-options': {
								flexDirection: 'column',
								'& .option': {
									margin: theme.spacing(1),
									textTransform: 'none',
								},
							},
						},
					},
				},
			},
		},
	})
);

export { useActivity };
export default useActivity;
