import React, { useState } from 'react';
import { ActivityReactives, TypeActivity } from '../Activity-interface';
import { StepProgress } from './ProgressExerciseActivity';
import {
	Write,
	WriteMultipleLineLabel,
	WriteMultipleLine,
	WriteCompleteSentence,
	WriteCompleteWord,
	WriteMutipleImagen,
	CompleteSentence,
	CompleteSentenceLetter,
	MultipleOption,
	MultipleOptionMultipleAudio,
	MultipleOptionInline,
	WriteCompleteLine,
	ReactiveBase,
	DragAndDrop,
	DragAndDropMultipleImagen,
	DragCompleteSentence,
	Match,
	DragMultipleAnswer,
	RecordOption,
	DragSortList,
	DragListToList,
	WriteMultipleAnswerOneByOne,
	DragCompleteWord,
	SelectWordSentence,
} from './Reactive-Type';
import ComboOption from './Reactive-Type/Combo-Option';
import SelectWriteAnswer from './Reactive-Type/Select-Write-Answer';

export function TypeExercise({
	reactive,
	totalAttempts,
	activaAnswer,
	typeActivity,
	isExample,
	isImagenUnica,
	isZoom,
	answers,
	onNext,
	onCheck,
}: {
	reactive: ActivityReactives;
	totalAttempts: number;
	activaAnswer: boolean;
	typeActivity: TypeActivity;
	isExample: boolean;
	isImagenUnica: boolean;
	isZoom: boolean;
	answers: StepProgress[];
	onNext: () => void;
	onCheck: (
		correct: boolean,
		userAnswer: string[],
		question: string,
		media: string | string[],
		localMedia: string | string[],
		imagen: string | string[],
		activeAnswer: boolean
	) => void;
}) {
	type Reactive =
		| 'Write'
		| 'Write-Multiple-Line'
		| 'Write-Audio-Multianswer'
		| 'Write-Complete-Line'
		| 'Write-Complete-Word'
		| 'Write-Multiple-Line-Label'
		| 'Match'
		| 'Multiple-Option'
		| 'Multiple-Option-Inline'
		| 'Multiple-Option-Img'
		| 'Write-Multiple-Answer-One-By-One'
		| 'Drag-And-Drop'
		| 'Drag-Multiple-Answer'
		| 'Drag-Complete-Sentence'
		| 'Drag-Complete-Word'
		| 'Drag-List-To-List'
		| 'Drag-Sort-List'
		| 'Drag-And-Drop-Multiple-Imagen'
		| 'Select-Write-Answer'
		| 'Complete-Sentence'
		| 'Select-Word-Sentence';

	const { type } = reactive;
	const { component } = typeActivity;
	const components = {
		Write: (
			<Write
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Audio-Multianswer': (
			<Write
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Select-Write-Answer': (
			<SelectWriteAnswer
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Number': (
			<Write
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Multiple-Line': (
			<WriteMultipleLine
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Random-Answer': (
			<WriteMultipleLine
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Multiple-Answer-One-By-One': (
			<WriteMultipleAnswerOneByOne
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				answers={answers}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Complete-Line': (
			<WriteCompleteLine
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Complete-Sentence': (
			<WriteCompleteSentence
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),

		'Write-Complete-Word': (
			<WriteCompleteWord
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Multiple-Line-Label': (
			<WriteMultipleLineLabel
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Write-Mutiple-Imagen': (
			<WriteMutipleImagen
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Multiple-Option': (
			<MultipleOption
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isZoom={isZoom}
				isImagenUnica={isImagenUnica}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Multiple-Option-Multiple-Audio': (
			<MultipleOptionMultipleAudio
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),

		'Multiple-Option-Inline': (
			<MultipleOptionInline
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Drag-And-Drop': (
			<DragAndDrop
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Drag-And-Drop-Multiple-Imagen': (
			<DragAndDropMultipleImagen
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Drag-Complete-Sentence': (
			<DragCompleteSentence
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Drag-Complete-Word': (
			<DragCompleteWord
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Drag-Multiple-Answer': (
			<DragMultipleAnswer
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Drag-Sort-List': (
			<DragSortList
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Drag-List-To-List': (
			<DragListToList
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Multiple-Option-Img': (
			<MultipleOption
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Multiple-Option-Img-Write': (
			<MultipleOption
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Multiple-Option-Number': (
			<MultipleOption
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Complete-Sentence': (
			<CompleteSentence
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Complete-Sentence-Letter': (
			<CompleteSentenceLetter
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Combo-Option': (
			<ComboOption
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Combo-Option-1': (
			<ReactiveBase
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Record-Option': (
			<RecordOption
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		Match: (
			<Match
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
		'Select-Word-Sentence': (
			<SelectWordSentence
				reactive={reactive}
				totalAttempts={totalAttempts}
				activaAnswer={activaAnswer}
				isExample={isExample}
				isImagenUnica={isImagenUnica}
				isZoom={isZoom}
				typeActivity={type.component ? type : typeActivity}
				onNext={onNext}
				onCheck={onCheck}
			/>
		),
	};

	const [isBlocked, SetIsBlocked] = useState(false);

	navigator?.mediaDevices
		.getUserMedia({ audio: true })
		.then(() => SetIsBlocked(true))
		.catch(() => SetIsBlocked(false));

	return (
		<>
			{component === 'Record-Option' && !isBlocked
				? components['Write' as Reactive]
				: components[component as Reactive]}
		</>
	);
}

export default TypeExercise;
