import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Typography, makeStyles, Theme } from '@material-ui/core';

import { CheckButtons } from '../Check-Buttons';

import { ReactiveProps } from '../../Activity-interface';
import AudioExercise from '../audio-exercise';
import ImageExercise from '../Image-Exercise';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		verticalAlign: 'baseline',
		marginTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},

	droppable: {
		color: theme.palette.primary.main,
		border: `solid 2px ${theme.palette.primary.main}`,
		textAlign: 'center',
		margin: '0 5px',
		height: 'calc(34px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));',
		borderRadius: '30px',
		padding: `0 ${theme.spacing(2)}px`,
		minWidth: `120px`,
		lineHeight: 'normal',
	},
	over: { border: `2px dashed ${theme.palette.grey[900]} !important` },
	correct: {
		border: `2px solid ${theme.palette.success.main} !important`,
		color: theme.palette.success.main,
	},
	incorrect: {
		border: `2px solid ${theme.palette.error.light} !important`,
		color: theme.palette.error.light,
	},
	answer: {
		marginTop: theme.spacing(1),
	},
	adornment: {
		fontSize: '2rem',
	},

	chart: { display: 'inline-block' },
	optionContainer: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.up('md')]: {
			justifyContent: 'center',
		},
	},
	options: {
		lineHeight: `calc(47.8px + (47.8 - 66.8) * ((100vw - 320px) / (1280 - 320)))`,
		cursor: 'pointer',
		padding: '0px',
		display: 'inline-block',
		minWidth: '33px',
		minHeight: '50px',
		fontSize: 'inherit',
		width: '27px',
		height: '36px',
		margin: '4px',
		boxSizing: 'content-box',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		border: `2px solid`,
		borderBlockColor: `${theme.palette.primary.main}`,
		borderStyle: 'dashed',
		borderRadius: '8px',
		[theme.breakpoints.up('sm')]: {
			minWidth: '43px',
			minHeight: '60px',
			lineHeight: `calc(60.8px + (60.8 - 66.8) * ((100vw - 320px) / (1280 - 320)))`,
		},
		'&.options-disable': {
			borderBlockColor: `${theme.palette.grey[500]}`,
			color: theme.palette.grey[500],
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
		},
	},
	dropContainer: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	listStyle: {
		minWidth: '33px',
		minHeight: '50px',
		boxSizing: 'content-box',
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		borderRadius: '8px',
		borderStyle: 'double',
		border: '2px solid gray',
		margin: '4px',
		textAlign: 'center',
		alignItems: 'center',
		lineHeight: `calc(47.8px + (47.8 - 66.8) * ((100vw - 320px) / (1280 - 320)))`,
		[theme.breakpoints.up('sm')]: {
			minWidth: '43px',
			minHeight: '60px',
			lineHeight: `calc(60.8px + (60.8 - 66.8) * ((100vw - 320px) / (1280 - 320)))`,
		},
		'&.soltar': {
			borderStyle: 'dashed',
			borderColor: `${theme.palette.primary.main}`,
			'& .soltar': {
				border: 'unset',
				backgroundColor: 'unset',
				margin: 'unset',
			},
		},
		'&.isDraggingOver': {
			border: '2px dashed gray',
		},
		'&.correct': { borderColor: `${theme.palette.success.dark}`, backgroundColor: '#4caf500d' },
		'&.inCorrect': { borderColor: `${theme.palette.error.dark}`, backgroundColor: '#f443360d' },
		[theme.breakpoints.up('md')]: {
			minHeight: '60px',
			minWidth: '43px',
		},
	},
}));

function DragCompleteWord(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		totalAttempts,
		onNext,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent } = typeActivity;
	const { question, answer, img, reactives, media, localMedia, time } = reactive;

	const classes = useStyles();
	// const [userAnswer, setUserAnswer] = useState<any[]>([]);
	const [reactiveOptions, setReactiveOptions] = useState<string[]>([]);
	// const [correctAnswer, setCorrectAnswer] = useState<string>('');
	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [mount, setMount] = useState<boolean>(false);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);
	const [questionReactive, setQuestionReactive] = useState<any[]>([]);

	const checkAnswer = () => {
		let isTrue = true;
		const qr = questionReactive;

		for (const r of qr) {
			if (r.reactives) {
				for (const chart of r.reactives) {
					if (chart.answer === chart.userAnswer[0] && chart.isDragable) {
						chart.isCorrect = true;
					} else if (chart.isDragable) {
						chart.isCorrect = false;
						isTrue = false;
					}
				}
			}
		}
		setQuestionReactive(qr);
		setIsCorrect(isTrue);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const nextAnswer = () => {
		onNext();
		let userAnswerTmp = '';

		for (const r of questionReactive) {
			if (r.reactives) {
				userAnswerTmp = `${userAnswerTmp}  <b>`;
				for (const chart of r.reactives) {
					if (chart.answer === chart.userAnswer[0] && chart.isDragable) {
						userAnswerTmp = `${userAnswerTmp}<u class='correct'>${chart.userAnswer[0]}</u>`;
					} else if (chart.isDragable) {
						userAnswerTmp = `${userAnswerTmp}<u class='incorrect'>${
							chart.userAnswer[0] !== undefined ? chart.userAnswer[0] : '_'
						}</u>`;
					} else {
						userAnswerTmp = `${userAnswerTmp}<u class='disable' >${chart.answer}</u>`;
					}
				}
				userAnswerTmp = `${userAnswerTmp}</b> `;
			} else {
				userAnswerTmp = `${userAnswerTmp}${r.content} `;
			}
		}

		onCheck(isCorrect ?? false, [userAnswerTmp], '', media, localMedia, img, activaAnswer);
	};

	const onDragEnd = (result: any) => {
		const { source, destination } = result;
		const { index } = source;
		// dropped outside the list

		if (!destination) {
			return;
		}
		const arrayDestination = destination?.droppableId?.split('-');
		const indexWord = arrayDestination[3];
		const indexReactive = arrayDestination[2];
		const qr = questionReactive;
		const ro = reactiveOptions;

		setCheckDisabled(false);

		if (
			source.droppableId !== destination.droppableId &&
			destination.droppableId !== 'options' &&
			source.droppableId !== 'options'
		) {
			const sourceId = source.droppableId.split('-');
			const indexSourceWord = sourceId[3];
			const indexSourceReactive = sourceId[2];
			const answerDestination = qr[indexWord].reactives[indexReactive].userAnswer[0];
			const answerSource = qr[indexSourceWord].reactives[indexSourceReactive].userAnswer[0];

			qr[indexWord].reactives[indexReactive].userAnswer.pop();
			qr[indexWord].reactives[indexReactive].userAnswer.push(answerSource);
			if (answerDestination) {
				qr[indexSourceWord].reactives[indexSourceReactive].userAnswer.pop();
				qr[indexSourceWord].reactives[indexSourceReactive].userAnswer.push(answerDestination);
			} else {
				qr[indexSourceWord].reactives[indexSourceReactive].userAnswer.pop();
			}

			setQuestionReactive(qr);
		} else if (source.droppableId === 'options' && destination.droppableId !== 'options') {
			if (qr[indexWord].reactives[indexReactive].userAnswer.length > 0) {
				ro.push(qr[indexWord].reactives[indexReactive].userAnswer[0]);
				qr[indexWord].reactives[indexReactive].userAnswer.pop();
			}
			qr[indexWord].reactives[indexReactive].userAnswer.push(reactiveOptions[index]);

			ro.splice(index, 1);
			setQuestionReactive(qr);
			setReactiveOptions(ro);
		} else if (destination.droppableId === 'options' && source.droppableId !== 'options') {
			const sourceId = source.droppableId.split('-');
			const indexSourceWord = sourceId[3];
			const indexSourceReactive = sourceId[2];
			const answerSource = qr[indexSourceWord].reactives[indexSourceReactive].userAnswer[0];
			qr[indexSourceWord].reactives[indexSourceReactive].userAnswer.pop();
			ro.push(answerSource);
			setReactiveOptions(ro);
			setQuestionReactive(qr);
		}
	};
	useEffect(() => {
		const createQuestionReactive = () => {
			setReactiveOptions(reactives);
			const separador = typeComponent !== 'complete-line' ? '¬' : ' ';
			const q: any = question.split(separador).reduce((acc: any[], current: string, index) => {
				const isReactive = current.includes('|');
				const correctAnswer = answer[0].split('');
				const r = isReactive ? current.split('') : null;
				const a = isReactive ? answer[0] : null;

				acc.push({
					content: current,
					reactives: r
						? r.reduce((arrayR: any[], currentR, indexNumber) => {
								arrayR.push({
									userAnswer: isExample ? [correctAnswer[indexNumber]] : [],
									answer: correctAnswer[indexNumber],
									chart: currentR,
									isDragable: currentR === '|',
									id: indexNumber,
									isCorrect: null,
								});
								return arrayR;
						  }, [])
						: null,
					answer: a,
				});

				return acc;
			}, []);
			setQuestionReactive(q);
		};
		if (!mount) {
			createQuestionReactive();
			setMount(true);
		}
	}, [question, mount, answer, reactives, typeComponent, isExample]);

	return (
		<DragDropContext
			// eslint-disable-next-line react/jsx-props-no-spreading
			onDragEnd={onDragEnd}
		>
			<div className={classes.reactive}>
				{typeComponent ? '' : null}
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}

				<div className={classes.question}>
					{questionReactive?.map((word: any, indexWord) => (
						<React.Fragment key={uuid()}>
							{word.reactives ? (
								<div className={classes.dropContainer}>
									{word.reactives.map((chart: any, index: number) => (
										<React.Fragment key={uuid()}>
											{chart.isDragable ? (
												<Droppable
													key={`answer-option-word-${index + 1}`}
													droppableId={`answer-option-${index}-${indexWord}`}
													direction='horizontal'
												>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															className={clsx(classes.listStyle, 'soltar', [
																{
																	isDraggingOver: snapshot.isDraggingOver,
																	correct: chart.isCorrect || isExample,
																	inCorrect: chart.isCorrect !== null && !chart.isCorrect,
																},
															])}
														>
															{chart.userAnswer.map((chartAnswer: string) => (
																<Draggable
																	key={`option-${index * 1}`}
																	draggableId={`option-${index * 1}`}
																	index={0}
																	isDragDisabled={isCorrect !== null || isExample}
																>
																	{(dragProvided, DragSnapshot) => (
																		<div
																			className={clsx(classes.options, 'soltar')}
																			ref={dragProvided.innerRef}
																			// eslint-disable-next-line react/jsx-props-no-spreading
																			{...dragProvided.draggableProps}
																			// eslint-disable-next-line react/jsx-props-no-spreading
																			{...dragProvided.dragHandleProps}
																		>
																			{chartAnswer}
																		</div>
																	)}
																</Draggable>
															))}
														</div>
													)}
												</Droppable>
											) : (
												<div key={`answer-option-word-${index + 1}`} className={classes.listStyle}>
													{chart.chart.charCodeAt() === 172 ? ' ' : chart.chart}
												</div>
											)}
										</React.Fragment>
									))}
								</div>
							) : (
								<div>{word?.content}&nbsp;</div>
							)}
						</React.Fragment>
					))}
				</div>
				<Droppable droppableId='options' direction='horizontal'>
					{(provided, snapshot) => (
						<div ref={provided.innerRef} className={classes.optionContainer}>
							{reactiveOptions.map((item, index) => (
								<Draggable
									key={`option-${item}-${index * 1}`}
									draggableId={`option-${item}-${index * 1}`}
									index={index}
									isDragDisabled={isCorrect !== null || isExample}
								>
									{(dragProvided, DragSnapshot) => (
										<div
											className={clsx(classes.options, {
												'options-disable': isExample || !nextDisabled,
											})}
											ref={dragProvided.innerRef}
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...dragProvided.draggableProps}
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...dragProvided.dragHandleProps}
										>
											{item}
										</div>
									)}
								</Draggable>
							))}
							{/* {provided.placeholder} */}
						</div>
					)}
				</Droppable>
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</DragDropContext>
	);
}

export { DragCompleteWord };
export default DragCompleteWord;
