import React from 'react';
import { v4 as key } from 'uuid';
import {
	Grid,
	Typography,
	Button,
	Popover,
	Card,
	CardContent,
	Theme,
	makeStyles,
} from '@material-ui/core';

import { LockOpen, Lock, Check } from '@material-ui/icons';
import { BlockActivites } from '.';
import { LessonsProps, Lesson, quiz } from './Unit-interface';
import { useMessage } from '../../lib';

const useStyles = makeStyles((theme: Theme) => ({
	PopoverContenido: {
		textAlign: 'center',
		padding: theme.spacing(4),
		backgroundColor: theme.palette.warning.dark,
		color: theme.palette.warning.contrastText,
	},
	lesson: {
		backgroundColor: theme.palette.divider,
		marginBottom: theme.spacing(6),
		borderRadius: '15px 15px 0 0',
		[theme.breakpoints.up('sm')]: {
			borderRadius: '15px 15px 0 15px',
		},
	},
	tittleLesson: {
		maxHeight: '34px',
	},
	quiz: {
		marginTop: '-48px',
		marginBottom: '48px',
	},
	quizContent: {
		padding: '16px',
		paddingTop: '0px',
		borderRadius: '0 0 15px  15px',
		backgroundColor: ' rgba(0, 0, 0, 0.12)',
		position: 'relative',
	},
	roundCorner: {
		width: '15px',
		height: '15px',
		background: '#fff',
		left: '-15px',
		top: '0px',
		position: 'absolute',
		borderRadius: '0 50% 0 0',
	},
	corner: {
		width: '15px',
		height: '15px',
		background: ' rgba(0, 0, 0, 0.12)',
		left: '-15px',
		top: '0px',
		position: 'absolute',
	},
	quizImage: {
		textAlign: 'center',
	},
	quizImageContent: {
		display: 'inline-block',
		borderRadius: '50%',
		border: `8px solid ${theme.palette.primary.main}`,
		width: '75px',
		height: '75px',
	},
	contentButton: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'space-between',
	},
}));

interface QuizProps {
	content: quiz;
}

function QuizComponent(props: QuizProps) {
	const { content } = props;
	const { activated, completed, url } = content;
	const classes = useStyles();
	return (
		<>
			<Card style={{ filter: `grayscale(${activated ? 0 : 1})` }}>
				<CardContent>
					<Grid container justifyContent='center' spacing={4} alignItems='stretch'>
						<Grid item md={4} className={classes.quizImage}>
							<div className={classes.quizImageContent}>
								<img src={`/${process.env.PUBLIC_URL}imagen/icon/icon_Write.svg`} alt='quiz' />
							</div>
							<Typography color='primary' variant='h4'>
								Quiz
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<div className={classes.contentButton}>
								<p>
									Take the quiz and <LockOpen /> unlock the next lesson
								</p>
								<Button
									color='primary'
									variant='contained'
									disabled={!activated}
									href={url}
									endIcon={completed ? <Check /> : <Lock />}
								>
									Take the quiz
								</Button>
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
}

function Lessson(props: LessonsProps): JSX.Element {
	const { lessons, update } = props;
	const classes = useStyles();

	const mesaggeBlock = useMessage.bloques[0];

	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'popover' : undefined;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const ActiveBloqueMessage = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		activated: boolean
	) => {
		if (!activated) {
			setAnchorEl(event.currentTarget);
		}
	};

	return (
		<>
			{lessons.map((lesson: Lesson, index) => (
				<React.Fragment key={key()}>
					<Grid container justifyContent='center' spacing={4} className={classes.lesson}>
						<Grid item xs={12} sm={10}>
							<Typography className={classes.tittleLesson} variant='h5'>
								{lesson.name}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<BlockActivites blocks={lesson.blockActivities} update={update} />
						</Grid>
					</Grid>
					<Grid
						container
						justifyContent='flex-end'
						spacing={4}
						className={classes.quiz}
						onClick={event => ActiveBloqueMessage(event, lesson.quiz.activated)}
					>
						<div className={classes.quizContent}>
							<div className={classes.corner} />
							<div className={classes.roundCorner} />
							<QuizComponent content={lesson.quiz} />
						</div>
					</Grid>
				</React.Fragment>
			))}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div className={classes.PopoverContenido}>
					<Lock fontSize='large' />
					<Typography>{mesaggeBlock.message}</Typography>
				</div>
			</Popover>
		</>
	);
}

export { Lessson };
export default Lessson;
