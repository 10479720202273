import React from 'react';
import { v4 as key } from 'uuid';
import { Grid, Popover, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import { BlockActivitiesProps, BlockActivities } from './Unit-interface';
import { BlockActivity } from '.';
import { useStyles, useMessage } from '../../lib';

function BlockActivites(props: BlockActivitiesProps): JSX.Element {
	const { blocks, update } = props;
	const classes = useStyles();
	const mesaggeBlock = useMessage.bloques[0];

	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'popover' : undefined;

	const handleClose = () => {
		setAnchorEl(null);
	};

	const ActiveBloqueMessage = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		activated: boolean
	) => {
		if (!activated) {
			setAnchorEl(event.currentTarget);
		}
	};

	return (
		<>
			<Grid container justifyContent='center' spacing={2}>
				{blocks.map((block: BlockActivities, index) => (
					<React.Fragment key={key()}>
						<Grid
							item
							xs={12}
							sm={6}
							className='MiuBloques'
							onClick={event => ActiveBloqueMessage(event, block.activated)}
						>
							<BlockActivity
								activated={block.activated}
								activities={block.activities}
								guides={block.guides}
								update={update}
							/>
						</Grid>
					</React.Fragment>
				))}
			</Grid>
			<Popover
				className={classes.PopoverMessage}
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<div className='Popover-contenido'>
					<LockIcon fontSize='large' />
					<Typography>{mesaggeBlock.message}</Typography>
				</div>
			</Popover>
		</>
	);
}

export { BlockActivites };
export default BlockActivites;
