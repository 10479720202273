import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import moment from 'moment';

import { Theme, makeStyles, IconButton, Container, Grid, Paper } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { Activity, ActivityReactives } from '../Activity-interface';
import { ProgressExerciseActivity, StepProgress } from './ProgressExerciseActivity';
import { TypeExercise } from './TypeExercise';
import { EndActivity } from './End-Activity';
import TextReading from './Text-Reading';
import ImageExercise from './Image-Exercise';
import AudioExercise from './audio-exercise';
import InstructionExercise from './Instruction-Exercise';
import { GlobalTimeProvider } from '../../../providers/GlobalTimeContext';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(1),
	},
	progress: {
		display: 'flex',
	},
	activity: {
		flex: 1,
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
		maxHeight: 'calc(100vh - 80px)',
		overflow: 'hidden',
	},

	paper: {
		height: '100%',
		minHeight: '96vh',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(1),
		justifyContent: 'space-between',
	},
	topActivity: {},
	ocultar: {
		display: 'none',
	},
}));

export function Exercise({ activity }: { activity: Activity }) {
	const { reactives, example, typeActivity, type, unit, lesson, block, zoom, globalTime } =
		activity;
	const classes = useStyles();

	const [progress, setProgress] = useState<number>(0);
	const [dialogInstruction, setDialogInstrucction] = useState<string>(activity.instruction);

	const tmpType = type.substring(0, 2);
	const totalAttempts = tmpType === 'CR' || tmpType === 'Q' ? 1 : 100;
	const activaAnswer = tmpType !== 'CR' && tmpType !== 'Q';

	const [acerts, setAcerts] = useState<number>(0);
	const initCurrent = example ? 0 : 1;
	const [current, setCurrent] = useState<number>(0);
	const [currentStep, setCurrentStep] = useState<number>(initCurrent);
	const [globalTimeEnd, setGlobalTimeEnd] = useState<any>();
	const [stepsProgress, setStepsProgress] = useState<StepProgress[]>([]);
	const total = example ? reactives.length - 1 : reactives.length;
	const history = useHistory();
	const [instructionOpen, setInstructionOpen] = useState<boolean>(true);
	const updateStepProgress = (
		isCorrect: boolean,
		userAnswer: string[],
		question: string,
		media: string | string[],
		localMedia: string | string[],
		imagen: string | string[],
		activeAnswer: boolean
	) => {
		const newStepsProgress = [];
		for (const step of stepsProgress) {
			if (step.number === currentStep) {
				step.isCorrect = isCorrect;
				step.userAnswer = userAnswer;
				step.question = question;
				step.media = media;
				step.localMedia = localMedia;
				step.imagen = imagen;
				step.activeAnswer = activeAnswer;
				step.typeActivity = '';
			}
			newStepsProgress.push(step);
		}
		setStepsProgress(newStepsProgress);
	};

	const onNext = () => {
		setCurrent(current + 1);
		setCurrentStep(currentStep + 1);
		if (currentStep + 1 < total) {
			const { instruction } = reactives[current + 1];

			if (instruction && instruction !== dialogInstruction) {
				setDialogInstrucction(instruction);
				setInstructionOpen(true);
			}
		}
	};
	const onCheck = (
		isCorrect: boolean,
		userAnswer: string[],
		question: string,
		media: string | string[],
		localMedia: string | string[],
		imagen: string | string[],
		activeAnswer: boolean
	) => {
		updateStepProgress(isCorrect, userAnswer, question, media, localMedia, imagen, activeAnswer);
		if (isCorrect) {
			const correctAcerts = acerts + 1;
			setAcerts(correctAcerts);
			const totalProgress = (correctAcerts / total) * 100;
			setProgress(totalProgress);
		}
	};

	useEffect(() => {
		const createStepProgress = () => {
			if (stepsProgress.length <= 0) {
				const newStepsProgress: StepProgress[] = [];
				for (let i = 1; i <= total; i += 1) {
					newStepsProgress.push({
						number: i,
						media: '',
						localMedia: '',
						imagen: '',
						isCorrect: null,
						userAnswer: [],
						question: '',
						activeAnswer: activaAnswer,
						typeActivity: '',
					});
				}
				return newStepsProgress;
			}
			return stepsProgress;
		};
		if (stepsProgress.length <= 0) {
			const reactivestepsProgress = createStepProgress();
			setStepsProgress(reactivestepsProgress);
			if (globalTime > 0) {
				const endTime = moment().add(globalTime, 'seconds');

				setGlobalTimeEnd(endTime);
			} else {
				setGlobalTimeEnd(globalTime);
			}
		}
	}, [setStepsProgress, stepsProgress, activaAnswer, total, globalTime]);

	const imagenunica =
		activity.img !== null || activity.media !== null || activity.localMedia !== null;

	return (
		<>
			<Container className={classes.root} maxWidth='lg'>
				<Grid container justifyContent='center' spacing={1}>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<div className={classes.topActivity}>
								<div className={classes.progress}>
									<IconButton onClick={history.goBack}>
										<Close />
									</IconButton>
									<ProgressExerciseActivity steps={stepsProgress} current={currentStep} />
									<InstructionExercise
										instruction={dialogInstruction}
										openInstruction={() => {
											setInstructionOpen(!instructionOpen);
										}}
										open={instructionOpen}
									/>
								</div>

								{activity.text ? <TextReading data={activity.text} /> : null}
							</div>
							<GlobalTimeProvider endTime={globalTimeEnd} time={globalTime}>
								<div
									className={clsx(classes.activity, {
										[classes.ocultar]: currentStep > total,
									})}
								>
									{(activity.media || activity.localMedia) && currentStep <= total ? (
										<AudioExercise
											view='Circulo'
											media={activity.media}
											localMedia={activity.localMedia}
											totalAttempts={totalAttempts as number}
										/>
									) : null}
									{activity.img && currentStep <= total ? (
										<ImageExercise img={activity.img} alt='ImagenUnica' isZoom={zoom} />
									) : null}

									{currentStep <= total ? (
										<>
											{reactives?.map((reactive: ActivityReactives, index: number) => (
												<Fragment key={`reactives-${200 * index}`}>
													{current === index ? (
														<TypeExercise
															reactive={reactive}
															totalAttempts={totalAttempts as number}
															activaAnswer={activaAnswer as boolean}
															typeActivity={reactive.type.component ? reactive.type : typeActivity}
															isExample={example && index === 0}
															isImagenUnica={imagenunica}
															isZoom={zoom}
															answers={stepsProgress}
															onNext={onNext}
															onCheck={onCheck}
														/>
													) : null}
												</Fragment>
											))}
										</>
									) : null}
								</div>
							</GlobalTimeProvider>

							{currentStep > total ? (
								<EndActivity
									average={progress}
									type={type}
									unit={unit}
									lesson={lesson}
									block={block}
									activity={activity.numActivity}
									userAnswers={stepsProgress}
									typeActivity={typeActivity}
									reactive={reactives}
									imagenUnica={{ imgUnica: activity.img, zoom }}
									audioUnico={{
										media: activity.media,
										localMedia: activity.localMedia,
									}}
									ShowText={activity.text != null}
								/>
							) : null}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default Exercise;
