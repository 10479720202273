import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

import { makeStyles, Theme, Typography, IconButton, CircularProgress } from '@material-ui/core';

import MicOffIcon from '@material-ui/icons/MicNone';
import MicOnIcon from '@material-ui/icons/Mic';
import { Check, Close } from '@material-ui/icons';

import { CheckButtons } from '../Check-Buttons';
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx

import { ReactiveProps } from '../../Activity-interface';

import { usePeticiones } from '../../../../lib/use-Peticiones';
import { Detectmovil } from '../../../../lib/use-DetectMovil';

const MicRecorder = require('mic-recorder').default;

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},

	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},

	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		textAlign: 'center',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},
	questionLeft: {
		textAlign: 'left',
	},
	BtnMic: {
		textAlign: 'center',
		marginTop: theme.spacing(1),
		'& .MuiSvgIcon-root': {
			fontSize: '6.5rem',
		},
		'& .MuiIconButton-root.Mui-disabled': {
			color: theme.palette.grey[500],
			'& .MuiCircularProgress-colorPrimary': {
				color: theme.palette.grey[500],
			},
		},
	},
	respuesta: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		textAlign: 'center',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},

	root: {
		marginTop: theme.spacing(2),
		borderColor: theme.palette.primary.main,
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			transition: 'none',
			borderBottom: `2px solid ${theme.palette.primary.main}`,
		},
	},
	correct: {
		color: theme.palette.success.main,
		'& .MuiCircularProgress-root': {
			color: theme.palette.success.main,
		},
		'& .MuiIconButton-root.Mui-disabled': {
			color: theme.palette.success.main,
		},
	},
	incorrect: {
		color: theme.palette.error.light,
		'& .MuiCircularProgress-root': {
			color: theme.palette.error.light,
		},
		'& .MuiIconButton-root.Mui-disabled': {
			color: theme.palette.error.light,
		},
	},
	answer: {
		marginTop: theme.spacing(1),
	},
	adornment: {
		fontSize: '2rem',
	},
}));

const recorder = new MicRecorder({
	bitRate: 128,
	encoder: 'wav', // default is mp3, can be wav as well
	sampleRate: 44100, // default is 44100, it can also be set to 16000 and 8000.
});

function RecordOption(props: ReactiveProps) {
	const { reactive, isExample, typeActivity, isZoom, onNext, onCheck } = props;
	const { typeComponent } = typeActivity;
	const { question, answer, img, time } = reactive;
	const { getTextRecognition } = usePeticiones();
	const classes = useStyles();
	const [userAnswer, setUserAnswer] = useState('');

	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);

	const questionClean = question?.replace('|.', '').replace('|?', '');

	const timeRecording = 100;
	const [isBlocked, SetIsBlocked] = useState(false);
	// const [second, setSecond] = useState('00');
	// const [minute, setMinute] = useState('00');
	const [isActive, setIsActive] = useState(false);
	const [isProcess, setIsProcess] = useState(false);
	const [counter, setCounter] = useState(0);
	// const [blobAudio, setBlobAudio] = useState<any>('');

	const InitRecording = () => {
		setCounter(0);
		// setSecond('00');
		// setMinute('00');
		setIsProcess(false);
		setIsActive(false);
	};

	const startRecording = () => {
		recorder
			.start()
			.then(() => {
				setIsActive(true);
			})
			.catch((e: any) => {
				setIsActive(false);
			});
	};

	const stopRecording = () => {
		recorder
			.stop()
			.getAudio()
			.then(async ([buffer, blob]: any) => {
				const fileName = `${uuid()}.wav`;
				const fd = new FormData();
				const file = new File(buffer, fileName, {
					type: blob.type,
					lastModified: Date.now(),
				});

				const fileReader = new FileReader();

				fileReader.onload = async evt => {
					fd.append('file_tmp', file, fileName);
					try {
						const response = await getTextRecognition({
							accion: 'speech-recognition',
							formData: fd,
						});
						const result = await response;
						const { data } = result;
						setUserAnswer(data);
						setCheckDisabled(false);
						InitRecording();
					} catch (e) {
						InitRecording();
					}
				};

				fileReader.onerror = evt => {
					InitRecording();
				};

				fileReader.readAsDataURL(blob);
			})
			.catch((e: any) => {
				InitRecording();
			});
	};

	// eslint-disable-next-line
	const stopTimer = () => {
		setIsProcess(true);
		setIsActive(false);
		stopRecording();
	};

	const checkAnswer = () => {
		const correct = answer.includes(userAnswer);
		setIsCorrect(correct);
		setNextDisabled(false);
		setCheckDisabled(true);
	};
	const nextAnswer = () => {
		onNext();
		onCheck(isCorrect ?? false, [userAnswer], question, '', '', '', false);
	};
	const updateAnswer = () =>
		`<u style="min-width:120px"> ${userAnswer}${
			userAnswer ? '' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
		}</u>`;

	let answerActivity = '';

	if (typeComponent === 'question') {
		answerActivity = question;
	}

	const [mount, setMount] = useState<boolean>(true);

	useEffect(() => {
		if (mount) {
			setMount(false);
			navigator?.mediaDevices
				.getUserMedia({ audio: true })
				.then(() => SetIsBlocked(true))
				.catch(() => SetIsBlocked(false));
		}

		const interval = setInterval(() => {
			if (isActive) {
				/*
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1 ? `0${secondCounter}` : `${secondCounter}`;
        const computedMinute =
          String(minuteCounter).length === 1 ? `0${minuteCounter}` : `${minuteCounter}`;
        setSecond(computedSecond);
        setMinute(computedMinute);
        */
				setCounter(counter + 1);
				if (counter >= timeRecording && !isProcess) {
					stopTimer();
				}
			}
		}, 100);
		return () => clearInterval(interval);
	}, [isActive, isProcess, counter, stopTimer, mount]);

	return (
		<>
			<div className={classes.reactive}>
				{typeComponent === 'imagen' && questionClean ? (
					<ImageExercise img={img as string} alt={question} text={question} isZoom={isZoom} />
				) : null}
				{typeComponent !== 'imagen' && questionClean ? (
					<Typography
						component='div'
						className={clsx(classes.question, {
							[classes.questionLeft]: question.length >= 15,
						})}
					>
						<DivSanitized text={question?.replace('|', updateAnswer())} />
					</Typography>
				) : null}
				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}
				{isExample ? (
					<Typography
						component='div'
						className={clsx(classes.question, {
							[classes.questionLeft]: question.length >= 15,
							[classes.correct]: isCorrect,
							[classes.incorrect]: !isCorrect && isCorrect !== null,
						})}
					>
						<DivSanitized text={isExample ? answer[0] : userAnswer} />
					</Typography>
				) : null}
				<div
					className={clsx(classes.BtnMic, {
						[classes.correct]: isCorrect,
						[classes.incorrect]: !isCorrect && isCorrect !== null,
					})}
				>
					{Detectmovil() ? (
						<IconButton
							aria-label='Record-mic'
							color='primary'
							disabled={isCorrect !== null || isExample || !isBlocked}
							onTouchStart={() => {
								if (!isExample && !isActive && !isProcess) {
									startRecording();
								}
							}}
							onTouchEnd={() => {
								if (!isExample && !isProcess) {
									stopTimer();
								}
							}}
						>
							<CircularProgress
								style={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									top: '0px',
									left: '0px',
								}}
								variant={isProcess ? 'indeterminate' : 'determinate'}
								value={(counter / timeRecording) * 100}
							/>
							<>
								{isCorrect !== null ? (
									<>{isCorrect ? <Check /> : <Close />}</>
								) : (
									<>{isActive ? <MicOnIcon /> : <MicOffIcon />}</>
								)}
							</>
						</IconButton>
					) : (
						<IconButton
							aria-label='Record-mic'
							color='primary'
							disabled={isCorrect !== null || isExample || !isBlocked}
							onMouseUp={() => {
								if (!isExample && !isProcess) {
									stopTimer();
								}
							}}
							onMouseDown={() => {
								if (!isExample && !isActive && !isProcess) {
									startRecording();
								}
							}}
						>
							<CircularProgress
								style={{
									position: 'absolute',
									width: '100%',
									height: '100%',
									top: '0px',
									left: '0px',
								}}
								variant={isProcess ? 'indeterminate' : 'determinate'}
								value={(counter / timeRecording) * 100}
							/>
							<>
								{isCorrect !== null ? (
									<>{isCorrect ? <Check /> : <Close />}</>
								) : (
									<>{isActive ? <MicOnIcon /> : <MicOffIcon />}</>
								)}
							</>
						</IconButton>
					)}

					<div>{userAnswer}</div>
				</div>
				{answerActivity ? <DivSanitized className={classes.answer} text={answerActivity} /> : null}
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={false}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export { RecordOption };
export default RecordOption;
