import React from 'react';
import { useScrollTrigger, Zoom, Fab } from '@material-ui/core';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import useStyles from '../lib/use-Classes';

export interface ScrolltopProps {
	window?: () => Window | undefined;
	children?: React.ReactNode | undefined;
}

function Scrolltop({ children, window }: ScrolltopProps): JSX.Element {
	const classes = useStyles();

	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
			'#back-to-top-anchor'
		);

		if (anchor) {
			anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

	return (
		<>
			<Zoom in={trigger}>
				<div onClick={handleClick} role='presentation' className={classes.scrollTop}>
					<Fab color='primary' size='small' aria-label='scroll back to top'>
						<KeyboardArrowUpIcon />
					</Fab>
				</div>
			</Zoom>
		</>
	);
}

Scrolltop.defaultProps = {
	window: undefined,
	children: undefined,
};

export { Scrolltop };
export default Scrolltop;
