import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Typography, Link } from '@material-ui/core';
import { useStyles, useScreenSize } from '../lib';

function Copyright() {
	const classes = useStyles();

	const { windowDimensions } = useScreenSize();

	return (
		<>
			<Typography
				className={clsx(classes.Copyright, {
					[classes.CopyrightH]: windowDimensions.height <= 640,
				})}
				variant='body1'
				color='textSecondary'
				align='center'
			>
				{'Copyright © '}
				<Link color='inherit' href='https://winningwithenglish.com/'>
					Winning with english
				</Link>{' '}
				{new Date().getFullYear()}.
			</Typography>
		</>
	);
}

export { Copyright };
export default Copyright;
