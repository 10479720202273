import React from 'react';
import { v4 as uuid } from 'uuid';

import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Container,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { VolumeUp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		columnRuleColor: theme.palette.secondary.contrastText,
		background: theme.palette.secondary.main,
		fontWeight: 'bold',
		padding: `${theme.spacing() * 0.5}px`,
	},
	actions: {
		justifyContent: 'end',
		display: 'flex',
	},

	title: { fontSize: '15px !important' },
	content: { display: 'flex' },
}));

export function TeslProgress() {
	const classes = useStyles();

	return (
		<Container style={{ marginTop: '36px' }}>
			<Typography variant='h3' style={{ marginBottom: '36px' }} color='secondary'>
				Tesl{' '}
				<Button color='secondary' variant='contained'>
					Start
				</Button>
			</Typography>
			<Grid container spacing={2} justifyContent='space-between'>
				{['Listening', 'Writing', 'Reading', 'Speaking', 'Tesl Exam'].map(item => (
					<Grid item xs={12} md={3} key={uuid()}>
						<Card>
							<CardHeader
								className={classes.header}
								title={
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<b className={classes.title}>{item}</b>
										<div style={{ fontSize: '12px', marginLeft: '8px' }}>
											Studying time{' '}
											<Typography style={{ fontSize: '10px', display: 'inline-block' }}>
												48 h 25min
											</Typography>
										</div>
									</div>
								}
							/>
							<CardContent>
								<Grid container justifyContent='center'>
									<Grid item md={5}>
										<div style={{ position: 'relative', width: '90px', height: '90px' }}>
											<CircularProgress
												style={{
													width: '90px',
													height: '90px',
													color: '#ccc',
													position: 'absolute',
													left: '0px',
													top: '0px',
												}}
												value={100}
												variant='determinate'
											/>
											<div
												style={{
													position: 'absolute',
													width: '100%',
													height: '100%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<VolumeUp />
											</div>
											<CircularProgress
												style={{ width: '90px', height: '90px' }}
												value={80}
												color='secondary'
												variant='determinate'
											/>
										</div>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
export default TeslProgress;
