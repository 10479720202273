import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import Moment from 'react-moment';
import moment from 'moment';

import {
	Avatar,
	Divider,
	IconButton,
	InputBase,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Paper,
} from '@material-ui/core';

import { Close, FiberManualRecord, Send } from '@material-ui/icons';

import { IApp, Message, ChatUser } from './chat-interface';
import { AppContext } from '../../providers/AppContext';
import { usePeticiones } from '../../lib';

const useStyles = makeStyles(theme => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
	chatContainer: {
		display: 'flex',
		position: 'fixed',
		bottom: '0px',
		right: '0px',
		width: 'auto',
		zIndex: 9999,
		justifyContent: 'flex-end',
		marginRight: theme.spacing(2),
	},
	chat: {
		borderRadius: '8px 8px 0px  0px',
		border: theme.palette.grey[800],
		background: theme.palette.background.paper,
		boxShadow: '1px 1px 3px #000',
		marginRight: theme.spacing(1),
	},
	messages: {
		width: '250px',
		height: '250px',
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		overflow: 'auto',
		'&::-webkit-scrollbar ': {
			width: '5px;',
		},

		/* Track */
		'&::-webkit-scrollbar-track': {
			background: ' #f1f1f1;',
		},

		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: '#888;',
		},

		/* Handle on hover */
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#555',
		},
	},
	avatarContainer: { position: 'relative' },
	onlineIcon: {
		position: 'absolute',
		top: '-5px;',
		right: '8px;',
	},
	message: {
		background: theme.palette.divider,
		borderRadius: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		padding: theme.spacing(1),
	},
	me: {
		background: theme.palette.primary.light,
		borderRadius: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		padding: theme.spacing(1),
	},
}));

interface WindowChatProps {
	user: ChatUser;
}

export function WindowChat(props: WindowChatProps) {
	const { user } = props;
	const { room, open } = user;
	const classes = useStyles();
	const msgRefs = useRef<any>();
	const bottomRef = useRef<any>();
	// const [messages, setMessages] = useState<Message[] | null>(null);
	const [waitMessage, setWaitMessage] = useState<Message | null>(null);
	const {
		me,
		avatar,
		messages,
		closeWindow,
		updateNotification,
		socket,
		setMessages,
		updateMessage,
	} = useContext<IApp>(AppContext);
	const [update, setUpdate] = useState<boolean>(false);
	const { permisos, id } = me;
	const { peticion } = usePeticiones();

	const send = () => {
		msgRefs.current.focus();
		const msg: Message = {
			teacher: permisos === '1' ? id : user.id,
			user: permisos === '1' ? user.id : id,
			content: msgRefs.current.value,
			createdAt: moment.utc().format(),
			room: user.room,
			from: id,
			avatar,
			to: user.id,
		};
		addMessage(msg);

		if (!socket.connected) {
			socket.connect();
			setWaitMessage(msg);
		} else {
			socket.emit('private message', msg);
			// eslint-disable-next-line no-console
			msgRefs.current.value = '';
			updateMessage(room);
		}
	};

	const addMessage = useCallback(
		(msg: Message) => {
			const messsagesCopy: Message[] = messages;
			messsagesCopy.push(msg);
			setMessages(messsagesCopy);
			setUpdate(!update);
		},
		[messages, update, setMessages]
	);

	useEffect(() => {
		if (waitMessage && socket.connected) {
			socket.emit('private message', waitMessage);
			setWaitMessage(null);
		}

		if (messages && open && messages?.length > 0) {
			bottomRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		if (!messages) {
			setMessages([]);
		}
	}, [
		messages,
		open,
		peticion,
		room,
		socket,
		user,
		update,
		updateNotification,
		waitMessage,
		setMessages,
	]);

	return (
		<>
			{open ? (
				<div className={classes.chat}>
					<List style={{ paddingBottom: 0 }}>
						<ListItem button>
							<ListItemAvatar>
								<div className={classes.avatarContainer}>
									<Avatar alt={user.user} src={user.avatar ?? ''} />
									<FiberManualRecord
										className={classes.onlineIcon}
										color={user.online ? 'secondary' : 'disabled'}
									/>
								</div>
							</ListItemAvatar>
							<ListItemText primary={<>{user.user}</>} />
							<ListItemSecondaryAction>
								<IconButton edge='end' onClick={() => closeWindow(user)}>
									<Close />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					</List>
					<div className={classes.messages}>
						{messages ? (
							<>
								{messages
									?.filter(item => item.room === room)
									?.map((msg: Message, index: number) => (
										<div
											key={uuid()}
											style={{
												display: 'flex',
												alignItems: 'start',
												justifyContent: msg.from === id ? 'flex-start' : 'flex-end',
												marginBottom: '8px',
											}}
										>
											{msg.from === id ? (
												<Avatar style={{ width: '30px', height: '30px' }} src={avatar} />
											) : null}
											<div className={msg.from === id ? classes.message : classes.me}>
												{msg.content}

												<div
													style={{
														fontSize: '0.7rem',
														textAlign: msg.from === id ? 'right' : 'left',
													}}
												>
													<Moment format='hh:mm a' trim local>
														{msg.createdAt}
													</Moment>
												</div>
											</div>
											{msg.from !== id ? (
												<Avatar style={{ width: '30px', height: '30px' }} src={user.avatar} />
											) : null}
										</div>
									))}
							</>
						) : null}
						<div style={{ height: '35px', marginTop: '35px' }} ref={bottomRef} />
					</div>
					<Paper className={classes.root}>
						<InputBase
							disabled={waitMessage !== null}
							inputRef={msgRefs}
							className={classes.input}
							placeholder='Send Message'
							inputProps={{ 'aria-label': 'Send Message' }}
							onKeyPress={(e: any) => {
								if (e.charCode === 13 || e.code === 'Enter') {
									send();
								}
							}}
						/>
						<Divider className={classes.divider} orientation='vertical' />
						<IconButton
							className={classes.iconButton}
							onClick={send}
							disabled={waitMessage !== null}
						>
							<Send />
						</IconButton>
					</Paper>
				</div>
			) : null}
		</>
	);
}

export default WindowChat;
