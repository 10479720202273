import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
	TextField,
	makeStyles,
	Theme,
	Typography,
	FormControl,
	InputAdornment,
} from '@material-ui/core';
import { CheckCircle, Cancel as CancelIcon } from '@material-ui/icons';

import { CheckButtons } from '../Check-Buttons';
import { DivSanitized } from '../../../DivSanitized'; // agregar a index.tsx
import { ImageExercise } from '../Image-Exercise'; // agregar a index.tsx
import { AudioExercise } from '../audio-exercise';

import { ReactiveProps } from '../../Activity-interface';
import Options from './Options';

const useStyles = makeStyles((theme: Theme) => ({
	reactive: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));',
		marginBottom: 'auto',
		// No seleccionar Elementos
		userSelect: 'none',
		'-webkitTouchCallout': 'none',
		'-webkit-user-select': 'none',
		'-khtml-user-select': 'none',
		'-moz-user-select': 'none',
		'-ms-user-select': 'none',
		height: '74vh',
		maxHeight: '74vh',
		'&.is-ImagenUnica': {
			maxHeight: '40vh',
		},
		// Scroll Personalizado
		overflowX: 'hidden',
		overflow: 'auto',
		'&.scrollCss': {
			'&::-webkit-scrollbar': {
				width: '12px',
			},
			'&::-webkit-scrollbar-track': {
				background: theme.palette.grey[100],
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.primary.light,
				borderRadius: '20px',
				border: `3px solid ${theme.palette.grey[100]}`,
			},
		},
	},
	example: {
		marginTop: theme.spacing(1),
		fontFamily: 'inherit',
		fontWeight: 'bold',
		fontSize: 'calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));',
		lineHeight: '1.2',
	},
	question: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'normal',
		minHeight: '2em',
		marginTop: theme.spacing(1),
	},
	formControl: {
		display: 'block',
		marginTop: theme.spacing(1),
	},
	rootLine: {
		marginTop: theme.spacing(1),
		borderColor: theme.palette.primary.main,
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		'& .MuiInputBase-root': {
			fontSize: 'inherit',
			fontFamily: "'Lato', sans-serif;",
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
			transition: 'none',
			borderBottomStyle: 'dotted',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderColor: theme.palette.primary.main,
			borderBottomStyle: 'inset',
		},
		'& .MuiInputBase-root:not(.Mui-disabled)::before': {
			borderBottomStyle: 'inset',
		},
	},
	correct: {
		backgroundColor: '#4caf500d',
		'& .MuiInputBase-root,.MuiInputBase-root p': {
			color: theme.palette.success.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.success.main}`,
		},
	},
	incorrect: {
		backgroundColor: '#f443360d',
		'& .MuiInputBase-root, .MuiInputBase-root p': {
			color: theme.palette.error.main,
		},
		'& .MuiInputBase-root::before': {
			borderBottom: `2px solid ${theme.palette.error.main}`,
		},
	},
	adornment: {
		fontSize: '2rem',
	},
}));

export function WriteMultipleLineLabel(props: ReactiveProps) {
	const {
		reactive,
		isExample,
		isZoom,
		typeActivity,
		onNext,
		totalAttempts,
		activaAnswer,
		onCheck,
	} = props;
	const { typeComponent } = typeActivity;
	const { question, answer, img, reactives, media, localMedia, options, time } = reactive;
	const classes = useStyles();
	const [userAnswer, setUserAnswer] = useState<any[]>([]);
	const [multipleAnswer, setMultipleAnswer] = useState<string[][]>([]);
	const [correctAnswer, setCorrectAnswer] = useState<string[]>([]);

	const [isCorrect, setIsCorrect] = useState<null | boolean>(null);
	const [checkDisabled, setCheckDisabled] = useState<boolean>(true);
	const [mount, setMount] = useState<boolean>(false);
	const [nextDisabled, setNextDisabled] = useState<boolean>(!isExample);

	const keyInput: any = reactives.reduce((arr: string[], b, index) => {
		arr.push(`inputs-keys-${index.toString()}`);
		return arr;
	}, []);

	const checkAnswer = () => {
		const multiAnswers = multipleAnswer;
		const inputAnswer = userAnswer;
		let correctAnswersNumber = 0;

		for (const ia of inputAnswer) {
			let indexMultiAnswer = 0;
			ia.isCorrect = false;
			for (const ma of multiAnswers) {
				const correct = ma.includes(ia.value);
				if (correct) {
					correctAnswersNumber += 1;
					ia.isCorrect = correct;
					multiAnswers.splice(indexMultiAnswer, 1);
				}
				indexMultiAnswer += 1;
			}
		}

		setUserAnswer(inputAnswer);

		const isTrue = correctAnswersNumber === inputAnswer.length;

		setIsCorrect(isTrue);
		setNextDisabled(false);
		setCheckDisabled(true);
	};

	const nextAnswer = () => {
		onNext();
		const anwers = userAnswer.reduce((array, b, index) => {
			array.push(
				`<b>${
					typeComponent === 'signo'
						? reactives[index].substring(0, reactives[index].length - 1)
						: reactives[index]
				}    
        </b>
        <span>
        <u class='${b.isCorrect ? 'correct' : 'incorrect'}'>${
					typeComponent === 'signo'
						? b.value + reactives[index].substring(reactives[index].length - 1)
						: b.value
				}<u/>
        `
			);
			return array;
		}, []);
		onCheck(isCorrect ?? false, anwers, question, media, localMedia, img, activaAnswer);
	};
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const answerInput: any = userAnswer;
		answerInput[index] = { value: `${e.currentTarget.value}`, isCorrect: null };
		setUserAnswer(answerInput);
		let length = 0;
		for (const item of userAnswer) {
			if (item.value) {
				length += 1;
			}
		}
		if (multipleAnswer.length === length) {
			setCheckDisabled(false);
		} else {
			setCheckDisabled(true);
		}
	};
	// eslint-disable-next-line
	console.log(reactives);
	useEffect(() => {
		const createMultipleAnswers = () => {
			const answers = answer.reduce((array: any[], b: string) => {
				const a = b.split('=');
				array.push(a);
				return array;
			}, []);
			setMultipleAnswer(answers);
		};
		if (!mount) {
			createMultipleAnswers();
			setMount(true);
		}

		if (mount && multipleAnswer.length > 0 && correctAnswer.length === 0) {
			const ca = multipleAnswer.reduce((array: any, item: any, index: number) => {
				array.push(`${reactives[index]}: ${item[0]}`);
				return array;
			}, []);
			setCorrectAnswer(ca);
		}
	}, [mount, setMount, reactives, answer, correctAnswer, multipleAnswer]);

	return (
		<>
			<div className={classes.reactive}>
				{options ? <Options options={options} /> : null}
				<AudioExercise
					view='Circulo'
					media={media as string}
					localMedia={localMedia as string}
					totalAttempts={totalAttempts}
				/>
				<ImageExercise img={img as string} alt={question} isZoom={isZoom} />
				{isExample ? (
					<Typography component='div' color='primary' variant='h6' className={classes.example}>
						Example:
					</Typography>
				) : null}
				{question ? (
					<Typography component='div' className={classes.question}>
						<DivSanitized text={question} />
					</Typography>
				) : null}

				{mount ? (
					<>
						<FormControl className={classes.formControl}>
							{reactives.map((reactiveInput, index) => (
								<TextField
									key={keyInput[index]}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												{userAnswer[index]?.isCorrect || isExample ? <CheckCircle /> : null}
												{!userAnswer[index]?.isCorrect && isCorrect !== null ? (
													<CancelIcon />
												) : null}
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end' className={classes.adornment}>
												<b>
													{typeComponent === 'signo'
														? reactiveInput.substring(reactiveInput.length - 1)
														: ''}
												</b>
											</InputAdornment>
										),
									}}
									fullWidth
									variant='standard'
									label={
										<>
											{typeComponent === 'signo'
												? reactiveInput.substring(0, reactiveInput.length - 1)
												: reactiveInput}
										</>
									}
									className={clsx(classes.rootLine, {
										[classes.correct]: userAnswer[index]?.isCorrect,
										[classes.incorrect]: userAnswer[index]?.isCorrect === false,
									})}
									disabled={
										isExample ||
										userAnswer[index]?.isCorrect === true ||
										userAnswer[index]?.isCorrect === false
									}
									defaultValue={isExample ? multipleAnswer[index][0] : userAnswer[index]?.value}
									type='text'
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										handleChange(e, index);
									}}
								/>
							))}
						</FormControl>
					</>
				) : null}
			</div>
			<CheckButtons
				answer={answer}
				time={time}
				activaAnswer={activaAnswer}
				typeActivity={typeActivity}
				isCorrect={isCorrect}
				isExample={isExample}
				checkDisabled={checkDisabled}
				nextDisabled={nextDisabled}
				checkAnswer={checkAnswer}
				nextAnswer={nextAnswer}
			/>
		</>
	);
}

export default WriteMultipleLineLabel;
