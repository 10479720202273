import React from 'react';
import {
	Button,
	TextField,
	ListItem,
	List,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core';
import { usePeticiones } from '../lib';

export interface iCalendarProps {
	// eslint-disable-next-line react/no-unused-prop-types
	url: string;
	// eslint-disable-next-line react/no-unused-prop-types
	file: string;
}
export function GenerateGoogleEvent(props: any) {
	const { peticion } = usePeticiones();
	const [month, setMonth] = React.useState<string>('');
	const [year, setYear] = React.useState<string>('');
	const [calendar, setCaledar] = React.useState<iCalendarProps[]>([]);

	const generateMonth = async () => {
		// eslint-disable-next-line no-console
		console.log(`/virtual-class-admin/add-event/${month}/${year}`);
		const result: { data: iCalendarProps[] } = await peticion({
			accion: `/virtual-class-admin/add-event/${month}/${year}`,
			formData: {},
		});
		const { data } = result;
		setCaledar(data);
		// eslint-disable-next-line no-console
	};

	const handleChangeMonth = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setMonth(event.target.value);
	};
	const handleChangeYear = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setYear(event.target.value);
	};
	return (
		<>
			<TextField onChange={handleChangeMonth} label='month' />
			<TextField onChange={handleChangeYear} label='year' />
			<div>
				<Button onClick={generateMonth} variant='contained' color='secondary'>
					Generar Eventos Mensuales
				</Button>
			</div>
			<List>
				{calendar.map(({ file, url }: iCalendarProps) => (
					<ListItem>
						<ListItemText primary={file} />
						<ListItemSecondaryAction>
							<Button size='small' variant='contained' href={url} color='primary'>
								download
							</Button>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</>
	);
}

export default GenerateGoogleEvent;
