import React, { useState } from 'react';
import { Button, IconButton, TextField, Snackbar } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogSimple } from '../../Dialog-Simple';
import { usePeticiones } from '../../../lib/index';

export function Alert(props: AlertProps) {
	const { severity, onClose, children } = props;
	return (
		<MuiAlert elevation={6} variant='filled' severity={severity} onClose={onClose}>
			{children}
		</MuiAlert>
	);
}
interface AddComponentProps {
	onChange: (component: any) => void;
}
export function AddComponent({ onChange }: AddComponentProps) {
	const [newComponent, setNewComponent] = useState<string | null>('');
	const [response, setResponse] = useState<any>({ msg: null, open: null, type: null });
	const [openDialog, setOpenDialog] = useState(false);
	const handleOpen = () => setOpenDialog(true);
	const handleClose = () => setOpenDialog(false);
	const closeToastBar = () => {
		setResponse({ msg: null, open: null, type: null });
	};
	const { peticion } = usePeticiones();

	const saveComponent = async () => {
		try {
			const result = await peticion({
				accion: 'proccess-activity/add-component',
				formData: { component: newComponent },
			});

			onChange({ id: result.data.id, component: newComponent as string });
			setResponse({ msg: 'success', open: true, type: 'success' });

			setTimeout(() => {
				setOpenDialog(false);
				closeToastBar();
			}, 500);
		} catch (error) {
			setResponse({ msg: 'error', open: true, type: 'error' });
		}
	};
	return (
		<>
			<IconButton onClick={handleOpen}>
				<AddCircle />
			</IconButton>
			<DialogSimple
				title='Agregar componente'
				handleClose={handleClose}
				open={openDialog}
				action={
					<>
						<Button onClick={handleClose} color='primary' autoFocus>
							cancelar
						</Button>
						<Button onClick={saveComponent} variant='contained' color='primary' autoFocus>
							Guardar
						</Button>
					</>
				}
			>
				<TextField fullWidth label='Component' onChange={e => setNewComponent(e.target.value)} />
				{response.open ? (
					<>
						<Snackbar open={response.msg !== null} autoHideDuration={2500} onClose={closeToastBar}>
							<Alert onClose={closeToastBar} severity={response.type}>
								{response.msg}
							</Alert>
						</Snackbar>
					</>
				) : null}
			</DialogSimple>
		</>
	);
}

export default AddComponent;
