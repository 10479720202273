import React, { useEffect, useState } from 'react';
import { Button, IconButton, makeStyles, Theme, Modal, Box } from '@material-ui/core';
import {
	ZoomIn,
	ZoomOut,
	AspectRatio,
	FullscreenExit,
	Rotate90DegreesCcw,
} from '@material-ui/icons';

import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import clsx from 'clsx';
import { usePeticiones } from '../../../lib';

const useStyles = makeStyles((theme: Theme) => ({
	imageContainer: {
		position: 'relative',
		overflow: 'hidden',
		minWidth: '133px',
		maxWidth: '307px',
		margin: '0 auto',
		// marginBottom: '0.7em',
		background: '#fff',
		textAlign: 'center',
		padding: theme.spacing(1 / 5),
		userSelect: 'none',
		border: `solid 2px ${theme.palette.primary.main}`,
		'& > img': {
			width: '100%',
			display: 'block',
		},
	},
	text: {
		fontFamily: "'Lato', sans-serif;",
		fontSize: 'calc(130px + (138 - 130) * ((100vw - 320px) / (1280 - 320)))',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
	},
	zoomContainer: {
		position: 'relative',
		display: 'flex',
		[theme.breakpoints.up('sm')]: {
			maxHeight: '200px',
		},
		// overflow: 'hidden',
		minWidth: '133px',
		maxWidth: '307px',
		border: `solid 2px ${theme.palette.primary.main}`,
		padding: theme.spacing(1 / 4),
		margin: '0 auto',
		'& .box-zoom': {
			border: 'unset',
			'& button.MuiButton-contained': {
				backgroundColor: '#e0e0e096 !important',
				'&:hover': {
					backgroundColor: `#0000008f !important`,
					'& svg': {
						color: theme.palette.common.white,
					},
				},
				'& .MuiSvgIcon-root': {
					width: '1.5em',
					height: '1.5em',
				},
			},
		},
		'& .imagen': {
			width: '100%',
		},
	},
	contenidoCenter: {
		justifyContent: 'center',
	},
	margenImagen: {
		marginBottom: '1.5em',
	},
	zoom: {
		position: 'absolute',
		bottom: '10px',
		zIndex: 3,
		padding: '5px',
	},
	zoomFull: {
		position: 'absolute',
		zIndex: 3,
		padding: '5px',
	},
	in: {
		left: '-1px',
		borderRadius: '0 20px 20px 0 ',
	},
	out: {
		right: '-1px',
		borderRadius: ' 20px 0 0  20px',
	},
	full: {
		top: '5px',
		right: '5px',
		backgroundColor: '#e0e0e096 !important',
		'&:hover': {
			backgroundColor: `#0000008f !important`,
			'& svg': {
				color: theme.palette.common.white,
			},
		},
	},
	fullExit: {
		top: '2.5%',
		right: '2.5%',
		'&.girar': {
			top: '9.5%',
			'& svg': {
				transform: 'scaleX(-1)',
			},
			'&.GirarHV': {
				'& svg': {
					transform: 'scaleX(1)',
				},
			},
		},
		backgroundColor: '#e0e0e096 !important',
		'&:hover': {
			backgroundColor: `#0000008f !important`,
			'& svg': {
				color: theme.palette.common.white,
			},
		},
	},
	containerFullZoom: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		'& Img': {
			width: 'calc(85vw)',
			border: `5px solid ${theme.palette.common.white}`,
			'&.Girar': {
				height: 'calc(90vw)',
				width: 'unset',
				transform: 'rotate(90deg)',
			},
		},
	},
	zoomPrimario: {
		overflow: 'hidden',
	},
}));

export interface ImageExerciseProps {
	img: string;
	alt: string;
	text?: string;
	mImagen?: boolean;
	isZoom: boolean;
}

function ImageExercise({ img, alt, text, mImagen, isZoom }: ImageExerciseProps) {
	const classes = useStyles();
	const { peticionLocalAsync } = usePeticiones();
	const [image, setImage] = useState<string>(img);
	const [mount, setMount] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [zoomGirar, setZoomGirar] = React.useState(false);

	const clearMargenImagen = mImagen;

	useEffect(() => {
		const fetchdata = async (info: any) => {
			const imageBlob: any = await peticionLocalAsync(info);
			if (!imageBlob) {
				setImage('/imagen/logo.png');
			}
		};

		if (!mount && img) {
			setMount(true);
			fetchdata(img);
		}

		return () => {
			setMount(true);
		};
	}, [mount, img, peticionLocalAsync]);

	const handleClose = () => setOpen(!open);
	const handleGirar = () => setZoomGirar(!zoomGirar);

	const fullImagen = (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='modal-full-imagen'
			aria-describedby='modal-full-rotar'
		>
			<Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
				<Box className={clsx(classes.containerFullZoom)}>
					<img src={image} alt={alt} className={clsx('imagen', { Girar: zoomGirar })} />
				</Box>
				<IconButton className={clsx([classes.zoomFull, classes.fullExit])} onClick={handleClose}>
					<FullscreenExit color='primary' fontSize='large' />
				</IconButton>
				<IconButton
					className={clsx(classes.zoomFull, classes.fullExit, 'girar', { GirarHV: zoomGirar })}
					onClick={handleGirar}
				>
					<Rotate90DegreesCcw color='primary' fontSize='large' />
				</IconButton>
			</Box>
		</Modal>
	);

	return (
		<>
			{img ? (
				<>
					{isZoom ? (
						<div
							className={clsx(['ImageExercise', classes.zoomContainer], {
								[classes.margenImagen]: clearMargenImagen,
							})}
						>
							<div className={clsx('zoom', classes.zoomPrimario)}>
								<div className={clsx([classes.imageContainer, 'box-zoom'])}>
									<TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
										{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
											<>
												<IconButton
													onClick={handleClose}
													className={clsx([classes.zoomFull, classes.full])}
												>
													<AspectRatio color='primary' />
												</IconButton>
												<Button
													variant='contained'
													color='default'
													onClick={() => zoomOut()}
													className={clsx([classes.zoom, classes.in])}
												>
													<ZoomOut color='primary' />
												</Button>
												<Button
													variant='contained'
													color='default'
													onClick={() => zoomIn()}
													className={clsx([classes.zoom, classes.out])}
												>
													<ZoomIn color='primary' />
												</Button>
												<TransformComponent>
													<img src={image} alt={alt} className='imagen' />
												</TransformComponent>
											</>
										)}
									</TransformWrapper>
								</div>
							</div>
							{fullImagen}
						</div>
					) : (
						<div
							className={clsx(['ImageExercise', classes.zoomContainer], {
								[classes.margenImagen]: clearMargenImagen,
							})}
						>
							<img src={image} alt={alt} className='imagen' />
						</div>
					)}
				</>
			) : null}
			{text ? (
				<div
					className={clsx(['ImageExercise', classes.zoomContainer, classes.contenidoCenter], {
						[classes.margenImagen]: clearMargenImagen,
					})}
				>
					<div className={classes.text}>{text}</div>
				</div>
			) : null}
		</>
	);
}

ImageExercise.defaultProps = {
	text: null,
	mImagen: true,
};

export { ImageExercise };
export default ImageExercise;
