import React from 'react';
import IndexedDBProvider from 'use-indexeddb';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core/';
import { useThemeconfig } from './lib';
import { RootRedirect, PrivateRoute } from './components';

import { AppProvider } from './providers/AppContext';

import {
	Pagelogin,
	Pageregister,
	Pagerecovery,
	Pageactivate,
	Pageresetpassword,
	Pagehome,
	Pageunit,
	PageActivity,
	Pagedictionary,
	PageCalendar,
	GenerateGoogleEvent,
} from './pages';
import Admin from './components/Activity-Conten/Admin/Admin';
import PageDashboard from './pages/Page-Dashboard';

const idbConfig = {
	databaseName: 'Content-db',
	version: 1,
	stores: [
		{
			name: 'Content',
			id: { keyPath: 'id', autoIncrement: true },
			indices: [{ name: 'keysearch', keyPath: 'keysearch', options: { unique: false } }],
		},
		{
			name: 'Control',
			id: { keyPath: 'id', autoIncrement: true },
			indices: [{ name: 'unit', keyPath: 'unit', options: { unique: true } }],
		},
		{
			name: 'AvanceOff',
			id: { keyPath: 'id', autoIncrement: true },
			indices: [{ name: 'idprograma', keyPath: 'idprograma', options: { unique: false } }],
		},
	],
};

function App() {
	const { theme, toggleTheme, modoOscuro } = useThemeconfig();

	return (
		<>
			<AppProvider>
				<IndexedDBProvider config={idbConfig}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<BrowserRouter>
							<Switch>
								<Route exact path='/'>
									<RootRedirect />
								</Route>
								<PrivateRoute path='/home'>
									<Pagehome modeTheme={modoOscuro} changeTheme={toggleTheme} />
								</PrivateRoute>

								<PrivateRoute path='/unit/:unity/:name'>
									<Pageunit modeTheme={modoOscuro} changeTheme={toggleTheme} />
								</PrivateRoute>

								<PrivateRoute path='/activity/:idActivity/:activity'>
									<PageActivity />
								</PrivateRoute>

								<PrivateRoute path='/dashboard'>
									<PageDashboard modeTheme={modoOscuro} changeTheme={toggleTheme} />
								</PrivateRoute>
								<PrivateRoute path='/admin'>
									<Admin />
								</PrivateRoute>
								<PrivateRoute path='/generate-google-calendar'>
									<GenerateGoogleEvent />
								</PrivateRoute>
								<PrivateRoute path='/dictionary/'>
									<Pagedictionary modeTheme={modoOscuro} changeTheme={toggleTheme} />
								</PrivateRoute>
								<PrivateRoute path='/class-calendar/'>
									<PageCalendar modeTheme={modoOscuro} changeTheme={toggleTheme} />
								</PrivateRoute>

								<Route exact path='/reset-password/:token/'>
									<Pageresetpassword />
								</Route>
								<Route exact path='/activate/:token/'>
									<Pageactivate />
								</Route>
								<Route exact path='/activate/'>
									<Pageactivate />
								</Route>
								<Route exact path='/recovery'>
									<Pagerecovery />
								</Route>
								<Route exact path='/register'>
									<Pageregister />
								</Route>
								<Route exact path='/signin'>
									<Pagelogin />
								</Route>
								<Route>
									<div> Error</div>
								</Route>
							</Switch>
						</BrowserRouter>
					</ThemeProvider>
				</IndexedDBProvider>
			</AppProvider>
		</>
	);
}

export default App;
