import React from 'react';
import { IconButton } from '@material-ui/core';
import { LiveHelp } from '@material-ui/icons';
import DialogSimple from '../../Dialog-Simple';

interface Iinstruction {
	instruction: string;
	open: boolean;
	openInstruction: () => void;
}
export function InstructionExercise(props: Iinstruction) {
	const { instruction, open, openInstruction } = props;

	return (
		<>
			<DialogSimple
				content={instruction}
				open={open}
				handleClose={openInstruction}
				title='Instruction'
			/>
			<IconButton onClick={openInstruction}>
				<LiveHelp />
			</IconButton>
		</>
	);
}

export default InstructionExercise;
